import { Box, Flex, FlexProps, Text, Icon } from "@chakra-ui/react";
import { MdSwapHorizontalCircle, MdWarning } from "react-icons/md";

interface IScoreMessage extends FlexProps {
  isSuccessBased?: boolean;
  successTheme?: boolean;
  showBorder?: boolean;
  heading: string;
  iconSlot?: React.ReactNode;
  children: React.ReactNode;
  [x: string]: any;
}

export const scoresMessageIconStyle = {
  color: "currentColor",
  mr: "8px",
};

export const ScoreMessage = ({
  isSuccessBased,
  successTheme,
  showBorder,
  bg,
  color,
  heading,
  iconSlot,
  children,
  ...props
}: IScoreMessage) => {
  const successThemeColor = successTheme ? "form.good" : "form.bad";
  const successThemeBg = successTheme ? "form.goodTint" : "form.badTint";

  return (
    <Flex
      bg={isSuccessBased ? successThemeBg : bg}
      border={showBorder ? "1px solid" : undefined}
      borderColor={showBorder ? color : undefined}
      // borderRadius={showBorder ? "lg" : undefined}
      borderRadius="md"
      color={isSuccessBased ? successThemeColor : color}
      py="10px"
      px="10px"
      w="full"
      // allow borderRadius none when used on final Scorecard
      {...props}
    >
      {/* isSuccessBased uses predefined icons */}
      {isSuccessBased && (
        <>
          {successTheme ? (
            <Icon
              boxSize="1em"
              as={MdSwapHorizontalCircle}
              {...scoresMessageIconStyle}
            />
          ) : (
            <Icon as={MdWarning} boxSize="0.8em" {...scoresMessageIconStyle} />
          )}
        </>
      )}

      {/* !isSuccessBased can use an iconSlot */}
      {iconSlot}

      <Box>
        <Text as="div" textStyle="crumb">
          {heading}
        </Text>
        <Text
          as="div"
          // color="currentColor"
          textStyle="meta"
          fontWeight="400"
          pt={1}
        >
          {children}
        </Text>
      </Box>
    </Flex>
  );
};

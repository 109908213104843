import { Box, Flex, HStack, StackDivider, Text } from "@chakra-ui/react";
import { HoleCircle } from "./index";
import { TScorecardVariant, EScorecardVariant } from "types";

/*
  ScorecardRow is only used in ScorecardRowModal

  We add scores and icons in the augmented `ScorecardRowFor*` components
  so that we can use different scores & icons for different variants

  We pass variant & matchType as props so that ScorecardRow is not dependent on using useScorecardContext
 */

interface IScorecardRow {
  children: React.ReactNode;
  distance: string | number;
  holeNumber: number;
  leftSlot: React.ReactNode;
  onClick?: () => void | undefined;
  par: number;
  strokeIndex: string | number;
  variant: TScorecardVariant;
}

export const ScorecardRowBase = ({
  children,
  distance,
  holeNumber,
  leftSlot,
  onClick,
  par,
  strokeIndex,
  variant,
}: IScorecardRow) => {
  return (
    <Box
      as={variant === EScorecardVariant.scoring ? "button" : "div"}
      d="flex"
      alignItems="center"
      justifyContent="space-between"
      w="full"
      h={{ base: "48px", lg: "54px" }}
      borderBottom="1px"
      borderColor="primary.100"
      onClick={onClick}
      cursor={variant !== EScorecardVariant.leaderboard ? "pointer" : "default"}
    >
      {/* LEFT */}
      <Flex align="center">
        <HoleCircle holeNumber={holeNumber} />
        <Text
          as="div"
          textStyle="meta"
          fontWeight="500"
          textAlign="left"
          ml={3}
          mr={1}
          w="36px"
        >
          Par {par}
        </Text>
        <HStack divider={<StackDivider borderColor="grey.100" mx={0} />}>
          <Text
            as="div"
            textStyle="meta"
            color="grey.400"
            textAlign="left"
            w={{ base: "32px", lg: "40px" }}
          >
            {distance}m
          </Text>
          <Text as="div" textStyle="meta" color="grey.400">
            {strokeIndex} index
          </Text>
        </HStack>

        {leftSlot}
      </Flex>

      {/* RIGHT */}
      <Flex align="center">{children}</Flex>
    </Box>
  );
};

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { LgModalCloseButton } from "components/Block";
import { Settings } from "components/ClubCompSettings";
import { SettingsIcon } from "@chakra-ui/icons";
import { ICompetition } from "types";

export const SettingsModal = ({
  comp,
  onCompetitionUpdate,
}: {
  comp: ICompetition;
  onCompetitionUpdate: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        color="white"
        fontWeight="500"
        iconSpacing="6px"
        size="sm"
        textStyle="crumb"
        variant="ghostInverse"
        _hover={{
          bg: "blackAlpha.500",
        }}
        rightIcon={<SettingsIcon />}
        onClick={onOpen}
      >
        Settings
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="scale"
        scrollBehavior="inside"
      >
        <ModalOverlay bg="rgb(0 0 0 / 40%)" />
        <ModalContent
          alignItems="center"
          borderRadius="md"
          maxW="container.lg"
          minH="85vh"
          mt="12vh"
          mb="4vh"
          mx="5vw"
          overflow="hidden"
          shadow="none"
        >
          <LgModalCloseButton />
          <ModalBody p={0} w="full">
            <Settings
              comp={comp}
              onParentClose={onClose}
              onCompetitionUpdate={onCompetitionUpdate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

import { TableHeadingButton } from "../TableHeadingButton";
import { ScoreShell } from "./RowElements";
import { matchSortByCompToLabel } from "lib";

export const TableHeaderLabel = ({
  label,
  onClick,
  sortBy,
}: {
  label: string;
  onClick?: (label: string) => void;
  sortBy?: string;
}) => {
  return (
    <ScoreShell showEdge>
      <TableHeadingButton
        mx="auto"
        onClick={onClick}
        fontWeight={
          sortBy && matchSortByCompToLabel(sortBy) === label ? "700" : "500"
        }
        color={
          sortBy && matchSortByCompToLabel(sortBy) === label
            ? "text.800"
            : "text.600"
        }
      >
        {label}
      </TableHeadingButton>
    </ScoreShell>
  );
};

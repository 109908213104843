const typography = {
  fontSizes: {
    xxs: "9px",
    xs: "10px",
    sm: "11px",
    meta: "12px",
    rg: "13px",
    base: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "22px",
    "3xl": "25px",
    "4xl": "32px",
    "5xl": "36px",
    "6xl": "64px",
    "7xl": "88px",
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    shortest: "1.125",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
    taller: "2",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.01em",
    close: "-0.008em",
    normal: "0",
    body: "0.01em",
    wide: "0.0333em",
    wider: "0.06em",
    widest: "0.11em",
  },
  fonts: {
    heading:
      "Heading, Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    // Inter,
    body:
      "Heading, Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    mono:
      "SFMono-Regular,Menlo,Monaco,Consolas,'Liberation Mono','Courier New',monospace",
  },
};

export default typography;

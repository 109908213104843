import * as React from "react";
import { IMatchForContext } from "types";

/*
  This Context component is built to accept a match object
  in the Scorecard/Scorecard component.
  Pattern from: https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */

const ScorecardContext = React.createContext<IMatchForContext | undefined>(
  undefined
);

interface IScorecardProvider {
  children: React.ReactNode;
  value: IMatchForContext;
}

function ScorecardProvider({ children, value }: IScorecardProvider) {
  return (
    <ScorecardContext.Provider value={value}>
      {children}
    </ScorecardContext.Provider>
  );
}

function useScorecardContext() {
  const context = React.useContext(ScorecardContext);
  if (context === undefined) {
    throw new Error(
      "useScorecardContext must be used within a ScorecardProvider"
    );
  }
  return context;
}

export { ScorecardProvider, useScorecardContext };

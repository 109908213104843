import { ScoreMessage } from "./index";

// Set error messages for the returned error codes here
function mapApiErrorToMessage(errorCode, errorToMessageMap) {
  return errorToMessageMap[errorCode] || errorToMessageMap.default;
}

export const ScoreMessageError = ({
  errorCode,
  messageMap,
}: {
  errorCode: any;
  messageMap: any;
}) => {
  const error = mapApiErrorToMessage(errorCode, messageMap);

  let heading;
  let children;

  if (typeof error === "string") {
    heading = error;
  } else {
    heading = error.primary;
    children = error.secondary;
  }

  return (
    <ScoreMessage isSuccessBased heading={heading}>
      {children}
    </ScoreMessage>
  );
};

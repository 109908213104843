import {
  HStack,
  Box,
  Flex,
  Text,
  VStack,
  Link,
  Icon,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { BlockLoading } from "components/Block2";
import { CardHeaderShell, CardPlayer, TextMetaCard } from "./index";
import { MdCheckCircle } from "react-icons/md";
// import { convertToDisplayText } from "lib/comp";
import { ICompCardWithPlayers } from "types";

export const Card = ({ comp }: { comp: ICompCardWithPlayers }) => {
  const players = comp.players;
  const previewNumber = 7;
  const previewPlayers = players.slice(0, previewNumber);
  const [breakpoint] = useMediaQuery("(min-width: 1024px)");

  if (!comp) return <BlockLoading />;

  return (
    <Link
      as={ReactRouterLink}
      d="block"
      to={`/clubhouse/competitions/${comp.id}`}
    >
      <CardHeaderShell
        id={comp.id}
        finishedSlot={
          comp.isFinished && (
            <HStack
              pos="absolute"
              top={0}
              left={0}
              w="full"
              bg="text.900"
              color="orange.500"
              px={3}
              py={2}
              spacing={1.5}
            >
              <Icon as={MdCheckCircle} boxSize="0.9em" />
              <Text as="div" textStyle="metaButton" fontWeight="500">
                Competition finished
              </Text>
            </HStack>
          )
        }
      >
        <>
          {/* LEFT SLOT: HEADER */}
          <VStack align="flex-start" spacing={1}>
            <Text as="h2" textStyle="hTertiary" color="white" pl="2px" pb="1px">
              {comp.name}
            </Text>
            <Flex
              textStyle="lg"
              fontSize={{ base: "14px", lg: "17px", xl: "19px" }}
              color="white"
            >
              {/* TUrn this back on when comps are more than just eclectic */}
              {/* {convertToDisplayText(comp.type)} competition at{" "} */}
              {comp.course?.clubName}
              {comp.course?.courseName && (
                <Box pl={1.5} opacity={0.7}>
                  {comp.course?.courseName}
                </Box>
              )}
            </Flex>
          </VStack>

          {/* RIGHT SLOT: PLAYERS */}
          {breakpoint && (
            <VStack
              d={{ base: "none", lg: "flex" }}
              spacing="3px"
              alignItems="flex-start"
              // minW={{ lg: "30%" }}
            >
              {previewPlayers.map((player: any, index: number) => (
                <CardPlayer key={index.toString()} player={player} />
                /* UI TEST */
                // <>
                //   <CardPlayer key={index.toString()} player={player} />
                //   <CardPlayer key={index.toString()} player={player} />
                //   <CardPlayer key={index.toString()} player={player} />
                //   <CardPlayer key={index.toString()} player={player} />
                // </>
              ))}
              {players && players?.length > previewNumber && (
                <TextMetaCard isQuiet pt="2px">
                  …and {players?.length - previewNumber} others
                </TextMetaCard>
              )}
            </VStack>
          )}
        </>
      </CardHeaderShell>
    </Link>
  );
};

import { Text } from "@chakra-ui/react";
import { LayoutClubhouse } from "components/Layout";
import { Leaderboard } from "components/ClubTable";
import { SponsorInScore } from "components/Sponsor";
import { BlockStack } from "components/Block2";
import { UserProvider } from "components/Context";
import { useGetDailyResults } from "lib/api";
import { useSponsorClubhouse } from "hooks";

export const ClubhouseLeaderboard = () => {
  const { sponsor, defaultSponsor } = useSponsorClubhouse();

  return (
    <UserProvider>
      <LayoutClubhouse
        sponsorSlot={<SponsorInScore sponsor={sponsor || defaultSponsor} />}
      >
        <BlockStack pb="w11">
          <Leaderboard
            noRoundsMessage={
              <>
                <Text as="span" fontWeight="500">
                  No verified rounds on this course today.
                </Text>{" "}
                Be the first!
              </>
            }
            tableHeading="Daily Leaderboard"
            useGetRounds={useGetDailyResults}
          />
        </BlockStack>
      </LayoutClubhouse>
    </UserProvider>
  );
};

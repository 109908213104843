import { Box, Image } from "@chakra-ui/react";
import config from "config";

/*
  LgImageHeader requires having a relative parent container
  See: https://chakra-ui.com/image

  Image problems with imported local images? CRA adds a hash to the src attribute on build, so need to reference that, eg.
  `local-hero-portrait.95cf0f96.jpg`
*/

export const LgImageHeader = ({
  alt = "Your Local Golf",
  fixed,
  innerBg = "text.900",
  opacity = 1,
  shade = "rgb(10 54 56 / 50%)",
  src = config.imgCourseWithFlag,
  fallbackSrc = config.imgCourseWithFlagFallback,
  useHomePosition,
  useBlend,
  useShade,
}: {
  alt?: string;
  fallbackSrc?: string;
  fixed?: boolean;
  innerBg?: string;
  opacity?: number;
  shade?: string;
  src?: string;
  useBlend?: boolean;
  useHomePosition?: boolean;
  useShade?: boolean;
}) => {
  return (
    <Box
      pos={fixed ? "fixed" : "absolute"}
      bg={innerBg}
      inset="0"
      // _after={{
      //   // eslint-disable-next-line quotes
      //   content: useShade ? `""` : "none",
      //   bg: `${shade}`,
      //   pos: "absolute",
      //   inset: "0",
      //   mixBlendMode: useBlend ? "multiply" : "initial",
      // }}
      // style={{ height: "100vh" }}
      // className="all-screen-h"
    >
      <Image
        src={src}
        fallbackSrc={fallbackSrc}
        alt={alt}
        opacity={opacity}
        objectFit="cover"
        objectPosition={
          useHomePosition ? { base: "center", lg: "50% 20%" } : "center"
        }
        h="full"
        w="full"
      />

      <Box
        bg={shade}
        pos="absolute"
        left={0}
        top={0}
        bottom={0}
        w="full"
        mixBlendMode={useBlend ? "multiply" : "initial"}
      />
    </Box>
  );
};

import { Flex, Divider, Text, HStack, StackDivider } from "@chakra-ui/react";
import { RowHoleScore } from "./index";
import { TableHeaderLabel } from "components/ClubTable";
import { TMatchType, EMatchType } from "types";

interface IScorecardRowHeader {
  kind: "header" | "footer";
  theme: "primary" | "secondary" | "sub" | "meta";
  isOut?: boolean;
  isTotal?: boolean;
  startTime?: string;
  endTime?: string;
  headerLabel?: string;
  scoreLabel?: string;
  // oneOf(Object.values(constants.MATCH_TYPES))
  matchType: TMatchType;
  // roundIsVerified: boolean;
  totalStrokes?: number;
  totalScore?: string; // string because of the +/- sign
  variantIsEclectic?: boolean;
}

const rowStyles = {
  primary: {
    // NB: borderBottom must come first to get borderColor!
    // Order of the cascade still matters!
    borderBottom: "2px",
    borderColor: "text.600",
    bg: "breeze.200",
  },
  secondary: {
    borderBottom: "1px",
    borderColor: "text.600",
    bg: "breeze.100",
  },
  sub: {
    borderBottom: "1px",
    borderColor: "text.600",
    bg: "transparent",
  },
  meta: {
    borderBottom: "1px",
    borderColor: "primary.100",
    bg: "transparent",
  },
};

const lastRowHoleScore = {
  pr: { base: 1.5, lg: 3 },
};

export const ScorecardRowHeader = ({
  kind = "header",
  theme = "meta",
  isOut, // in = 2nd 9 holes, out = 1st 9 holes
  isTotal,
  startTime,
  endTime,
  matchType,
  scoreLabel,
  headerLabel = "Your score",
  // roundIsVerified,
  totalStrokes,
  totalScore,
  variantIsEclectic,
}: IScorecardRowHeader) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      w="full"
      h={12}
      {...rowStyles[theme]}
    >
      {/* LEFT: not total */}
      {!isTotal && (
        <Flex align="center" flex="1" justify="space-between">
          <Text as="div" textStyle="crumb" fontWeight="700" ml="5px">
            {isOut ? "Out" : "In"}
          </Text>

          {!variantIsEclectic && (
            <Text
              as="div"
              textStyle="meta"
              mx={3}
              color="grey.400"
              transform="translateY(1px)"
            >
              {kind === "header" && `Started ${startTime}`}
              {kind === "footer" && `Finished ${endTime}`}
            </Text>
          )}
        </Flex>
      )}

      {/* LEFT: total */}
      {isTotal && (
        <Flex align="center" flex="1" justify="flex-start">
          <Text as="div" textStyle="crumb" fontWeight="700" ml="5px">
            {headerLabel}
          </Text>
        </Flex>
      )}

      {/*

        There are 3 x possible cells in the header row:

      */}

      {/* RIGHT: !total header */}
      {!isTotal && kind === "header" && (
        <Flex>
          <TableHeaderLabel label="STR" />
          {matchType !== EMatchType.stroke && scoreLabel && (
            <>
              <Divider
                orientation="vertical"
                borderColor="grey.100"
                h="full"
                mx={0}
              />
              <TableHeaderLabel label={scoreLabel} />
            </>
          )}
        </Flex>
      )}

      {/* RIGHT: !total footer */}
      {!isTotal && kind === "footer" && totalStrokes && totalScore && (
        <HStack
          divider={
            <StackDivider borderColor="grey.100" sx={{ mx: "0 !important" }} />
          }
        >
          <RowHoleScore score={totalStrokes} />
          {matchType !== EMatchType.stroke && (
            <>
              <Divider
                orientation="vertical"
                borderColor="grey.100"
                h="full"
                mx={0}
              />
              <RowHoleScore {...lastRowHoleScore} score={totalScore} />
            </>
          )}
        </HStack>
      )}

      {/* RIGHT: total (both header & footer) */}
      {isTotal && totalScore && (
        <HStack
          divider={
            <StackDivider borderColor="grey.100" sx={{ mx: "0 !important" }} />
          }
        >
          {/* eslint-disable indent */}
          {/* If matchType stroke, nett strokes are not computed until round signing. Show a note about this until verified */}
          {/* {matchType === EMatchType.stroke &&
          !roundIsVerified &&
          theme === "primary" ? (
            <ScorecardRowPopover />
          ) : (
            <RowHoleScore score={totalStrokes} />
          )} */}
          {/* eslint-enable indent */}
          <RowHoleScore score={totalStrokes} />

          {matchType !== EMatchType.stroke && (
            <>
              <Divider orientation="vertical" borderColor="grey.100" mx={0} />
              <RowHoleScore {...lastRowHoleScore} score={totalScore} />
            </>
          )}
        </HStack>
      )}
    </Flex>
  );
};

/**
 * This module handles selecting the correct course rules
 * for the players account and match settings
 *
 * @module course
 */
import * as constants from "../config/constants";
import { TTee, TGender, ICourseHole, IHoleDetail, ETee, EGender } from "types";

/**
 *
 * User Match Settings Getters
 *
 */

const sumArray = (arr) => arr.reduce((sum, x) => (x ? x + sum : sum), 0);

export function _calcScoreTotals(scores) {
  const strokes = [];
  const points = [];
  const pointsStableford = [];
  const pointsPar = [];
  const pointsStroke = [];
  Object.values(scores).forEach((score) => {
    strokes.push(score.strokes);
    points.push(score.score);
    pointsStableford.push(score.scoreStableford);
    pointsPar.push(score.scorePar);
    pointsStroke.push(score.scoreStrokes);
  });

  const split = {
    firstNineStrokes: sumArray(strokes.slice(0, 9)),
    lastNineStrokes: sumArray(strokes.slice(9)),
    firstNineScore: sumArray(points.slice(0, 9)),
    lastNineScore: sumArray(points.slice(9)),
  };

  return {
    ...split,
    strokes: split.firstNineStrokes + split.lastNineStrokes,
    score: split.firstNineScore + split.lastNineScore,
  };
}

/**
 * @param {object} scores
 * @param {object} options
 * @param {string} [options.matchType]
 * @param {string} [options.dailyHandicap] - required if matchType is "STROKE"
 */
export function calcScoreTotals(scores, options) {
  const totals = _calcScoreTotals(scores);

  if (options.matchType === constants.MATCH_TYPES.STROKE) {
    totals.nett = totals.strokes - options.dailyHandicap;
  }

  return totals;
}

/**
 * Get par - not adjusted for handicap
 *
 * This method is duplicated on the front-end since it's not calculated backend
 * until a score is recorded
 * @public
 * @param {object} hole
 * @param {string} teeType
 * @param {string} gender
 */
export function getPar(hole, teeType, gender) {
  if (gender === EGender.female) {
    if (teeType === ETee.pro || teeType === ETee.championship) {
      return hole.par.ladiesChampionship || hole.par.ladies;
    }
    // Assuming standard tee
    return hole.par.ladies;
  }

  if (teeType === ETee.pro || teeType === ETee.championship) {
    return hole.par.mensChampionship || hole.par.mens;
  }
  // Assuming standard tee
  return hole.par.mens;
}

/**
 * @typedef {Object} HoleTee
 * @property {string} distance
 * @property {string} index
 */

/**
 * NOT IN USE: CF prefers getTeeFromGender (see below)
 *
 * This method is duplicated on the front-end since it's not calculated backend
 * until a score is recorded
 *
 * @public
 * @param {string} teeType
 * @param {string} gender
 *
 * @returns {function(): HoleTee}
 */
export function getTeeSelector(teeType: TTee, gender: TGender) {
  // Female
  if (gender === EGender.female) {
    if (teeType === ETee.pro || teeType === ETee.championship) {
      return (hole) => hole.ladiesChampionship || hole.ladiesStandard;
    }
    // Assuming standard tee
    return (hole) => hole.ladiesStandard;
  }

  // Male
  if (teeType === ETee.pro) {
    return (hole) => hole.pro || hole.championship || hole.standard;
  }
  if (teeType === ETee.championship) {
    return (hole) => hole.championship || hole.standard;
  }
  // Assuming standard tee
  return (hole) => hole.standard;
}

function _ratingNameFromSettings(tee, gender) {
  if (tee === ETee.pro || tee === ETee.championship) {
    return "Championship Tees";
  }
  if (tee === ETee.standard) {
    if (gender === EGender.female) {
      return "Ladies Tees";
    }
    return "Standard Tees";
  }
  return null;
}

function _findRatingOfKind(kind, ratings) {
  console.log("_findRatingOfKind called with kind:", kind);
  console.log("ratings:", ratings);

  const result = ratings.find((rating) => rating.ratingKind === kind);

  console.log("_findRatingOfKind result:", result);

  return result;
}

function _getMaxDailyHandicapForGender(gender) {
  return {
    [EGender.female]: 45,
    [EGender.male]: 36,
  }[gender];
}

/**
 *
 * Handicap Calculations
 *
 */

function _calcDailyHandicap(handicap, slopeRating, scratchRating, par, gender) {
  //  if the calculated value has a decimal portion greater than or equal to 0.5, the Math.round() function will round it up to the next integer. If the decimal portion is less than 0.5, the Math.round() function will round it down to the previous integer
  const dailyHandicap = Math.round(
    ((handicap * slopeRating) / 113 + (scratchRating - par)) * 0.93
  );
  return Math.min(dailyHandicap, _getMaxDailyHandicapForGender(gender));
}

/**
 * @param {string} tee
 * @param {object} course
 * @param {string} gender
 * @param {number} handicap
 * @public
 */
export function calculateDailyHandicap(tee, course, gender, handicap) {
  const ratingKind = _ratingNameFromSettings(tee, gender);

  console.log("course.slopeRatings:", course.slopeRatings);
  console.log("ratingKind:", ratingKind);

  const slopeRatingObj = _findRatingOfKind(ratingKind, course.slopeRatings);

  if (!slopeRatingObj) {
    console.error("slopeRating is undefined");
    return null;
  }

  console.log("slopeRating:", slopeRatingObj);

  const slopeRating = slopeRatingObj.ratingCopy;

  if (typeof slopeRating === "undefined") {
    console.error("slopeRating.ratingCopy is undefined");
    return null;
  }

  const scratchRatingObj = _findRatingOfKind(ratingKind, course.scratchRatings);

  if (!scratchRatingObj) {
    console.error("scratchRating is undefined");
    return null;
  }

  const scratchRating = scratchRatingObj.rating;

  if (typeof scratchRating === "undefined") {
    console.error("scratchRating.rating is undefined");
    return null;
  }

  const parRatingObj = _findRatingOfKind(ratingKind, course.parRatings);

  if (!parRatingObj) {
    console.error("parRating is undefined");
    return null;
  }

  const par = parRatingObj.rating;

  if (typeof par === "undefined") {
    console.error("parRating.rating is undefined");
    return null;
  }

  return _calcDailyHandicap(handicap, slopeRating, scratchRating, par, gender);
}

/*
  CF refactor of getTeeSelector with explicit hole prop 22-03-04
  1. Male switch cases to set a hole type from tee & hole props
  2. Female switch cases to set a hole type from tee & hole props
  3. Function to set switch case use by gender
 */
export const getMaleTeeKind = (
  teeType: TTee,
  hole: ICourseHole
): IHoleDetail => {
  switch (teeType) {
    case ETee.pro:
      return hole.pro || hole.championship || hole.standard;
    case ETee.championship:
      return hole.championship || hole.standard;
    default:
      return hole.standard;
  }
};

export const getFemaleTeeKind = (
  teeType: TTee,
  hole: ICourseHole
): IHoleDetail => {
  switch (teeType) {
    case ETee.pro:
      return hole.ladiesChampionship || hole.ladiesStandard;
    case ETee.championship:
      return hole.ladiesChampionship || hole.ladiesStandard;
    default:
      return hole.ladiesStandard;
  }
};

export const getTeeFromGender = (
  gender: TGender,
  tee: TTee,
  hole: ICourseHole
) =>
  gender === EGender.female
    ? getFemaleTeeKind(tee, hole)
    : getMaleTeeKind(tee, hole);

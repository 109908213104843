import React from "react";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { BlockContainerClubhouse } from "components/Block2";

/*
  LayoutClubhouse is used exclusively in the Clubhouse flow
  The Score flow uses the Score page
 */

export const LayoutClubhouse = ({
  children,
  sponsorSlot,
  showSponsorOnMobile = true,
}: {
  children: React.ReactNode;
  sponsorSlot?: React.ReactNode;
  showSponsorOnMobile?: boolean;
}) => {
  const [breakpoint] = useMediaQuery("(max-width: 640px)");

  return (
    <>
      <BlockContainerClubhouse h="full" zIndex={2}>
        {children}
      </BlockContainerClubhouse>

      {/* desktop sponsor */}
      {sponsorSlot !== undefined && (
        <Box
          className="SponsorSlot"
          // 240 is the width of the aside nav
          width={{ base: "full", lg: "calc(100vw - 240px)" }}
          bg={{ base: "white", md: "transparent" }}
          bottom={0}
          // allow us not to show the sponsor on create-competition on mobile
          d={breakpoint && !showSponsorOnMobile ? "none" : "block"}
          pos="fixed"
          pb={{ base: 6, lg: 9 }}
          pt={{ base: 6, lg: 0 }}
          zIndex={1}
          _before={{
            bgGradient: "linear(to-b, transparent 0%, rgba(0,0,0,0.07) 100%)",
            // eslint-disable-next-line quotes
            content: breakpoint ? `""` : "none",
            height: 8,
            pos: "absolute",
            right: "0",
            left: "0",
            top: -8,
          }}
        >
          <BlockContainerClubhouse pt={0} bg="transparent">
            {sponsorSlot}
          </BlockContainerClubhouse>
        </Box>
      )}
    </>
  );
};

import { Link as RLink } from "react-router-dom";
import { Box, Flex, Link, Icon } from "@chakra-ui/react";
import { MdWarning } from "react-icons/md";

export const NavsideLink = ({
  activePath = "/",
  hasUnverifiedRounds,
  label = "Round Play",
  path = "/",
}: {
  activePath?: string;
  hasUnverifiedRounds?: boolean;
  label: string;
  path: string;
}) => {
  const isActive = activePath.includes(path) ? "text.800" : "text.400";
  // const isActive = path === activePath;

  return (
    <Link
      as={RLink}
      to={path}
      _hover={{ textDecoration: "none", color: "text.800" }}
      _focus={{
        boxShadow: "initial",
      }}
      sx={{
        ":hover svg": {
          color: "text.800",
        },
      }}
    >
      <Flex
        as="span"
        textStyle="body"
        fontWeight="400"
        // color={hasUnverifiedRounds ? "form.bad" : isActive}
        color={isActive}
        w="full"
        h="32px"
        alignItems="center"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _hover={{
          color: "text.800",
        }}
      >
        {label}
        {hasUnverifiedRounds && (
          <Box pl={1.5} transform="translateY(-0.1em)">
            <Icon as={MdWarning} boxSize="1em" color="form.bad" />
          </Box>
        )}
      </Flex>
    </Link>
  );
};

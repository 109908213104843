import { Icon } from "@chakra-ui/react";
import { MdChevronRight, MdCheckCircle } from "react-icons/md";

export function ButtonRightIcon({
  btnValue,
  btnChevron,
}: {
  btnValue?: boolean;
  btnChevron?: boolean;
}) {
  if (btnChevron) {
    return (
      <Icon
        as={MdChevronRight}
        w="1.5em"
        h="1.5em"
        color={btnValue ? "grey.400" : "text.800"}
        mr={2}
        ml={2}
      />
    );
  }
  return (
    <Icon
      as={btnValue ? MdCheckCircle : MdChevronRight}
      w={btnValue ? "1em" : "1.5em"}
      h={btnValue ? "1em" : "1.5em"}
      color={btnValue ? "complement.500" : "text.800"}
      mr={btnValue ? 2 : 1}
      ml={2}
    />
  );
}

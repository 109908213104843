/**
 * Invites sent to the current user
 */

import {
  SET_INVITES,
  ADD_INVITE,
  DELETE_INVITE,
  ACCEPT_INVITE,
} from "../actions/invites";

const initialState = {
  invites: [],
};

function invites(state = initialState, action) {
  switch (action.type) {
    case SET_INVITES:
      return {
        ...state,
        invites: action.invites,
      };
    case ADD_INVITE:
      return {
        ...state,
        invites: [...state.invites, action.invite],
      };
    case DELETE_INVITE:
      return {
        ...state,
        invites: state.invites.filter(
          (invite) => invite.id !== action.inviteId
        ),
      };
    case ACCEPT_INVITE:
      return {
        ...state,
        // All other invites are denied
        invites: [],
      };
    default:
      return state;
  }
}

export default invites;

import { Box, Text } from "@chakra-ui/react";
import { TableHeaderStatus } from "components/Block2";
import { ScorecardPostMatch } from "components/Scorecard";
import { IGetRoundPreviewData } from "lib/api";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { SWRResponse } from "swr";
import { ESortBy, EScorecardVariant, ICourse, ITableRound } from "types";
import { TableNotice, TableRowRound } from "./index";
import { useMemo } from "react";
import {
  ILeaderboardPresenter,
  LeaderboardPresenter,
} from "./LeaderboardPresenter";

interface ILeaderboard extends ILeaderboardPresenter {
  noticeSlot?: ReactNode;
  noRoundsMessage: string | ReactNode;
  hasRounds?: boolean;
  showRoundDate?: boolean;
  useGetRounds: (
    courseId: number,
    sortBy: ESortBy | undefined
  ) => SWRResponse<IGetRoundPreviewData, Error>;
}

export const Leaderboard = ({
  tableHeading,
  viewAllLink,
  noticeSlot,
  noRoundsMessage,
  showRoundDate,
  useGetRounds,
}: ILeaderboard) => {
  const userHomeCourse = useSelector(
    (state: any) => state.authentication.user.homeCourse.id
  );

  const [courseId, setCourseId] = useState<number>(userHomeCourse);
  const [sortBy, setSortBy] = useState<ESortBy>(ESortBy.stableford);

  const { data, error } = useGetRounds(courseId, sortBy);

  const rounds: ITableRound[] = data?.data?.rounds ?? [];
  const verifiedRounds = rounds?.filter((round) => round.userRound.verified);

  // 1. Sort the rounds based on the sortBy criteria
  const sortedVerifiedRounds = useMemo(() => {
    if (verifiedRounds) {
      return [...verifiedRounds].sort((a, b) => {
        switch (sortBy) {
          case ESortBy.stableford:
            return (
              b.userRound.scoreTotals.stableford -
              a.userRound.scoreTotals.stableford
            );
          case ESortBy.stroke:
            return a.userRound.scoreTotals.nett - b.userRound.scoreTotals.nett;
          case ESortBy.par:
            return b.userRound.scoreTotals.par - a.userRound.scoreTotals.par;
          default:
            return 0; // Default return value if no case matches
        }
      });
    }
    return undefined;
  }, [verifiedRounds, sortBy]);

  // 2. Calculate the placement and handle ties
  let previousScore: number | undefined = undefined;
  let placement: number = 0; // Start with 0 to be incremented before use
  let nextPlacement: number = 1;

  if (error)
    return (
      <TableHeaderStatus showLoader tableHeading={tableHeading}>
        <Text textStyle="body">Got an error here. Please contact support.</Text>
      </TableHeaderStatus>
    );

  return (
    <LeaderboardPresenter
      tableHeading={tableHeading}
      viewAllLink={viewAllLink}
      onSearchSelect={(course: ICourse) => setCourseId(course.id)}
      sortBy={sortBy}
      setSortBy={setSortBy}
    >
      {/* NOTICE SLOT */}
      {noticeSlot}

      {/* NO ROUNDS YET */}
      {!rounds?.length && <TableNotice mt="3">{noRoundsMessage}</TableNotice>}

      {/* ROUNDS GRID with mobile mx outset */}
      {!!verifiedRounds?.length && (
        <Box mx={{ base: "calc(var(--chakra-space-inset) * -1)", lg: 0 }}>
          {sortedVerifiedRounds?.map((round, index: number) => {
            let score: number | undefined = undefined;
            switch (sortBy) {
              case ESortBy.stableford:
                score = round.userRound.scoreTotals.stableford;
                break;
              case ESortBy.stroke:
                score = round.userRound.scoreTotals.nett;
                break;
              case ESortBy.par:
                score = round.userRound.scoreTotals.par;
                break;
            }

            if ((score !== undefined && score) !== previousScore) {
              placement = nextPlacement; // Assign nextPlacement to placement when score changes
            }

            previousScore = score;
            nextPlacement++; // Increment nextPlacement for the next round

            return (
              <TableRowRound
                key={index.toString()}
                round={round}
                index={index}
                placement={placement}
                sortBy={sortBy}
                showRoundDate={showRoundDate}
              >
                <ScorecardPostMatch
                  matchId={round.userRound.matchId}
                  notVerified={!round.userRound.verified}
                  userId={round.player.id}
                  variant={EScorecardVariant.leaderboard}
                />
              </TableRowRound>
            );
          })}
        </Box>
      )}
    </LeaderboardPresenter>
  );
};

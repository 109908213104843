import { Text } from "@chakra-ui/react";
// import { useField } from "formik";

export const FormFieldError = ({ meta }: { meta: any }) => {
  // const [field, meta] = useField(props);

  return (
    <>
      {meta.touched && meta.error ? (
        <Text
          textStyle="body"
          fontWeight="400"
          color="form.bad"
          w="full"
          pt={2}
        >
          {meta.error}
        </Text>
      ) : null}
      {/* <Text textStyle="body" fontWeight="400" color="form.bad" w="full" pt={2}>
        Hey you have error
      </Text> */}
    </>
  );
};

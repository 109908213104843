import { Box } from "@chakra-ui/react";

export const PosterSmallNumber = ({
  isLeft,
  children,
}: {
  isLeft?: boolean;
  children: React.ReactNode;
}) => (
  <Box
    fontSize="72px"
    fontWeight="500"
    textStyle="hPrimary"
    pos="absolute"
    top={4}
    right={!isLeft ? "-0.7em" : undefined}
    left={isLeft ? -10 : undefined}
  >
    {children}
  </Box>
);

import { Message } from "./Message";

interface IMessageErrorMap {
  errorCode?: string;
  messageMap?: any;
}

// Set error messages for the returned error codes here
function mapApiErrorToMessage(errorCode, errorToMessageMap) {
  return errorToMessageMap[errorCode] || errorToMessageMap.default;
}

export const MessageErrorMap = ({
  errorCode,
  messageMap,
}: IMessageErrorMap) => {
  const error = mapApiErrorToMessage(errorCode, messageMap);

  let heading;
  let children;

  if (typeof error === "string") {
    heading = error;
  } else {
    heading = error.primary;
    children = error.secondary;
  }

  return (
    <Message underHeader heading={heading}>
      {children}
    </Message>
  );
};

/**
 * @module schemas
 *
 * Exports yup schemas for form fields
 * Reuse these schemas for consistent validation requirements across the app
 */

import * as Yup from "yup";
import * as constants from "../config/constants";

/**
 * User
 */
export const fullName = Yup.string().required("Full name is required");
export const email = Yup.string()
  .email("Invalid email address")
  .label("Email")
  .required("Email is required");
export const golflinkNumber = Yup.string()
  .label("Golflink number")
  .test(
    "len",
    "Golflink number must be 7, 8 or 10 digits long",
    (value) => value && [7, 8, 10].includes(value.length)
  )
  // Only contains digits
  // specifies first 10 digits to avoid showing the wrong error
  .matches(/^[0-9]{1,10}$/gi, "Golflink number can only contain digits")
  .required("Golflink number is required");
export const handicap = Yup.number()
  .label("Handicap")
  .required("Handicap is required");
export const password = Yup.string()
  .label("Password")
  .min(8, "Password must be at least 8 characters")
  .required("Password is required");
export const passwordConfirm = Yup.string()
  .label("Confirm Password")
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .required("Confirm Password is required");
export const gender = Yup.string()
  .label("Gender")
  .oneOf(Object.keys(constants.GENDERS));
export const avatar = Yup.mixed().test(
  "fileSize",
  "File Size is too large",
  (value) => {
    // Make field optional
    if (!value) return true;

    const mb = 1024 * 1024;
    return value.size <= 2 * mb;
  }
);

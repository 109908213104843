import * as api from "lib/api";

export const FETCH_COMPETITION_PREVIEWS = "FETCH_COMPETITION_PREVIEWS";
export const FETCH_COMPETITION_PREVIEWS_ERROR =
  "FETCH_COMPETITION_PREVIEWS_ERROR";

export const CREATE_NEW_COMPETITION = "CREATE_NEW_COMPETITION";
export const CREATE_NEW_COMPETITION_ERROR = "CREATE_NEW_COMPETITION_ERROR";
export const CREATE_NEW_COMPETITION_SUCCESS = "CREATE_NEW_COMPETITION_SUCCESS";

export const getCompetitionPreviews = () => async (dispatch) => {
  const response = await api.getCompetitionPreviews();
  if (response.error) {
    dispatch({
      type: FETCH_COMPETITION_PREVIEWS_ERROR,
    });
  }

  dispatch({
    type: FETCH_COMPETITION_PREVIEWS,
    competitions: response.json.competitions,
  });
};

export const createCompetition = (newCompetitionSettings) => async (
  dispatch
) => {
  const response = await api.createCompetition(newCompetitionSettings);
  if (response.error) {
    dispatch({
      type: CREATE_NEW_COMPETITION_ERROR,
    });
  }

  dispatch({
    type: CREATE_NEW_COMPETITION_SUCCESS,
    competition: response.data,
  });
};

/**
 * This page is used by email invites. Email match invites link to this page which

 * Actions of this page:
 * 1. Accept the invite
 * 2. Redirect to the lobby page
 *
 * The invite must be accepted before joining so "user match settings" are created. Otherwise
 * attempting to join the match errors.
 */

import React from "react";
import useInvite from "hooks/useInvite";
import useQuery from "hooks/useQuery";
import { BlockLoading } from "components/Block2/BlockLoading";

const JoinLobbyPage = () => {
  const { handleAcceptInvite } = useInvite();
  const query = useQuery();
  const inviteId = query.get("inviteId");
  const matchId = query.get("matchId");

  React.useEffect(() => {
    handleAcceptInvite(inviteId, matchId);
  }, [handleAcceptInvite, inviteId, matchId]);
  return <BlockLoading />;
};

export default JoinLobbyPage;

/* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/form-label.ts */

const baseStyle = {
  fontSize: "md",
  marginEnd: 3,
  mb: 2,
  fontWeight: "medium",
  // these don't work despite being copied directly from Chakra src!
  // transitionProperty: "common",
  // transitionDuration: "fast",
  // transitionTimingFunction: "ease-in-out",
  transitionProperty:
    "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
  transitionDuration: "50ms",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  opacity: 1,
  _disabled: {
    opacity: 0.4,
  },
};

const variants = {
  floatingLabel: {
    pos: "absolute",
    left: 0,
    zIndex: "1",
    transformOrigin: "top left",
    // fontSize will get transformed by active states
    fontSize: { base: "16px", md: "17px" },
    textTransform: "capitalize",
    m: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseStyle,
  variants,
};

import { useDispatch } from "react-redux";
import { Box, Flex, Button, useDisclosure } from "@chakra-ui/react";
import { DeleteModal } from "./index";
import { MdArrowBack } from "react-icons/md";
import * as matchActions from "store/actions/match";
import { ConfigRow } from "components/Config";
import { buttonH } from "theme/components/button";

export const DeleteRoundModal = ({ forNavbar }: { forNavbar?: boolean }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  async function handleConfirmDeleteRound() {
    dispatch(matchActions.deleteRound());
  }

  return (
    <>
      {forNavbar ? (
        /* same button as in DeleteLobbyModal */
        <Flex alignItems="center" h={buttonH}>
          <Button
            size="lg"
            variant="ghost"
            colorScheme="textButton"
            justifyContent="flex-start"
            w="full"
            px={0}
            leftIcon={<MdArrowBack />}
            onClick={onOpen}
          >
            Delete round
          </Button>
        </Flex>
      ) : (
        <ConfigRow
          name="Delete Round"
          onClick={onOpen}
          iconSlot={
            <Box mr={1}>
              <MdArrowBack />
            </Box>
          }
        ></ConfigRow>
      )}

      <DeleteModal
        heading="Delete Round"
        confirm="Are you sure you want to delete this round? Any configuration and scores will be lost."
        buttonLabel="Delete round"
        isOpen={isOpen}
        onClose={onClose}
        onClick={handleConfirmDeleteRound}
      />
    </>
  );
};

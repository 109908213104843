import React from "react";

export const LgIconPlus = ({ height = 40, color = "white" }) => {
  return (
    <svg
      fill="currentColor"
      focusable="false"
      role="presentation"
      viewBox="0 0 120 120"
      height={height}
      width={height}
    >
      <path
        fill={color}
        d="M108.29,11.81c-4.93-4.84-11.47-6.55-24-6.55H35C23.33,5.26,16.64,7,11.81,11.9S5.26,23.28,5.26,35V84.27c0,12.6,1.62,19.14,6.45,23.92s11.47,6.55,24,6.55H84.32c12.5,0,19.14-1.71,24-6.55s6.45-11.32,6.45-23.92V35.73C114.74,23.13,113.13,16.59,108.29,11.81Zm2.54,22.26V85.88c0,9-1.66,15.38-5.52,19.29s-10.4,5.61-19.38,5.61H34.07c-8.93,0-15.43-1.66-19.33-5.61S9.17,94.91,9.17,85.88v-51c0-9.66,1.61-16.21,5.47-20.11S25.28,9.22,34.8,9.22H85.93c9,0,15.43,1.66,19.38,5.57S110.83,25,110.83,34.07ZM60,88.22a2,2,0,0,0,2.15-2V62H86.32a2.07,2.07,0,0,0,2.15-2,2.11,2.11,0,0,0-2.15-2.15H62.1V33.63A2.05,2.05,0,0,0,60,31.48a2,2,0,0,0-2,2.15V57.85H33.73a2.05,2.05,0,1,0,0,4.1H58V86.17A2,2,0,0,0,60,88.22Z"
      />
    </svg>
  );
};

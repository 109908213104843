import { Box, Text } from "@chakra-ui/react";
import { TableHeaderStatus } from "components/Block2";
import { ScorecardPostMatch } from "components/Scorecard";
import { IGetRoundPreviewData } from "lib/api";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { SWRResponse } from "swr";
import { ESortBy, EScorecardVariant, ICourse, ITableRound } from "types";
import { TableNotice, TableRowRound } from "./index";
import {
  ILeaderboardPresenter,
  LeaderboardPresenter,
} from "./LeaderboardPresenter";

interface ILeaderboardByDate extends ILeaderboardPresenter {
  noticeSlot?: ReactNode;
  noRoundsMessage: string | ReactNode;
  hasRounds?: boolean;
  showRoundDate?: boolean;
  useGetRounds: (
    courseId: number,
    sortBy: ESortBy | undefined
  ) => SWRResponse<IGetRoundPreviewData, Error>;
}

export const LeaderboardByDate = ({
  tableHeading,
  viewAllLink,
  noticeSlot,
  noRoundsMessage,
  showRoundDate,
  useGetRounds,
}: ILeaderboardByDate) => {
  const userHomeCourse = useSelector(
    (state: any) => state.authentication.user.homeCourse.id
  );
  const [courseId, setCourseId] = useState<number>(userHomeCourse);
  const [sortBy, setSortBy] = useState<ESortBy>(ESortBy.dateDesc);
  const { data, error } = useGetRounds(courseId, sortBy);

  const rounds: ITableRound[] = data?.data?.rounds ?? [];
  const verifiedRounds = rounds?.filter((round) => round.userRound.verified);

  if (error)
    return (
      <TableHeaderStatus showLoader tableHeading={tableHeading}>
        <Text textStyle="body">Got an error here. Please contact support.</Text>
      </TableHeaderStatus>
    );

  return (
    <LeaderboardPresenter
      tableHeading={tableHeading}
      viewAllLink={viewAllLink}
      onSearchSelect={(course: ICourse) => setCourseId(course.id)}
      sortBy={sortBy}
      setSortBy={setSortBy}
    >
      {/* NOTICE SLOT */}
      {noticeSlot}

      {/* NO ROUNDS YET */}
      {!rounds?.length && <TableNotice mt="3">{noRoundsMessage}</TableNotice>}

      {/* ROUNDS GRID with mobile mx outset */}
      {!!verifiedRounds?.length && (
        <Box mx={{ base: "calc(var(--chakra-space-inset) * -1)", lg: 0 }}>
          {verifiedRounds?.map((round, index: number) => {
            return (
              <TableRowRound
                key={index.toString()}
                round={round}
                index={index}
                placement={index + 1}
                sortBy={sortBy}
                showRoundDate={showRoundDate}
              >
                <ScorecardPostMatch
                  matchId={round.userRound.matchId}
                  notVerified={!round.userRound.verified}
                  userId={round.player.id}
                  variant={EScorecardVariant.leaderboard}
                />
              </TableRowRound>
            );
          })}
        </Box>
      )}
    </LeaderboardPresenter>
  );
};

import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { ScoresSummary } from "components/Score";
import { ScoreInputPickers, ScoreMeta } from "components/ScoreInput";
import { ScorecardHeader } from "components/Scorecard";
// import { setPlayerTabIndex } from "lib/score";
import { shScoringH } from "components/Scorecard";
import * as constants from "config/constants";
import {
  useScorecardContext,
  useScoreContext,
  useHoleStatsContext,
} from "components/Context";
import { IScore } from "types";

// shScoringH, shLeaderboardH, shEclecticH;

/*
  Almost the same as ScorecardRowCard,
  which is used on the round's final Scorecard.
  Uses ScoresSummary.
*/

interface IScoreInputPresenter {
  onSetScores: (playerScores: IScore, markerScores: IScore) => void;
  onClickNext: () => void;
  roundSettingsSlot: React.ReactNode;
  sponsorH: string;
  sponsorSlot: React.ReactNode;
}

export const ScoreInputPresenter = ({
  onSetScores,
  onClickNext,
  roundSettingsSlot,
  sponsorH,
  sponsorSlot,
}: IScoreInputPresenter) => {
  // Context
  const { variant, isSocial } = useScorecardContext();
  const { incompleteHoles, holeNumber } = useScoreContext();
  const {
    markerScores,
    playerScores,
    markerTeeStats,
    playerTeeStats,
  } = useHoleStatsContext();

  // Use a custom tracked index so we can update the value & set a default
  const [tabIndex, setTabIndex] = React.useState(0);
  const [showScore, setShowScore] = React.useState(false);

  // User strokes: default to previously input score, falling back to par
  const [userStrokes, setUserStrokes] = React.useState(
    playerScores.noScore
      ? constants.NO_SCORE
      : playerScores.strokes || playerScores.par
  );

  // Set the player's tabIndex so we can follow it thru data assignments
  // This index is toggled by isSocial
  // const playerTab = setPlayerTabIndex(isSocial, tabIndex);
  const playerTab = isSocial ? true : false;

  // use playerTab to toggle holeStats per active tab
  const teeStats = playerTab ? playerTeeStats : markerTeeStats;
  const par = playerTab ? playerScores.par : markerScores?.par;

  // Marker strokes: default to par
  const initialMarkerStrokes = markerScores
    ? markerScores.strokes || markerScores.par
    : undefined;

  const [markerStrokes, setMarkerStrokes] = React.useState(
    initialMarkerStrokes
  );

  async function handleSetMarkerStrokes(strokes) {
    setMarkerStrokes(strokes);
    setTabIndex(1);
  }

  async function handleSetPlayerStrokes(strokes) {
    setUserStrokes(strokes);

    if (isSocial) {
      // During social player is the only player so call onSetScores now
      onAllStrokesSet(strokes);
    } else {
      // Otherwise call onSetScores when the player sets their score
      onAllStrokesSet(strokes, markerStrokes);
    }
  }

  async function onAllStrokesSet(...scores) {
    // Submit scores to redux store
    await onSetScores(...scores);
    // show the scores summary, set to the marker tab
    setTabIndex(0);
    setShowScore(true);
  }

  function handleEditClick() {
    setTabIndex(0);
    setShowScore(false);
  }

  return (
    <>
      <ScorecardHeader
        variant={variant}
        heading={`Hole ${holeNumber}`}
        buttonSlot={roundSettingsSlot}
      >
        <ScoreMeta
          par={par}
          distance={teeStats.distance}
          strokeIndex={teeStats.strokeIndex}
        />
      </ScorecardHeader>

      {/* ScorecardHeader w/ variant "scoring", so height is shScoringH */}
      {/* h={`calc(100vh - ${shScoringH} - ${sponsorH})`} */}
      <Box as="section" flex={1}>
        {/* initially SCORE INPUT PICKERS: ScoreTabs for initial score input */}
        {!showScore ? (
          <ScoreInputPickers
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            userStrokes={userStrokes}
            markerScores={markerScores}
            markerStrokes={markerStrokes}
            handleSetPlayerStrokes={handleSetPlayerStrokes}
            handleSetMarkerStrokes={handleSetMarkerStrokes}
          />
        ) : (
          /* then SHOW SCORES (ScoreCard) after scores are set in the store */
          <ScoresSummary
            tabIndex={tabIndex}
            onTabClick={(index) => setTabIndex(index)}
            onEditClick={handleEditClick}
          >
            <Box layerStyle="containerScoreBtn" pb={6}>
              <Button w="full" onClick={() => onClickNext()}>
                {holeNumber === 18 && incompleteHoles.length === 0
                  ? "Complete round"
                  : "Next hole"}
              </Button>
            </Box>
          </ScoresSummary>
        )}
      </Box>

      {/* SPONSORS BLOCK */}
      {sponsorSlot}
    </>
  );
};

import { useSelector } from "react-redux";
import * as matchActions from "store/actions/match";
import * as courseLib from "lib/course";
import * as dateLib from "lib/date";
import {
  ICourseHole,
  IPlayerBase,
  IScores,
  IUserMatchSettings,
  TTee,
} from "types";

// it's possible to use context to supply as props… but it's easier to use the store here and avoid props set-up everytime we use it.
// hole: ICourseHole,
// holeNumber: number,
// isSocial: boolean,
// marker: IPlayerBase,
// scores: Record<number, IScores>,
// tee: TTee,
// user: IPlayerBase

export default function useHoleStats(holeNumber: number) {
  const user: IPlayerBase = useSelector((state) => state.authentication.user);
  const isSocial = useSelector(matchActions.selectors.getIsSocial);
  const tee: TTee = useSelector((state) => state.match.match.tee);
  const scores: IScores = useSelector((state) => state.match.scores);
  const userMatchSettings: IUserMatchSettings = useSelector(
    matchActions.selectors.getUserMatchSettings
  );

  // get this hole's scores
  const holeScores = scores[holeNumber];

  // hole is used as the key arguement in genderTeeStats
  const hole: ICourseHole = useSelector(
    matchActions.selectors.getHole(holeNumber)
  );

  // filter scores by tee & gender for both user
  const genderTeeStats = courseLib.getTeeFromGender(user.gender, tee, hole);

  const playerTeeStats = {
    distance: genderTeeStats.distance,
    strokeIndex: genderTeeStats.index,
  };

  const playerScores = {
    par: courseLib.getPar(hole, tee, user.gender),
    strokes: holeScores?.user.strokes,
    score: holeScores?.user.score,
    noScore: holeScores?.user.noScore,
  };

  // TESTS
  // console.log("useHoleStats hole", hole);
  // console.log("scores", scores);
  // console.log("playerTeeStats", playerTeeStats);

  const markerHole: ICourseHole = useSelector(
    matchActions.selectors.getMarkerHole(holeNumber)
  );

  let markerTeeStats;
  let markerScores;

  if (!isSocial && userMatchSettings.marker) {
    const markerGender = userMatchSettings.marker?.gender;
    const genderTeeStats = courseLib.getTeeFromGender(markerGender, tee, hole);

    markerTeeStats = {
      distance: genderTeeStats.distance,
      strokeIndex: genderTeeStats.index,
    };
    markerScores = {
      par: courseLib.getPar(markerHole, tee, markerGender),
      strokes: holeScores?.marker.strokes,
      score: holeScores?.marker.score,
      noScore: holeScores?.marker.noScore,
    };
  }

  return {
    time: dateLib.toTimeString(holeScores?.createdAt),
    playerTeeStats,
    markerTeeStats,
    playerScores,
    markerScores,
  };
}

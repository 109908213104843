export const spacing = {
  px: "1px",
  "2px": "2px",
  "3px": "3px",
  0: "0",
  1: "0.25rem",
  2: "0.5rem",
  3: "0.75rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem",

  /* DYNAMIC */

  // https://moderncss.dev/generating-font-size-css-rules-and-creating-a-fluid-type-scale/
  // https://developer.mozilla.org/en-US/docs/Web/CSS/clamp()

  // example 1
  // font-size: clamp(18px, 1.4vw, 22px);
  // The minimum font size is 18px, the max is 22px, and in-between, it will be sized relative to 1.4% of the viewport width, meaning relative sizing will kick in once the window is wider than about 1200px
  // (1290 actually: 1290 * 0.014 = 18.06)

  // example 2
  // at 1400 wide, w8 is 56px: 1400 * 0.04 = 56

  // responsive typography may require some maths help
  // https://clamp.font-size.app/

  // middle values computed at 1024 wide, eg. 1024 * 0.006 = 6px
  w1: "clamp(0.25rem, 0.6vw, 0.5rem)", // 4, 6, 8
  w2: "clamp(0.5rem, 1.1175vw, 1rem)", // 8, 12, 16
  w3: "clamp(0.75rem, 1.75vw, 1.5rem)", // 12, 18, 24
  w4: "clamp(1rem, 2.35vw, 2rem)", // 16, 24, 32
  w5: "clamp(1.25rem, 2.9375vw, 2.5rem)", // 20, 30, 40
  w6: "clamp(1.875rem, 4.4vw, 3.75rem)", // 30, 45, 60
  w7: "clamp(2.5rem, 5.875vw, 5rem)", // 40, 60, 80
  w8: "clamp(5rem, 11.75vw, 10rem)", // 80, 120, 160
  w9: "clamp(7.5rem, 17.58vw, 15rem)", // 120, 180, 240
  w10: "clamp(10rem, 23.5vw, 20rem)", // 160, 240, 320
  w11: "clamp(18.125rem, 39vw, 36.25rem)", // 290, 435, 580

  // is { base: 6, lg: 12 } aka 24px, 48px
  inset: "clamp(16px, -0.4286rem + 4.5714vw, 48px)",
  inset2x: "48px",

  // this older method uses --viewport-basis set responsively,
  // see /public/utils.css
  // originally taken from klim.co.nz
  // w1: "calc(8 / var(--viewport-basis) * 100vw)",

  // emulate Klim
  // --spacing1: calc(8 / var(--viewportBasis) * 100vw);
  // --spacing2: calc(16 / var(--viewportBasis) * 100vw);
  // --spacing3: calc(24 / var(--viewportBasis) * 100vw);
  // --spacing4: calc(32 / var(--viewportBasis) * 100vw);
  // --spacing5: calc(40 / var(--viewportBasis) * 100vw);
  // --spacing6: calc(60 / var(--viewportBasis) * 100vw);
  // --spacing7: calc(80 / var(--viewportBasis) * 100vw);
  // --spacing8: calc(160 / var(--viewportBasis) * 100vw);
  // --spacing9: calc(240 / var(--viewportBasis) * 100vw);
  // --spacing10: calc(320 / var(--viewportBasis) * 100vw);
  // --spacing11: calc(580 / var(--viewportBasis) * 100vw);
};

export type Spacing = typeof spacing;

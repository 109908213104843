import * as React from "react";
import { IScoreContext } from "types";

/*
  This Context component is built to accept a match object
  in the Score/Score component
 */

const ScoreContext = React.createContext<IScoreContext | undefined>(undefined);

interface IScoreProvider {
  children: React.ReactNode;
  value: IScoreContext;
}

function ScoreProvider({ children, value }: IScoreProvider) {
  return (
    <ScoreContext.Provider value={value}>{children}</ScoreContext.Provider>
  );
}

function useScoreContext() {
  const context = React.useContext(ScoreContext);
  if (context === undefined) {
    throw new Error("useScoreContext must be used within a ScoreProvider");
  }
  return context;
}

export { ScoreProvider, useScoreContext };

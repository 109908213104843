/**
 * Collection of functions for interacting with other apis
 *
 * Functions in this file should only define an endpoint and and how to parse
 * the recieved data
 *
 * @module Api
 */

import API, { ApiError } from "./apiFactory";
import config from "config";
import * as constants from "config/constants";
import useRequest from "hooks/useRequest"; // reusable SWR
import { ICompetition, ITableRound, IMatchForContext } from "types";
import { IEclecticRoundData } from "components/Context";
import formatMatchResponse from "./formatMatchResponse";

const api = API({
  url: config.serverUrl as string,
});

/**
 * YLG app
 */

function objectToURLSearchParams(obj: object): URLSearchParams {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      const valueString: string = value as string;
      searchParams.set(key, valueString);
    }
  }
  return searchParams;
}

/**
 * @param {object} credentials
 * @param {string} credentials.email
 * @param {string} credentials.password
 * @returns {ApiResponse}
 */
export async function login(credentials) {
  const response = await api.basicPost("/api/auth/login", credentials);

  if (response.error) return response;
  if (!response?.json?.token) {
    return ApiError("GENERIC", "Failed to login");
  }

  return response;
}

/**
 * @param {object} credentials
 * @param {string} credentials.email
 * @param {string} credentials.password
 * @param {string} credentials.passwordConfirm
 * @returns {ApiResponse}
 */
export async function register(credentials) {
  return api.basicPost("/api/auth/register", credentials);
}

/**
 * @returns {ApiResponse}
 */
export async function getUser() {
  const response = await api.authedGet("/api/auth/user");

  if (response.status === 401) {
    return ApiError("UNAUTHORIZED", "Your authentication token is invalid");
  }
  if (response.error) return response;
  if (!(response && response.json && response.json.user)) {
    return ApiError("GENERIC", "Failed to fetch user");
  }

  return response;
}

/**
 * @param {string} token
 * @returns {null|ApiResponse}
 */
export async function verifyEmail(token) {
  const response = await api.basicGet(`/api/auth/verify-email?token=${token}`);

  if (response.error) return response;
  // Request has no response body, only 200 status code
  return null;
}

/**
 * @param {string} email
 * @returns {ApiResponse}
 */
export async function resendVerificationEmail(email) {
  return api.basicPost("/api/auth/resend-verification-email", {
    email,
  });
}

/**
 * @param {string} email
 * @returns {ApiResponse}
 */
export async function forgotPassword(email) {
  return api.basicPost("/api/auth/forgot-password", {
    email,
  });
}

/**
 * @param {string} token
 * @param {string} password
 * @returns {ApiResponse}
 */
export async function resetPassword(token, password) {
  return api.basicPost("/api/auth/reset-password", {
    token,
    password,
  });
}

/**
 * @param {object} settings
 * @param {string} password
 * @returns {ApiResponse}
 */
export async function updateUserSettings(settings) {
  return api.authedFormDataPost("/api/user/settings", settings);
}

/**
 * @returns {ApiResponse}
 */
export async function deleteUser() {
  return api.authedDelete("/api/user/delete");
}

/**
 * @param {string} id
 * @returns {ApiResponse}
 */

/**
 * Same endpoint as useGetMatch
 * Kept this method for current use in the redux store match reducer
 */
export async function getMatch(id) {
  const response = await api.authedGet(`/api/match/${id}`);
  if (response.json && !response.json.error)
    response.json = formatMatchResponse(response.json);
  return response;
}

export function useGetMatch(id, userId) {
  let { data, ...rest } = useRequest<IMatchForContext>(
    `/api/match/${id}?userId=${userId}`
  );
  if (data) data = formatMatchResponse(data);
  return { data, ...rest };
}

/**
 * @param {string} userId
 * @param {string} matchId
 * @returns {ApiResponse}
 */
export async function inviteToLobby(userId, matchId) {
  return api.authedPost("/api/match/invite", {
    id: userId,
    matchId,
  });
}

/**
 * @param {string|number} id
 */
export async function getCourse(id) {
  return api.basicGet(`/api/courses/${id}`);
}

/**
 * @param {string} query
 */
export async function getCourses(query) {
  return api.basicGet(`/api/courses?query=${query}`);
}

/**
 * @param {object} options
 */
export async function createMatch(options) {
  return api.authedPost("/api/match/create", options);
}

/**
 * @param {number} matchId
 * @param {object} userSettings - settings of the host (only host can start match)
 */
export async function startMatch(matchId, userSettings) {
  return api.authedPost("/api/match/start", {
    matchId,
    userSettings,
  });
}

/**
 * List all users
 *
 * @param {string} query - filters users
 */
export async function getUsers(query) {
  return api.authedGet(`/api/user/search?query=${query}`);
}

/**
 * @param {number} inviteId
 */
export async function acceptInvite(inviteId) {
  return api.authedPut("/api/match/invite", {
    id: inviteId,
    status: constants.INVITE_STATES.ACCEPTED,
  });
}

/**
 * Host deleting invite
 * @param {string} userId
 * @param {string} matchId
 * @returns {ApiResponse}
 */
export async function deleteInvite(userId, matchId) {
  return api.authedDelete("/api/match/invite", {
    userId: userId,
    matchId,
  });
}

/**
 * Invited player denying invite
 * @param {number} inviteId
 */
export async function denyInvite(inviteId) {
  return api.authedPut("/api/match/invite", {
    id: inviteId,
    status: constants.INVITE_STATES.DENIED,
  });
}

/**
 * @param {number} matchId
 * @param {object} settings
 */
export async function readyForMatch(matchId, settings) {
  return api.authedPost("/api/match/ready", {
    matchId,
    settings,
  });
}

/**
 * @param {object} data
 */
export async function setScore(data) {
  return api.authedPost("/api/match/score", data);
}

/**
 * @param {number} matchId
 */
export async function deleteRound(matchId) {
  return api.authedDelete("/api/match/round", { matchId });
}

/**
 * @param {number} matchId
 */
export async function leaveLobby(matchId) {
  return api.authedPost("/api/match/leave-lobby", { matchId });
}

/**
 * Verify scores of the player your marking
 *
 * @param {string} matchId
 * @param {string} userId - id of user your verifying scores of
 */
export async function verifyRound(matchId, userId) {
  return api.authedPost(`/api/match/${matchId}/verify`, {
    userId,
  });
}

/**
 *
 * MOTHERSHIP aka CLUBHOUSE
 *
 **/

/**
 * @param {object} options
 * Example options = {
 *   competitionName: "Name 1" // less than 25 chars
 *  competitionType: "Race to the top" // or "Eclectic"
 *  courseName: "Name 2" // less than 25 chars
 *  days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
 *  duration: "6 weeks" // 6 weeks -> 15 weeks
 *  players: [player1ID, player2ID, player3ID]
 * }
 */
export async function createCompetition(options) {
  return api.authedPost("/api/competitions", options);
}

/**
 * @param {array} playerIDs
 */
export async function editCompetition(playerIDs) {
  return api.authedPut("/api/mothership/competitions/edit", {
    playerIDs: playerIDs,
  });
}

/**
 * Examples showing how the routes relate

  const competitionPreviewRes = await api.getCompetitionPreviews();
  const competitionsRes = await api.getCompetitions();
  const competitionRes = await api.getCompetition(competitionsRes.data[0].id);
  const dailyResultsRes = await api.getDailyResults();
  const historyRes = await api.getHistory();
  const getUserRound = await api.getUserRound(dailyResultsRes.data.rounds[0].userRound.id);
 */

/**
 * List of competition data for preview cards
 * Matching UI: initial 04 "Your competitions" in figma
 */
export async function getCompetitionPreviews() {
  return api.authedGet("/api/competitions");
}
export async function getCompetitionPreviewsMock() {
  const res = await fetch("/api/mothership/competitions/preview");
  return res.json();
}

/**
 * List of competitions with full data
 * Matching UI: competition 01 in figma
 */
export async function getCompetitions() {
  const res = await fetch("/api/mothership/competitions");
  return res.json();
}

/**
 * Get full data of a single competition
 * Matching UI: competition 02 in figma
 *
 * @param {string} id - competition id
 */
export async function getCompetitionMock(id) {
  const res = await fetch(`/api/mothership/competitions/${id}`);
  return res.json();
}

interface ICompDataFromServer {
  competition: ICompetition;
  rounds: any;
}
export function useGetCompetition(id: string | number, sort: string | null) {
  const searchParams = objectToURLSearchParams({ sort });
  return useRequest<ICompDataFromServer | null>(
    `/api/competitions/${id}?${searchParams.toString()}`
  );
}

// Shape of data returned by update competition endpoint is not needed because we simply re fetch competition data by calling the mutate() method
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ICompUpdateDataFromServer {
  competition: ICompetition;
}
export async function updateCompetition(
  id: number,
  data: {
    players?: number[];
    days?: string[];
  }
) {
  const res = await api.authedPut(`/api/competitions/${id}`, data);
  if (res.error) {
    return res;
  }
}

/**
 * @param {number} id
 * @returns {ApiResponse}
 */
export async function deleteCompetition(id) {
  return api.authedDelete(`/api/competitions/${id}`);
}

export interface IGetRoundPreviewData {
  data: {
    rounds: ITableRound[];
  };
}

/**
 * List of rounds played by any user today
 * Filtered by course
 * Matching UI: initial 04 "Daily Results" in figma
 */
export function useGetDailyResults(
  courseId: number,
  sortBy: string | undefined = undefined
) {
  const searchParams = objectToURLSearchParams({
    course: courseId,
    sort: sortBy,
  });
  return useRequest<IGetRoundPreviewData>(
    `/api/mothership/daily-results?${searchParams.toString()}`
  );
}

/**
 * List of rounds played by the current user
 * Filtered by course
 * Matching UI: "Your History" page, link shown in side navigation
 */
export function useGetHistory(
  courseId: number,
  sortBy: string | undefined = undefined
) {
  const searchParams = objectToURLSearchParams({
    course: courseId,
    sort: sortBy,
  });
  return useRequest<IGetRoundPreviewData>(
    `/api/mothership/history?${searchParams.toString()}`
  );
}

/**
 * Users eclectic round for a given course
 */
export function useGetEclecticRound(courseId: number) {
  const searchParams = objectToURLSearchParams({
    course: courseId,
  });
  return useRequest<IEclecticRoundData>(
    `/api/mothership/eclectic-round?${searchParams.toString()}`
  );
}

/**
 * Get data of a single round
 * Matching UI: Expanded accordion on "initial 04 - open" in figma
 */
export async function getUserRound(id: string) {
  const res = await fetch(`/api/mothership/user-round/${id}`);
  return res.json();
}

interface IUnverifiedRoundsData {
  unverifiedRounds: Record<string, any>;
}
/**
 * Get list of unverifed rounds not yet verifed
 * Either:
 * * your markers round you haven't signed for
 * * your round your marker hasn't signed
 * In other words, rounds of yours
 */
export function useGetUnverifiedRounds() {
  return useRequest<IUnverifiedRoundsData>("/api/mothership/unverified-rounds");
}

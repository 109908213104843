import { Box, Flex, Text, Button } from "@chakra-ui/react";

/*

  This wraps the input when opening a panel
  from the ConfigRowDrawer

*/

interface IConfigRowInputWrap {
  direction?: string | React.ReactNode;
  isValid: boolean;
  isGoBack?: boolean;
  isDisabled?: boolean;
  btnText?: string;
  onSubmit: () => void;
  children: React.ReactNode;
}

export const ConfigRowInputWrap = ({
  direction,
  isValid = true,
  isDisabled,
  isGoBack,
  onSubmit,
  btnText = "Save",
  children,
  ...props
}: IConfigRowInputWrap) => {
  return (
    <Flex direction="column" h="full" className="ConfigRowInputWrap">
      {direction && (
        <Text textStyle="copy" color="text.500" pb={4}>
          {direction}
        </Text>
      )}
      <Box flex="1">{children}</Box>
      <Box layerStyle="containerScoreBtn" px={0}>
        <Button
          variant={isGoBack ? "outline" : "default"}
          colorScheme="text"
          w="full"
          onClick={() => isValid && onSubmit()}
          isDisabled={isDisabled}
          // for type, etc
          {...props}
        >
          {btnText}
        </Button>
      </Box>
    </Flex>
  );
};

import { createBreakpoints } from "@chakra-ui/theme-tools";

/* https://github.com/chakra-ui/chakra-ui/blob/develop/packages/theme/src/foundations/breakpoints.ts */

// keys can't be quoted, eg. "2xl": "95em" doesn't work

const breakpoints = createBreakpoints({
  sm: "480px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xxl: "1520px",
  xxxl: "1840px",
  super: "2080px",
});

export default breakpoints;

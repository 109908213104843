import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
// import { Avatar, Box, Spinner, Button } from "@chakra-ui/react";
import { SettingsFormLayout } from "./SettingsFormLayout";
// import { initialValues, validationSchema } from "./FormSettings";
import * as Yup from "yup";
import * as schemas from "lib/schemas";
import * as authActions from "store/actions/authentication";
import { IUser } from "types";
// import { IUser } from "types";

interface IFormUpdateUserSettings {
  onClose: () => void;
}

const initialValues = (user: IUser) => ({
  email: user.email,
  fullName: user.fullName,
  golflinkNumber: user.golflinkNumber,
  handicap: user.handicap,
  avatar: undefined,
});

const FormUpdateUserSettings = ({ onClose }: IFormUpdateUserSettings) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  console.log("account user's course:", user.homeCourse.name);

  // submit
  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    const response = await dispatch(
      authActions.updateUser({
        fullName: values.fullName,
        golflinkNumber: values.golflinkNumber,
        handicap: values.handicap,
        avatar: values.avatar,
      })
    );

    if (response.error) {
      setSubmitting(false);
      setStatus({
        apiError: response.error,
      });
      return;
    }

    onClose();
  };

  return (
    <Formik
      initialValues={initialValues(user)}
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={Yup.object({
        fullName: schemas.fullName,
        golflinkNumber: schemas.golflinkNumber,
        handicap: schemas.handicap,
        avatar: schemas.avatar,
      })}
    >
      {() => <SettingsFormLayout avatarSrc={user.avatar} />}
    </Formik>
  );
};

export default FormUpdateUserSettings;

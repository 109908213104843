import React from "react";
import useGetMatchIdFromPath from "../hooks/useGetMatchIdFromPath";
import useMatchSocket from "../hooks/useMatchSocket";

export default function withMatchSocket(Component) {
  return (props) => {
    const id = useGetMatchIdFromPath();
    useMatchSocket(id);
    return <Component {...props} />;
  };
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSponsorsForCourse } from "store/reducers/sponsors";
import { fetchSponsorsForCourseIfNeeded } from "store/actions/sponsors";
import { selectors as authSelectors } from "store/actions/authentication";
import * as lib from "lib/sponsorship";
import { IPlayerBase } from "types";

export function useSponsorClubhouse() {
  const dispatch = useDispatch();
  const [sponsor, setSponsor] = React.useState(undefined);

  // Set-up
  // NB. golflinkNumber substring for Cairns = 40603 and DatoCourseId = 5618900
  const user: IPlayerBase = useSelector(authSelectors.getUser);
  const courseId = user.golflinkNumber.substring(0, 5);
  const date = new Date();
  const dayNumber = date.getDay();
  const isSunday = date.getDay() === 0;
  const isSaturday = date.getDay() === 6;

  // Get sponsors & defaultSponsor from state by the user's courseId
  const sponsorsObj = useSelector((state) =>
    selectSponsorsForCourse(state, courseId)
  );

  // Only fetch Dato sponsors if !state.sponsors[courseId]
  React.useEffect(() => {
    dispatch(fetchSponsorsForCourseIfNeeded(courseId));
  }, [dispatch, courseId]);

  const sponsors = sponsorsObj?.sponsors;
  const defaultSponsor = sponsorsObj?.defaultSponsor;

  // TESTS
  // console.log("use courseId", courseId);
  // console.log("use dayNumber", dayNumber);
  // console.log("use selectSponsorsForCourse", sponsorsObj);
  // console.log("use sponsors", sponsors);
  // console.log("use defaultSponsor", defaultSponsor);

  // If sponsors exist and are loaded, set the sponsor
  React.useEffect(() => {
    if (!!sponsors?.length) {
      const sponsor = sponsors.find(
        // if dayNumbers 0 or 6, match to `weekendSlot` 2 or 1
        // else, dayNumbers 1–5, match to `slot` of same number
        // See: https://www.notion.so/YLG-Sponsorship-requirements-6d4f9f644d4646f49c36b9a2400a0a3b
        (sponsor) => {
          if (isSaturday) {
            return lib.convertStrToNum(sponsor.weekendSlot) === 1;
          }
          if (isSunday) {
            return lib.convertStrToNum(sponsor.weekendSlot) === 2;
          }
          return lib.convertStrToNum(sponsor.slot) === dayNumber;
        }
      );
      setSponsor(sponsor);
    }
  }, [sponsors, dayNumber, isSunday, isSaturday]);

  return {
    sponsor,
    defaultSponsor,
  };
}

import { useFormikContext } from "formik";
import { MessageErrorMap } from "./index";

interface IMessageErrorApi {
  messageMap?: {
    [key: string]:
      | string
      | React.ReactNode
      | {
          primary: string | React.ReactNode;
          secondary?: string | React.ReactNode;
        };
  };
}

export const MessageErrorApi = ({ messageMap }: IMessageErrorApi) => {
  const formik = useFormikContext();
  const apiError = formik.status && formik.status.apiError;

  if (!apiError) return null;

  const mergedMessageMap = {
    ...messageMap,
    default: apiError.message,
  };

  return (
    <MessageErrorMap errorCode={apiError.code} messageMap={mergedMessageMap} />
  );
};

import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import { ScorecardRowForModal, ScorecardRowCard } from "./index";
import { LgModalCloseButton, LgModalInner } from "components/Block";
import { useUserContext } from "components/Context/User";
import { useScorecardContext } from "components/Context";
import * as courseLib from "lib/course";
import { useHoleStats } from "hooks/index";
import { HoleStatsProvider } from "components/Context";
import { ICourseHole, IHoleStatsContext } from "types";

/*
  Only used on ScorecardPresenter
  when the ScorecardContext variant is "scoring"
  ie. the Scoring flow

  Uses ScorecardRowCard which is
  roughly the same as ScoreInputPresenter
*/

export interface IScorecardRowModalCard {
  playerRoundSigned: boolean;
  markerRoundSigned: boolean;
  isMarker: boolean;
  mismatchingScore?: boolean;
}

interface IScorecardRowModal extends IScorecardRowModalCard {
  hole: ICourseHole;
}

export const ScorecardRowModal = ({
  isMarker,
  hole,
  mismatchingScore,
  playerRoundSigned,
  markerRoundSigned,
}: IScorecardRowModal) => {
  // Chakra
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Context
  const user = useUserContext();
  const { marker, match, matchType, scores, variant } = useScorecardContext();

  // Get hole stats and format for a new Context
  const holeStats = useHoleStats(hole.number);

  const holeStatsContext: IHoleStatsContext = {
    markerScores: holeStats.markerScores,
    markerTeeStats: holeStats.markerTeeStats,
    playerScores: holeStats.playerScores,
    playerTeeStats: holeStats.playerTeeStats,
    time: holeStats?.time,
  };

  const gender = isMarker && marker ? marker.gender : user.gender;

  // Hole details relative to the player & course
  const userPar = courseLib.getPar(hole, match.tee, gender);
  // const teeSelector = courseLib.getTeeSelector(match.tee, gender);
  // const { distance, index: strokeIndex } = teeSelector(hole);
  const { distance, index: strokeIndex } = courseLib.getTeeFromGender(
    gender,
    match.tee,
    hole
  );
  const renderScore = (hole: number) =>
    isMarker ? scores[hole].marker : scores[hole].user;

  return (
    <HoleStatsProvider value={holeStatsContext}>
      {/* pass variant & matchType as props so that ScorecardRow is not dependent on using useScorecardContext */}
      <ScorecardRowForModal
        distance={distance}
        holeNumber={hole.number}
        isFinalScorecard
        mismatchingScore={mismatchingScore}
        matchType={matchType}
        onClick={onOpen}
        par={userPar}
        scores={renderScore(hole.number)}
        strokeIndex={strokeIndex}
        variant={variant}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="full" motionPreset="scale">
        <ModalOverlay />
        <ModalContent m={0} bg="transparent" shadow="none" h="full">
          <LgModalInner>
            <LgModalCloseButton zIndex="100" />
            <ScorecardRowCard
              matchType={matchType}
              hole={hole}
              holeNumber={hole.number}
              isMarker={isMarker}
              onClose={onClose}
              playerRoundSigned={playerRoundSigned}
              markerRoundSigned={markerRoundSigned}
              mismatchingScore={mismatchingScore}
            />
          </LgModalInner>
        </ModalContent>
      </Modal>
    </HoleStatsProvider>
  );
};

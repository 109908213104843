import * as React from "react";
import {
  Flex,
  useMediaQuery,
  useDisclosure,
  Box,
  Icon,
} from "@chakra-ui/react";
import { MdWarning } from "react-icons/md";
import { TableRowElement, TableRowGrid } from "./index";
import { RowLeft, TextMeta, Score, Marker } from "./RowElements";
import { ScorecardModal } from "components/Notices";
// import { MdSwapHorizontalCircle } from "react-icons/md";
import { EMatchType, ITableRound } from "types";

/*
  TableRowRound is only used on Clubhouse tables. Similar to ScorecardRow.

  It takes a child of ScorecardPostMatch on Leaderboard, which unlike ScorecardRowModal, has no modal.

  AND YET! we include a modal at the base of this component! Maybe it should keep the same pattern as ScorecardRowModal?
 */

export function TableRowRound({
  index,
  placement,
  round,
  showRoundDate,
  sortBy,
  children,
}: {
  index: number;
  placement: number;
  round: ITableRound;
  showRoundDate?: boolean;
  sortBy?: string;
  children: React.ReactNode;
}) {
  const [breakpoint] = useMediaQuery("(min-width: 1024px)");
  const { isOpen, onOpen, onClose } = useDisclosure();

  // TESTS
  // console.log("round", round);
  // console.log("userRound", round.userRound);
  // console.log("scoreTotals.par", round.userRound.scoreTotals.par);

  return (
    // the `px` allows us nice overflow alignment on mobile
    // NB! must always be matched with mx on the parent!
    <TableRowGrid px={{ base: "inset", lg: 0 }}>
      {/* L */}
      <TableRowElement
        variant="round"
        isLeft
        hasDivider
        index={index}
        onClick={onOpen}
      >
        <Flex
          alignItems="baseline"
          justifyContent="flex-end"
          w={{ base: "20px", lg: 10 }}
          transform="translateY(0.25em)"
        >
          <TextMeta>{placement}</TextMeta>
        </Flex>
        <RowLeft
          player={round.player}
          date={showRoundDate ? round.userRound.createdAt : undefined}
          handicap={round.userRound.dailyHandicap}
          showPlayingHandicap
          // ready for incomplete holes warning
          iconSlot={
            !round.userRound.verified && (
              <Box pl={1.5} transform="translateY(-0.1em)">
                <Icon as={MdWarning} color="form.bad" boxSize="1.2em" />
              </Box>
            )
          }
        />
      </TableRowElement>

      {/* R */}
      <TableRowElement
        variant="round"
        index={index}
        justifyContent="flex-end"
        showGradient={breakpoint ? false : true}
        // this whole thing is wrapped by TableRowGrid
        // but setting a width here is a nice easy customisation
        // width={{ xl: "540px" }}
        onClick={onOpen}
      >
        {breakpoint && round.marker && <Marker name={round.marker.fullName} />}
        <Score
          value={round.userRound.scoreTotals.stableford}
          matchType={EMatchType.stableford}
          isActive={sortBy === EMatchType.stableford}
          showEdge
        />
        <Score
          value={round.userRound.scoreTotals.par}
          matchType={EMatchType.par}
          isActive={sortBy === EMatchType.par}
          showEdge
        />
        {/* "Nett" */}
        <Score
          value={round.userRound.scoreTotals.nett}
          matchType={EMatchType.stroke}
          isActive={sortBy === EMatchType.stroke}
          showEdge
        />
      </TableRowElement>

      {/* Scorecard modal */}
      <ScorecardModal isOpen={isOpen} onClose={onClose}>
        {children}
      </ScorecardModal>
    </TableRowGrid>
  );
}

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useField } from "formik";
import { Text, VStack, Box } from "@chakra-ui/react";
import { FormElement } from "./index";
import { SearchFormikArrayContainer } from "components/Search";
import { getUsersFactory } from "lib/getUsersFactory";
import { ICourse } from "types";
import { DeleteCompPlayerModal } from "components/Notices";

const RenderResult = (item) => {
  return (
    <VStack align="flex-start" spacing="2px">
      <Text textStyle="body">{item.fullName}</Text>
      <Text textStyle="crumb" fontWeight="500" color="primary.300">
        {item.homeCourse.name}
      </Text>
    </VStack>
  );
};

// interface FormSearchPlayerProps = {
//   // [x: string]: string | FieldHookConfig<any>;
//   [x: string]: any;
// }

export const FormSearchPlayer = (props) => {
  const user = useSelector((state) => state.authentication.user);
  const getUsers = useMemo(() => getUsersFactory(user.id), [user.id]);
  const [field, , helpers] = useField(props as any);
  const { value } = field;
  const { setValue } = helpers;

  const handleRemovePlayer = (player) => {
    // filter out the player from value
    const newValue = value.filter((x) => x.id !== player.id);
    // setValue
    return setValue(newValue);
  };

  return (
    <>
      <SearchFormikArrayContainer
        getItems={getUsers}
        selector={(item: ICourse) => item.clubName}
        // spread props for Formik
        {...props}
        noResultsMessage={
          <>
            Sorry, we couldn't find anyone by that name.
            <br />
            <Box as="span" fontWeight="400">
              You'll need to ask them to sign up.
            </Box>
          </>
        }
        renderResults={RenderResult}
        showSearchIcon="right"
      />

      {/* List the selected players using Formik value */}
      {value.length > 0 && (
        <FormElement>
          {value?.map((player, index) => (
            // <Text key={player.id} textStyle="body" color="text.800">
            //   {player.fullName}
            // </Text>
            <DeleteCompPlayerModal
              key={index.toString()}
              player={player}
              index={index}
              onClick={() => handleRemovePlayer(player)}
            />
          ))}
        </FormElement>
      )}
    </>
  );
};

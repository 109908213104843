import { Text, VStack } from "@chakra-ui/react";
import { SearchFormikContainer } from "components/Search";
import { MessageNoCourse } from "components/Lobby";
import { fetchCourses } from "lib/fetchCourses";
import { ICourse } from "types";

const RenderResult = (item) => {
  return (
    <VStack align="flex-start" spacing="2.5px">
      <Text as="span" textStyle="body" color="text.900" fontWeight={500}>
        {item.clubName}
        {item?.courseName !== null && (
          <Text as="span" color="text.500" fontWeight={400}>
            {" "}
            {item?.courseName}
          </Text>
        )}
      </Text>
      <Text textStyle="crumb" fontWeight={400} color="text.400">
        {item.name}
      </Text>
    </VStack>
  );
};

// NB: Formik requires a name prop to work
// This is passed implicitly to the search component from
// FormCompLayout b/c that component is wrapped by Formik's context
// We have access to name in this component at `field.name`

export const FormSearchCourse = (props) => {
  // console.log("FormSearchCourse courses", fetchCourses);
  return (
    <SearchFormikContainer
      getItems={fetchCourses}
      selector={(item: ICourse) => item.clubName}
      // spread props for Formik
      {...props}
      showSearchIcon="right"
      renderResults={RenderResult}
      noResultsMessage={<MessageNoCourse />}
    />
  );
};

import { LgHeader } from "components/Block/LgHeader";
import { FormResendVerificationEmail } from "components/Forms/";
import { BlockContainerScore } from "components/Block2";

const ResendVerificationEmailPage = () => {
  return (
    <BlockContainerScore spacing={0} pb="w7">
      <LgHeader heading="Verify your email" headingKind="hSecondary" />
      <FormResendVerificationEmail />
    </BlockContainerScore>
  );
};

export default ResendVerificationEmailPage;

import {
  TabList,
  TabPanels,
  TabPanel,
  Box,
  VStack,
  Text,
} from "@chakra-ui/react";
import { ScoreSheetTable, ScoreSheetMessage } from "./index";
import { ScoreTab, ScoreTabs } from "../ScoreTabs";
import { ScoreMessageNoScore } from "../Score";

interface ISheet {
  title: string;
  holes: any[];
  strokes: any[];
  scores: any[];
  pars: any[];
  handicap: number;
  hasShotANoScore: boolean;
}

interface IScoreSheetPresenter {
  scoresheets: ISheet[];
  matchType: string;
  scoreLabel: string;
  goToHole: (hole: number) => void;
  isSocial: boolean;
}

export const ScoreSheetPresenter = ({
  isSocial,
  scoresheets = [],
  matchType,
  scoreLabel,
  goToHole,
}: IScoreSheetPresenter) => {
  return (
    <>
      <Box bg="complement.50" pt={16} pb={4} px={4} mx={-6}>
        <Text as="h2" textStyle="hSecondary" px={2}>
          Scorecard
        </Text>
      </Box>
      {/* <Divider bordercolor="text.800" borderWidth="1px" /> */}

      <ScoreTabs mt={0}>
        {!isSocial && (
          <TabList mx={-6} maxW="auto">
            {scoresheets.map((scoresheet, index) => (
              <ScoreTab
                key={index}
                handicap={scoresheet.handicap}
                label={scoresheet.title}
              />
            ))}
          </TabList>
        )}

        <TabPanels pt={4}>
          {scoresheets.map((scoresheet, index) => (
            <TabPanel key={index} p={0}>
              <Box overflowX="scroll">
                <Box style={{ scrollSnapType: "x mandatory" }}>
                  {/*
                    NOTE WELL:
                    Score labels are variations derived from
                    constants.MATCH_TYPES (set within store/actions/match)
                    which we use, among other things, to handle score signs
                    for specific match types.
                    Need to handle more score signs? Do so from within a lib/score function.
                  */}

                  <VStack spacing={4} w="full">
                    {/* Holes 1-9 */}
                    <ScoreSheetTable
                      holeTotalLabel="Out"
                      holes={scoresheet.holes[0]}
                      strokes={scoresheet.strokes[0]}
                      scores={scoresheet.scores[0]}
                      pars={scoresheet.pars[0]}
                      matchType={matchType}
                      scoreLabel={scoreLabel}
                      goToHole={goToHole}
                    />
                    {/* Holes 10-18 */}
                    <ScoreSheetTable
                      holeTotalLabel="In"
                      holes={scoresheet.holes[1]}
                      strokes={scoresheet.strokes[1]}
                      scores={scoresheet.scores[1]}
                      pars={scoresheet.pars[1]}
                      matchType={matchType}
                      scoreLabel={scoreLabel}
                      goToHole={goToHole}
                    />

                    {/* INFO MESSAGES: show `Noticed` by default, show `NoScore` when there is a NoScore */}
                    <Box pt={3}>
                      {/* if any score is `noScore`, show message explaining why strokes are equivalent to a double-bogey*/}
                      {scoresheet.hasShotANoScore ? (
                        <ScoreMessageNoScore
                          onProgScorecard
                        ></ScoreMessageNoScore>
                      ) : (
                        <ScoreSheetMessage heading="Noticed an error?">
                          Click on any hole to change a score. Once fixed, open
                          this scorecard again and choose your current hole to
                          continue playing.
                        </ScoreSheetMessage>
                      )}
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </ScoreTabs>
    </>
  );
};

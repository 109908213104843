import { LgHeader } from "components/Block/LgHeader";
import { FormLogin } from "components/Forms";
import { BlockContainerScore } from "components/Block2";

const LoginPage = () => {
  return (
    <BlockContainerScore spacing={0} pb="w7">
      <LgHeader heading="Sign in" />
      <FormLogin />
    </BlockContainerScore>
  );
};

export default LoginPage;

// @refresh reset
// https://next.chakra-ui.com/docs/features/text-and-layer-styles

// 2. Consume the text styles in your components
// function Example() {
//   return <Box layerStyle="selected">This is a box</Box>
// }

// 3. You can also switch layer styles
// function Example({ isSelected }) {
//   const layerStyle = isSelected ? "selected" : "base"
//   return <Box layerStyle={layerStyle}>This is a box</Box>
// }

const navH = { base: "54px", md: "72px", xxl: "80px" };
// const scoreW = { base: "35px", lg: "48px" };

const layerStyles = {
  gapInset: {
    p: { base: 8, lg: 12 },
  },
  gapGrid: {
    gap: { base: 8, lg: 12 },
  },

  /* NAVBAR HEIGHT */
  navHeight: {
    h: navH,
  },
  mtNav: {
    top: navH,
  },

  /* CONTAINER INSET */
  containerInset: {
    px: "inset",
  },
  containerInsetFull: {
    px: "inset",
    py: 6,
  },
  containerNav: {
    h: navH,
    px: { base: 3, lg: 6 },
  },
  containerScoreBtn: {
    pt: 6,
    px: "inset",
    // this matches screen-pb: 4rem
    pb: 16,
  },

  /* OTHER */

  dividerIntro: {
    borderBottomColor: "black",
    borderBottomWidth: "2px",
    opacity: "100%",
  },
  dividerProductCard: {
    borderBottomColor: "blackAlpha.600",
    opacity: "100%",
  },
  base: {
    bg: "gray.50",
    border: "2px solid",
    borderColor: "gray.500",
  },
  selected: {
    bg: "teal.500",
    color: "teal.700",
    borderColor: "orange.500",
  },
  iconCircleSm: {
    width: "40px",
    height: "40px",
    border: "1px solid black",
  },
  iconCircleLg: {
    display: ["none", "", "flex"],
    width: "100px",
    height: "100px",
    marginTop: ["-50px"],
    marginBottom: ["-50px"],
    marginLeft: ["", "-100px"],
    border: "1px solid black",
    lineHeight: "0",
  },
  hr: {
    border: "none",
    height: "1px",
    backgroundColor: "black",
    marginRight: ["0", "0", "-100px"],
    marginBottom: ["25px", "25px", "0"],
  },
};

export default layerStyles;

import { TabList, TabPanels, TabPanel } from "@chakra-ui/react";
import { ConfigRowPickerScore } from "components/Config";
import { ScoreTab, ScoreTabs } from "components/ScoreTabs";
import * as nameLib from "lib/name";
import { useScorecardContext } from "components/Context";

export const ScoreInputPickers = ({
  tabIndex,
  setTabIndex,
  userStrokes,
  markerScores, // used as a toggle for marker UI
  markerStrokes,
  handleSetPlayerStrokes,
  handleSetMarkerStrokes,
}) => {
  // Context
  const {
    matchType,
    userMatchSettings,
    marker,
    markerMatchSettings,
  } = useScorecardContext();

  return (
    <ScoreTabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
      <TabList>
        {markerScores && (
          <ScoreTab
            label={nameLib.getFirstName(marker.fullName)}
            handicap={markerMatchSettings.dailyHandicapForShow}
          ></ScoreTab>
        )}
        <ScoreTab
          label="Your score"
          handicap={userMatchSettings.dailyHandicapForShow}
          showNoBorder={!markerScores}
        ></ScoreTab>
      </TabList>

      {/* PANELS for PICKERS */}
      <TabPanels d="flex" flex="1">
        {markerScores && (
          <TabPanel flex="1" py={0} px="inset">
            <ConfigRowPickerScore
              matchType={matchType}
              onSelect={handleSetMarkerStrokes}
              valueGroups={{
                unit: markerStrokes.toString(),
              }}
            />
          </TabPanel>
        )}
        <TabPanel flex="1" py={0} px="inset">
          <ConfigRowPickerScore
            matchType={matchType}
            onSelect={handleSetPlayerStrokes}
            valueGroups={{
              unit: userStrokes.toString(),
            }}
          />
        </TabPanel>
      </TabPanels>
    </ScoreTabs>
  );
};

import { SimpleGrid, GridItem } from "@chakra-ui/react";

export const FormElement = ({
  className = "FormElement",
  label,
  children,
  ...props
}: {
  className?: string;
  label?: React.ReactNode;
  children: React.ReactNode;
  [x: string]: any;
}) => {
  // const [field, meta] = useField(props);
  return (
    <SimpleGrid
      className={className}
      columns={{ base: 2, lg: 9 }}
      // buttonH at lg only
      minH={{ base: "auto", lg: "48px" }}
      spacing={{ base: 1, lg: 4 }}
    >
      {/* LABEL: no alignSelf:center to allow flexible alignment */}
      {label !== undefined && (
        <GridItem colSpan={{ base: 2, lg: 3 }}>{label}</GridItem>
      )}

      {/* INPUT: alignSelf:center default */}
      <GridItem
        colSpan={{ base: 2, lg: 4 }}
        colStart={{ base: 1, lg: 4 }}
        pos="relative"
        alignSelf="center"
      >
        {children}
        {/* <Box w="full">
            {meta.touched && meta.error ? (
              <Text color="red">{meta.error}</Text>
            ) : null}
            <Text color="red">Hey you have error</Text>
          </Box> */}
      </GridItem>
    </SimpleGrid>
  );
};

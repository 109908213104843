import { HStack, StackDivider, Flex, Icon } from "@chakra-ui/react";
import { MdSwapHorizontalCircle, MdWarning } from "react-icons/md";
import { ScorecardRowBase, RowHoleScore } from "./index";
import * as scoreLib from "lib/score";
import { IScoresBasic, EMatchType } from "types";

/*
  ScorecardRowForModal is used in ScorecardRowModal and ScorecardRowModalHistorical
  * Augments ScoreRowX so we can config icons & scores for non-eclectic rounds
  * Has a different scores shape to ScorecardRowForEclectic
  * We pass variant & matchType as props so that ScorecardRow is not dependent
    on using useScorecardContext
 */

interface IScorecardRowForModal {
  isFinalScorecard?: boolean;
  matchType: EMatchType;
  mismatchingScore?: boolean;
  scores: IScoresBasic;
  [x: string]: any;
}

export const ScorecardRowForModal = ({
  isFinalScorecard,
  matchType,
  mismatchingScore,
  scores,
  ...props
}: IScorecardRowForModal) => {
  return (
    <ScorecardRowBase {...props}>
      {/* ICONS */}
      {/* Sync warning icon */}
      {mismatchingScore && (
        <Flex
          h="full"
          ml={scores.noScore ? 2 : undefined}
          transform="translateX(0.6em)"
        >
          <Icon as={MdWarning} color="form.bad" boxSize="1.2em" />
        </Flex>
      )}

      {/* No score info icon */}
      {scores.noScore && (
        <Flex h="full" transform="translateX(0.6em)">
          <Icon as={MdSwapHorizontalCircle} color="form.good" boxSize="1.4em" />
        </Flex>
      )}

      {/* SCORES */}
      <HStack
        divider={
          <StackDivider borderColor="grey.100" sx={{ mx: "0 !important" }} />
        }
      >
        <RowHoleScore
          score={!isFinalScorecard && scores.noScore ? "-" : scores.strokes}
        />
        {matchType !== EMatchType.stroke && (
          <RowHoleScore
            score={scoreLib.scoreSign(scores, matchType)}
            centerByWidth={EMatchType.par ? true : false}
          />
        )}
      </HStack>
    </ScorecardRowBase>
  );
};

import { Link } from "react-router-dom";
import {
  Icon,
  Box,
  Flex,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import {
  NavsideShell,
  NavsideLink,
  NavsideHeading,
  NavbarAccountPresenter,
} from "./index";
import { LgLogoInline } from "../Block";
import { BlockStack } from "../Block2";
import { SupervisedUserCircle } from "../Icons";
import { useGetUnverifiedRounds } from "lib/api";

export const Navside = ({ path = "/" }: { path: string }) => {
  // CF: use routes for dynamic components
  // import { useLocation, Link } from "react-router-dom";
  // const location = useLocation();
  // use: { location.pathname !== "/" && () }

  const unverifiedRoundsRes = useGetUnverifiedRounds();
  const hasUnverifiedRounds = !!unverifiedRoundsRes.data?.unverifiedRounds
    ?.length;

  const innerStackStyle = {
    flex: 1,
    pb: 0,
    spacing: "2px",
    zindex: 1,
  };

  const { onClose } = useDisclosure();
  function handleClose() {
    onClose();
  }

  // make the DOM clean: only render when needed
  // 1024 = breakpoints.lg
  const [render] = useMediaQuery("(min-width: 1024px)");

  if (!render) {
    return null;
  }

  return (
    <NavsideShell bg="neutral.200">
      <Flex direction="column" justifyContent="space-between" h="full">
        {/* TOP */}
        <Box pb="w4">
          {/* pb aligns with BlockContainer in LayoutClubhouse */}
          <Box pb="w6" color="text.800">
            <Link to="/">
              <LgLogoInline></LgLogoInline>
            </Link>
          </Box>

          {/* NAV */}
          <BlockStack alignItems="flex-start" spacing={4}>
            <BlockStack {...innerStackStyle}>
              <Link to="/clubhouse">
                <NavsideHeading
                  label="Clubhouse"
                  icon={<Icon as={MdHome} />}
                ></NavsideHeading>
              </Link>
              <NavsideLink
                label="Daily leaderboard"
                path="/clubhouse/leaderboard"
                activePath={path}
              ></NavsideLink>
              <NavsideLink
                hasUnverifiedRounds={hasUnverifiedRounds}
                label="Your History"
                path="/clubhouse/your-rounds-history"
                activePath={path}
              ></NavsideLink>
              <NavsideLink
                label="Eclectic round"
                path="/clubhouse/your-eclectic-round"
                activePath={path}
              ></NavsideLink>
            </BlockStack>
            <BlockStack {...innerStackStyle}>
              <Link to="/clubhouse/competitions">
                <NavsideHeading
                  label="Competitions"
                  icon={<SupervisedUserCircle />}
                ></NavsideHeading>
              </Link>
              <NavsideLink
                label="Your competitions"
                path="/clubhouse/competitions"
                activePath={path}
              ></NavsideLink>
              <NavsideLink
                label="Create competition"
                path="/clubhouse/new-competition"
                activePath={path}
              ></NavsideLink>
              {/* <NavsideLink
                label="Eclectic competitions"
                path="/clubhouse/competitions"
                activePath={path}
              ></NavsideLink> */}
            </BlockStack>
          </BlockStack>
        </Box>

        {/* ACCOUNT: pb matches sponsor block */}
        <Box pb="4">
          <NavbarAccountPresenter onParentClose={handleClose} />
        </Box>
      </Flex>
    </NavsideShell>
  );
};

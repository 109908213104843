import * as api from "../../lib/api";

export const SET_INVITES = "SET_INVITES";
export const ADD_INVITE = "ADD_INVITE";
export const DELETE_INVITE = "DELETE_INVITE";
export const ACCEPT_INVITE = "ACCEPT_INVITE";

/**
 * Accept an invite
 *
 * @param {number} inviteId
 */
export const acceptInvite = (inviteId) => async (dispatch) => {
  const response = await api.acceptInvite(inviteId);

  if (response.error) {
    return response;
  }

  dispatch({
    type: ACCEPT_INVITE,
    inviteId,
  });

  return { true: true };
};


/**
 * Deny an invite
 *
 * @param {number} inviteId
 */
export const denyInvite = (inviteId) => async (dispatch) => {
  const response = await api.denyInvite(inviteId);

  if (response.error && response.code !== "INVITE_NOT_FOUND") {
    return response;
  }

  dispatch({
    type: DELETE_INVITE,
    inviteId,
  });

  return { true: true };
};

import { combineReducers } from "redux";
import authentication from "./authentication";
import lobby from "./lobby";
import match from "./match";
import invites from "./invites";
import sponsors from "./sponsors";
import competitions from "./competitions";

const reducers = combineReducers({
  authentication,
  lobby,
  match,
  invites,
  sponsors,
  competitions
});

export default reducers;

import ReactMarkdown from "react-markdown";
import { Flex, Image, ImageProps, Link } from "@chakra-ui/react";
import { SponsorTextWrapper } from "./index";
import config from "config";
import { ISponsor } from "types";

// Backgrounds
// "#FFB4C3",
// "(to top,rgb(101 189 47) 50%,rgb(78 158 29) 93%,rgb(65 136 22))",
// "(to top, rgb(239, 239, 187), rgb(212, 211, 221))",
// "(to top,rgb(251 234 196),rgb(208 206 228) 70%, rgb(180 176 218))",
// "(to top,rgb(82 173 26) 66%,rgb(55 138 20) 93%,rgb(21 74 72 / 80%))",

// Old image defaults
// fallbackSrc = "https://www.datocms-assets.com/31722/1597977267-crans-sur-sierre-e1523884629576-375x48.jpg",
// src = "https://www.datocms-assets.com/31722/1601703210-sponsor-placeholder-copy-2x-100.jpg",

interface ISponsorInScore extends ImageProps {
  alt?: string;
  bg?: string;
  height?: string;
  sponsor: ISponsor;
  textColor?: string;
}

export const SponsorInScore = ({
  alt = "Your Local Golf | Sponsorship opportunities",
  bg = "linear-gradient(to top,rgb(23 105 109) 50%,rgb(21 74 72) 93%,rgb(21 74 72));",
  // IMPORTANT: determine height when composed for layout purposes
  height = "6.5rem",
  sponsor,
  textColor = "white",
}: ISponsorInScore) => {
  return (
    <Flex height={height} justify="center" className="SponsorInScore">
      <Flex
        background={bg}
        border="1px solid"
        direction="column"
        height="full"
        justify="center"
        maxW="840px"
        mx="auto"
        overflow="hidden"
        pos="relative"
        rounded={{ lg: "lg" }}
        w="full"
      >
        {sponsor?.image && (
          <>
            <Flex>
              <Link
                bg="neutral.300"
                color="text.500"
                fontSize="9px"
                fontWeight="500"
                href={config.brandUrl}
                isExternal
                pos="absolute"
                py="6px"
                px={3}
                right={0}
                target="_blank"
                textStyle="crumb"
                top={0}
                zIndex={1}
                _hover={{
                  color: "text.800",
                }}
              >
                advertise here
              </Link>
            </Flex>
            <Image
              pos="absolute"
              inset="0"
              h="full"
              w="full"
              objectFit="cover"
              src={sponsor.image.responsiveImage.src}
              alt={sponsor.image.alt || alt}
            />
          </>
        )}
        <SponsorTextWrapper color={textColor}>
          <ReactMarkdown source={sponsor?.text} linkTarget="_blank" />
        </SponsorTextWrapper>
      </Flex>
    </Flex>
  );
};

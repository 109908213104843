import { EMatchType } from "types";

/**
 * MatchType PAR
 * show par as even, under or over
 * @param {number} score
 */
export function parScoreSign(score: number): string {
  // if (score === undefined) return null;
  // if "no score", use the "+" provided to the `data` prop
  // eg. in ScoreSheetTable:213 via `score.noScore`
  // if (score === "+") return "–"; // no score converted to double bogey
  if (score > 0) return "+"; // loss, above par
  if (score < 0) return "–"; // win, below par
  return "□"; // even, par
}

export function parScoreNumber(score: number): string {
  if (score === 0) return "□";
  if (score < 0) return score.toString().slice(1);
  return score.toString();
}

/**
 * MatchType STABLEFORD
 * show stableford 0 score as `–`
 */
export function stablefordScoreSign(
  score: number | undefined
): string | number | undefined {
  if (score === undefined) return undefined;
  if (score === 0) return "–";
  return score;
}

/**
 * MatchType STROKES
 * return strokes as a doublebogey
 * used when shooting "no score" on a hole
 * for stableford and par matchtypes only
 *
 * @param {number|undefined}
 * @returns {number|null}
 */
export function showNoScoreAsDoubleBogey(par: string): number | undefined {
  if (par === undefined) return;
  return Number(par) + 2;
}

/**
 * MatchType PAR, STABLEFORD or STROKE
 * return a score as a sign
 * used in ScoreRow, ScorePoster & ScoreSheetTable
 *
 * @param {object} score
 * @param {number} score.score
 * @param {boolean} score.noScore
 * @param {object} matchType - one of constants.MATCH_TYPES
 * @returns {string|number}
 */
export function scoreSign(score, matchType) {
  if (matchType === EMatchType.par || matchType === "Par") {
    // all score types are converted to a sign
    // no need to check for score.noScore
    return parScoreSign(score.score);
  }
  if (matchType === EMatchType.stableford || matchType === "S'ford") {
    // only the "0" score is converted to a sign
    // check for score.noScore
    // NB! the T was added to "–" for testing purposes
    // return score.noScore ? "–T" : stablefordScoreSign(score.score);
    // return score.noScore ? "–" : score.score;
    return stablefordScoreSign(score.score);
  }
  // if you see an X, you made it this far…
  return score.noScore ? "–X" : score.score;
}

/**
 * MatchType STROKE
 * Show strokes score as par (like classic TV golf)
 * @param {number} strokes
 * @param {number} par
 * @returns {string|number}
 */
export function strokesAsPar(strokes, par) {
  if (strokes === undefined) return null;
  return strokes - par;
}

/**
 * MatchType PAR, STABLEFORD, STROKE
 * return a total score as a number, a sign or show nothing
 * used in ScoreSheetRow on a ProgressiveScoreCard
 * uses isStableford and isPar props on the component
 *
 * @param {number} total
 * @param {string} matchType - one of constants.MATCH_TYPES or EMatchType
 * @return {string|number}
 */
export function scoreSheetTotal(total, matchType) {
  const totalAsNumber = Number(total);
  if (matchType === "PAR" || matchType === "Par") {
    return totalAsNumber === 0 ? "□" : totalAsNumber;
  }
  if (matchType === "STABLEFORD" || matchType === "S'ford") {
    return totalAsNumber === 0 ? "–" : totalAsNumber;
  }
  return totalAsNumber;
}

/**
 * MatchType PAR
 * return a total score as a number, a sign or show nothing
 * used in ScoreSheetRow on a ProgressiveScoreCard
 * uses isStableford and isPar props on the component
 *
 * @param {number} total
 * @param {string} matchType - one of constants.MATCH_TYPES or EMatchType
 * @returns {string}
 */
export function scoreRowTotalSign(total: number, matchType: EMatchType) {
  if (matchType === EMatchType.par) {
    if (total === 0) return "□";
    return total > 0 ? `+${total}` : total.toString();
  }
  return total.toString();
}

/**
 * Sum reducer
 * @param {array} arr
 * @param {function} selector
 * @returns {number} sum total
 */
export const sum = (arr, selector) =>
  arr.reduce((sum, x) => {
    if (x) {
      const val = selector ? selector(x) : x;
      return val + sum;
    }
    return sum;
  }, 0);

/**
 * set the Player tab index (opposite of the marker's tab index)
 * @param {boolean} isSocial
 * @param {number} tabIndex
 * @returns {number} index
 */

// export const setPlayerTabIndex = (
//   isSocial: boolean,
//   tabIndex: number
// ): number => (isSocial ? (tabIndex = 0) : (tabIndex = 1));

// This makes no sense 4 months later. What is the intention???
export const setPlayerTabIndex = (
  isSocial: boolean,
  tabIndex: number
): boolean => (isSocial ? tabIndex === 0 : tabIndex === 1);

/**
 * Return total strokes by kind
 * !!! CURRENTLY NOT IN USE !!!
 * If matchType is stroke, we only adjust for handicap (nett) in the round totals, that's to say a nett stroke total isn't calculated until the round is completed. So we can't show a total Nett stroke score until the round is verified.
 * @param {string} matchType - one of constants.MATCH_TYPES or EMatchType
 * @param {number} roundsKindTotal
 * @param {number} totalsKindTotal
 * @returns {string}
 */
export const renderTotalStrokes = (
  matchType,
  roundsKindTotal,
  totalsKindTotal
) => (matchType === EMatchType.stroke ? roundsKindTotal : totalsKindTotal);

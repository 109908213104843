import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Box,
  HStack,
  Icon,
  Text,
  TabList,
  TabPanels,
  TabPanel,
  Link,
} from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import { MdError, MdArrowForward } from "react-icons/md";
import {
  ScorecardHeader,
  ScorecardPresenterPanel,
  ScorecardSettings,
  ScorecardCta,
} from "./index";
import { TableNotice } from "components/ClubTable";
import { ScoreTabs, ScoreTab } from "components/ScoreTabs";
import { VerifyRound } from "components/VerifyRound";
import useVerifyRound from "hooks/useVerifyRound";
import * as nameLib from "lib/name";
import * as dateLib from "lib/date";
import { useUserContext, useScorecardContext } from "components/Context";
import { EScorecardVariant } from "types";

/*
  ScorecardPresenter is used in the
  Scorecard and ScorecardPostMatch components
 */

interface IScorecardPresenter {
  notVerified: boolean;
  defaultTabsIndex?: number;
}

export const ScorecardPresenter = ({
  notVerified,
  defaultTabsIndex = 0,
}: IScorecardPresenter) => {
  const {
    isSocial,
    marker,
    markerCourse,
    markerMatchSettings,
    scores,
    uiConfig,
    userCourse,
    userMatchSettings,
    variant,
  } = useScorecardContext();
  const user = useUserContext();

  // `mismatchingScoreHoleNumbers` is an array of mismatching holes.
  // It won't be set until an attempt to verify the round is made.
  // It is used in ScorecardPresenterPanel to designate a ScorecardRowModal hole as having an issue. Otherwise, don't use this in the UI.
  const [mismatchingScoreHoleNumbers, setMismatchingScoreHoleNumbers] =
    useState<number[] | undefined>(undefined);

  // Handle marker round verification to allow scorecard signing
  const {
    isVerifyingRound, // Round is being verified
    verifyRoundResponse, // Round was verified just now
    roundIsVerified, // Round has been verifed now or in the past
    handleVerifyRound,
  } = useVerifyRound(setMismatchingScoreHoleNumbers);

  // Settings passed through to Scorecard components
  const dateTimeStarted: string = dateLib.toDateTimeString(scores[1].createdAt);

  // Intersection observer
  const [ref, headerInView] = useInView({ threshold: 0 });

  // FSC = Final Scorecard
  const fscHeaderInView =
    variant === EScorecardVariant.scoring && !headerInView;

  const practiceMatchNotice =
    "Because this is a practice match, your round will not be recorded in your Clubhouse stats";

  return (
    <Box bg="white" pos="relative" className="ScorecardPresenter">
      {/* HEADER */}
      {uiConfig.showHeader && (
        /* pass uiConfig & variant as props to ScorecardHeader so we can use it w/o being tied to context */
        <ScorecardHeader
          variant={variant}
          uiConfig={uiConfig}
          heading="Scorecard"
          settingsSlot={
            <Box ref={ref} pos="relative" className="inViewRef">
              <ScorecardSettings datetime={dateTimeStarted} />
            </Box>
          }
        />
      )}

      {marker === null && (
        <TableNotice mt={0}>{practiceMatchNotice}</TableNotice>
      )}

      {notVerified && (
        <Link
          as={ReactRouterLink}
          to={`/${userMatchSettings.match}/scorecard`}
          _hover={{ textDecoration: "none" }}
        >
          <Box
            bg="form.bad"
            color="white"
            fontWeight="400"
            px={{ base: 4, lg: 6 }}
            py={4}
            textStyle="body"
          >
            <HStack spacing={1.5} pb="1px">
              <Icon as={MdError} mt="-1px" />
              <Text as="div" fontWeight="500" pb="1px">
                This match has not been signed!{" "}
                <Text as="span" fontWeight="400">
                  Please sign it
                </Text>
              </Text>
              <Icon as={MdArrowForward} mt="-1px" />
            </HStack>
          </Box>
        </Link>
      )}

      {/* SCORECARD: 2 x tabs for user & marker (if isSocial) */}
      <Box pb={12}>
        <ScoreTabs defaultIndex={defaultTabsIndex}>
          <TabList
            w="full"
            pos={fscHeaderInView ? "fixed" : "sticky"}
            top={0}
            boxShadow={fscHeaderInView ? "md" : undefined}
            zIndex="101"
            backgroundColor="white"
            pt={fscHeaderInView ? "55px" : 0}
          >
            {!isSocial && marker && markerMatchSettings && (
              <ScoreTab
                label={`${nameLib.getFirstName(marker.fullName)}'s score`}
                handicap={markerMatchSettings.dailyHandicap}
              ></ScoreTab>
            )}
            <ScoreTab
              label="Your score"
              handicap={userMatchSettings.dailyHandicap}
              showNoBorder={isSocial}
              // visually hide this tab on TableEclecticRound
              className={
                variant === EScorecardVariant.eclectic ? "sr-only" : undefined
              }
            ></ScoreTab>
          </TabList>

          <TabPanels>
            {!isSocial && markerCourse && (
              <TabPanel py={0} px="inset">
                <ScorecardPresenterPanel
                  key={`marker-${Math.random()}`}
                  isMarker={true}
                  course={markerCourse}
                  mismatchingScoreHoleNumbers={mismatchingScoreHoleNumbers}
                  roundIsVerified={roundIsVerified}
                  // verify round is only available to the marker
                  verifyRoundSlot={
                    variant === EScorecardVariant.scoring && (
                      <Box py={3}>
                        <VerifyRound
                          isForMarker
                          playerName={user.fullName}
                          isVerifyingRound={isVerifyingRound}
                          verifyRoundResponse={verifyRoundResponse}
                          roundIsVerified={roundIsVerified}
                          handleVerifyRound={handleVerifyRound}
                        />
                      </Box>
                    )
                  }
                />
              </TabPanel>
            )}
            <TabPanel
              py={0}
              px={variant === EScorecardVariant.eclectic ? 0 : "inset"}
            >
              <ScorecardPresenterPanel
                key="user"
                isMarker={false}
                course={userCourse}
                mismatchingScoreHoleNumbers={mismatchingScoreHoleNumbers}
                roundIsVerified={roundIsVerified}
              />
            </TabPanel>
          </TabPanels>
        </ScoreTabs>
      </Box>

      {/* Goto Clubhouse */}
      {variant === EScorecardVariant.scoring && (
        <ScorecardCta
          practiceMatchNotice={
            marker === null ? (
              <Text
                as="div"
                textStyle="copy"
                fontWeight="500"
                color="orange.700"
                pb="2"
                px={12}
              >
                {practiceMatchNotice}
              </Text>
            ) : undefined
          }
          allowClubhouse={isSocial ? true : roundIsVerified}
        />
      )}
    </Box>
  );
};

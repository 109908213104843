import { Box, Button, UseDisclosureProps } from "@chakra-ui/react";
import { ConfigRow, ConfigRowDrawer } from "../Config";
import { LobbyUserSettings, InvitePlayers } from "./index";
// import { ILobbyUserSettings } from "./LobbyUserSettings";
import * as constants from "config/constants";
import { EConfigRowTheme } from "types";

export const LobbyHostPresenter = ({
  areUserSettingsValid,
  children,
  handicapSuccess,
  handleSettingsSubmit,
  handleStart,
  match,
  readyToStartMatch,
  setUserSetting,
  userSettings,
}: {
  areUserSettingsValid?: boolean;
  children: React.ReactNode;
  handicapSuccess?: boolean;
  handleSettingsSubmit: (boolean: any) => void;
  handleStart: () => void;
  match: any;
  readyToStartMatch?: boolean;
  setUserSetting: (key: string, value: any) => void;
  userSettings: any;
} & UseDisclosureProps) => {
  return (
    <>
      <Box layerStyle="containerInset" flex={1} pt={2}>
        {/* MESSAGES …static layouts only… */}
        {/* {props.lobbyInviteError && (
          <Message underHeader heading="Mixed markers">
            <Text as="div" textStyle="meta" color="white">
              Looks like your invited players are both marking themselves and
              nobody is marking you. Each player must have a marker.
            </Text>
          </Message>
        )} */}

        {/**
         * The selected course and play type are shown for context
         * They're disabled because changing the play type changes the
         * requirements to be ready and changing requirements after
         * the lobby is created is not handled
         **/}

        {/* COURSE */}
        <ConfigRow
          // name={match.course.state}
          name="Course"
          value={match.course.name
            .replace("Golf Club", "GC")
            .replace("Course", "")}
          theme={EConfigRowTheme.valid}
          isSet
        />

        {/* MATCH */}
        <ConfigRow
          name={`${constants.constantToFriendly(match.playType)} Match`}
          value={constants.constantToFriendly(match.matchType)}
          theme={EConfigRowTheme.valid}
          isSet
        />

        {/* TEE */}
        <ConfigRow
          name="Tee"
          value={constants.constantToFriendly(match.tee)}
          theme={EConfigRowTheme.valid}
          isSet
        />

        {/* HANDICAP */}
        <ConfigRowDrawer
          heading="Set round handicap"
          btnBackLabel="Settings"
          btnName={handicapSuccess ? "Round handicap" : ""}
          btnTheme={
            handicapSuccess ? EConfigRowTheme.valid : EConfigRowTheme.awaiting
          }
          btnValue={
            handicapSuccess
              ? userSettings.dailyHandicapForShow
              : "Confirm your handicap"
          }
        >
          {(disclosure) => (
            <LobbyUserSettings
              settings={userSettings}
              match={match}
              onChange={setUserSetting}
              isFormComplete={areUserSettingsValid}
              // onSubmit={() => [disclosure.onClose(), setHandicapSuccess]}
              // onSubmit={handleSettingsSubmit}
              onSubmit={() => [
                disclosure.onClose(),
                handleSettingsSubmit(true),
              ]}
            />
          )}
        </ConfigRowDrawer>
      </Box>

      {/* INVITE PLAYERS SEARCH: show only after `handicapSuccess` */}
      {!match.isSocial && handicapSuccess && (
        <InvitePlayers
          // opacity={!handicapSuccess && "0"}
          // transition="opacity 1s ease"
          markerId={userSettings.marker?.id}
          setUserSetting={setUserSetting}
        />
      )}

      {/* START BUTTON */}
      {/* mt={match.isSocial && "auto"} */}
      <Box layerStyle="containerScoreBtn" mt="auto">
        <Button
          variant="solid"
          colorScheme="textButton"
          w="full"
          isDisabled={!readyToStartMatch}
          isLoading={!readyToStartMatch}
          loadingText={
            !handicapSuccess ? "Confirm your handicap" : "Awaiting players"
          }
          onClick={handleStart}
        >
          Start playing
        </Button>
      </Box>

      {/* NOTICES */}
      {children}
    </>
  );
};

import { HStack, Box, Text, StackDivider } from "@chakra-ui/react";
import * as constants from "config/constants";
import { useScorecardContext } from "components/Context";

/*
  Display the ScorecardSettings
  eg. course, matchType, tee & time started
 */

interface IScorecardSettings {
  datetime: string;
}

export const ScorecardSettings = ({ datetime }: IScorecardSettings) => {
  const { uiConfig, userCourse, marker, match } = useScorecardContext();
  const courseName = userCourse?.name;
  const markerName = marker?.fullName;
  // const courseName = data?.userMatchSettings?.course.name;
  // const markerName = data?.userMatchSettings?.marker?.fullName;
  // console.log("ScorecardSettings", markerName);

  const style = {
    textStyle: "copy",
    fontWeight: 500,
    lineHeight: 1,
  };

  const crumbStyle = {
    textStyle: "crumb",
    fontWeight: 400,
    d: "block",
    pt: "5px",
  };

  return (
    // breeze.300
    // bg="complement.50"
    <Box w="full">
      <Text textStyle="crumb" fontWeight="400">
        {datetime}
      </Text>
      <HStack
        pt="10px"
        pb="3px"
        spacing={3}
        divider={<StackDivider borderColor="currentColor" opacity={0.5} />}
      >
        <Text {...style}>
          {courseName?.replace("Golf Club", "GC").replace("Course", "")}
          <Text as="span" {...crumbStyle}>
            course
          </Text>
        </Text>
        <Text {...style}>
          {constants.constantToFriendly(match?.matchType)}
          <Text as="span" {...crumbStyle}>
            match
          </Text>
        </Text>
        <Text {...style}>
          {constants.constantToFriendly(match?.tee)}
          <Text as="span" {...crumbStyle}>
            tee
          </Text>
        </Text>
        {uiConfig.showMarkerInSettings && markerName && (
          <Text {...style}>
            {markerName}
            <Text as="span" {...crumbStyle}>
              marker
            </Text>
          </Text>
        )}
      </HStack>
    </Box>
  );
};

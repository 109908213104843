import { LgHeader } from "components/Block/LgHeader";
import FormRegister from "components/Forms/Register";
import { BlockContainerScore } from "components/Block2";

const RegisterPage = () => {
  return (
    <BlockContainerScore spacing={0} pb="w7">
      <LgHeader heading="Create account" />
      <FormRegister />
    </BlockContainerScore>
  );
};

export default RegisterPage;

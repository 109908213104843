import { Link } from "react-router-dom";
import { Form, useFormikContext } from "formik";
import { chakra, Avatar, Box, Spinner, Button } from "@chakra-ui/react";
import {
  ConfigRowInput,
  ConfigRowInputWrap,
  ConfigRowDrawer,
  ConfigRowPickerHandicap,
} from "components/Config";
import { LgInputFile } from "components/Form/LgInputFile";
import { MessageErrorApi, Message, GolflinkNumberInput } from "components/Form";
import * as handicapLib from "lib/handicap";
import { EConfigRowTheme } from "types";

// chakra factory function to style the Formik Form
// NOT using the CHForm in the Form dir as unsure how that layerStyle may affect this layout…
export const ChForm = chakra(Form, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
  },
});

interface IFormUpdateUserSettings {
  avatarSrc: string;
}

export const SettingsFormLayout = ({ avatarSrc }: IFormUpdateUserSettings) => {
  const formik = useFormikContext<any>();

  // refresh the page if your avatar upload errors
  function refreshPage() {
    window.location.reload();
  }

  return (
    <ChForm id="update-user-settings" style={{ paddingTop: "12px" }}>
      <MessageErrorApi />

      {/* AVATAR */}
      {/* submitting state: {formik.isSubmitting && <h1>Hey!</h1>} */}
      <Box textAlign="center" pb={1} d={{ lg: "none" }}>
        {/* <LgInputFile
          handleSelect={(file) => formik.setFieldValue("avatar", file)}
        >
          <Avatar
            size="lg"
            border="3px solid white"
            name={formik.values.fullName}
            src={
              formik.values.avatar
                ? URL.createObjectURL(formik.values.avatar)
                : avatarSrc
            }
          />
          <Box pt={1}>
            {formik.isSubmitting ? (
              <Button
                as="span"
                colorScheme="textButton"
                variant="ghost"
                size="sm"
              >
                <Spinner size="xs" />
                <Box as="span" ml={1}>
                  Saving
                </Box>
              </Button>
            ) : (
              <Button
                as="span"
                colorScheme="textButton"
                variant="ghost"
                size="sm"
              >
                {formik.values.avatar ? "Save to update" : "Update"}
              </Button>
            )}
          </Box>
        </LgInputFile> */}

        <Avatar
          size="lg"
          border="3px solid white"
          name={formik.values.fullName}
          src={
            formik.values.avatar
              ? URL.createObjectURL(formik.values.avatar)
              : avatarSrc
          }
        />

        {formik.errors.avatar && (
          <Message heading={formik.errors.avatar}>
            Must be less than 2mb. Please remove{" "}
            <Box as="span" textDecoration="underline" onClick={refreshPage}>
              or refresh the page.
            </Box>
          </Message>
        )}
      </Box>

      {/* NAME */}
      <ConfigRowDrawer
        btnBackLabel="Account"
        btnName="Full name"
        btnTheme={EConfigRowTheme.valid}
        btnValue={formik.values.fullName}
        heading="Your full name"
      >
        {(disclosure) => (
          <ConfigRowInputWrap
            isValid={!formik.errors.fullName}
            onSubmit={() => disclosure.onClose()}
          >
            <ConfigRowInput placeholder="Type your name…" name="fullName" />
          </ConfigRowInputWrap>
        )}
      </ConfigRowDrawer>

      {/* EMAIL */}
      <ConfigRowDrawer
        btnBackLabel="Account"
        btnName="Email"
        btnTheme={EConfigRowTheme.valid}
        btnValue={formik.values.email}
        heading="Your email"
      >
        {(disclosure) => (
          <ConfigRowInputWrap
            isValid={!formik.errors.email}
            onSubmit={() => disclosure.onClose()}
            isGoBack
            btnText="Go Back"
            direction={
              <>
                Your email is not editable. If you need to change it, please
                contact{" "}
                <Link to="/support" style={{ textDecoration: "underline" }}>
                  support
                </Link>
                .
              </>
            }
          >
            <ConfigRowInput type="email" name="email" isDisabled />
          </ConfigRowInputWrap>
        )}
      </ConfigRowDrawer>

      {/* GOLFLINK NUMBER */}
      <GolflinkNumberInput btnBackLabel="Account" />

      {/* HANDICAP */}
      <ConfigRowDrawer
        btnBackLabel="Account"
        btnTheme={EConfigRowTheme.valid}
        btnName="Handicap"
        btnValue={
          formik.values.handicap
            ? handicapLib.forShow(formik.values.handicap.toString())
            : "Your handicap"
        }
        heading="Your handicap"
      >
        {(disclosure) => (
          <ConfigRowPickerHandicap
            handicap={formik.values.handicap}
            onSelect={(value) => {
              formik.setFieldValue("handicap", value);
              disclosure.onClose();
            }}
          />
        )}
      </ConfigRowDrawer>
    </ChForm>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { Heading } from "@chakra-ui/react";

/*
  HEADING
  Apply color mostly separately
*/

// …sizing ideas taken from Chakra codebase
// const headingSizes = {
//   "5xl": ["5xl", null, "6xl"],
//   "4xl": ["4xl", null, "5xl"],
//   "2xl": ["4xl", null, "5xl"],
//   xl: ["3xl", null, "4xl"],
//   lg: ["xl", null, "2xl"],
//   md: "xl",
//   sm: "md",
//   xs: "sm",
// };
// export const LgHeading = ({ size = "xl", kind, ...props }) => (
//   <Heading
//     fontSize={sizes[size]}
//     {...headingStyles[kind]}
//     {...props}
//   />
// );

const headingStyles = {
  primary: {
    fontSize: "5xl",
    fontWeight: "500",
    lineHeight: "none",
    letterSpacing: "normal",
    color: "primary.500",
    ml: "-2px",
    pb: "1px",
  },
  secondary: {
    fontSize: "4xl",
    fontWeight: "500",
    lineHeight: "none",
    letterSpacing: "tight",
    color: "primary.500",
    ml: "-1px",
    pb: "1px",
  },
  tertiary: {
    fontSize: "3xl",
    fontWeight: "500",
    lineHeight: "shortest",
    color: "primary.500",
  },
  minor: {
    fontSize: "xl",
    fontWeight: "500",
    lineHeight: "shortest",
    letterSpacing: "body",
    color: "primary.500",
  },
};

export const LgHeading = ({ kind, ...props }) => (
  <Heading {...headingStyles[kind]} {...props} />
);

LgHeading.propTypes = {
  kind: PropTypes.oneOf(["primary", "secondary", "tertiary", "minor"]),
};
LgHeading.defaultProps = {
  kind: "primary",
};

export const LgHeadingModal = ({
  kind = "tertiary",
  bg = "breeze.300",
  ...props
}) => (
  <LgHeading kind={kind} bg={bg} pt={16} pb={4} px={6} mx={-6} {...props}>
    {props.children}
  </LgHeading>
);

LgHeadingModal.propTypes = {
  kind: PropTypes.oneOf(["primary", "secondary", "tertiary", "minor"]),
};

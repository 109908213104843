import { Input } from "@chakra-ui/react";
import { useField, FieldHookConfig } from "formik";
import { FormElement, FormLabel, FormFieldError } from "./index";

export const FormInputText = ({
  label,
  placeholder,
  ...props
}: {
  label: string;
  placeholder: string;
  // name: string | FieldHookConfig<any>;
  [props: string]: string | FieldHookConfig<any>;
}) => {
  const [field, meta] = useField(props as any);

  return (
    <FormElement className="CompName" label={<FormLabel>{label}</FormLabel>}>
      <Input
        size="md"
        border="1px solid"
        borderColor="grey.100"
        _placeholder={{
          color: "text.300",
        }}
        _hover={{
          borderColor: "text.300",
        }}
        placeholder={placeholder}
        id={field.name}
        // onChange={field.onChange}
        // give Formik everything
        // https://formik.org/docs/api/useField
        {...field}
        {...props}
      />
      <FormFieldError meta={meta} />
    </FormElement>
  );
};

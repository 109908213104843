import { useDispatch } from "react-redux";
import { Box, Icon, useDisclosure } from "@chakra-ui/react";
import { DeleteModal } from "./index";
import { ConfigRow } from "../Config";
// import { MdDeleteForever } from "react-icons/md";
import { MdAutorenew } from "react-icons/md";
import * as authActions from "store/actions/authentication";
// import { EConfigRowTheme } from "types";

export const DeleteAccountModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  function handleConfirmDeleteAccount() {
    dispatch(authActions.deleteAccount());
  }

  return (
    <>
      <ConfigRow
        borderColor="transparent"
        iconSlot={
          <Box mr={2}>
            <Icon as={MdAutorenew} />
          </Box>
        }
        onClick={onOpen}
        opacity="0.333"
        name="Delete Account"
      ></ConfigRow>

      <DeleteModal
        heading="Are you sure?"
        confirm="Are you sure you want to delete your account? If you delete your account, your account's history and personal information are completely removed."
        buttonLabel="Delete account"
        isOpen={isOpen}
        onClose={onClose}
        onClick={handleConfirmDeleteAccount}
      />
    </>
  );
};

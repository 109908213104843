import { Container, ContainerProps } from "@chakra-ui/react";

/*
  BlockContainerClubhouse is a container for the Clubhouse content.
  Unlike BlockContainerScore, it does not manage spacing between the vertical sections. It's just a div.
*/

export const BlockContainerClubhouse = ({
  as = "div",
  children,
  ...props
}: ContainerProps) => (
  <Container
    as={as}
    className="BlockContainerClubhouse"
    maxW="container.xl"
    ml="0"
    pt={{
      base: "calc(75px + var(--chakra-space-w7))",
      lg: "calc(12px + var(--chakra-space-w7))",
    }}
    px="inset"
    w="full"
    {...props}
  >
    {children}
  </Container>
);

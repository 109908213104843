// import React from "react";
// import { Box } from "@chakra-ui/react";
import { LayoutClubhouse } from "components/Layout";
import { CompetitionsList } from "components/ClubComp";
import { SponsorInScore } from "components/Sponsor";
import { BlockStack } from "components/Block2";
import { useSponsorClubhouse } from "hooks";

export const ClubhouseCompetitionsPage = () => {
  const { sponsor, defaultSponsor } = useSponsorClubhouse();

  return (
    <LayoutClubhouse
      sponsorSlot={<SponsorInScore sponsor={sponsor || defaultSponsor} />}
    >
      <BlockStack pb="w11">
        <CompetitionsList />
      </BlockStack>
    </LayoutClubhouse>
  );
};

import { useFormikContext } from "formik";
import {
  Box,
  Text,
  Input,
  Button,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
// import { ArrowDownIcon } from "@chakra-ui/icons";
import { LgLink } from "components/Typography";
import { MessageErrorApi, Message, ChForm } from "components/Form";
import DefaultVStack from "components/VStack";

export const LoginFormLayout = ({
  fromAccountVerified,
  fromPasswordReset,
}: {
  fromAccountVerified?: boolean;
  fromPasswordReset?: boolean;
}) => {
  const formik = useFormikContext<any>();

  const apiMessageErrorMap = {
    EMAIL_UNVERIFIED: {
      primary: "Please verify your email.",
      secondary: (
        <>
          Please check your email for a verification code. Can't find it?{" "}
          <LgLink to="/resend-verification-email" label="Send a new link" />
        </>
      ),
    },
    INVALID_CREDENTIALS: {
      primary: "Incorrect email or password",
    },
  };

  return (
    <ChForm>
      <DefaultVStack flex="1" spacing={4}>
        {/* Only show redirection messages until 1st submission is complete */}
        {(!formik.submitCount ||
          (formik.submitCount === 1 && formik.isSubmitting)) && (
          <>
            {/* PASSWORD RESET MSG */}
            {fromPasswordReset && (
              <Message
                success
                underHeader
                heading="Your password has been reset"
              >
                Please signin with your new password
              </Message>
            )}
            {/* EMAIL VERIFICATION REDIRECT: SHOW VERIFIED MESSAGE */}
            {fromAccountVerified && (
              <Message
                success
                underHeader
                heading="Thanks for verifying your email"
              >
                We've enabled your account
              </Message>
            )}
          </>
        )}

        {/* SIGNIN ERRORS */}
        <MessageErrorApi messageMap={apiMessageErrorMap} />

        {!fromAccountVerified && (
          <Text textStyle="copy" color="grey.400">
            Don't have an account? <LgLink to="/signup" label="Sign up" />
            <br />
            Forgot your password?{" "}
            <LgLink to="/forgot-password" label="Click here" />
          </Text>
        )}

        <FormControl
          isInvalid={formik.errors.email && formik.touched.email ? true : false}
        >
          <Input
            variant="setRow"
            {...formik.getFieldProps("email")}
            id="email"
            placeholder="Type your email…"
            type="email"
          />
          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={
            formik.errors.password && formik.touched.password ? true : false
          }
        >
          <Input
            variant="setRow"
            {...formik.getFieldProps("password")}
            id="password"
            placeholder="Type your password…"
            type="password"
          />
          <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
        </FormControl>
      </DefaultVStack>

      {/* keep outside VStack for mobile layout */}
      <Box pt={{ lg: 12 }}>
        <Button
          type="submit"
          variant="solid"
          colorScheme="textButton"
          w="full"
          isDisabled={!(formik.dirty && formik.isValid)}
        >
          Sign In
        </Button>
      </Box>
    </ChForm>
  );
};

import { Box } from "@chakra-ui/react";
import ScoreSheetRow from "./ScoreSheetRow";
import StablefordBox from "./StablefordBox";
import StrokeBox from "./StrokeBox";
import * as scoreLib from "lib/score";
import { EMatchType } from "types";

interface IScoreSheetTable {
  holes: number[];
  pars: number[];
  strokes: number[];
  scoreRowHeading?: string;
  scores: {
    score: number | string;
    noScore: boolean;
  };
  holeTotalLabel: string;
  matchType: string;
  goToHole?: (hole: number) => void;
}

/* SCORESHEET */
export const ScoreSheetTable = ({
  holes,
  pars,
  strokes,
  scoreRowHeading = "Score",
  scores,
  matchType,
  holeTotalLabel,
  goToHole,
}: IScoreSheetTable) => (
  <Box w="full">
    {/* HOLE ROW */}
    <ScoreSheetRow
      matchType={matchType}
      heading="Hole"
      data={holes.map((hole) => ({ value: hole }))}
      total={holeTotalLabel}
      holes={holes}
      onClick={goToHole}
    />

    {/* PAR ROW */}
    <ScoreSheetRow
      matchType={matchType}
      heading="Par"
      data={pars.map((par) => ({ value: par }))}
      total={scoreLib.sum(pars)}
      holes={holes}
      onClick={goToHole}
    />

    {/* STROKES ROW: only show if matchType is NOT "stroke" */}
    {matchType !== EMatchType.stroke ? (
      <ScoreSheetRow
        matchType={matchType}
        isAltHighlight
        isBold
        heading="Strokes"
        data={strokes.map((stroke, index) => ({ value: stroke }))}
        total={scoreLib.sum(strokes)}
        holes={holes}
        onClick={goToHole}
      />
    ) : null}

    {/* HOLE & TOTAL SCORES ROW */}
    {/* dataSymbols={scores.map((x) => x && } */}
    <ScoreSheetRow
      isTotalHighlight
      isBold
      matchType={matchType}
      heading={scoreRowHeading}
      holes={holes}
      total={scoreLib.sum(scores, (score) => score.score)}
      onClick={goToHole}
      data={scores.map((score, index) => ({
        value: score && scoreLib.scoreSign(score, matchType),
        boxComponents: (
          <>
            {matchType === EMatchType.stableford && score !== undefined && (
              <StablefordBox
                noScore={score.noScore}
                kind={scoreLib.stablefordScoreSign(score?.score)}
              />
            )}
            {matchType === EMatchType.stroke && score !== undefined && (
              <StrokeBox
                kind={scoreLib.strokesAsPar(score?.score, pars[index])}
              />
            )}
          </>
        ),
      }))}
    />
  </Box>
);

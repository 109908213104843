import React from "react";
import { useDebounce } from "use-debounce";

export const useSearch = ({
  getItems,
  resultsLimit = 7,
}: {
  getItems: any;
  // Limit number of results rendered to avoid blocking the thread
  resultsLimit?: number;
}) => {
  const [text, setFilter] = React.useState("");
  const [filter] = useDebounce(text, 400);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    async function updateItems() {
      setIsLoading(true);
      const items = await getItems(filter);
      setItems(items.slice(0, resultsLimit));
      setIsLoading(false);
      // Run on first search with a filter
      if (filter !== "") setIsDirty(true);
    }

    updateItems();
  }, [filter, getItems, resultsLimit]);

  function setSearch(event) {
    setFilter(event.target.value);
  }

  const hasNoResults = isDirty && items.length === 0 && !isLoading;

  return {
    isLoading,
    hasNoResults,
    setSearch,
    items,
  };
};

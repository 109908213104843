import history from "../../history";
import config from "../../config";
import * as api from "../../lib/api";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const setToken = (token) => async (dispatch) =>
  dispatch({
    type: LOGIN_SUCCESS,
    user: null,
    token,
  });

/**
 * Login user using session cookies
 *
 * @param {object} credentials
 * @return {object} - server response
 */
export const loginUser = (credentials) => async (dispatch) => {
  const response = await api.login(credentials);

  if (response.error) {
    dispatch({
      type: LOGIN_FAILURE,
    });
    return response;
  }

  const { json } = response;
  // For use after page reload or separate tabs
  localStorage.setItem(config.localStorageBearerKey, json.token);

  dispatch({
    type: LOGIN_SUCCESS,
    user: json.user,
    token: json.token,
  });
  return json;
};

/**
 * Register a new user
 *
 * @param {object} credentials
 * @return {object} - server response
 */
export const registerUser = (credentials) => async (dispatch) => {
  const response = await api.register(credentials);

  if (response.error) {
    dispatch({
      type: REGISTER_FAILURE,
    });
    return response;
  }

  const { json } = response;
  // For use after page reload or separate tabs
  localStorage.setItem(config.localStorageBearerKey, json.token);

  dispatch({
    type: REGISTER_SUCCESS,
    user: json.user,
    token: json.token,
  });
  return json;
};

/**
 * Update user
 *
 * @param {object} credentials
 * @return {object} - server response
 */
export const updateUser = (settings) => async (dispatch) => {
  const formData = new FormData();
  formData.append("fullName", settings.fullName);
  formData.append("golflinkNumber", settings.golflinkNumber);
  formData.append("handicap", settings.handicap);
  formData.append("avatar", settings.avatar);

  const response = await api.updateUserSettings(formData);

  if (response.error) {
    dispatch({
      type: USER_UPDATE_FAILURE,
    });
    return response;
  }

  const { json } = response;
  const { user, token } = json;
  // For use across tabs and page reloads
  localStorage.setItem(config.localStorageBearerKey, token);

  if (settings.avatar) {
    // Appending a timestamp to the avatar url doesn't affect the image
    // However the change of url causes the browser fetch the new avatar image
    // instead of using the previous cached avatar
    user.avatar = `${user.avatar}?${Date.now()}`;
  }

  dispatch({
    type: USER_UPDATE_SUCCESS,
    user,
    token,
  });
  return json;
};

/**
 * Logout user from session
 */
export const logoutUser = () => async (dispatch) => {
  localStorage.removeItem(config.localStorageBearerKey);
  await history.push("/signin");
  await dispatch({
    type: LOGOUT,
  });
};

/**
 * Delete account
 */
export const deleteAccount = () => async (dispatch) => {
  const response = await api.deleteUser();

  if (response.error) {
    dispatch({
      type: DELETE_ACCOUNT_FAILURE,
    });
    return response;
  }

  localStorage.removeItem(config.localStorageBearerKey);
  await history.push("/signin");

  dispatch({
    type: DELETE_ACCOUNT_SUCCESS,
  });
};

/**
 * Logout user from session
 */
export const fetchUser = () => async (dispatch) => {
  const response = await api.getUser();

  if (response.error) {
    dispatch({
      type: FETCH_USER_FAILURE,
    });
    return response;
  }

  dispatch({
    type: FETCH_USER_SUCCESS,
    user: response.json.user,
  });
  return response.json;
};

export const selectors = {
  getUser: (state) => state.authentication.user,
};

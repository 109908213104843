import { Avatar, Box, HStack, useMediaQuery } from "@chakra-ui/react";
import { ITableRoundUser } from "types";
import { Date, Handicap } from "./index";

export const rowLeftWidth = { lg: "200px" };

export const RowLeft = ({
  date,
  handleClick,
  iconSlot,
  player,
  handicap,
  showPlayingHandicap,
  showSetWidth,
}: {
  date?: string;
  // optional click event for mobile competition player
  handleClick?(): void;
  iconSlot?: React.ReactNode;
  player: ITableRoundUser;
  handicap?: number;
  showPlayingHandicap?: boolean;
  showSetWidth?: boolean;
}) => {
  const [isLargerThan640] = useMediaQuery("(min-width: 640px)");
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

  if (!player) return <>Loading…</>;

  return (
    <HStack px={1} align="center" spacing={3} h="full" minW={rowLeftWidth}>
      {isLargerThan1280 && (
        <Avatar size="sm" src={player?.avatar} name={player?.fullName}></Avatar>
      )}
      <HStack align={{ base: "center", lg: "baseline" }} spacing={2}>
        <Box
          textStyle="body"
          // isTruncated
          // manually set truncated style based on maxWidth
          // not a good idea, but fuck you!
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          w={showSetWidth ? { base: "95px", sm: "auto" } : "auto"}
          // maxW={{ base: "95px", lg: "160px" }}
          maxWidth={{ base: "105px", lg: "120px" }}
          onClick={handleClick}
        >
          {player.fullName}
        </Box>
        {isLargerThan640 && showPlayingHandicap && handicap && (
          <>
            <Handicap label="HCP" value={handicap}></Handicap>
            {/* <Handicap label="HCAP" value={player.handicap}></Handicap> */}
            {/* <Handicap label="Playing" value={player.playingHandicap}></Handicap> */}
          </>
        )}

        {/* date of round */}
        {date && <Date date={date} />}

        {/* icons, eg. incomplete holes warning */}
        {iconSlot}
      </HStack>
    </HStack>
  );
};

import Scorecard from "components/Scorecard/Scorecard";
import { UserProvider } from "components/Context/User";
import { useDocumentDimensions } from "hooks";

const ScorecardPage = () => {
  useDocumentDimensions();

  return (
    <UserProvider>
      <Scorecard />
    </UserProvider>
  );
};

export default ScorecardPage;

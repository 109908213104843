import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import { LgLogo, LgModalInner, LgModalCloseButton } from "../Block";

interface IValidationModal {
  heading: string;
  isOpen: boolean;
  onClose: () => void;
  closeButton?: boolean;
  kind?: "full" | "half";
  children: React.ReactNode;
}

export const ValidationModal = ({
  isOpen,
  onClose,
  kind = "full",
  closeButton,
  heading,
  children,
}: IValidationModal) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      isCentered
      closeOnOverlayClick={kind === "half" ? true : false}
      motionPreset="scale"
    >
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent
        shadow="none"
        mb={0}
        mt="auto"
        color="white"
        alignItems="center"
        bg={kind === "half" ? "transparent" : "text.900"}
        className={kind === "half" ? "screen-h-1/2" : "screen-h"}
      >
        <LgModalInner
          px={6}
          bg="text.900"
          justify="center"
          items="center"
          textAlign="center"
        >
          {closeButton && <LgModalCloseButton reverse />}

          {kind === "full" && (
            <Flex justify="center" pb={6}>
              <LgLogo height={80} innerColor="var(--chakra-colors-text-800)" />
            </Flex>
          )}
          <Text textStyle="hSecondary" fontWeight="500" color="white" mb={3}>
            {heading}
          </Text>

          {children}
        </LgModalInner>
      </ModalContent>
    </Modal>
  );
};

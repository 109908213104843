import { Select } from "@chakra-ui/react";
import { FormElement, FormLabel } from "./index";
import { useField } from "formik";

export const FormSelectDuration = ({
  label,
  ...props
}: {
  label: string;
  [x: string]: any;
}) => {
  const [field] = useField(props);

  return (
    <FormElement
      className="CompDuration"
      label={<FormLabel>{label}</FormLabel>}
    >
      {/* <Select id={field.name} onChange={field.onChange}> */}
      <Select
        id={field.name}
        {...field}
        border="1px solid"
        borderColor="grey.100"
        _placeholder={{
          color: "text.300",
        }}
        _hover={{
          borderColor: "text.300",
        }}
        {...props}
      >
        <option value="4">4 weeks</option>
        <option value="5">5 weeks</option>
        <option value="6">6 weeks</option>
        <option value="7">7 weeks</option>
        <option value="8">8 weeks</option>
        <option value="9">9 weeks</option>
        <option value="10">10 weeks</option>
        <option value="11">11 weeks</option>
        <option value="12">12 weeks</option>
        <option value="13">13 weeks</option>
        <option value="14">14 weeks</option>
        <option value="15">15 weeks</option>
      </Select>
    </FormElement>
  );
};

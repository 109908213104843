import React from "react";
import { Flex } from "@chakra-ui/react";

// gradients
// to-r, transparent 0%, rgba(0,0,0,0.01) 70%, rgba(0,0,0,0.02) 96%)

export const NavsideShell = ({
  bg,
  children,
}: {
  bg: string;
  children: React.ReactNode;
}) => {
  return (
    <Flex
      as="nav"
      bg={bg}
      direction="column"
      flexShrink={0}
      // flexGrow={1}
      // align="center"
      // justify="space-between"
      // minW="220px"
      // maxW="220px"
      // minH="100vh"
      // h="full"
      p={{ base: 3, lg: 6 }}
      pr="0 !important"
      position="relative"
      w="240px"
      zIndex={1}
      _before={{
        bgGradient: "linear(to-r, transparent 0%, rgba(0,0,0,0.02) 100%)",
        bottom: "0",
        // eslint-disable-next-line quotes
        content: `""`,
        pos: "absolute",
        right: "0",
        top: "0",
        width: 8,
      }}
    >
      {children}
    </Flex>
  );
};

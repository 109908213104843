import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSponsorsForCourse } from "store/reducers/sponsors";
import { fetchSponsorsForCourseIfNeeded } from "store/actions/sponsors";
import * as lib from "lib/sponsorship";

export function useSponsorHole(courseId: string, holeNumber: number) {
  const dispatch = useDispatch();
  const [sponsor, setSponsor] = React.useState(undefined);

  // day numbers
  const date = new Date();
  // for JS Date(), 0 = Sunday, 6 = Saturday
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;

  // Get sponsors & defaultSponsor from state by the user's courseId
  const sponsorsObj = useSelector((state) =>
    selectSponsorsForCourse(state, courseId)
  );

  // Only fetch Dato sponsors if !state.sponsors[courseId]
  React.useEffect(() => {
    dispatch(fetchSponsorsForCourseIfNeeded(courseId));
  }, [dispatch, courseId]);

  const sponsors = sponsorsObj?.sponsors;
  const defaultSponsor = sponsorsObj?.defaultSponsor;

  React.useEffect(() => {
    if (!!sponsors?.length) {
      const slot = lib.getSlotForHoleToday(holeNumber);
      let sponsor;
      // if isWeekend, use the weekend sponsor slot
      if (isWeekend) {
        sponsor = sponsors.find((sponsor) => slot === sponsor.weekendSlot);
      }
      // else use the weekend slot
      sponsor = sponsors.find((sponsor) => slot === sponsor.slot);
      setSponsor(sponsor);
    }
  }, [sponsors, holeNumber, isWeekend]);

  return {
    sponsor,
    defaultSponsor,
  };
}

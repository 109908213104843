/**
 * @module Sponsorship
 * Design reqs: https://www.notion.so/YLG-Sponsorship-requirements-6d4f9f644d4646f49c36b9a2400a0a3b
 */

const weekdaySlotToHoleMap = {
  // Monday - Friday
  1: [1, 7, 13],
  2: [2, 8, 14],
  3: [3, 9, 15],
  4: [4, 10, 16],
  5: [5, 11, 17],
};
const weekendSlotToHoleMap = {
  // Saturday - Sunday
  1: [1, 3, 5, 7, 10, 12, 14, 16],
  2: [2, 4, 6, 8, 11, 13, 15, 17],
};

/**
 * @param {string} str
 */
export const convertStrToNum = (str) => parseInt(str, 10);

/**
 * @param {number} hole
 * @param {bool} isWeekend
 */
export function getSlotForHole(hole, isWeekend) {
  let keyValuePair;
  if (isWeekend) {
    keyValuePair = Object.entries(weekendSlotToHoleMap).find(([key, value]) =>
      value.includes(hole)
    );
  } else {
    keyValuePair = Object.entries(weekdaySlotToHoleMap).find(([key, value]) =>
      value.includes(hole)
    );
  }
  if (!keyValuePair) {
    // TODO: return Dato default LG sponsor (or let component handle with default sponsor?)
    return null;
  }
  return keyValuePair[0];
}

/**
 * @param {number} hole
 */
export function getSlotForHoleToday(hole) {
  const date = new Date();
  // for JS Date(), 0 = Sunday, 6 = Saturday
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;
  return getSlotForHole(hole, isWeekend);
}

import { useHistory } from "react-router";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Text,
  Button,
  Input,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import useQuery from "hooks/useQuery";
import { MessageErrorApi, ChForm } from "components/Form";
import { LgLink } from "components/Typography";
import DefaultVStack from "components/VStack";
import * as schemas from "lib/schemas";
import * as api from "lib/api";

const apiMessageErrorMap = {
  INVALID_RESET_TOKEN: "Password reset token is not valid",
};

export const FormResetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    const token = query.get("token");
    const response = await api.resetPassword(token, values.password);

    if (response.error) {
      setSubmitting(false);
      setStatus({
        apiError: response.error,
      });
      return;
    }

    history.push("/signin?password-reset=true");
  };

  return (
    <Formik
      initialStatus={{
        success: false,
      }}
      initialValues={{
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={Yup.object({
        password: schemas.password,
        passwordConfirm: schemas.passwordConfirm,
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <ChForm>
          <MessageErrorApi messageMap={apiMessageErrorMap} />
          <DefaultVStack flex="1" spacing={4}>
            <Text textStyle="copy" color="grey.400">
              Don't need to? <LgLink to="/signin" label="Sign in" />
            </Text>
            <FormControl
              isInvalid={
                formik.errors.password && formik.touched.password ? true : false
              }
            >
              <Input
                variant="setRow"
                {...formik.getFieldProps("password")}
                id="password"
                placeholder="Type your password…"
                type="password"
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.errors.passwordConfirm && formik.touched.passwordConfirm
                  ? true
                  : false
              }
            >
              <Input
                variant="setRow"
                {...formik.getFieldProps("passwordConfirm")}
                id="passwordConfirm"
                placeholder="Confirm password"
                type="password"
              />
              <FormErrorMessage>
                {formik.errors.passwordConfirm}
              </FormErrorMessage>
            </FormControl>
          </DefaultVStack>

          {/* keep outside VStack for mobile layout */}
          <Box pt={{ lg: 12 }}>
            <Button
              type="submit"
              variant="solid"
              colorScheme="textButton"
              w="full"
              isDisabled={!(formik.dirty && formik.isValid)}
              isLoading={!formik.isValidating && formik.isSubmitting}
              loadingText="Submitting"
            >
              Reset Password
            </Button>
          </Box>
        </ChForm>
      )}
    </Formik>
  );
};

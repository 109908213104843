import { Text } from "@chakra-ui/react";

// almost the same as ClubTable/TablerRowRoundElements/TextMeta
export const TextMetaCard = ({
  isQuiet,
  children,
  ...props
}: {
  isQuiet?: boolean;
  children: React.ReactNode;
  [x: string]: any;
}) => (
  <Text
    textStyle="meta"
    fontWeight="400"
    color="white"
    opacity={isQuiet ? 0.8 : 1}
    {...props}
    // textTransform="uppercase"
  >
    {children}
  </Text>
);

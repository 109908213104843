import { ConfigRowInput, ConfigRowInputWrap, ConfigRowDrawer } from "../Config";
import { useFormikContext } from "formik";
import { EConfigRowTheme } from "types";

export const GolflinkNumberInput = ({
  btnBackLabel,
}: {
  btnBackLabel: string;
}) => {
  const formik = useFormikContext<any>();

  return (
    <ConfigRowDrawer
      heading="Your Golflink Number"
      btnBackLabel={btnBackLabel}
      btnName="Golflink Number*"
      btnValue={formik.values.golflinkNumber}
      btnTheme={
        !!formik.values.golflinkNumber
          ? EConfigRowTheme.valid
          : EConfigRowTheme.awaiting
      }
    >
      {(disclosure) => (
        <ConfigRowInputWrap
          direction="Your Golflink Number helps us set your home golf course and is only available from Golf Australia."
          isValid={!formik.errors.golflinkNumber}
          onSubmit={() => disclosure.onClose()}
        >
          <ConfigRowInput
            placeholder="Type your Golflink number…"
            name="golflinkNumber"
          />
        </ConfigRowInputWrap>
      )}
    </ConfigRowDrawer>
  );
};

import React from "react";
import { useSelector } from "react-redux";
import withMatch from "hoc/withMatch.js";
import withMatchSocket from "hoc/withMatchSocket.js";
import { LgHeader } from "components/Block";
import { BlockContainerScore } from "components/Block2";
import LobbyInvited from "components/Lobby/LobbyInvited";
import history from "../history";
import * as matchActions from "store/actions/match";

const LobbyInvitedPage = () => {
  // Send to home page, home page will direct you to the correct page
  // Warning: Has caused a bug in the past where accepting an invite doesn't join the lobby:
  //   When sent to this page after already completing a previous match,
  //   the new match isn't yet set in redux instead the previous match is still set, ofcourse that match has started
  //   therefore you're redirect to the home page
  const matchStarted = useSelector(matchActions.selectors.getMatch).started;

  React.useEffect(() => {
    if (matchStarted) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlockContainerScore>
      <LgHeader heading="Player settings" />
      <LobbyInvited />
    </BlockContainerScore>
  );
};

export default withMatch(withMatchSocket(LobbyInvitedPage));

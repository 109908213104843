import React from "react";
import { useSelector } from "react-redux";
import { Text, VStack, useDisclosure, Button, Icon } from "@chakra-ui/react";
import { SearchPresenter } from "components/Search";
import { MessageNoCourse } from "components/Lobby";
import { MdSearch } from "react-icons/md";
import { TableSearchCourseModal } from "./index";
import { fetchCourses } from "lib/fetchCourses";
import { ICourse } from "types";

const RenderResult = (item) => {
  return (
    <VStack align="flex-start" spacing={1.5}>
      <Text textStyle="body" color="text.800" lineHeight={1}>
        {item.clubName}
        {item.courseName !== null && (
          <Text as="span" color="text.500">
            , {item.courseName}
          </Text>
        )}
      </Text>
      <Text textStyle="crumb" fontWeight="500" color="primary.300">
        {item.name}
      </Text>
    </VStack>
  );
};

export const TableSearchCourse = ({
  onSelect,
  ...props
}: {
  onSelect: (course: ICourse) => void;
  // Required elements for SearchPresenter
  name: string;
  label: string;
  placeholder: string;
  [x: string]: any;
}) => {
  const user = useSelector((state) => state.authentication.user);
  const [currentValue, setCurrentValue] = React.useState(user.homeCourse.name);
  const [value, setValue] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleSelect(item: any) {
    setValue(item.clubName);
    setCurrentValue(item.clubName);
    onSelect(item);
    onClose();
  }

  function handleChange(event) {
    setValue(event.target.value);
  }

  function handleIconClick() {}

  return (
    <>
      <Button
        onClick={onOpen}
        variant="outline"
        size="sm"
        colorScheme="text"
        transform={{ lg: "translateY(0.2em)" }}
        leftIcon={<Icon as={MdSearch} color="text.800" />}
      >
        {currentValue}
      </Button>
      <TableSearchCourseModal
        isOpen={isOpen}
        onClose={onClose}
        currentCourse={currentValue}
      >
        <SearchPresenter
          getItems={fetchCourses}
          isInTable={true}
          noResultsMessage={<MessageNoCourse />}
          onSelect={handleSelect}
          onIconClick={handleIconClick}
          // placeholder={user.homeCourse.name}
          renderResults={RenderResult}
          wrapInFormElement={false}
          // spread props before onChange & value
          {...props}
          value={value}
          onChange={handleChange}
        />
      </TableSearchCourseModal>
    </>
  );
};

import { ScoreBoxBase } from "./index";

type TKind = "-" | "–" | 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;

/* STABLEFORD: DENOTE HOLE SCORE via BORDERS */
const StablefordBox = ({
  kind,
  noScore,
}: {
  kind: TKind;
  noScore?: boolean;
}) => {
  // MAP negative kinds to Stableford box shapes
  // NB! we pass `–` for a negative score and we use noScore to style the box
  // ========================================
  // double bogey = -2 = sfd 0 = 2 squares
  // bogey = -1 = sfd 1 = 1 square

  // MAP zero or positive kinds to Stableford box shapes
  // NB! positive kinds are always circles (ie. `plus`)
  // ===================================================
  // par = 0 = sfd 2 = null
  // birdie = +1  = sfd 3 = 1 circle
  // eagle = +2 = sfd 4 = 2 circles
  // albatross = +3 = sfd 5 = 3 circles??? Nah, keep it 2

  // allow endash *and* hyphen in case we get the dash input wrong
  // const shell = [2, "-", "–", undefined]; // if shell, don't show border
  const inner = ["-", "–", 0, 4, 5, 6, 7]; // inner border
  const outer = ["-", "–", 1, 3, 4, 5, 6, 7]; // outer border
  const plus = [3, 4, 5, 6, 7]; // adds circle

  // convert arrays to a boolean so we can debug ScoreBoxBase
  const innerActive = inner.includes(kind);
  const outerActive = outer.includes(kind);
  const plusActive = plus.includes(kind);

  return (
    <ScoreBoxBase
      inner={innerActive}
      outer={outerActive}
      plus={plusActive}
      noScore={noScore}
    ></ScoreBoxBase>
  );
};

export default StablefordBox;

import { Flex, Box, Text, HStack } from "@chakra-ui/react";
import { IPlayerTee } from "types";

const style = {
  textStyle: "hMinor",
  // color: "white",
  fontWeight: "500",
  lineHeight: "1",
};

export const ScoreMeta = ({ strokeIndex, par, distance }: IPlayerTee) => (
  <Flex direction="column" align="flex-end">
    <Box borderBottom="0" borderColor="grey.100" pb="6px">
      <Text
        textStyle="copy"
        fontWeight="500"
        // color="white"
        lineHeight="1"
        textAlign="right"
        mr="2px"
      >
        Stroke index {strokeIndex}
      </Text>
    </Box>
    <HStack
      pb="1px"
      spacing={1.5}
      // divider={<StackDivider borderColor="text.700" />}
    >
      <Text as="div" {...style}>
        Par {par}
      </Text>
      <Text as="div" {...style} fontWeight="300">
        /
      </Text>
      <Text as="div" {...style}>
        {distance}m
      </Text>
    </HStack>
  </Flex>
);

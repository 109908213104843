import { BlockLoading } from "components/Block2";
import { ScorecardPresenter } from "./index";
import { ScorecardProvider } from "components/Context";
import { useGetMatch } from "lib/api";
import { TScorecardVariant, EScorecardVariant } from "types";

/*
  NOTE WELL!
  ScorecardPostMatch is the same as Scorecard,
  but it's used only in the Clubhouse flow.
  There are subtle differences, read the comments.
 */

export const ScorecardPostMatch = ({
  matchId,
  notVerified,
  userId,
  variant,
}: {
  matchId: number;
  notVerified: boolean;
  userId: string;
  variant: TScorecardVariant;
}) => {
  /*
    ========================================================================
    NOTE WELL!
    * In this component, we get the match using SWR using
      the supplied matchId and userId.
    * This is different from Scorecard which uses the store!
    * We do NOT use the store b/c I can't figure out how to get the
      matchId from the url. And we can already do that with SWR right here.
    ========================================================================
  */
  const { data } = useGetMatch(matchId, userId);
  console.log("ScorecardPostMatch data", data);

  // conditonally render so we can destructure data
  if (!data) return <BlockLoading />;

  // reformat data for context
  const { match, scores, rounds, userMatchSettings } = data;

  const scorecardContext = {
    // TODO: we mostly use `isSocial` to show a singular tab or two tabs in a player + marker score scenario. It was an older prop from prior to any Clubhouse work. Consider updating this prop name.
    isSocial: match?.isSocial || variant === EScorecardVariant.eclectic,
    marker: userMatchSettings?.marker,
    markerMatchSettings: userMatchSettings?.markerMatchSettings,
    markerCourse: userMatchSettings?.markerMatchSettings?.course,
    match: match,
    matchType: match?.matchType,
    scores: scores,
    rounds: rounds,
    uiConfig: {
      showHeader: true,
      showHeaderImage: false,
      showMarkerInSettings: !match.isSocial,
    },
    userCourse: userMatchSettings?.course,
    userMatchSettings: userMatchSettings,
    // Unlike the Scoring flow, variant is passed as a prop in the Clubhouse flow so we can easily swap between "leaderboard" and "eclectic".
    // However, b/c eclectic data shapes are quite different, we ended up using custom components. Which means the only variant that ScorecardPostMatch uses is "leaderboard"…
    variant,
  };

  return (
    <ScorecardProvider value={scorecardContext}>
      {/*
        ====================================================================
        NOTE WELL!
        * ScorecardPresenter + it's related components
          (ScorecardPresenterPanel, ScorecardRowModal etc.)
          are used across both the Scoring and Clubhouse flows.
        * Note that there is nuance to using the same scorecardContext
          across this set on components in both situations!
        * The crux is in ScorecardRowModal which uses a different holeStats
          hook dependent on the variant!
        ====================================================================
       */}
      <ScorecardPresenter notVerified={notVerified} defaultTabsIndex={1} />
    </ScorecardProvider>
  );
};

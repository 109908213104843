import { Text, Link } from "@chakra-ui/react";
import { Message } from "components/Form";
import config from "config";

export const MessageNoPlayer = () => (
  <Message underInput heading="We can't find your marker">
    <Text as="div" textStyle="meta" color="white">
      Looks like your marker isn't signed up. Have you got the right spelling?{" "}
      If you think this is a bug, please{" "}
      <Link
        textDecoration="underline"
        isExternal
        href={`mailto:${config.siteEmail}`}
      >
        email us
      </Link>{" "}
      and we'll take a look.
    </Text>
  </Message>
);

import { Box, Button, UseDisclosureProps } from "@chakra-ui/react";
import { ConfigRowButtons, ConfigRowDrawer } from "../Config";
import { MessageNoCourseWrapped } from "./index";
import { SearchScoring } from "../Search";
import { constantsToItemList, fetchCourses } from "lib";
import { IFormData } from "hooks/useFormData";
import { constantToFriendly } from "config/constants";
import type { ICourseAbbr } from "types";
import { EPlayType, EMatchType, EConfigRowTheme } from "types";

interface ICreateLobbyPresenter {
  formData: IFormData;
  playType: EPlayType;
  matchTypes: {
    value: EMatchType;
  }[];
  selectedCourse: ICourseAbbr;
  setFormOption: (key: string, value: any) => void;
  isFormValid: boolean;
  handleSubmit: (e: any) => void;
}

export const CreateLobbyPresenter = ({
  formData,
  playType,
  matchTypes,
  selectedCourse,
  setFormOption,
  isFormValid,
  handleSubmit,
}: ICreateLobbyPresenter) => {
  return (
    <>
      <Box layerStyle="containerInset" flex="1">
        {/* COURSE */}
        <ConfigRowDrawer
          heading="Choose a course"
          btnBackLabel="Settings"
          btnName="Course"
          btnValue={formData?.course?.value}
          btnTheme={
            !!formData.course ? EConfigRowTheme.valid : EConfigRowTheme.awaiting
          }
        >
          {(disclosure: UseDisclosureProps) => (
            <SearchScoring
              getItems={fetchCourses}
              kind="course"
              drawerHeading="Choose course"
              placeholder="Search for a course…"
              noResultsMessage={<MessageNoCourseWrapped />}
              onSelect={(item) => {
                setFormOption("course", item);
                disclosure.onClose();
              }}
            />
          )}
        </ConfigRowDrawer>

        {/* MATCH TYPE: only show if platType is competition */}
        {playType === EPlayType.comp && (
          <>
            <ConfigRowDrawer
              heading="Select your match"
              btnBackLabel="Settings"
              btnName={formData.matchType ? "Match type" : undefined}
              btnValue={
                formData.matchType
                  ? constantToFriendly(formData.matchType)
                  : "Select your match"
              }
              btnTheme={
                !!formData.matchType
                  ? EConfigRowTheme.valid
                  : EConfigRowTheme.awaiting
              }
            >
              {(disclosure: UseDisclosureProps) => (
                <ConfigRowButtons
                  items={matchTypes}
                  onSelect={(item) => {
                    setFormOption("matchType", item);
                    disclosure.onClose();
                  }}
                />
              )}
            </ConfigRowDrawer>
          </>
        )}

        {/* TEE */}
        <ConfigRowDrawer
          heading="Select your tee"
          btnBackLabel="Settings"
          btnName={formData.tee ? "Tee" : undefined}
          btnValue={
            formData.tee ? constantToFriendly(formData.tee) : "Select your tee"
          }
          btnTheme={
            !!formData.tee ? EConfigRowTheme.valid : EConfigRowTheme.awaiting
          }
        >
          {(disclosure: UseDisclosureProps) => (
            <ConfigRowButtons
              items={constantsToItemList(selectedCourse.tees)}
              onSelect={(item) => {
                setFormOption("tee", constantToFriendly(item));
                disclosure.onClose();
              }}
            />
          )}
        </ConfigRowDrawer>
      </Box>

      <Box layerStyle="containerScoreBtn">
        <Button
          variant="solid"
          w="full"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          {playType === EPlayType.comp ? "Next step" : "Continue"}
        </Button>
      </Box>
    </>
  );
};

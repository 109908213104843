import { Flex, Box, Image } from "@chakra-ui/react";
import config from "config";

/* CardImage is only used as a Competition Card */

export const CardImage = ({
  children,
  flatBottom,
  id,
  variant = "card",
}: {
  children: React.ReactNode;
  flatBottom?: boolean;
  id: number;
  variant?: string;
}) => {
  // random background image
  // const bgs = [bg1, bg2, bg3, bg4, bg5, bg6];
  // const getRandomInt = (max: number) =>
  //   Math.floor(Math.random() * Math.floor(max));
  // const renderBg = () => (bgs.length > 0 ? bgs[getRandomInt(bgs.length)] : bg2);

  // TODO: LG-258 or LG-229 use randomly assigned id to randomise bg image
  // const renderBg = (id: number) => {
  //   const index = Math.ceil(id / bgs.length);
  //   console.log("index: ", index);
  //   console.log("bg index: ", bgs[index]);
  //   return bgs[index];
  // };

  // const random = Math.floor(Math.random() * 10) + 1;

  return (
    /* eslint-disable indent */
    <Flex
      pos="relative"
      w="full"
      h={{ base: "140px", lg: "240px" }}
      borderRadius="0"
      borderBottomRadius={flatBottom ? "none" : undefined}
      overflow="hidden"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      // _hover={
      //   variant === "card"
      //     ? {
      //         // boxShadow: "2xl",
      //         boxShadow:
      //           "0 20px 25px -5px rgba(0, 0, 0, 0.2),0 10px 10px -5px rgba(0, 0, 0, 0.04);",
      //         transform: "scale(1.002) translateY(-0.1em)",
      //       }
      //     : undefined
      // }
    >
      {/* eslint-enable indent */}
      <Image
        // src={renderBg()}
        src={config.imgGrassLandscape}
        fallbackSrc={config.imgGrassLandscapeFallback}
        bg="black"
        pos="absolute"
        w="full"
        h="full"
        inset={0}
        fit="cover"
        // align="50% 30%"
      />
      <Box
        pos="absolute"
        w="full"
        inset={0}
        bgGradient="linear(to-b, blackAlpha.50 10%, blackAlpha.600 96%)"
      >
        {children}
      </Box>
    </Flex>
  );
};

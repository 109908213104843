import { Flex } from "@chakra-ui/react";
import ScoreSheetRowItem from "./ScoreSheetRowItem";
import * as scoreLib from "lib/score";
import { EMatchType } from "types";

interface IScoreSheetRow {
  data: any;
  heading?: string;
  holes: number[];
  isTotalHighlight?: boolean;
  isAltHighlight?: boolean;
  isBold?: boolean;
  // matchType: PropTypes.oneOf(MATCH_TYPES),
  matchType: string;
  onClick?: (e) => void;
  scoreIsHeading?: boolean;
  showHeading?: boolean;
  showTotal?: boolean;
  total?: string | number;
  [key: string]: any;
}

/* SCORESHEET ROW */
const ScoreSheetRow = ({
  matchType,
  isTotalHighlight,
  isAltHighlight,
  isBold,
  heading,
  data,
  total,
  holes,
  onClick,
  scoreIsHeading = true,
  showHeading = true,
  showTotal = true,
  ...props
}: IScoreSheetRow) => {
  const altBg = isAltHighlight ? "neutral.200" : "white";
  const totalBg = isTotalHighlight ? "neutral.300" : "white";
  const isPar = matchType === EMatchType.par;
  // const isStableford = matchType === EMatchType.stableford;
  // const isStroke = matchType === EMatchType.stroke;
  // if the first array item from data is undefined, then we know the player has not yet reach hole 1 or hole 10
  const hasTotalScore = data[0]?.value !== undefined;
  const totalIsString = typeof total === "string";

  return (
    <Flex
      justify="space-between"
      bg={isTotalHighlight ? totalBg : altBg}
      borderTop={isBold ? "1px" : "0"}
      borderColor="grey.100"
      // py={isStableford || isStroke ? "5px" : "1px"}
      py={isTotalHighlight ? "5px" : "2px"}
      {...props}
    >
      {/* HEADING */}
      {showHeading && (
        <ScoreSheetRowItem
          align="left"
          isHeading
          isBold={isBold}
          boxProps={{
            w: {
              base: "48px",
              lg: "64px",
            },
          }}
          childBoxProps={{
            left: "3px",
          }}
        >
          {heading}
        </ScoreSheetRowItem>
      )}

      {/* SCORES: `boxComponents` are passed in via `data` */}
      {data.map(({ value, boxComponents }, index) => {
        // console.log({ value, boxComponents });
        return (
          <ScoreSheetRowItem
            key={index}
            align={boxComponents !== undefined ? "center" : "right"}
            boxComponents={boxComponents}
            isBold={isBold}
            isHeading={scoreIsHeading}
            // isNoScore={isNoScore}
            onClick={() => onClick(holes[index])}
          >
            {value}
          </ScoreSheetRowItem>
        );
      })}

      {/* TOTAL */}
      {showTotal && (
        <ScoreSheetRowItem
          isBold={isBold}
          boxProps={{ w: "28px" }}
          childBoxProps={{
            right: "3px",
          }}
        >
          {totalIsString ? (
            total
          ) : (
            <>
              {/* winning PAR totals require a "+" — this is the only score sign we configure inline in this file, all other score signs are piped in from ScoreSheetTable */}
              {isPar && isTotalHighlight && parseFloat(total) > 0 && "+"}
              {/* don't show a total score unless it is defined */}
              {hasTotalScore
                ? scoreLib.scoreSheetTotal(total, matchType)
                : undefined}
            </>
          )}
        </ScoreSheetRowItem>
      )}
    </Flex>
  );
};

export default ScoreSheetRow;

import { VStack, Button, Text, Box } from "@chakra-ui/react";
import { ScoreMessage, ScoreMessageError } from "./Score";
import { IVerifyRound } from "types";

const verifyRoundMessageMap = {
  ROUND_NOT_COMPLETE: {
    primary: "Round is still in progress",
    secondary:
      "Both players must complete the round first before verifying and signing scores.",
  },
  ROUND_NOT_COMPLETE_BY_MARKER: {
    primary: "Round is still in progress",
    secondary:
      "Both players must complete the round first before verifying and signing scores.",
  },
  SCORES_DONT_MATCH: {
    primary: "Your scores don't match",
    secondary:
      "Scoring issues are highlighted on the scorecard below. Please fix them so that both yours and your marker's scores match.",
  },
  default: "Unable to verify your round",
};

export const VerifyRound = ({
  playerName,
  isForMarker,
  isVerifyingRound,
  verifyRoundResponse,
  roundIsVerified,
  handleVerifyRound,
}: IVerifyRound) => {
  // UI CONSTANTS
  const color = verifyRoundResponse?.errorCode ? "form.bad" : "form.good";

  return (
    <VStack
      align="center"
      spacing={0}
      border="1px solid"
      borderColor={color}
      borderRadius="lg"
    >
      {/* CTA */}
      {!roundIsVerified && verifyRoundResponse === null && (
        <ScoreMessage
          isSuccessBased
          successTheme
          heading="Sign the scorecard"
          borderBottomRadius={0}
        >
          Please tap to verify and sign the scorecard. Then we'll save this
          round to your daily results and scoring history.
        </ScoreMessage>
      )}

      {/* VERIFICATION ERROR */}
      {verifyRoundResponse?.errorCode && (
        <>
          {/* <MessageErrorMap
            errorCode={verifyRoundResponse.errorCode}
            messageMap={verifyRoundMessageMap}
          /> */}
          <ScoreMessageError
            errorCode={verifyRoundResponse.errorCode}
            messageMap={verifyRoundMessageMap}
          ></ScoreMessageError>
        </>
      )}

      {/* {!roundIsVerified && (
        <ScoreMessage heading="Your scores don't match">
          Scoring issues are highlighted on the scorecard below. Please fix them
          so that both yours and your marker's scores match.
        </ScoreMessage>
      )} */}

      {/* VERIFY BUTTON */}
      {!roundIsVerified ? (
        <VStack justify="center" pt={4} pb={5}>
          <Box px={12} pb={1}>
            <Text textStyle="meta" align="center" color="text.800">
              I, {playerName}, confirm that
              <br />
              my {isForMarker ? "Marker's score" : "score"} is legitimate:
            </Text>
          </Box>
          <Button
            size="md"
            textStyle="metaBold"
            colorScheme="textButton"
            isLoading={isVerifyingRound}
            onClick={handleVerifyRound}
          >
            Verify &amp; sign
          </Button>
        </VStack>
      ) : (
        /* VERIFIED MESSAGE */
        <ScoreMessage isSuccessBased successTheme heading="Verified and signed">
          Your scores match. We're recorded them to your daily results and
          scoring history.
        </ScoreMessage>
      )}
    </VStack>
  );
};

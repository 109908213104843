import { Text, useMediaQuery } from "@chakra-ui/react";
import * as dateLib from "lib/date";

export const Date = ({ date }: { date: string }) => {
  const [breakpoint] = useMediaQuery("(min-width: 1024px)");
  const desktopDate = dateLib.toShortDateString(date);
  const mobileDate = dateLib.toShortestDateString(date);

  return (
    <Text
      borderLeft="1px solid"
      borderColor="text.100"
      color="grey.400"
      ml={2}
      pl={2}
      textStyle="meta"
    >
      {breakpoint ? desktopDate : mobileDate}
    </Text>
  );
};

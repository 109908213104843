import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// import { useWindowSize } from "react-use";

/*
  LayoutRoot is the ReactRootFirstChild in DefaultLayout.
  Set with an explicit screen height value: `all-screen-h`. BlockMain and LayoutScroll use this to when the set a scrolling height.
 */

export const LayoutRoot = ({ children, ...props }) => {
  // const { height } = useWindowSize();
  const location = useLocation();
  const isScoringPath = location.pathname.startsWith("/score/");
  const isScorecardPath = location.pathname.includes("/scorecard");
  const isClubhousePath = location.pathname.startsWith("/clubhouse");
  const isAccountPath = location.pathname.startsWith("/account");
  const isHomePath = location.pathname === "/";
  const isSignPath =
    location.pathname === "/signup" || location.pathname === "/signin";
  const isEmailVerifyPath = location.pathname === "verify-email";
  const isEmailReverifyPath =
    location.pathname === "/resend-verification-email";
  const isForgotPasswordPath = location.pathname === "/forgot-password";
  const isResetPasswordPath = location.pathname === "/reset-password";
  const isLobbyPath =
    location.pathname === "/create-lobby" ||
    location.pathname === "/lobby" ||
    location.pathname === "/lobby-invitation" ||
    location.pathname === "/join-lobby";

  // `As` statements
  const showAsRow =
    isClubhousePath || isHomePath || isAccountPath || isScorecardPath;
  const showAsFixedOnMobile =
    isSignPath ||
    isEmailVerifyPath ||
    isEmailReverifyPath ||
    isForgotPasswordPath ||
    isResetPasswordPath ||
    isHomePath ||
    isAccountPath ||
    isLobbyPath ||
    isScoringPath;

  // Prevent overscroll or "pull to refresh" on score flows (mobile)
  useEffect(() => {
    if (isScoringPath) {
      // Add CSS to the HTML element when on the specific page
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.overscrollBehavior = "none";

      // Revert when component is unmounted
      return () => {
        document.documentElement.style.overflow = "";
        document.documentElement.style.overscrollBehavior = "";
      };
    }
  }, []);

  return (
    <Flex
      // When we open a Scorecard from Clubhouse Eclectic Round, it opens in a new tab in order to query the Scorecard data. On Safari iOS PWA, this new tab measures a different height (weird!). And so we're forever left with the wrong height for the remainder of the user session.
      // So if isScorecardPath, don't add the height class as the height is always measured incorrectly when hitting this page (for the above reason) and allow the content to occupy the full browser height.
      // But this doesn't prevent useEffect from running and incorrectly setting the incorrect height…
      className={
        isScorecardPath
          ? "ReactRootFirstChild LayoutRoot all-screen-h"
          : "ReactRootFirstChild LayoutRoot all-screen-h"
      }
      // window height if not isScorecardPath
      // style={
      //   !isScorecardPath ? { height: `${height}px !important` } : undefined
      // }
      // ensure height fallbacks
      // h="full"
      // minH="full"
      // hide overflow of all subsequent content in this box
      // overflow="hidden"
      // fix this box on mobile to restrict scroll bounce
      // position={
      //   showAsFixedOnMobile ? { base: "fixed", xl: "relative" } : "relative"
      // }
      position="relative"
      // yes, we use flex on purpose: column on mobile, row on Clubhouse desktop
      direction={{ base: "column", lg: showAsRow ? "row" : "column" }}
      // widths: container.xl + nav
      maxW="calc(1280px + 240px)"
      mx="auto"
      w="full"
      // all other styles…
      alignItems="stretch"
      placeItems="stretch"
      bg={{ lg: "neutral.200" }}
      {...props}
    >
      {/* TEST HEIGHT */}
      {/* <Flex pos="absolute" color="salmon" zIndex={1001}>
        {height}
      </Flex> */}
      {children}
    </Flex>
  );
};

import { useState } from "react";
import {
  Flex,
  Box,
  Text,
  HStack,
  VStack,
  StackDivider,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import { useHistory } from "react-router";
import { InfoIcon } from "@chakra-ui/icons";
import DefaultVStack from "components/VStack";
import { PreHeaderMeta } from "components/ClubTable/RowElements";
import {
  FormLabel,
  FormElement,
  FormElementDivider,
} from "components/ClubCompCreate";
import { DeleteCompPlayerModal, DeleteCompModal } from "components/Notices";
import { FormAddPlayers } from "./index";
import { constantToFriendly } from "config/constants";
import * as api from "lib/api";
import { ICompetition, IPlayer } from "types";

export const Settings = ({
  comp,
  onCompetitionUpdate,
  onParentClose,
}: {
  comp: ICompetition;
  onCompetitionUpdate: () => void;
  onParentClose: () => void;
}) => {
  const history = useHistory();
  const hostPlayer = comp?.players.find((p) => p.id === comp.hostId);
  const playersWithoutHost = comp?.players.filter((p) => p.id !== comp.hostId);
  const [removeError, setRemoveError] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [breakpoint] = useMediaQuery("(min-width: 1024px)");

  const removePlayerFromComp = (players, playerID) =>
    players.filter((x) => x.id !== playerID).map((x) => x.id);

  const handleRemoveCompPlayer = async (playerID: number) => {
    // filters out the player from the comp & set that player id as an array
    const playerIDs = removePlayerFromComp(comp.players, playerID);
    // console.log("playerIDs", playerIDs);

    const response = await api.updateCompetition(comp.id, {
      players: playerIDs,
    });
    // This is a handled error, we only want to show a UI message & log it
    if (response?.error) {
      setRemoveError(true);
      console.log("handleRemoveCompPlayer", response.error);
      return;
    }

    onCompetitionUpdate();
    onParentClose();
    return response;
  };

  // TODO: useEffect to update playersWithoutHost after handleRemoveCompPlayer

  async function deleteCompetition() {
    setIsDeleting(true);
    const response = await api.deleteCompetition(comp.id);

    if (response.status !== 200) {
      // Uh oh, deleting failed
      // Only set if failed so button stays in disabled loading state until redirected
      setIsDeleting(false);
    }

    history.push("/clubhouse/competitions");
  }

  // const handleAddPlayers = async (players) => {
  //   players = "hey!";
  //   alert(JSON.stringify(players));
  // };

  if (removeError) return <Text>You got error</Text>;

  return (
    <>
      {/* HEADER */}
      <Flex
        as="header"
        direction="column"
        justifyContent="flex-end"
        bg="complement.50"
        h={{ lg: "140px" }}
        px={6}
        pb={{ base: 3, lg: 4 }}
        pt={{ base: 6, lg: 0 }}
      >
        <DefaultVStack spacing={{ base: "1px", lg: 0.5 }} align="flex-start">
          <Text as="h2" textStyle="hTertiary" pb="2px">
            {comp.name}
          </Text>
          <HStack
            spacing={3}
            divider={<StackDivider borderColor="currentColor" opacity={0.3} />}
          >
            <Text textStyle="body">
              {constantToFriendly(comp.type)} competition at{" "}
              {comp.course?.clubName}
              {comp.course?.courseName !== null && (
                <Text as="span" color="text.500">
                  , {comp.course?.courseName}
                </Text>
              )}
            </Text>
          </HStack>
          <PreHeaderMeta comp={comp} />
        </DefaultVStack>
      </Flex>

      {/* NOTE */}
      <Flex
        bg="text.800"
        direction="column"
        px={{ base: 5, lg: 6 }}
        py={{ base: 3, lg: 4 }}
        justifyContent="flex-end"
      >
        <HStack color="white" alignItems="flex-start">
          <InfoIcon mt={{ base: "0.2em" }}></InfoIcon>
          <Text as="div" textStyle="copy" fontWeight="500">
            You are the competition owner.{" "}
            <Text as="span" color="whiteAlpha.700" fontWeight="400">
              Only you can manage the settings for this competition.
            </Text>
          </Text>
        </HStack>
      </Flex>

      {/* BODY */}
      <Box py={{ base: "w5", lg: "w3" }} px="inset" overflow="auto">
        <DefaultVStack align="flex-start" spacing={{ base: 4, lg: 0 }} pb="w8">
          {/* WEEKS */}
          <FormElement label={<FormLabel>Duration</FormLabel>}>
            <Box textStyle="body" pl={{ base: 0, lg: 3 }}>
              {comp.duration} weeks
            </Box>
          </FormElement>

          {/* DAYS */}
          <FormElement
            label={
              <FormLabel>Playing day{comp.days.length > 0 && "s"}</FormLabel>
            }
          >
            <Flex pl={{ base: 0, lg: 2 }} ml={{ base: "-2px" }} flexWrap="wrap">
              {comp.days.map((day: any, index: number) => (
                <Button
                  key={index.toString()}
                  variant="ghost"
                  size="mdNew"
                  border="1px solid"
                  borderColor="blackAlpha.300"
                  color="black"
                  cursor="default"
                  fontWeight="400"
                  m="2px"
                  _hover={{
                    bg: "initial",
                  }}
                >
                  {day}
                </Button>
              ))}
            </Flex>
          </FormElement>

          {/* CURRENT PLAYERS */}
          {/* FormLabel height matches DeleteCompPlayerModal button height */}
          {breakpoint && <FormElementDivider />}
          <FormElement label={<FormLabel translateY="0em">Players</FormLabel>}>
            <VStack
              spacing="0px"
              sx={{
                "& > *": {
                  w: "full",
                },
              }}
            >
              {/* host: match height with player button at mobile only  */}
              <Flex
                textStyle="body"
                pl={{ base: 0, lg: 2 }}
                height={{ base: "32px", md: "auto" }}
                alignItems={{ base: "center", md: "flex-start" }}
                pb={{ md: "0.5em" }}
              >
                {hostPlayer?.fullName}
                <Text as="span" pl={2} fontWeight="400" opacity={0.4}>
                  Owner
                </Text>
              </Flex>
              {/* map players */}
              {playersWithoutHost.map((player: IPlayer, index: number) => (
                <DeleteCompPlayerModal
                  key={index.toString()}
                  player={player}
                  index={index}
                  onClick={() => handleRemoveCompPlayer(player.id)}
                />
              ))}
            </VStack>
          </FormElement>

          {/* ADD PLAYERS */}
          {breakpoint && <FormElementDivider />}
          <FormAddPlayers
            comp={comp}
            onParentClose={onParentClose}
            onCompetitionUpdate={onCompetitionUpdate}
          />

          {/* DELETE BUTTON */}
          <FormElementDivider />
          <FormElement>
            <DeleteCompModal
              onClick={deleteCompetition}
              isDeleting={isDeleting}
            />
          </FormElement>
        </DefaultVStack>
      </Box>
    </>
  );
};

import { HStack, StackDivider, Flex, Icon } from "@chakra-ui/react";
import { MdSwapHorizontalCircle } from "react-icons/md";
import { ScorecardRowBase, RowHoleScore } from "./index";
import { EMatchType } from "types";

/*
  ScorecardRowForEclectic is only used in ScorecardRowEclectic
  * Eclectic rounds are ALWAYS STABLEFORD
  * Augments ScoreRowX so we can configure icons & scores for Eclectic rounds
  * We pass variant & matchType as props so that ScorecardRow
    is not dependent on using useScorecardContext
 */

interface IScoreEclectic {
  scoreStableford: number;
  scoreStroke: number;
  noScore: boolean;
}

interface IScorecardRowForModal {
  isFinalScorecard?: boolean;
  matchType: EMatchType;
  mismatchingScore?: boolean;
  scores: IScoreEclectic;
  [x: string]: any;
}

export const ScorecardRowForEclectic = ({
  isFinalScorecard,
  matchType,
  mismatchingScore,
  scores,
  ...props
}: IScorecardRowForModal) => {
  return (
    <ScorecardRowBase {...props}>
      {/* ICONS */}
      {/* No score info icon */}
      {scores.noScore && (
        <Flex h="full" transform="translateX(0.6em)">
          <Icon as={MdSwapHorizontalCircle} color="form.good" boxSize="1.4em" />
        </Flex>
      )}

      {/* SCORES */}
      <HStack
        divider={
          <StackDivider borderColor="grey.100" sx={{ mx: "0 !important" }} />
        }
      >
        {/* STR */}
        <RowHoleScore score={scores.scoreStroke} />
        {/* STFD */}
        <RowHoleScore score={scores.noScore ? "–" : scores.scoreStableford} />
      </HStack>
    </ScorecardRowBase>
  );
};

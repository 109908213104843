import { VStack, StackProps } from "@chakra-ui/react";

export const BlockStack = ({
  spacing = "w6",
  children,
  ...props
}: StackProps) => {
  return (
    <VStack
      w="full"
      spacing={spacing}
      pb="w9"
      {...props}
      sx={{
        "& > *": {
          w: "full",
        },
      }}
    >
      {children}
    </VStack>
  );
};

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import reducers from "./reducers";
import createSocketMiddleware from "./middleware/socket";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const socketMiddleware = createSocketMiddleware();
const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(thunk, socketMiddleware))
);

export default store;

import * as api from "./api";
import * as formatData from "./formatData";

// Refrain from type aliasing promises
// interface IFetchUsers {
//   query: string;
// }

export function getUsersFactory(
  userId: string,
  {
    filter = undefined,
    RESULTS_LIMIT = 7
  }: Record<string, any> = {},
) {
  return async function (query = "") {
    if (!query) return [];

    const response: any = await api.getUsers(query);
    if (response.error) return [];

    // Don't waste resources formatting,
    // filter out results which won't be rendered
    let users = response.json.users
      .filter((user) => user.id !== userId);

    if (filter) {
      users = users.filter(filter);
    }

    return users
      .slice(0, RESULTS_LIMIT)
      .map(formatData.formatUserForRowComponents);
  };
}

import { Button } from "@chakra-ui/react";
import { ScoreShell } from "./RowElements";
import {
  STYLE_ScoreShellWidthDecimal,
  STYLE_ScoreShellWidth,
} from "components";

/*
  TableHeaderLabelComp is the same style & behaviour
  as TableHeaderLabel but for use with a Competition
 */

interface Props {
  label: string;
  rawLabel: string;
  onClick?: (label: string) => void;
  sortBy?: string;
  showEdge?: boolean;
  showAsSpan?: boolean;
  showWidthAsDecimalStyle?: boolean;
  buttonStyleProps?: any;
}

export const TableHeaderLabelComp = ({
  label,
  rawLabel,
  onClick,
  sortBy,
  showEdge = true,
  showAsSpan,
  showWidthAsDecimalStyle,
  buttonStyleProps,
}: Props) => {
  const hoverStyle = {
    bg: "text.50",
  };

  const activeStyle = {
    bg: "text.700",
    color: "white",
  };

  const handleClick = () => {
    if (showAsSpan) return;
    if (onClick) onClick(label);
  };

  return (
    <ScoreShell
      showEdge={showEdge}
      width={
        showWidthAsDecimalStyle
          ? STYLE_ScoreShellWidthDecimal
          : STYLE_ScoreShellWidth
      }
    >
      {/* Same style as TableHeadingButton */}
      <Button
        onClick={handleClick}
        as={showAsSpan ? "span" : "button"}
        className="TableHeadingButton"
        colorScheme="text"
        size="sm"
        variant="ghost"
        mx="auto"
        _hover={showAsSpan ? {} : hoverStyle}
        _active={showAsSpan ? {} : activeStyle}
        fontWeight={sortBy === rawLabel ? "700" : "500"}
        color={sortBy === rawLabel ? "text.800" : "text.400"}
        {...buttonStyleProps}
      >
        {label}
      </Button>
    </ScoreShell>
  );
};

import { Flex, FlexProps } from "@chakra-ui/react";
import { ScoreShell } from "../ClubTable/RowElements";

// Used in ScorecardRow & ScorecardRowHeader
// V similar to the ScoreShell inner child in ClubTable/RowElements/Score
export const RowHoleScore = ({
  score,
  centerByWidth,
  pr = { base: 3, lg: 4 },
}: {
  score: number | string;
  centerByWidth?: boolean;
} & FlexProps) => {
  const centerByWidthStyle = {
    justifyContent: "center",
    pr: { base: 0, lg: 1 },
    w: "42px",
  };

  return (
    <ScoreShell>
      <Flex
        className="RowHoleScore-inner"
        alignSelf="center"
        // flex={1}
        fontWeight="500"
        justifyContent="flex-end"
        letterSpacing="wide"
        pl={{ base: 3, lg: 0 }}
        pr={pr}
        textStyle="hMinor"
        textAlign="center"
        {...(centerByWidth && centerByWidthStyle)}
      >
        {score}
      </Flex>
    </ScoreShell>
  );
};

import {
  Flex,
  Button,
  Box,
  Text,
  Avatar,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  HStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { SettingsIcon } from "@chakra-ui/icons";
import { AccountForm } from "components/AccountForm";
import { NavbarModalHeader } from "./index";

interface INavbarAccountPresenter {
  onParentClose: () => void;
  // invites: any;
}

export const NavbarAccountPresenter = ({
  onParentClose,
}: INavbarAccountPresenter) => {
  const history = useHistory();
  const user = useSelector((state) => state.authentication.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDesktop] = useMediaQuery("(min-width: 640px)");

  function handleClose() {
    onClose();
    onParentClose();
  }

  return (
    <>
      <Button
        variant="ghost"
        colorScheme="text"
        justifyContent="flex-start"
        w="full"
        px={0}
        _active={{
          bg: "transparent",
        }}
        // on desktop, goto /account else open as a modal
        onClick={isDesktop ? () => history.push("/account") : onOpen}
      >
        <HStack spacing={3}>
          <Avatar
            size="md"
            name={user.fullName}
            // src={user.avatar}
            key={user.avatar}
            border="1px solid white"
            cursor="pointer"
          />
          <Box>
            <Text
              as="div"
              textStyle="metaButton"
              color="text.800"
              fontWeight="500"
              textAlign="left"
            >
              {user.fullName}
            </Text>
            <Flex
              pt="2px"
              alignItems="center"
              textStyle="crumb"
              fontWeight="500"
              color="grey.600"
            >
              <SettingsIcon mr="4px" />
              <Box pt="1px">Account</Box>
            </Flex>
          </Box>
        </HStack>
      </Button>

      <Drawer
        placement="bottom"
        size="full"
        isOpen={isOpen}
        onClose={handleClose}
      >
        <DrawerOverlay className="" />
        <DrawerContent boxShadow="none" className="screen-pb">
          <NavbarModalHeader />
          <AccountForm
            handleClose={handleClose}
            wrapperProps={{
              layerStyle: "containerInset",
              mt: -12,
            }}
            buttonWrapperProps={{
              mt: "auto",
            }}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};

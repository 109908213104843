import * as Yup from "yup";
import { ECompKind } from "types";
import { COMPETITION_DAYS } from "config/constants";

export enum ECreateCompField {
  competitionName = "competitionName",
  competitionType = "competitionType",
  course = "course",
  duration = "duration",
  days = "days",
  players = "players",
}

export interface ICreateCompValues {
  competitionName: string;
  competitionType: string;
  duration: number;
  // course: Record<string, any>;
  course: {
    id: string;
  };
  days: string[];
  players: Record<string, any>;
}

export const initialValues = {
  competitionName: "",
  competitionType: ECompKind.eclectic, // added for our select
  duration: 6,
  course: null,
  days: [],
  players: [],
};

export const validationSchema = Yup.object({
  competitionName: Yup.string()
    .max(25, "Must be 25 characters or less")
    .required("Required"),
  competitionType: Yup.string()
    // specify the set of valid values for job type
    // @see http://bit.ly/yup-mixed-oneOf
    .oneOf([ECompKind.race, ECompKind.eclectic])
    .required("Required"),
  course: Yup.object().required("Required"),
  duration: Yup.number().required("Required"),
  days: Yup.array()
    .of(Yup.string().oneOf(Object.values(COMPETITION_DAYS)))
    .required("Required"),
  players: Yup.array().of(Yup.object()).required("Required"),
});

export const valuesList = (values: ICreateCompValues) => ({
  name: values.competitionName,
  type: values.competitionType,
  course: values?.course?.id,
  duration: values.duration,
  days: values.days,
  players: values?.players?.map(({ id }) => id),
});

export const compForm = (formik: any, errors: any) => [
  {
    name: ECreateCompField.competitionName,
    input: "Text",
    label: "Competition Name",
    gridColumn: "span 1/span 1",
  },
  {
    name: ECreateCompField.competitionType,
    input: "Email",
    label: "Competition Type",
    gridColumn: "span 1/span 1",
    // sectionHeading: "Personal details",
  },
  {
    name: ECreateCompField.course,
    input: "Text",
    label: "Course Name",
    gridColumn: "span 1/span 1",
  },
  {
    name: ECreateCompField.duration,
    input: "Text",
    label: "Duration",
    gridColumn: "span 1/span 1",
  },
  {
    name: ECreateCompField.days,
    input: "Text",
    label: "Day(s)",
    gridColumn: "span 1/span 1",
  },
  // TODO: invited players
];

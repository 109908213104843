const holes = [
  {
    "id":"Crescent Head Country Club-Hole-1",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":1,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":254,
      "index":4
    },
    "ladies_standard":{
      "distance":254,
      "index":1
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-2",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":2,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":105,
      "index":15
    },
    "ladies_standard":{
      "distance":76,
      "index":11
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-3",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":3,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":267,
      "index":10
    },
    "ladies_standard":{
      "distance":267,
      "index":13
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-4",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":4,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":277,
      "index":7
    },
    "ladies_standard":{
      "distance":277,
      "index":7
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-5",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":5,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":173,
      "index":3
    },
    "ladies_standard":{
      "distance":173,
      "index":4
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-6",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":6,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":100,
      "index":18
    },
    "ladies_standard":{
      "distance":100,
      "index":16
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-7",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":7,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":254,
      "index":5
    },
    "ladies_standard":{
      "distance":254,
      "index":2
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-8",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":8,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":105,
      "index":14
    },
    "ladies_standard":{
      "distance":105,
      "index":10
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-9",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":9,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":267,
      "index":11
    },
    "ladies_standard":{
      "distance":267,
      "index":14
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-10",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":10,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":277,
      "index":8
    },
    "ladies_standard":{
      "distance":277,
      "index":9
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-11",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":11,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":173,
      "index":2
    },
    "ladies_standard":{
      "distance":155,
      "index":5
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-12",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":12,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":91,
      "index":17
    },
    "ladies_standard":{
      "distance":91,
      "index":18
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-13",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":13,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":254,
      "index":6
    },
    "ladies_standard":{
      "distance":254,
      "index":3
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-14",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":14,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":105,
      "index":13
    },
    "ladies_standard":{
      "distance":76,
      "index":12
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-15",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":15,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":267,
      "index":12
    },
    "ladies_standard":{
      "distance":267,
      "index":15
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-16",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":16,
    "par":{
      "mens":4,
      "ladies":4
    },
    "standard":{
      "distance":277,
      "index":9
    },
    "ladies_standard":{
      "distance":277,
      "index":8
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-17",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":17,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":173,
      "index":1
    },
    "ladies_standard":{
      "distance":155,
      "index":6
    }
  },
  {
    "id":"Crescent Head Country Club-Hole-18",
    "course":[
      "rec0JILdah2t29bg7"
    ],
    "number":18,
    "par":{
      "mens":3,
      "ladies":3
    },
    "standard":{
      "distance":102,
      "index":16
    },
    "ladies_standard":{
      "distance":102,
      "index":17
    }
  }
];
export default holes;

import React from "react";
import { Button } from "@chakra-ui/react";
import { MdPlayArrow } from "react-icons/md";

export const NavbarButton = ({
  path = "/",
  activePath,
  label = "Round Play",
}: {
  path: string;
  activePath: string;
  label: string;
}) => (
  <Button
    variant="ghost"
    colorScheme="textButton"
    justifyContent="flex-start"
    w="full"
    px={0}
    // if path === activePath, show an icon
    leftIcon={path === activePath ? <MdPlayArrow /> : undefined}
    sx={{
      ".chakra-button__icon svg": {
        h: "0.7em",
        w: "0.7em",
      },
    }}
  >
    {label}
  </Button>
);

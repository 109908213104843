import { ICourse, IPlayer, IUserMatchSettingsComp } from "./index";

export type TCompKind = "RACE_TO_THE_TOP" | "ECLECTIC";

export enum ECompKind {
  race = "RACE_TO_THE_TOP",
  eclectic = "ECLECTIC",
  travelling = "TRAVELLING",
}

// Eclectic comps are stableford only
// See: https://www.notion.so/callum/YLG-Clubhouse-system-design-Mothership-4c1678643d804de1acfe3c9bd1f557c7
// Uses the same enum types as the server: https://github.com/callumflack/local-golf-server/blob/823e208e38da97663a231a1345d4833b3f17ded4/src/routes/competitions/index.js#L18
export enum ECompTotal {
  // ECLECTIC_ROUND_SORT_BY enums from server
  ROUND_COUNT = "ROUND_COUNT",
  STROKE_GROSS = "STROKE_GROSS", // Gross stableford total
  STROKE_NETT = "STROKE_NETT", // Nett stroke total
  FIRST_NINE = "FIRST_NINE",
  LAST_NINE = "LAST_NINE",
  // CF additions for labeling only
  FRONT = "FRONT",
  BACK = "BACK",
  RANK = "RANK",
}

export interface IHoleScore {
  createdAt: string;
  distance: string;
  holeNumber: number;
  id: string;
  noScore: boolean;
  par: number;
  score: number;
  strokes: number;
  user: string;
  userRound: string;
}

export interface IHoleScoreServer {
  createdAt: string;
  holeNumber: number;
  holePar: number;
  holeParUnadjusted: number;
  id: string;
  noScore: boolean;
  score: number;
  scorePar: number;
  scoreStableford: number;
  scoreStroke: number;
  strokes: number;
}

export interface IHoleScoreEclectic extends IHoleScoreServer {
  matchId: string;
  recordedByUserId: string;
  updatedAt: string;
  userId: number;
}

/* ICompCard: component-level types */
export interface ICompCard {
  id: number;
  name: string;
  type: TCompKind;
  hostId: number;
  course: ICourse;
  days: string[];
  // players: IPlayer[];
  duration: number;
  weeksPlayed: number;
  startDate: string;
  isFinished: boolean;
}

// useful for various components
export interface ICompCardWithPlayers extends ICompCard {
  players: IPlayer[];
}

/* IComp: competition types */
export interface ICompetition extends ICompCard {
  courseId?: number;
  players: IPlayer[];
  holeScores: IHoleScore[];
}

export interface IEclecticRoundTotalsBasic {
  firstNineScoreStableford: number;
  firstNineScoreStroke: number;
  firstNineScorePar: number;
  lastNineScoreStableford: number;
  lastNineScoreStroke: number;
  lastNineScorePar: number;
  totalScore: number;
  totalScorePar: number;
  totalScoreStableford: number;
  totalScoreStroke: number;
  totalScoreStrokeNett: number;
}

export interface IEclecticRoundTotals {
  firstNineScoreStableford: number;
  firstNineScoreStroke: number;
  firstNineScoreStrokeNett: number;
  firstNineScorePar: number;
  lastNineScoreStableford: number;
  lastNineScoreStroke: number;
  lastNineScoreStrokeNett: number;
  lastNineScorePar: number;
  totalScore: number;
  totalScorePar: number;
  totalScoreStableford: number;
  totalScoreStroke: number;
  totalScoreStrokeNett: number;
  firstNineScoreStrokeRank: number;
  lastNineScoreStrokeRank: number;
  firstNineScoreStrokeNettRank: number;
  lastNineScoreStrokeNettRank: number;
  totalScoreStrokeRank: number;
  totalScoreStrokeNettRank: number;
}

export interface IEclecticRound extends IEclecticRoundTotals {
  id: string;
  player: IPlayer;
  userMatchSettings: IUserMatchSettingsComp[];
  holeScores: IHoleScoreServer[];
  contributingRounds: number;
}

import {
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Text,
} from "@chakra-ui/react";
import { LgModalCloseButton } from "components/Block";
// import { BlockLoading } from "components/Block2";

export const TableSearchCourseModal = ({
  isOpen,
  onClose,
  children,
  currentCourse,
}: ModalProps & { currentCourse: string }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        // size="full"
        isCentered
        closeOnOverlayClick
        motionPreset="scale"
        scrollBehavior="inside"
      >
        <ModalOverlay bg="rgb(0 0 0 / 40%)" />
        <ModalContent
          alignItems="center"
          borderRadius="md"
          pos="relative"
          maxW="container.md"
          minHeight="calc(100vh - 16vw)"
          mx="5vw"
          my="8vw"
          overflow="hidden"
          shadow="none"
        >
          <LgModalCloseButton />
          {/* <BlockLoading /> */}
          <ModalBody p="inset" w="full">
            <Text textStyle="hFour" fontWeight="500" pt={4} pb={1}>
              Show another course
            </Text>
            <Text textStyle="body" fontWeight="400" color="text.400" pb={5}>
              Current course: {currentCourse}
            </Text>
            <Box pos="relative">{children}</Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

// @refresh reset
// import { theme, extendTheme, mode } from "@chakra-ui/react";
// import { merge } from "@chakra-ui/utils";
// import { extendTheme } from "@chakra-ui/react";
// import { mode } from "@chakra-ui/theme-tools";

// https://github.com/chakra-ui/chakra-ui/tree/develop/packages/theme/src

const styles = {
  global: {
    html: {
      bg: "neutral.200",
    },
    body: {
      bg: "neutral.200",
      fontFamily: "body",
      fontWeight: "400",
      color: "text.800",
      fontSize: "16px",
      lineHeight: "base",
      transition: "background-color 0.2s",
    },
    // "a:not(button)": {
    //   textDecoration: "none",
    //   _hover: {
    //     textDecoration: "none",
    //   },
    // },
    // "*::placeholder": {
    //   color: "gray.400",
    // },
    // "*, *::before, &::after": {
    // borderColor: "gray.200",
    // wordWrap: "break-word",
    // },
    // should work for any selector on a page but DOES NOT
    // https://github.com/chakra-ui/chakra-ui/issues/1561#issuecomment-671029136
    // "#__next": {
    //   backgroundColor: "pink",
    //   height: "100%",
    //   width: "100%",
    // },

    // reset the viewport inset method for Chakra models so they always fit the `window.innerHeight` on mobile
    // DUPLICATE STYLES ON PURPOSE! the `fill-available` rules otherwise doesn't seem to work on the first class…
    // ".chakra-modal__overlay": {
    //   // height: "100vh !important",
    //   // height: "-webkit-fill-available !important",
    //   // eslint-disable-next-line no-dupe-keys
    //   height: "var(--screen-height) !important",
    // },
    // ".chakra-modal__content-container": {
    //   // height: "100vh !important",
    //   // eslint-disable-next-line no-dupe-keys
    //   height: "var(--screen-height) !important",
    // },
    // ".chakra-slide.chakra-modal__content": {
    //   // height: "auto!important",
    //   // maxHeight: "initial!important",
    // },
    // Prose
    ".prose": {
      "p + p": {
        mt: [5],
      },
    },
  },
};

export default styles;

import * as datoApi from "lib/apiDato";

export const SPONSORS_FOR_COURSE_REQUEST = "SPONSORS_FOR_COURSE_REQUEST";
export const SPONSORS_FOR_COURSE_SUCCESS = "SPONSORS_FOR_COURSE_SUCCESS";
export const SPONSORS_FOR_COURSE_FAILURE = "SPONSORS_FOR_COURSE_FAILURE";

export const fetchSponsorsForCourse = (courseId) => async (dispatch) => {
  dispatch({
    type: SPONSORS_FOR_COURSE_REQUEST,
  });

  const sponsors = await datoApi.getSponsorsByGolflinkClubId(courseId);
  const defaultSponsor = await datoApi.getDefaultSponsor();

  // console.log("fetchSponsorsForCourse courseId", courseId);
  // console.log("fetchSponsorsForCourse sponsors", sponsors);
  // console.log("fetchSponsorsForCourse defaultSponsor", defaultSponsor);

  return dispatch({
    type: SPONSORS_FOR_COURSE_SUCCESS,
    courseId,
    sponsors,
    defaultSponsor,
  });
};

export const fetchSponsorsForCourseIfNeeded = (courseId) => async (
  dispatch,
  getState
) => {
  const state = getState();

  if (!state.sponsors[courseId]) {
    return dispatch(fetchSponsorsForCourse(courseId));
  }

  return null;
};


/**
 * Base functions for interacting with other apis
 *
 * Utility of this module:
 * * Authentication: Bearer token is set in the "Authorization" header
 * * Automatically resolves response json instead of calling const json = response.json() every time
 * * Supports sending a FormData object
 *
 * Requests will return either a ApiResponse or ApiError (defined in @typedef below)
 * For more info see the basicRequest method
 *
 * @module ApiFactory
 */

import config from "../config";

/**
 * @typedef {Object} ApiError - The server APIs error format
 * @property {string} ApiError.code - Errors unique identifier, used for errorToMessageMaps
 * @property {string} ApiError.message - Human readable message
 */

/**
 * @typedef {Object} ApiResponse
 * @property {number} [ApiResponse.status] - API response status
 * @property {object.<string, *>} [ApiResponse.json] - API response data
 * @property {ApiError} [ApiResponse.json.error] - Standard API error response
 * @property {ApiError} [ApiResponse.error] - Either from the api or this module if the request failed to send. Duplicates the json.error value for easy checking if the response errored
 *
 * There will always be either a `status` or `error`
 * You can expect `json` will always be set if the api response includes a body
 */

/**
 * @typedef {object} ApiErrorObject
 * @typedef {ApiError} ApiErrorObject.error
 */

/**
 * Construct an error object in the format of the servers errors response
 * @param {string} code
 * @param {string} message
 * @returns {ApiErrorObject}
 */
export function ApiError(code, message) {
  return {
    error: {
      code,
      message,
    },
  };
}

/**
 * API
 *
 * @param {object} options
 * @param {string} options.url
 */
export default function API({ url: baseUrl }) {
  /**
   * Parse api response
   *
   * @private
   * @async
   *
   * @param {object} response
   * @returns {Promise<ApiResponse>}
   */
  async function parseAPIResponse(response) {
    const contentType = response.headers.get("content-type");
    const isJson = contentType && contentType.includes("application/json");

    let res = {
      status: response.status,
    };

    if (isJson) {
      res.json = await response.json();

      if (res.json.error) {
        res.error = res.json.error;
      }
    }

    return res;
  }

  /**
   * Generic request template
   *
   * @private
   * @async
   *
   * @param {string} url
   * @param {object} data
   * @param {object} [options]
   * @param {string} [options.method]
   * @param {string} [options.bearerToken]
   * @param {string} [options.formDataObject]
   * @returns {Promise<ApiResponse>}
   */
  async function basicRequest(
    url,
    data,
    { method = "POST", bearerToken = null, formDataObject = false } = {}
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearerToken ? `Bearer ${bearerToken}` : undefined,
    };
    // Let the browser decide the content type
    if (formDataObject) delete headers["Content-Type"];
    const requestOptions = {
      method,
      headers: new Headers(headers),
    };

    if (method !== "GET") {
      requestOptions.body = formDataObject ? data : JSON.stringify(data);
    }

    const response = await fetch(`${baseUrl}${url}`, requestOptions);
    return parseAPIResponse(response);
  }

  /**
   * Send an authenticated request
   * Wraps a basic request to include the authorization header fetched
   * from the store
   *
   * Returns an error if no access_token is in the store
   *
   * @private
   * @async
   *
   * @param {string} url
   * @param {object} data
   * @param {object} options
   * @param {string} [options.method] - request http method
   * @param {string} [options.formDataObject] - data is a FormData object, should send content type of multipart form data
   * @returns {Promise<ApiResponse>}
   *
   * { error: }
   * { json, error }
   */
  async function authedRequest(url, data, options = {}) {
    const bearerToken = localStorage.getItem(config.localStorageBearerKey);

    if (!bearerToken) {
      return ApiError(
        "MISSING_AUTH_TOKEN",
        "To make an authorized request you must be signed in"
      );
    }

    return basicRequest(url, data, {
      ...options,
      bearerToken,
    });
  }

  return Object.freeze({
    basicGet: (url, data, options) =>
      basicRequest(url, data, { ...options, method: "GET" }),
    basicPost: (url, data, options) =>
      basicRequest(url, data, { ...options, method: "POST" }),
    basicPut: (url, data, options) =>
      basicRequest(url, data, { ...options, method: "PUT" }),
    basicDelete: (url, data, options) =>
      basicRequest(url, data, { ...options, method: "DELETE" }),

    authedGet: (url, data, options) =>
      authedRequest(url, data, { ...options, method: "GET" }),
    authedPost: (url, data, options) =>
      authedRequest(url, data, { ...options, method: "POST" }),
    authedPut: (url, data, options) =>
      authedRequest(url, data, { ...options, method: "PUT" }),
    authedDelete: (url, data, options) =>
      authedRequest(url, data, { ...options, method: "DELETE" }),

    authedFormDataPost: (url, data, options) =>
      authedRequest(url, data, {
        ...options,
        method: "POST",
        formDataObject: true,
      }),
  });
}

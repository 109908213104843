import { useState } from "react";
import ScorePicker from "../ScorePicker";
import { LgLink } from "../Typography";
import arrayOfRange from "lib/arrayOfRange";
import * as handicapLib from "lib/handicap";

function splitHandicap(handicap) {
  const value = handicapLib.forShow(handicap);
  return {
    unit: value.split(".")[0],
    decimal: value.split(".")[1],
  };
}

export const ConfigRowPickerHandicap = ({
  handicap = "9.5",
  isInDrawer,
  onSelect,
}: {
  handicap: number | string;
  isInDrawer?: boolean;
  onSelect: (handicap: number) => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSelect(value) {
    setIsSubmitting(true);
    onSelect(handicapLib.toRealValue(value));
  }

  return (
    <ScorePicker
      isInDrawer={isInDrawer}
      valueGroups={splitHandicap(handicap)}
      onSelect={handleSelect}
      isSubmitting={isSubmitting}
      optionGroups={{
        // the order of unit & decimal matters here!
        // they're rendered in the same order as they're defined
        unit: [
          ...arrayOfRange(10)
            .map((num) => `+${num}`)
            .reverse(),
          ...arrayOfRange(55, 0),
        ],
        // show decimals last!
        decimal: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      }}
      direction={
        <>
          Your handicap is officially set by{" "}
          <LgLink
            to="https://www.golf.org.au/member/dashboard"
            label="Golf Australia"
          />
          . Update your handicap based on your recent performances.
        </>
      }
    />
  );
};

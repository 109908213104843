import { Flex, Box, Text, Button } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import { PosterSmallNumber } from "./index";
import * as constants from "config/constants";
import { IScoreBase, EMatchType } from "types";

const ParEvenSymbol = () => (
  <Box border="7px solid" bordercolor="text.800" h={8} w={8} mt="10px" />
);

interface IPosterHoleScore extends IScoreBase {
  bigNumber: string | number | React.ReactNode;
  editButton?: boolean;
  metaLabel?: string;
  onEditClick?: () => void;
  smallNumber?: string | number | React.ReactNode;
  smallNumberIsSquare?: boolean;
  variantIsEclectic?: boolean;
}

export const PosterHoleScore = ({
  bigNumber,
  editButton,
  matchType,
  metaLabel = "Hole completed",
  onEditClick,
  smallNumber,
  smallNumberIsSquare,
  time,
  variantIsEclectic,
}: IPosterHoleScore) => (
  <Flex pt={4} direction="column" justify="center" align="center">
    <Box pos="relative">
      {/* big number! */}
      <Text as="div" textStyle="hPrimary" fontSize="188px" fontWeight="300">
        {bigNumber}
      </Text>

      {/* small number aside the big number */}
      {smallNumber !== undefined && (
        <PosterSmallNumber isLeft={matchType === EMatchType.par}>
          {smallNumberIsSquare ? <ParEvenSymbol /> : smallNumber}
        </PosterSmallNumber>
      )}
    </Box>

    {/* score meta info */}
    <Text as="div" textStyle="metaCopy" pt={2} pb={1}>
      {constants.constantToFriendly(matchType)}
    </Text>
    {variantIsEclectic ? (
      <Text as="div" textStyle="meta">
        {metaLabel}
      </Text>
    ) : (
      <Text as="div" textStyle="meta" color="grey.400">
        {metaLabel} {time}
      </Text>
    )}

    {/* edit button */}
    {editButton && (
      <Box pt="3px">
        <Button
          size="sm"
          variant="ghost"
          colorScheme="textButton"
          opacity="0.5"
          fontWeight="700"
          px={3}
          zIndex="3"
          leftIcon={<MdEdit />}
          onClick={onEditClick}
        >
          Edit
        </Button>
      </Box>
    )}
  </Flex>
);

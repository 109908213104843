import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ScoreSheetPresenter } from "./index";
import * as matchActions from "store/actions/match";
import useFormatScoresForScoresheet from "hooks/useFormatScoresForScoresheet";

export const ScoreSheet = ({ matchType }: { matchType: string }) => {
  const history = useHistory();
  const isSocial = useSelector(matchActions.selectors.getIsSocial);
  // score labels are variations derived from constants.MATCH_TYPES
  const scoreLabel = useSelector(matchActions.selectors.getScoreLabel);
  const scoresheets = useFormatScoresForScoresheet();

  function goToHole(x) {
    history.push(`/score/${x}`);
  }

  return (
    <ScoreSheetPresenter
      isSocial={isSocial}
      scoresheets={scoresheets}
      matchType={matchType}
      scoreLabel={scoreLabel.abbr || scoreLabel.long}
      goToHole={goToHole}
    />
  );
};

import { Icon } from "@chakra-ui/react";
import { ConfigRow, ConfigRowDrawer } from "../Config";
import { SearchScoring } from "../Search";
import { MessageNoPlayer } from "./index";
import { MdCancel } from "react-icons/md";
import { EConfigRowTheme } from "types";

export const LobbyInviteSearch = ({
  getUsers,
  onSelect,
  // Users currently in the lobby or invited
  users = [],
  isHost,
  userAccepted,
}: {
  getUsers: (search: string) => void;
  onSelect: (user: any) => void;
  users: any;
  isHost?: boolean;
  userAccepted?: boolean;
}) => {
  return (
    <>
      {users.map((user) => (
        <ConfigRow
          key={user.id}
          name={user.name}
          value={user.value}
          theme={
            userAccepted ? EConfigRowTheme.valid : EConfigRowTheme.awaiting
          }
        >
          <Icon as={MdCancel} color="text.800" mr={2} />
        </ConfigRow>
      ))}

      {isHost && (
        <ConfigRowDrawer
          btnBackLabel="Settings"
          btnName=""
          btnValue="Invite players"
          heading="Invite a player"
        >
          {(disclosure) => (
            <SearchScoring
              kind="player"
              getItems={getUsers}
              drawerHeading="Invite Player"
              placeholder="Search for a player…"
              // buttonLabel="Invite"
              onSelect={(item) => {
                onSelect(item);
                disclosure.onClose();
              }}
              noResultsMessage={<MessageNoPlayer />}
            />
          )}
        </ConfigRowDrawer>
      )}
    </>
  );
};

import * as React from "react";
import { VStack, StackProps } from "@chakra-ui/react";

export default function DefaultVStack({
  children,
  ...props
}: {
  children: React.ReactNode;
  [x: string]: any;
} & StackProps) {
  return (
    <VStack
      {...props}
      sx={{
        "& > *": {
          w: "full",
        },
      }}
    >
      {children}
    </VStack>
  );
}

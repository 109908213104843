import { Text } from "@chakra-ui/react";
import { LayoutClubhouse } from "components/Layout";
import { BlockStack } from "components/Block2";
import { Leaderboard } from "components/ClubTable";
import { CompetitionsList } from "components/ClubComp";
import { SponsorInScore } from "components/Sponsor";
import { useGetDailyResults } from "lib/api";
import { UserProvider } from "components/Context";
import { useSponsorClubhouse } from "hooks";

export const ClubhousePage = () => {
  const { sponsor, defaultSponsor } = useSponsorClubhouse();

  return (
    <UserProvider>
      <LayoutClubhouse
        sponsorSlot={<SponsorInScore sponsor={sponsor || defaultSponsor} />}
      >
        {/* <BlockStack pb={{ base: "w11", lg: 0 }}> */}
        <BlockStack pb="w11">
          {/* LEADERBOARD */}
          <Leaderboard
            tableHeading="Daily Leaderboard"
            useGetRounds={useGetDailyResults}
            viewAllLink="/clubhouse/leaderboard"
            noRoundsMessage={
              <>
                <Text as="span" fontWeight="500">
                  No verified rounds on this course today.
                </Text>{" "}
                Be the first!
              </>
            }
          />

          {/* COMPETITIONS */}
          <CompetitionsList />
        </BlockStack>
      </LayoutClubhouse>
    </UserProvider>
  );
};

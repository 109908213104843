import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import * as authActions from "store/actions/authentication";
import { Formik } from "formik";
import * as Yup from "yup";
import { Text, useDisclosure } from "@chakra-ui/react";
import { ValidationModal } from "components/Notices";
import { RegisterFormLayout } from "./RegisterFormLayout";
import * as schemas from "lib/schemas";

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const successModalDisclosure = useDisclosure();

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    const response = await dispatch(
      authActions.registerUser({
        email: values.email,
        password: values.password,
        fullName: values.fullName,
        golflinkNumber: values.golflinkNumber,
        handicap: values.handicap,
        gender: values.gender,
      })
    );

    if (response.error) {
      setSubmitting(false);
      setStatus({
        apiError: response.error,
      });
      return;
    }

    successModalDisclosure.onOpen();
    setTimeout(() => {
      history.push("/signin");
    }, 9000);
  };

  return (
    <>
      <Formik
        // It's important fields are validated on mount else all fields will
        // be valid before input …and an empty value likely isn't valid
        validateOnMount={true}
        initialValues={{
          fullName: "",
          email: "",
          golflinkNumber: "",
          handicap: "9.1",
          password: "",
          passwordConfirm: "",
          gender: null,
        }}
        validationSchema={Yup.object({
          fullName: schemas.fullName,
          email: schemas.email,
          golflinkNumber: schemas.golflinkNumber,
          handicap: schemas.handicap,
          password: schemas.password,
          passwordConfirm: schemas.passwordConfirm,
          gender: schemas.gender,
        })}
        onSubmit={onSubmit}
      >
        {() => <RegisterFormLayout />}
      </Formik>

      {/* Successful signup next step: check your email for a verify link */}
      <ValidationModal
        kind="full"
        heading="Success!"
        isOpen={successModalDisclosure.isOpen}
      >
        <Text textStyle="meta" color="white">
          Please check your email to verify your account.
        </Text>
        <Text textStyle="meta" color="white" opacity="0.7" pt={1}>
          If you don't receive it, check your spam.
        </Text>
      </ValidationModal>
    </>
  );
};

export default Register;

import useSWR from "swr";
import config from "../config";

// why SWR?
// https://swr.vercel.app/
// using async await blocks the component
// and we have to configure useEffect and useState for loading ourselves
// SWR handles all that
// useSWR to get back nice caching & req status
// unlike api.*, which uses async await, we don't need to await

const serverUrl: string = config.serverUrl || "";

export async function fetcher(url) {
  const bearerToken = localStorage.getItem(config.localStorageBearerKey);
  const res = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      ...(bearerToken && { Authorization: `Bearer ${bearerToken}` }),
    }),
  });
  return res.json();
}

export default function useRequest<Data>(path: string) {
  // The base url can be set on the global configuration
  return useSWR<Data>(`${serverUrl}${path}`, fetcher);
}

/**
 * Match
 */

import * as handicapLib from "@/lib/handicap";
import getIncompleteHoles from "@/lib/getIncompleteHoles";
import {
  MATCH_SET,
  MATCH_CLEAR,
  MATCH_MARKER_SET,
  MATCH_READY,
  MATCH_SET_SCORE,
} from "../actions/match";
import { ACCEPT_INVITE } from "../actions/invites";

const oneToEighteen = Array(18)
  .fill(null)
  .map((_, index) => index + 1);
const initialState = {
  match: null,
  userMatchSettings: null,
  // Results for each hole keyed by hole numbers
  // Example: {
  //   3: {
  //     user: {
  //       strokes: 5,
  //       score: 2,
  //       noScore: false,
  //     },
  //     marker: {
  //       markerStrokes: 8,
  //       markerScore: 0,
  //       markerNoScore: true,
  //     },
  //   },
  // }
  scores: null,
  incompleteHoles: oneToEighteen,
  hasVerifiedMarkersRound: null,
};

function addHandicapsForShow(userMatchSettings) {
  const { marker, markerMatchSettings } = userMatchSettings;
  return {
    ...userMatchSettings,
    handicapForShow: handicapLib.forShow(userMatchSettings.handicap),
    dailyHandicapForShow: handicapLib.forShow(userMatchSettings.dailyHandicap),

    ...(marker && {
      marker: marker,
    }),
    ...(markerMatchSettings &&
      markerMatchSettings.handicap &&
      markerMatchSettings.dailyHandicap && {
      markerMatchSettings: {
        ...markerMatchSettings,
        handicapForShow: handicapLib.forShow(marker.handicap),
        dailyHandicapForShow: handicapLib.forShow(
          markerMatchSettings.dailyHandicap
        ),
      },
    }),
  };
}

function match(state = initialState, action) {
  if (
    action.userMatchSettings &&
    Object.keys(action.userMatchSettings).length &&
    action.userMatchSettings.handicap
  ) {
    action.userMatchSettings = addHandicapsForShow(action.userMatchSettings);
  }

  switch (action.type) {
    case MATCH_CLEAR:
    case ACCEPT_INVITE:
      return initialState;
    case MATCH_SET:
      return {
        ...state,
        match: action.match,
        userMatchSettings: action.userMatchSettings,
        scores: action.scores,
        rounds: action.rounds,
        incompleteHoles: action.incompleteHoles,
        hasVerifiedMarkersRound: action.hasVerifiedMarkersRound,
      };
    case MATCH_MARKER_SET:
      return {
        ...state,
        userMatchSettings: {
          ...state.userMatchSettings,
          marker: action.marker,
          markerMatchSettings: action.marker,
        },
      };
    case MATCH_READY:
      return {
        ...state,
        userMatchSettings: action.userMatchSettings,
      };
    case MATCH_SET_SCORE:
      const scores = {
        ...state.scores,
        [action.holeNumber]: action.score,
      };
      return {
        ...state,
        scores,
        incompleteHoles: getIncompleteHoles(scores),
      };
    default:
      return state;
  }
}

export default match;

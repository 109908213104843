import { Text, TextProps, Flex } from "@chakra-ui/react";
import { LgLogoMasthead } from "./index";

interface ILgHeader extends TextProps {
  isMasthead?: boolean;
  heading?: string;
  headingAs?: any | undefined;
  // headingKind?: "hPrimary" | "hSecondary" | "hTertiary" | "hFour";
  // use `any` so we can add objects…
  headingKind?: any;
  headingColor?: string;
  center?: boolean;
  isTransparent?: boolean;
  topLeftSlot?: React.ReactNode;
}

export const LgHeader = ({
  isMasthead,
  heading = "Heading",
  headingAs = "h1",
  headingKind = "hPrimary",
  headingColor = "text.800",
  center,
  isTransparent,
  topLeftSlot,
}: ILgHeader) => (
  <Flex
    as="header"
    bg={isTransparent ? "transparent" : "complement.50"}
    direction="column"
    h={{ base: "20vh", lg: "192px" }}
    justify="space-between"
    pos="relative"
    px="inset"
    pt={{ lg: 12 }}
    pb={{ base: 4, lg: 5 }}
    className="LgHeader pt-safe"
    // extend outside the parent container?
    // mx="-inset"
    // px="inset"
  >
    {/* top left slot for eg. back buttons */}
    {/* always render the div so the parent's justify="space-between" works */}
    <Flex py={topLeftSlot ? 2 : undefined} justify="space-between">
      {topLeftSlot}
    </Flex>

    {/* heading */}
    <Flex
      justify={isMasthead ? "center" : "start"}
      w="full"
      color={headingColor}
    >
      {isMasthead ? (
        <LgLogoMasthead />
      ) : (
        <Text
          as={headingAs}
          textStyle={headingKind}
          color={isTransparent ? "white" : headingColor}
          textAlign={center ? "center" : undefined}
          w="full"
          role="heading"
        >
          {heading}
        </Text>
      )}
    </Flex>
  </Flex>
);

import { Link as ReactRouterLink } from "react-router-dom";
import { Link, LinkProps } from "@chakra-ui/react";

export const LgLink = ({
  children,
  label,
  ...props
}: {
  children?: string;
  label?: string;
  [x: string]: any;
} & LinkProps) => (
  <Link as={ReactRouterLink} textDecoration="underline" {...props}>
    {children || label}
  </Link>
);

import { IUserMatchSettings, IPlayerBase, IScores, IMatchRound } from "types";

export type TScorecardVariant = "scoring" | "leaderboard" | "eclectic";

export enum EScorecardVariant {
  eclectic = "eclectic",
  leaderboard = "leaderboard",
  scoring = "scoring",
}

export type IScorecardUiConfig = {
  showHeader?: boolean;
  showHeaderImage?: boolean;
  showMarkerInSettings?: boolean;
};

// extends IMatchFromServer
export interface IScorecardPanel {
  verifyRoundSlot?: React.ReactNode;
  user: IPlayerBase;
  scores: Record<number, IScores>;
  // duplicate of IMatchRounds from lib/api types
  // so that we can use IMatchRound elsewhere
  rounds: {
    user: IMatchRound;
    marker?: IMatchRound;
  };
}

export interface IScorecard extends IScorecardPanel, IScorecardUiConfig {
  userMatchSettings: IUserMatchSettings;
  isSocial: boolean;
  // userCourse: ICourse;
  // markerCourse: ICourse;
}

export interface IVerifyRound {
  isForMarker: boolean;
  playerName: string;
  isVerifyingRound: boolean;
  verifyRoundResponse: any;
  roundIsVerified: boolean;
  handleVerifyRound: (mismatchingScoreHoleNumbers: any) => void;
}

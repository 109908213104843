/**
 * Join the socket room for the lobby of the current ppage
 * Will disconnect on leaving (unmounting)
 */

import React from "react";
import socket from "../lib/socket";

export default function useMatchSocket(matchId) {
  const hasJoined = React.useRef(false);

  React.useEffect(() => {
    socket.lobbyJoin(matchId);
    hasJoined.current = true;
    return () => {
      // Do we need to leave the lobby?
      // Or can we persist the connection across pages
      socket.lobbyLeave(matchId);
    };
  }, [matchId]);

  return {
    hasJoined,
  };
}

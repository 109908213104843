import { Flex, Text, Button, Modal, ModalContent } from "@chakra-ui/react";
import { LgLogo, LgModalInner } from "../Block";
import DefaultVStack from "components/VStack";
import useInvite from "hooks/useInvite";

interface IInvite {
  id: number;
  course: {
    name: string;
  };
  host: {
    fullName: string;
  };
  match: {
    id: number;
  };
}

export const LobbyInvite = ({ invite }: { invite: IInvite }) => {
  const { handleAcceptInvite, handleDenyInvite } = useInvite();

  return (
    <Modal
      isOpen={true}
      onClose={() => undefined}
      closeOnOverlayClick={false}
      size="full"
      motionPreset="scale"
    >
      <ModalContent
        // mb={0}
        bg="blackAlpha.400"
        shadow="none"
        height="full"
        minHeight="full"
        className="LobbyInviteModal-content"
      >
        <LgModalInner
          color="white"
          px="inset"
          bg="text.900"
          justify="center"
          items="center"
          textAlign="center"
        >
          <Flex justify="center" pb={4}>
            <LgLogo height={80} innerColor="var(--chakra-colors-text-900)" />
          </Flex>
          <Text textStyle="hSecondary" fontWeight="500" color="white" mb={2}>
            Invitation!
          </Text>
          <Text textStyle="body" color="white">
            {invite.host.fullName} has invited you
            <br />
            to a round at {invite.course.name}.
          </Text>
          <DefaultVStack spacing={4} pt={6}>
            <Button
              w="full"
              variant="outline"
              colorScheme="whites"
              onClick={() => handleAcceptInvite(invite.id, invite.match.id)}
            >
              Accept
            </Button>
            <Button
              w="full"
              size="md"
              variant="ghost"
              colorScheme="whites"
              opacity="0.7"
              onClick={() => handleDenyInvite(invite)}
            >
              No thanks
            </Button>
          </DefaultVStack>
        </LgModalInner>
      </ModalContent>
    </Modal>
  );
};

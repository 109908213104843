/**
 * Manage a users lobby settings
 */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import useFormData from "../hooks/useFormData";
import * as constants from "../config/constants";
import * as matchActions from "../store/actions/match";
import * as courseLib from "../lib/course";
import * as handicapLib from "../lib/handicap";

/**
 *
 * @param {object} settings - current settings
 */
export default function useRoundSettings(match, settings) {
  // Set default values
  const initialValues = {
    ready: false,
    ...settings,
  };
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);

  // Shorthand function to using the current round settings
  const dailyHandicapForRound = (handicap) => {
    if (
      !match ||
      !match.course ||
      !match.course.slopeRatings ||
      !match.course.scratchRatings ||
      !match.course.parRatings
    ) {
      console.error("match.course or its required properties are undefined");
      return null;
    }

    return courseLib.calculateDailyHandicap(
      match.tee,
      // Use matches course with no handicap modifier as ratings do not change
      // between different handicap modified courses
      // FIX: Ensure this is defined and has the expected shape
      // If match.course is undefined or doesn't have the expected shape (e.g., missing slopeRatings, scratchRatings, or parRatings properties), it could cause the TypeError: Cannot read properties of undefined (reading 'ratingCopy') at course.ts:174
      match.course,
      user.gender,
      parseFloat(handicap, 10)
    );
  };

  // Complete = if all fields are set, validate = if all requried fields are set
  // Case: Some fields are only required if others have a certain value
  // e.g. "play type" is competitive
  const [areUserSettingsValid, setAreUserSettingsValid] = React.useState(false);
  const dailyHandicap = dailyHandicapForRound(user.handicap);
  const [userSettings, setUserSetting, areUserSettingsComplete] = useFormData({
    handicap: user.handicap,
    dailyHandicap: dailyHandicap,
    handicapForShow: handicapLib.forShow(user.handicap),
    dailyHandicapForShow: handicapLib.forShow(dailyHandicap),
  });

  function setUserSettingsWrapper(name, value) {
    setAreLatestSettingsReadied(false);
    setUserSetting(name, value);
    if (name === "handicap") {
      const dailyHandicap = dailyHandicapForRound(value);
      setUserSetting("dailyHandicap", dailyHandicap);
      setUserSetting("handicapForShow", handicapLib.forShow(value));
      setUserSetting(
        "dailyHandicapForShow",
        handicapLib.forShow(dailyHandicap)
      );
    }
  }

  // Settings validator
  React.useEffect(() => {
    if (!match) return;
    // All SOCIAL settings have default values
    // Otherwise expect all possible fields to be set
    if (match.playType === constants.PLAY_TYPES.SOCIAL) {
      setAreUserSettingsValid(true);
      return;
    }
    setAreUserSettingsValid(areUserSettingsComplete);
  }, [userSettings, areUserSettingsComplete, match]);

  // Have user settings changed since player last readied
  // A user may ready up then change their settings this will be true until they
  // click the ready button again and resubmit their settings
  const [areLatestSettingsReadied, setAreLatestSettingsReadied] =
    React.useState(initialValues.ready);

  async function readyUser() {
    await dispatch(
      matchActions.ready(match.id, {
        handicap: userSettings.handicap,
        dailyHandicap: userSettings.dailyHandicap,
        ready: true,
      })
    );

    setAreLatestSettingsReadied(true);
  }

  return {
    userSettings,
    setUserSetting: setUserSettingsWrapper,
    areUserSettingsComplete,
    areUserSettingsValid,
    areLatestSettingsReadied,
    readyUser,
  };
}

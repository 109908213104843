import { useSelector } from "react-redux";
import * as matchActions from "store/actions/match";
import { ScorecardPresenter } from "./index";
import { BlockLoading } from "components/Block2";
import { ScorecardProvider } from "components/Context";
import withMatch from "hoc/withMatch";
import { EScorecardVariant } from "types";

/*
  Scorecard gets the match from the store via the matchId in the url
  and passes it to the ScorecardProvider as context
 */

const Scorecard = () => {
  /* NOTE WELL! We use store here but in the sister component (ScorecardPostMatch), we use SWR */
  const match = useSelector(matchActions.selectors.getMatch);
  const scores = useSelector(matchActions.selectors.getScores);
  const rounds = useSelector(matchActions.selectors.getRounds);
  const userMatchSettings = useSelector(
    matchActions.selectors.getUserMatchSettings
  );

  // conditonally render so we can destructure data
  if (!match) return <BlockLoading />;

  // reformat data
  // NB: we don't need to set types as it's been doen in the ContextProvider
  const scorecardContext = {
    isSocial: match?.isSocial,
    marker: userMatchSettings?.marker,
    markerCourse: userMatchSettings?.markerMatchSettings?.course,
    markerMatchSettings: userMatchSettings?.markerMatchSettings,
    match: match,
    matchType: match?.matchType,
    scores: scores,
    rounds: rounds,
    uiConfig: {
      showHeader: true,
      showHeaderImage: true,
      showMarkerInSettings: false,
    },
    userCourse: userMatchSettings?.course,
    userMatchSettings: userMatchSettings,
    variant: EScorecardVariant.scoring,
  };

  return (
    <ScorecardProvider value={scorecardContext}>
      <ScorecardPresenter notVerified={false} />
    </ScorecardProvider>
  );
};

// even though we're using SWR to fetch the match, we still need to use withMatch because subcomponents still rely on redux state
// This could be refactored… But later
export default withMatch(Scorecard);

/**
 * Fetch match and users match settings
 */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as matchActions from "../store/actions/match";

export default function useMatch(matchId) {
  const dispatch = useDispatch();
  const match = useSelector(matchActions.selectors.getMatch);
  const userMatchSettings = useSelector(
    matchActions.selectors.getUserMatchSettings
  );

  // Fetch the match
  React.useEffect(() => {
    dispatch(matchActions.getMatch(matchId));
  }, [dispatch, matchId]);

  return {
    match,
    userMatchSettings,
  };
}

import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import * as api from "lib/api";
import { NoticeAwaitingPlayers } from "./NoticeAwaitingPlayers";
import useRoundSettings from "hooks/useRoundSettings";
import useRestrictToHost from "hooks/useRestrictToHost";
import useOpenOnTimeout from "hooks/useOpenOnTimeout";
import * as matchActions from "store/actions/match";
// import { RESULTS_LIMIT } from "../Search";
import { InvitePlayersModal } from "../Notices";
import { useUserContext } from "components/Context";
import { LobbyHostPresenter } from "./index";

/**
 * This is the lobby page for hosts
 * Players use the LobbyInvited page
 */

const LobbyHost = () => {
  // const user = useSelector((state) => state.authentication.user);
  const user = useUserContext();
  const history = useHistory();
  const match = useSelector(matchActions.selectors.getMatch);

  useRestrictToHost(match, user.id, history);

  // Round settings: missing settings arg?
  const {
    userSettings,
    setUserSetting,
    areUserSettingsValid,
  } = useRoundSettings(match);
  const usersInLobby = useSelector((state) => state.lobby.users);
  const isHost = (u) => u.id === user.id;
  const readyToStartMatch =
    match.isSocial ||
    (areUserSettingsValid &&
      usersInLobby.every((u) => (u.ready || isHost(u)) && u.markers.length));

  // setTime + modal out for invite player modal based on handicap set
  const [handicapSuccess, setHandicapSuccess] = React.useState<boolean>(false);
  const { isOpen, onClose } = useOpenOnTimeout(handicapSuccess, 500);

  async function handleStart() {
    const response = await api.startMatch(match.id, {
      handicap: userSettings.handicap,
      dailyHandicap: userSettings.dailyHandicap,
      ...(!match.isSocial && {
        ready: true,
      }),
    });

    if (response.error) {
      // eslint-disable-next-line no-console
      console.error("Error starting match:", response.error);
      return;
    }

    // "match_start" socket event will be emitted
    // sending players (including host) to the next page
  }

  return (
    <LobbyHostPresenter
      match={match}
      userSettings={userSettings}
      setUserSetting={setUserSetting}
      handicapSuccess={handicapSuccess}
      handleSettingsSubmit={() => setHandicapSuccess(true)}
      areUserSettingsValid={areUserSettingsValid}
      readyToStartMatch={readyToStartMatch}
      handleStart={handleStart}
      isOpen={isOpen}
      onClose={onClose}
    >
      {/* Notices: assert player invite process */}
      {!match.isSocial && (
        <>
          {/* OK, now start inviting players */}
          <InvitePlayersModal isOpen={isOpen} onClose={onClose} />

          {/* Why can't I click to start? B/c yr awaiting invited players */}
          <NoticeAwaitingPlayers />
        </>
      )}
    </LobbyHostPresenter>
  );
};

export default LobbyHost;

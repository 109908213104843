import { Flex, FlexProps } from "@chakra-ui/react";
import { STYLE_ScoreShellWidth } from "components";

interface IScoreShell extends FlexProps {
  showEdge?: boolean;
}

// This component simply ensures consistent score cell widths
export const ScoreShell = ({ showEdge, children, width }: IScoreShell) => (
  <Flex
    align="center"
    borderLeft="1px solid"
    borderColor={showEdge ? "grey.100" : "transparent"}
    h="full"
    justifyContent="flex-end"
    pos="relative"
    w={width ? width : STYLE_ScoreShellWidth}
  >
    {children}
  </Flex>
);

import { VStack, Button } from "@chakra-ui/react";
import { constantToFriendly } from "config/constants";

export const ConfigRowButtons = ({ items, onSelect }) => {
  return (
    <VStack spacing={3} pt={2}>
      {items.map((item, index) => (
        <Button
          key={index}
          variant="outline"
          colorScheme="textButton"
          w="full"
          icon={item.icon}
          onClick={() => onSelect(item.value)}
        >
          {constantToFriendly(item.value)}
        </Button>
      ))}
    </VStack>
  );
};

import { Button, Link, Box, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { MdArrowForward } from "react-icons/md";

export const ScorecardCta = ({
  allowClubhouse,
  practiceMatchNotice,
}: {
  allowClubhouse?: boolean;
  practiceMatchNotice?: React.ReactNode;
}) => {
  return (
    <Box pt={8} pb={40} textAlign="center">
      {practiceMatchNotice}
      <Text as="div" textStyle="copy" fontWeight="400" pb="w4" px={12}>
        <Box fontWeight="600">
          {allowClubhouse ? "All good?" : "Sign your marker's round."}
          <Box fontWeight="400">
            {allowClubhouse
              ? "Check out your scores and competitions:"
              : "Then you can check out all your scores and competitions at the Clubhouse."}
          </Box>
        </Box>
      </Text>
      {allowClubhouse && (
        <Link
          as={ReactRouterLink}
          d="block"
          textDecoration="none !important"
          to="/clubhouse"
          textAlign="center"
        >
          <Button
            variant="outline"
            colorScheme="textButton"
            minW="66%"
            pl={3}
            pr={1}
            iconSpacing="3px"
            rightIcon={<MdArrowForward />}
            disabled={!allowClubhouse}
          >
            Clubhouse
          </Button>
        </Link>
      )}
    </Box>
  );
};

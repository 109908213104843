import React from "react";
import { VStack, StackProps } from "@chakra-ui/react";

/*
  BlockContainerScore is a container for the Scoring content
  that automatically manages spacing between the vertical sections
  on both mobile and desktop.

  Unlike BlockContainerClubhouse, the SponsorSlot is not contained here and is instead set in the Score page. This allows the VStack to set it at bottom in the available space.
*/

interface Props extends StackProps {
  children: React.ReactNode;
}

export const BlockContainerScore = ({
  as = "div",
  spacing = { base: "4", lg: 0 },
  children,
  ...props
}: Props) => {
  return (
    <VStack
      as={as}
      pos="relative"
      w="full"
      h="full"
      // flex={1}
      // flexGrow={1}
      bg="white"
      spacing={spacing}
      className="BlockContainerScore"
      sx={{
        "& > *": {
          w: "full",
        },
      }}
      {...props}
    >
      {children}
    </VStack>
  );
};

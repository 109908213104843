import { Flex, Box } from "@chakra-ui/react";
import { ScoreShell } from "./index";
import { EMatchType } from "types";

interface Props {
  // index?: number;
  // par?: string | number;
  // isMobile?: boolean;
  // showHole?: boolean;
  // showLegend?: boolean;
  // [x: string]: any;
  value: string | number;
  theme?: "default" | "mobile" | "mobileMinor";
  isActive?: boolean;
  matchType?: EMatchType;
  showEdge?: boolean;
  scoreShellWidth?: { base: string; md: string; xl: string };
}

const themeStyles = {
  default: {
    color: "text.800",
    textStyle: "hMinor",
    letterSpacing: "wide",
  },
  mobile: {
    color: "text.800",
    textStyle: "xl",
    fontSize: "20px",
    letterSpacing: "base",
  },
  mobileMinor: {
    color: "text.400",
    textStyle: "xl",
    fontSize: "20px",
    letterSpacing: "base",
  },
};

// const barH = "5px";

export const Score = ({
  // index,
  // par,
  // isMobile,
  // showHole,
  // showLegend,
  // ...props
  value,
  theme = "default",
  isActive,
  matchType,
  showEdge = true,
  scoreShellWidth,
}: Props) => {
  return (
    <ScoreShell showEdge={showEdge} width={scoreShellWidth}>
      <Flex
        flex={1}
        alignSelf="center"
        justifyContent="flex-end"
        {...themeStyles[theme]}
        fontWeight={isActive ? "600" : "400"}
        // pb={showLegend ? barH : 0}
        pl={{ base: 2, lg: 0 }}
        pr={{ base: 2, lg: 4 }}
      >
        {matchType === EMatchType.par && value && value > 0 ? (
          <Box fontSize="90%" pr="1px" mt="1px">
            +
          </Box>
        ) : null}
        {matchType === EMatchType.par && value === 0 ? "□" : value}
      </Flex>

      {/*
      ========================================================================
      THE FOLLOWING ELEMENTS were used only on Competitions. But since that's been redesigned, we don't use them at all. Will await project end before deleting…
      ========================================================================
      */}

      {/* UNUSED */}
      {/* if not a total, show par */}
      {/* nice old modulo: index !== undefined && index % 3 === 2 */}
      {/* {!isMobile && par && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          p={3}
          textStyle="crumb"
          fontSize={{ base: "9px", lg: "10px" }}
          fontWeight="400"
          color="text.800"
        >
          {par}
        </Box>
      )} */}

      {/* UNUSED */}
      {/* if not a total, show hole */}
      {/* nice old modulo:  */}
      {/* {!isMobile && showHole && index !== undefined && index % 3 === 2 && (
        <Box
          pos="absolute"
          bottom={0}
          left={0}
          p={3}
          textStyle="crumb"
          fontSize={{ base: "9px", lg: "10px" }}
          fontWeight="400"
          color="text.300"
        >
          {index + 1}
        </Box>
      )} */}

      {/*
        UNUSED
        Legend color bar
        spread props so we can add a bgColor function inline
       */}
      {/* {showLegend && (
        <Box pos="absolute" bottom={0} left={0} w="full" h={barH} {...props} />
      )} */}
    </ScoreShell>
  );
};

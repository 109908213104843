import { Box } from "@chakra-ui/react";
import { ScoreTabs, ScoreTabsFrame } from "../ScoreTabs";
import { PosterHoleScore, ParFinalScore } from "./index";
import * as scoreLib from "lib/score";
import { useScorecardContext } from "components/Context";
import { EMatchType } from "types";

/*
  ScorePoster displays the hole score as large typographic "poster".
  It is used in 2 places:
  1. ScoresSummary component (in turn used across
     ScoresSummarySection, ScoreInput & ScorecardRowModal)
  2. Scorecard page (the final round scorecard)
*/

interface IScorePoster {
  // formerly IScore but NOT IScoreBase
  //  see Scoring.type for all the mess…
  score: {
    firstNineScore: number;
    firstNineStrokes: number;
    lastNineScore: number;
    lastNineStrokes: number;
    score: number;
    strokes: number;
    nett?: number;
    par?: number;
    noScore?: boolean;
  };
  time: string;
  // The UI is slightly different during a round vs final round scorecard
  isFinalScorecard?: boolean; // for a completed round's final scorecard
  variantIsEclectic?: boolean;
  editable?: boolean;
  onEditClick?: () => void;
}

export const ScorePoster = ({
  score,
  time,
  isFinalScorecard, // for a completed round's final scorecard
  variantIsEclectic,
  editable = true,
  onEditClick,
}: IScorePoster) => {
  // Context
  const { matchType } = useScorecardContext();

  return (
    <Box
      flex="1"
      className="ScorePoster"
      bg={variantIsEclectic ? "complement.50" : undefined}
    >
      <ScoreTabs>
        {/* STABLEFORD */}
        {matchType === EMatchType.stableford && (
          <>
            {isFinalScorecard ? (
              // if isFinalScorecard, show both Stableford and Stroke scores
              // by wrapping 2 x HoleScores in Tabs
              // matchType={constants.constantToFriendly(matchType)}
              // assign noScore as "–"
              // NOTE WELL! we also styles this via variantIsEclectic for TableEclecticRound
              <ScoreTabsFrame showTabListDots>
                <PosterHoleScore
                  matchType={EMatchType.stableford}
                  variantIsEclectic={variantIsEclectic}
                  time={time}
                  bigNumber={score.noScore ? "–" : score.score}
                />
                <PosterHoleScore
                  // this? {constants.constantToFriendly(matchType)}
                  // matchType="Strokes"
                  matchType={EMatchType.stroke}
                  variantIsEclectic={variantIsEclectic}
                  time={time}
                  bigNumber={
                    score.noScore
                      ? scoreLib.showNoScoreAsDoubleBogey(score.par)
                      : score.strokes
                  }
                />
              </ScoreTabsFrame>
            ) : (
              // if !isFinalScorecard, show a single score for a single player
              // still wrapped in Tabs for layout simplicity
              // assign noScore as "–"
              // make editable
              // smallNumber={score.noScore ? "–" : score.score}
              <ScoreTabsFrame>
                <PosterHoleScore
                  matchType={EMatchType.stableford}
                  time={time}
                  bigNumber={score.noScore ? "–" : score.strokes}
                  smallNumber={scoreLib.stablefordScoreSign(score.score)}
                  editButton={editable}
                  onEditClick={onEditClick}
                />
              </ScoreTabsFrame>
            )}
          </>
        )}

        {/* PAR */}
        {matchType === EMatchType.par && (
          <>
            {isFinalScorecard ? (
              <ScoreTabsFrame showTabListDots>
                <PosterHoleScore
                  matchType={EMatchType.par}
                  time={time}
                  smallNumber={
                    score.score === 0
                      ? undefined
                      : scoreLib.parScoreSign(score.score)
                  }
                  bigNumber={scoreLib.parScoreNumber(score.score)}
                />
                <PosterHoleScore
                  matchType="Strokes"
                  time={time}
                  bigNumber={
                    score.noScore
                      ? scoreLib.showNoScoreAsDoubleBogey(score.par)
                      : score.strokes
                  }
                />
              </ScoreTabsFrame>
            ) : (
              <ScoreTabsFrame>
                <PosterHoleScore
                  matchType={EMatchType.par}
                  time={time}
                  smallNumber={
                    score.noScore ? "–" : scoreLib.parScoreSign(score.score)
                  }
                  smallNumberIsSquare={
                    !score.noScore && score.score === 0 && true
                  }
                  bigNumber={score.noScore ? "–" : score.strokes}
                  editButton={editable}
                  onEditClick={onEditClick}
                />
              </ScoreTabsFrame>
            )}
          </>
        )}

        {/* STROKE */}
        {matchType === EMatchType.stroke && (
          <>
            {isFinalScorecard ? (
              <ScoreTabsFrame showTabListDots>
                <PosterHoleScore
                  matchType="Nett"
                  time={time}
                  bigNumber={score.nett}
                />
                <PosterHoleScore
                  matchType="Gross"
                  time={time}
                  bigNumber={score.strokes}
                  editButton={!isFinalScorecard && editable}
                  onEditClick={onEditClick}
                />
              </ScoreTabsFrame>
            ) : (
              <ScoreTabsFrame>
                <PosterHoleScore
                  matchType="Gross"
                  time={time}
                  bigNumber={score.strokes}
                  editButton={!isFinalScorecard && editable}
                  onEditClick={onEditClick}
                />
              </ScoreTabsFrame>
            )}
          </>
        )}
      </ScoreTabs>
    </Box>
  );
};

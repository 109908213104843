/**
 * Collection of functions for interacting with the Dato API
 *
 * Functions in this file should only define an endpoint and and how to parse
 * the recieved data
 *
 * @module ApiDato
 */

import { ApiError } from "./apiFactory";
import config from "../config";

async function datoQuery(query) {
  const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: config.datoApiToken,
    }),
    body: JSON.stringify({
      query,
    }),
  };

  try {
    const res = await fetch(config.datoUrl, requestOptions);
    return res.json();
  } catch (error) {
    console.error(error);
    return ApiError("GENERIC", "Failed to communicate with Dato");
  }
}

/**
 * Api functions
 */

export async function getClub(clubId) {
  return datoQuery(`
    {
      course(filter:{ courseId:{ eq: "${clubId}"}}) {
        id
        courseId
      }
    }
  `);
}

/**
 * Only works when one indexed
 * If index is 0 it will incorrectly become rangeLength
 */
function shiftInRange(index, rangeLength) {
  const modulus = index % rangeLength;
  const shifted = modulus === 0 ? rangeLength : modulus;
  return shifted;
}

/**
 * JS days start on sunday, dato days start on monday
 * Therefore dato is offset by one day, dato is also one indexed where js is zero indexed
 * @example
 *   convertJSDayIndexToDatoDayIndex(new Date.getDay())
 */

// For daily results
// eslint-disable-next-line no-unused-vars
function convertJSDayIndexToDatoDayIndex(jsDayIndex) {
  return shiftInRange(jsDayIndex, 7);
}

export async function getSponsors(datoCourseId) {
  const date = new Date();
  const isoDate = date.toISOString();

  return datoQuery(`
    {
      allAds(
        filter: {
          sponsoringCourse: { anyIn: ${datoCourseId} },
          startDate: { lt: "${isoDate}" },
          endDate: { gt: "${isoDate}" }
        }
      ) {
        id
        sponsorName
        sponsoringCourse {
          id
          name
        }
        slot
        weekendSlot
        text
        image {
          id
          url
          alt
          responsiveImage(imgixParams: { w: 1250, q: 40, auto: format }) {
            src
            bgColor
          }
        }
      }
    }
  `);
}

/**
 * @returns {array}
 */
export async function getSponsorsByGolflinkClubId(clubId) {
  const res = await getClub(clubId);
  const course = res.data.course;
  // console.log("getSponsorsByGolflinkClubId course", course);

  if (!course) return [];

  const datoCourseId = res.data.course.id;
  const sponsorsRes = await getSponsors(datoCourseId);
  // console.log("getSponsorsByGolflinkClubId datoCourseId", datoCourseId);
  // console.log("getSponsorsByGolflinkClubId sponsorsRes", sponsorsRes);

  return sponsorsRes.data.allAds;
}

export async function getDefaultSponsorQuery() {
  return datoQuery(`
    {
      defaultAd {
        image {
          url
          alt
           responsiveImage(imgixParams: { w: 1250, q: 40, auto: format }) {
            src
            bgColor
          }
        }
        text
      }
    }
  `);
}

/**
 * @returns {array}
 */
export async function getDefaultSponsor() {
  const res = await getDefaultSponsorQuery();
  const defaultSponsor = res.data.defaultAd;

  return defaultSponsor;
}

import { Box, BoxProps } from "@chakra-ui/react";
import { TableHeader } from "components/ClubTable";
import { BlockLoading } from "./index";

interface ITableHeaderStatus extends BoxProps {
  tableHeading: string;
  showLoader?: boolean;
}

export const TableHeaderStatus = ({
  tableHeading,
  minH = "25vh",
  showLoader,
  children,
}: ITableHeaderStatus) => (
  <Box as="section" pos="relative">
    <TableHeader heading={tableHeading} />
    <Box pos="relative" pt="w3" minH={minH}>
      {showLoader ? <BlockLoading /> : children}
    </Box>
  </Box>
);

import { Box, HStack, Link } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { LayoutClubhouse } from "components/Layout";
import {
  TableHeader,
  TableHeaderLabel,
  TableHeaderLabels,
  leaderboardHeaderLabels,
  TableRowRoundStatic,
} from "components/ClubTable";
import { SponsorInScore } from "components/Sponsor";
import { BlockStack } from "components/Block2";
import { useGetUnverifiedRounds } from "lib/api";
import { useSponsorClubhouse } from "hooks";

export const ClubhouseYourHistoryUnsigned = () => {
  const { sponsor, defaultSponsor } = useSponsorClubhouse();
  const unverifiedRoundsRes = useGetUnverifiedRounds();
  const allUnverifiedRoundsRelatedToUser =
    unverifiedRoundsRes.data?.unverifiedRounds;
  const roundsToVerify =
    allUnverifiedRoundsRelatedToUser &&
    allUnverifiedRoundsRelatedToUser.filter((x) => x.completeByMarker);

  // TESTS
  // console.log(
  //   "unverifiedRoundsRes",
  //   unverifiedRoundsRes.data?.unverifiedRounds
  // );
  // console.log("roundsToVerify", roundsToVerify);

  return (
    <LayoutClubhouse
      sponsorSlot={<SponsorInScore sponsor={sponsor || defaultSponsor} />}
    >
      <BlockStack pb="w11">
        <Box as="section">
          <TableHeader
            heading="Your Unsigned Rounds"
            mobileStackDirection="column"
            showDivider
            rightContent={
              <HStack spacing={1.5} transform={{ lg: "translateY(0.5em)" }}>
                <TableHeaderLabels>
                  {Object.keys(leaderboardHeaderLabels).map((label, index) => (
                    <TableHeaderLabel
                      key={index.toString()}
                      label={label}
                      sortBy="default"
                    />
                  ))}
                </TableHeaderLabels>
              </HStack>
            }
          />

          {/* ROUNDS GRID with mobile mx outset */}
          {!!allUnverifiedRoundsRelatedToUser?.length && (
            <Box mx={{ base: "calc(var(--chakra-space-inset) * -1)", lg: 0 }}>
              {roundsToVerify?.map((round, index: number) => {
                return (
                  <Link
                    key={round.matchId}
                    to={`/${round.matchId}/scorecard`}
                    as={ReactRouterLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <TableRowRoundStatic
                      key={index.toString()}
                      round={round}
                      index={index}
                    />
                  </Link>
                );
              })}
            </Box>
          )}
        </Box>
      </BlockStack>
    </LayoutClubhouse>
  );
};

import * as React from "react";
// import { Flex } from "@chakra-ui/react";
import { TableRowElement, TableRowGrid } from "./index";
import { RowLeft } from "./RowElements";
// import { IEclecticRound, ECompTotal } from "types";

/* Stateless means for comps without any rounds player yet */

export function TableRowCompStateless({
  index,
  player,
}: {
  index: number;
  player: any;
}) {
  return (
    <TableRowGrid>
      {/* L: NAME & SCORES */}
      <TableRowElement variant="comp" isLeft index={index}>
        <RowLeft player={player} />
      </TableRowElement>
      {/* R: stateless */}
      <TableRowElement
        variant="comp"
        index={index}
        justifyContent="flex-end"
        showGradient={false}
      ></TableRowElement>
    </TableRowGrid>
  );
}

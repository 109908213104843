import {
  HStack,
  StackDivider,
  StackProps,
  useMediaQuery,
} from "@chakra-ui/react";
import { inputLargeH } from "theme/components/input";
import { STYLE_TableRowElementleftStyle } from "components";
import { TableRowElement as TableRowElementProps } from "types";

interface ITableRowElement extends TableRowElementProps, StackProps {
  variant: "round" | "comp";
  showShadow?: boolean;
}

export const TableRowElement = ({
  index,
  isLeft,
  isSticky,
  showGradient,
  hasDivider,
  variant,
  children,
  onClick,
  showShadow,
  ...props
}: ITableRowElement) => {
  // set TS inference via `as const`, see https://stackoverflow.com/questions/46710747/type-string-is-not-assignable-to-type-inherit-initial-unset-fixe
  const stickyStyle = {
    position: "sticky",
    right: 0,
    _before: {
      // eslint-disable-next-line quotes
      content: showGradient && `""`,
      // don't use rgb(0 0 0 / 0.5) because it doesn't work here
      bgGradient: "linear(to-r, transparent, rgba(51, 51, 51, 0.08))",
      pos: "absolute",
      top: "0",
      bottom: "0",
      right: "100%",
      left: "-18px",
      // width: "18px",
      // zIndex: "100",
    },
  } as const;

  const [breakpoint] = useMediaQuery("(min-width: 1280px)");

  // https://stackoverflow.com/a/11926475
  const isOdd = (x: number) => x & 1;

  return (
    <HStack
      className={isLeft ? "TableRowElement isLeft" : "TableRowElement"}
      align="center"
      bg={isOdd(index) ? "neutral.100" : "white"}
      borderBottom="1px solid"
      borderBottomColor="grey.100"
      borderLeft="1px solid"
      borderLeftColor={breakpoint && isSticky ? "grey.100" : "transparent"}
      cursor={variant !== "comp" ? "pointer" : undefined}
      flex={isLeft ? 1 : undefined}
      h={inputLargeH}
      // {...(breakpoint && isSticky && stickyStyle)}
      {...(!breakpoint &&
        isLeft &&
        showShadow &&
        STYLE_TableRowElementleftStyle)}
      // any Stack props eg. justifyContent or width
      {...props}
      // only use divider on the left of a TableRowRound
      spacing={hasDivider ? { base: 2, lg: 3 } : 0}
      divider={hasDivider ? <StackDivider borderColor="grey.100" /> : undefined}
      // onClick
      onClick={onClick}
    >
      {children}
    </HStack>
  );
};

// import { Text, Flex, Box, Button } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { LgImageHeader } from "../Block";
import config from "config";

// Account header
export const NavbarModalHeader = () => (
  <Box pb={20} bg="text.800" pos="relative">
    {/* BG IMAGE */}
    <LgImageHeader
      useShade
      shade="blackAlpha.500"
      src={config.imgGrassLandscape}
    />

    {/* 3 BUTTONS */}
    {/* <Flex
      direction="row"
      align="center"
      justify="space-between"
      px={6}
      w="full"
      pos="relative"
      layerStyle="navHeight"
    >
      <Box w={1 / 3}>
        <Button
          variant="ghost"
          size="sm"
          color="white"
          colorScheme="whites"
          fontWeight="700"
          ml={-3}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
      <Text as="h1" textStyle="metaButton" color="white" fontWeight="700">
        Account
      </Text>
      <Box w={1 / 3} textAlign="right">
        <Button
          variant="ghost"
          size="sm"
          color="white"
          fontWeight="700"
          mr={-3}
          onClick={() => {
            // Save
          }}
          type="submit"
          form="update-user-settings"
        >
          Save
        </Button>
      </Box>
    </Flex> */}
  </Box>
);

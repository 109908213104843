import { Link } from "react-router-dom";
import { NavbarButton } from "./index";

export const NavbarLink = ({
  path = "/",
  label,
  link,
}: {
  path: string;
  label: string;
  link: string;
}) => {
  return (
    <Link
      to={link}
      style={{
        width: "100%",
      }}
    >
      <NavbarButton label={label} path={path} activePath={link}></NavbarButton>
    </Link>
  );
};

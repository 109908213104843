import { Box } from "@chakra-ui/react";

export const SponsorTextWrapper = ({
  color,
  children,
}: {
  color: string;
  children: React.ReactNode;
}) => (
  <Box
    pos="relative"
    as="div"
    textStyle="copy"
    color={color}
    fontWeight="600"
    textAlign="center"
    px={6}
    sx={{
      "& a": {
        textDecoration: "underline",
      },
    }}
  >
    {children}
  </Box>
);

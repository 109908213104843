import { Formik, Form } from "formik";
import * as Yup from "yup";
import { chakra, Button, Box } from "@chakra-ui/react";
import DefaultVStack from "components/VStack";
import { FormSearchPlayer, FormElement } from "components/ClubCompCreate";
import * as api from "lib/api";
import { ECreateCompField } from "components/ClubCompCreate";
import { ICompetition } from "types";

const FormikForm = chakra(Form, {
  baseStyle: {
    py: "0",
  },
});

export const FormAddPlayers = ({
  comp,
  onCompetitionUpdate,
  onParentClose,
}: {
  comp: ICompetition;
  onCompetitionUpdate: () => void;
  onParentClose: () => void;
}) => {
  const handleAddPlayers = async (values) => {
    const currentPlayerIDs = comp?.players.map((x) => x.id);
    const newPlayerIDs = values?.players.map((x) => x.id);
    // console.log("handle add", values);
    // console.log("handle add player IDs", playerIDs);

    const response = await api.updateCompetition(comp.id, {
      players: [...currentPlayerIDs, ...newPlayerIDs],
    });
    // This is a handled error, we only want to log it
    if (response?.error) {
      console.log("FormAddPlayers", response.error);
      return;
    }

    onCompetitionUpdate();
    onParentClose();
    return response;
  };

  return (
    <Formik
      initialValues={{ players: [] }}
      validationSchema={Yup.object({
        players: Yup.array().of(Yup.object()),
      })}
      onSubmit={handleAddPlayers}
    >
      {(formik) => {
        // const playerIDs = formik.values?.players?.map((x) => x.id);
        // console.log("FormAddPlayer IDs", playerIDs);
        // console.log("FormAddPlayers", formik.values);

        return (
          <FormikForm>
            <DefaultVStack spacing={3}>
              {/* TODO: don't search for players already existing in this competition… */}
              <FormSearchPlayer
                name={ECreateCompField.players}
                label="Add players"
                placeholder="Enter a player's name…"
              />

              {formik.values.players.length > 0 && (
                <FormElement>
                  <Box pt="3px" w="full">
                    <Button
                      type="submit"
                      isDisabled={!formik.isValid}
                      isLoading={formik.isSubmitting}
                      loadingText="Submitting"
                      variant="accent"
                      minW={{ base: "full", lg: "full" }}
                    >
                      Add to competition
                    </Button>

                    {/* {apiError && (
                    <Box pt="w2">
                    <FormMessageErrorApi messageMap={apiMessageErrorMap} />
                    </Box>
                  )} */}
                  </Box>
                </FormElement>
              )}
            </DefaultVStack>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

import * as React from "react";
import { ScorePoster } from "components/Score";
import {
  ScorecardRowModal,
  ScorecardRowModalHistorical,
  ScorecardRowHeader,
} from "components/ScorecardRow";
import * as dateLib from "lib/date";
import * as scoreLib from "lib/score";
import useFormatScoreTotals from "hooks/useFormatScoreTotals";
import { useScorecardContext } from "components/Context";
import { EMatchType, EScorecardVariant, ICourse } from "types";

// extends IScorecardPanel
interface IScorecardPresenterPanel {
  isMarker: boolean;
  course: ICourse;
  // `rounds` (extended from IScorecardPanel) holds full round data for both user and marker. `scoreTotals` holds the currently finalised score totals as they are progressively edited by verification.
  // `scoreTotals` is accumulated via the `useFormatScoreTotals` hook from within ScorecardPresenter, holds the the total score for the user on initial render and after editing a score.
  // —— `rounds` is static, completed data
  // —— `scoreTotals` is progressively updated data
  // scoreTotals: any;
  verifyRoundSlot?: React.ReactNode;
  // TODO: ScorecardRowHeader potentially uses it, else delete it
  roundIsVerified?: boolean;
  mismatchingScoreHoleNumbers?: number[];
}

export const ScorecardPresenterPanel = ({
  isMarker,
  course,
  mismatchingScoreHoleNumbers,
  verifyRoundSlot,
}: IScorecardPresenterPanel) => {
  // Context
  const { variant, match, matchType, scores, rounds } = useScorecardContext();

  // scoreTotals holds the currently finalised score totals as they are progressively edited upon verification. Runs on initial render and after editing a score.
  const scoreTotals = useFormatScoreTotals();
  // console.log("ScorecardPresenterPanel scoreTotals", scoreTotals);

  // Settings & methods determined by player type isMarker
  // const stringKind = isMarker ? "marker" : "user";
  const totalsKind = isMarker ? scoreTotals?.marker : scoreTotals?.user;

  // Times
  const timeStarted: string = dateLib.toTimeString(scores[1].createdAt);
  const timeCompleted: string = dateLib.toTimeString(scores[18].createdAt);
  const timeFirstNineCompleted: string = dateLib.toTimeString(
    scores[9].createdAt
  );
  const timeLastNineStarted: string = dateLib.toTimeString(
    scores[10].createdAt
  );

  const renderMismatchingScore = (hole: number) =>
    isMarker ? mismatchingScoreHoleNumbers?.includes(hole) : undefined;

  return (
    <>
      {/* POSTER with TABS: player's score as par & stableford */}
      <ScorePoster
        score={totalsKind}
        time={timeCompleted}
        isFinalScorecard
        variantIsEclectic={variant === "eclectic" ? true : false}
      />

      {/* VERIFY ROUND with MARKER'S SIGNATURE */}
      {isMarker && verifyRoundSlot}

      {/* OUT */}
      <ScorecardRowHeader
        kind="header"
        theme="sub"
        isOut
        matchType={matchType}
        scoreLabel={match.scoreLabel.short}
        startTime={timeStarted}
      />
      {course.holes.slice(0, 9).map((hole) => (
        <React.Fragment key={hole.id}>
          {/*
            If variant is scoring, use ScorecardRowModal (Scoring flow).
            Else use ScorecardRowModalHistorical (Clubhouse "leaderboard" flow).
           */}
          {variant === EScorecardVariant.scoring ? (
            <ScorecardRowModal
              key={`out-${hole.number}`}
              hole={hole}
              isMarker={isMarker}
              mismatchingScore={renderMismatchingScore(hole.number)}
              // We want to use signedByUserId to allow editing. Until a round has been signed/verified it should be editable.
              // `signedByUserId` starts null and is set to the users ID when the round is verified by that user's marker. So if the user has verified the round of their marker, but their marker hasn't verified the user's round, it will be null.
              playerRoundSigned={!!rounds.user?.signedByUserId}
              markerRoundSigned={!!rounds.marker?.signedByUserId}
              // playerRoundSigned={!!roundsKind.complete}
              // markerRoundSigned={!!roundsKind.completeByMarker}
            />
          ) : (
            <ScorecardRowModalHistorical
              key={`out-historical-${hole.number}`}
              hole={hole}
              isMarker={isMarker}
              mismatchingScore={renderMismatchingScore(hole.number)}
            />
          )}
        </React.Fragment>
      ))}
      <ScorecardRowHeader
        kind="footer"
        theme="meta"
        isOut
        matchType={matchType}
        totalStrokes={totalsKind.firstNineStrokes}
        totalScore={scoreLib.scoreRowTotalSign(
          totalsKind.firstNineScore,
          matchType
        )}
        endTime={timeFirstNineCompleted}
      />

      {/* IN */}
      <ScorecardRowHeader
        kind="header"
        theme="sub"
        matchType={matchType}
        scoreLabel={match.scoreLabel.short}
        startTime={timeLastNineStarted}
      />
      {course.holes.slice(9, 18).map((hole) => (
        <React.Fragment key={hole.id}>
          {variant === "scoring" ? (
            <ScorecardRowModal
              key={`out-${hole.number}`}
              hole={hole}
              isMarker={isMarker}
              mismatchingScore={renderMismatchingScore(hole.number)}
              playerRoundSigned={!!rounds.user?.signedByUserId}
              markerRoundSigned={!!rounds.marker?.signedByUserId}
            />
          ) : (
            <ScorecardRowModalHistorical
              key={`in-historical-${hole.number}`}
              hole={hole}
              isMarker={isMarker}
              mismatchingScore={renderMismatchingScore(hole.number)}
            />
          )}
        </React.Fragment>
      ))}

      {/* TOTALS */}
      {/* OUT: repeat the `out` score */}
      <ScorecardRowHeader
        kind="footer"
        theme="meta"
        matchType={matchType}
        totalStrokes={totalsKind.lastNineStrokes}
        totalScore={scoreLib.scoreRowTotalSign(
          totalsKind.lastNineScore,
          matchType
        )}
        endTime={timeCompleted}
      />
      {/* IN */}
      <ScorecardRowHeader
        kind="footer"
        theme="meta"
        isOut
        matchType={matchType}
        totalStrokes={totalsKind.firstNineStrokes}
        totalScore={scoreLib.scoreRowTotalSign(
          totalsKind.firstNineScore,
          matchType
        )}
        endTime={timeFirstNineCompleted}
      />
      {/* If matchType stroke, show Total Gross */}
      {matchType === EMatchType.stroke && (
        <ScorecardRowHeader
          kind="header"
          theme="secondary"
          matchType={EMatchType.stroke}
          isTotal
          headerLabel="Your Gross Score"
          totalStrokes={totalsKind.strokes}
          totalScore={scoreLib.scoreRowTotalSign(
            totalsKind.score,
            EMatchType.stroke
          )}
        />
      )}
      {/* Total Score (or Total Nett if matchType stroke */}
      {/* …potentially uses roundIsVerified */}
      <ScorecardRowHeader
        kind="header"
        theme="primary"
        matchType={matchType}
        isTotal
        headerLabel={
          matchType === EMatchType.stroke ? "Your Nett Score" : "Your score"
        }
        // Status at 21-10-25
        // If matchType is stroke, we only adjust for handicap (nett) in the round totals. That is, a nett stroke total isn't calculated until the round is completed. So we can't show a total Nett stroke score until the round is verified. Until nett score is available, we show a message within ScorecardRowHeader using roundIsVerified.
        // Status at 21-10-26
        // Seeing instant feedback helps the user, and it's ok that it's not the verified final strokes score. Just show the a stroke total via totalsKind, same as the ScorePoster does.
        // See LG-137 for details.
        // roundIsVerified={roundIsVerified}
        totalStrokes={
          matchType === EMatchType.stroke ? totalsKind.nett : totalsKind.strokes
        }
        // totalStrokes={
        //   matchType === EMatchType.stroke
        //     ? roundsKind.totalScoreStrokeNett
        //     : totalsKind.strokes
        // }
        // totalStrokes={scoreLib.renderTotalStrokes(
        //   matchType,
        //   roundsKind.totalScoreStrokeNett,
        //   totalsKind.strokes
        // )}
        totalScore={scoreLib.scoreRowTotalSign(totalsKind.score, matchType)}
      />
    </>
  );
};

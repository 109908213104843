import React from "react";
import { Flex, HStack, Stack, Text, Divider } from "@chakra-ui/react";

interface ITableHeader {
  heading?: string;
  innerLeftStackProps?: any;
  innerRightStackProps?: any;
  isComp?: boolean;
  leftSearch?: React.ReactNode;
  leftContent?: React.ReactNode;
  mobileStackDirection?: "row" | "column";
  rightContent?: React.ReactNode;
  showDivider?: boolean;
  useOverflowScrollOnRight?: boolean;
  [x: string]: any;
}

export const defaultHeight = {
  base: "calc(var(--chakra-sizes-9) + 9px)",
  lg: "calc(var(--chakra-sizes-9) + 0px)",
};

export const competitionHeight = {
  base: "calc(var(--chakra-sizes-9) + 9px)",
  lg: "calc(var(--chakra-sizes-9) + 16px)",
};

export const TableHeader = ({
  heading,
  innerLeftStackProps,
  innerRightStackProps,
  isComp,
  leftSearch,
  leftContent,
  mobileStackDirection = "row",
  rightContent,
  showDivider = true,
  useOverflowScrollOnRight = true,
  ...props
}: ITableHeader) => {
  return (
    <>
      <Stack
        align={{ base: "flex-start", lg: "flex-end" }}
        className="TableHeader"
        direction={{ base: mobileStackDirection, lg: "row" }}
        items="center"
        justify="space-between"
        overflowX={{ base: "auto", lg: "initial" }}
        pb={{ base: "0px", lg: "16px" }}
        position="sticky"
        spacing={0}
        {...props}
      >
        {/* LEFT */}
        <HStack
          className="TableHeader-LeftHStack"
          alignItems={{ base: "center", lg: "flex-end" }}
          borderBottom="1px solid"
          borderBottomColor={
            mobileStackDirection === "column"
              ? { base: "gray.500", lg: "transparent" }
              : "transparent"
          }
          height={isComp ? competitionHeight : defaultHeight}
          justifyContent={{ base: "space-between", lg: "flex-start" }}
          // mr={3}
          spacing="4"
          width="100%"
          {...innerLeftStackProps}
        >
          {/* HEADING (DESKTOP) */}
          {heading && (
            <Text as="h2" textStyle="hFour">
              {heading}
            </Text>
          )}

          {/* SEARCH (DESKTOP) */}
          {leftSearch && (
            <Flex
              className="leftSearch"
              justifyContent={{ base: "flex-end", lg: "flex-start" }}
              pos="relative"
              // allow space @ base for the button focus boxShadow
              pr={{ base: "1px", lg: 4 }}
            >
              {leftSearch}
            </Flex>
          )}

          {/* for Competition */}
          {leftContent}
        </HStack>

        {/* RIGHT */}
        {rightContent && (
          <Flex
            className="TableHeader-RightHStack"
            alignItems="center"
            height={isComp ? competitionHeight : defaultHeight}
            // flex={breakpoint ? 1 : "initial"}
            justifyContent="flex-end"
            overflowX={useOverflowScrollOnRight ? "scroll" : "initial"}
            pr="1px"
            width="100%"
            {...innerRightStackProps}
          >
            {rightContent}
          </Flex>
        )}
      </Stack>

      {/* DIVIDER */}
      {showDivider && <Divider opacity="100%" borderColor="text.800" />}
    </>
  );
};

import { Select } from "@chakra-ui/react";
import { FormElement, FormLabel } from "./index";
import { useField } from "formik";
import { ECompKind } from "types";

export const FormSelect = ({
  label,
  ...props
}: {
  label: string;
  [x: string]: any;
}) => {
  const [field] = useField(props);

  return (
    <FormElement className="CompType" label={<FormLabel>{label}</FormLabel>}>
      <Select
        border="1px solid"
        borderColor="grey.100"
        _placeholder={{
          color: "text.300",
        }}
        _hover={{
          borderColor: "text.300",
        }}
        id={field.name}
        // onChange={field.onChange}
        // give Formik everything
        {...field}
        {...props}
      >
        <option value={ECompKind.eclectic}>Eclectic Ringers</option>
        <option disabled value={ECompKind.race}>
          Race To The Top
        </option>
        <option disabled value={ECompKind.race}>
          Travelling
        </option>
      </Select>
    </FormElement>
  );
};

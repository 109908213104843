import { Tab, forwardRef } from "@chakra-ui/react";

export const TabDot = forwardRef(({ ...props }, ref) => {
  return (
    <Tab
      ref={ref}
      d="block"
      flex="initial"
      fontSize="rg"
      height="14px"
      width="14px"
      p={0}
      mx="0.85rem"
      rounded="full"
      borderWidth="2px"
      borderColor="text.800"
      _focus={{
        outline: "none",
        // boxShadow: "none",
        boxShadow: "#d7ecef 0 0 0 4px",
        zIndex: "1",
      }}
      _selected={{
        bg: "text.800",
        borderColor: "initial",
        boxShadow: "#d7ecef 0 0 0 4px",
      }}
      {...props}
    />
  );
});

import { useState } from "react";
import ScorePicker from "../ScorePicker";
import * as constants from "config/constants";

export const ConfigRowPickerScore = ({
  matchType,
  btnText,
  isInModal,
  valueGroups,
  onSelect,
}: {
  matchType: string;
  btnText?: string;
  isInModal?: boolean;
  valueGroups: {
    unit?: string | number;
    decimal?: string | number;
  };
  onSelect: (value: string) => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const units = constants.possibleStrokes[matchType]();
  const defaultValueGroup = {
    unit: "6",
  };

  function parseScore(strokes) {
    const num = parseInt(strokes, 10);
    return Number.isNaN(num) ? strokes : num;
  }

  function handleSelect(value) {
    setIsSubmitting(true);
    onSelect(parseScore(value));
  }

  return (
    <ScorePicker
      optionGroups={{
        unit: units,
      }}
      valueGroups={valueGroups || defaultValueGroup}
      onSelect={handleSelect}
      isSubmitting={isSubmitting}
      isInModal={isInModal}
      btnText={btnText}
    />
  );
};

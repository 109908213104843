import React from "react";
import { useSelector } from "react-redux";
import * as matchActions from "../store/actions/match";
import * as nameLib from "../lib/name";
import * as courseLib from "../lib/course";

/**
 * Format player and marker scores & strokes to the structure a scoresheet
 * expects
 *
 * Change in format:
 * * Redux stores scores and strokes as an object keyed by hole number
 *   e.g. { user: { score: 3, strokes: 5 }, marker: { score: 4, strokes: 4 } }
 * * Scoresheet expects scores, strokes and hole numbers each to be a separate array
 */

function splitAt(array, pos) {
  return [array.slice(0, pos), array.slice(pos)];
}

const oneToEighteen = Array(18)
  .fill(null)
  .map((_, index) => index + 1);

/**
 * @param {object[]} scores - two objects, first and last nine hole scores
 * @param {HoleScoreAbbr} scores[x].score
 * @returns {boolean} if any scores were a "no score"
 **/
function anyWasNoScore(scores) {
  return scores.some((scores) => scores.some((score) => score?.noScore));
}

export default function useFormatScoresForScoresheet() {
  const scores = useSelector(matchActions.selectors.getScores);
  const user = useSelector((state) => state.authentication.user);
  const marker = useSelector(matchActions.selectors.getMarker);
  const userMatchSettings = useSelector(
    matchActions.selectors.getUserMatchSettings
  );
  const isSocial = useSelector(matchActions.selectors.getIsSocial);
  const match = useSelector(matchActions.selectors.getMatch);
  const holes = useSelector(matchActions.selectors.getCourse).holes;
  const holeNumbers = splitAt(oneToEighteen, 9);
  const userGender = user.gender;
  const markerGender = marker && marker.gender;

  const strokesAndScores = React.useMemo(() => {
    let userStrokes = [];
    let userScores = [];
    let userPars = [];
    let markerStrokes = [];
    let markerScores = [];
    let markerPars = [];

    oneToEighteen.forEach((x) => {
      const score = scores[x];
      userStrokes.push(score && score.user.strokes);
      userScores.push(
        score && {
          score: score.user.score,
          noScore: score.user.noScore,
        }
      );
      userPars.push(courseLib.getPar(holes[x - 1], match.tee, userGender));
      if (!isSocial) {
        markerStrokes.push(score && score.marker.strokes);
        markerScores.push(
          score && {
            score: score.marker.score,
            noScore: score.marker.noScore,
          }
        );
        markerPars.push(
          courseLib.getPar(holes[x - 1], match.tee, markerGender)
        );
      }
    });

    return {
      userStrokes: splitAt(userStrokes, 9),
      userScores: splitAt(userScores, 9),
      userPars: splitAt(userPars, 9),
      markerStrokes: splitAt(markerStrokes, 9),
      markerScores: splitAt(markerScores, 9),
      markerPars: splitAt(markerPars, 9),
    };
  }, [scores, isSocial, match.tee, holes, userGender, markerGender]);

  const scoresheets = [
    {
      title: "Your score",
      handicap: userMatchSettings.dailyHandicap,
      strokes: strokesAndScores.userStrokes,
      scores: strokesAndScores.userScores,
      pars: strokesAndScores.userPars,
      holes: holeNumbers,
      hasShotANoScore: anyWasNoScore(strokesAndScores.userScores),
    },
  ];

  if (!isSocial) {
    scoresheets.splice(0, 0, {
      title: `${nameLib.getFirstName(marker.fullName)}'s score`,
      handicap: userMatchSettings.markerMatchSettings.dailyHandicap,
      strokes: strokesAndScores.markerStrokes,
      scores: strokesAndScores.markerScores,
      pars: strokesAndScores.markerPars,
      holes: holeNumbers,
      hasShotANoScore: anyWasNoScore(strokesAndScores.markerScores),
    });
  }
  return scoresheets;
}

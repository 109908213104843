import { useHistory } from "react-router";
import { Box } from "@chakra-ui/react";
import { Formik } from "formik";
import { LayoutClubhouse } from "components/Layout";
import { TableHeader } from "components/ClubTable";
import { BlockStack } from "components/Block2";
import { SponsorInScore } from "components/Sponsor";
import {
  ICreateCompValues,
  initialValues,
  valuesList,
  validationSchema,
  FormCompLayout,
} from "components/ClubCompCreate";
import * as api from "lib/api";
import { useSponsorClubhouse } from "hooks";

export const CreateCompetition = () => {
  const history = useHistory();
  const { sponsor, defaultSponsor } = useSponsorClubhouse();

  const onSubmit = async (values: ICreateCompValues, { setStatus }) => {
    const response = await api.createCompetition(valuesList(values));

    if (response.error) {
      setStatus({ apiError: response.error });
      return;
    }

    history.push(`/clubhouse/competitions/${response.json.competition.id}`);
  };

  return (
    <LayoutClubhouse
      showSponsorOnMobile={false}
      sponsorSlot={<SponsorInScore sponsor={sponsor || defaultSponsor} />}
    >
      <BlockStack pb="w11">
        <Box as="section">
          <TableHeader
            showHStackOnMobile={false}
            showDivider
            heading="Create a competition"
          />
          <Box bg={{ lg: "neutral.200" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => <FormCompLayout />}
            </Formik>
          </Box>
        </Box>
      </BlockStack>
    </LayoutClubhouse>
  );
};

import { Flex, HStack, StackDivider, StackProps, Box } from "@chakra-ui/react";
import { toDateString } from "lib/date";
import { ICompCard } from "types";

export const PreHeaderMeta = ({
  comp,
  color = "text.500",
  showBorder = true,
  showPlayingDays = false,
  children,
}: {
  comp: ICompCard;
  color?: string;
  showBorder?: boolean;
  showPlayingDays?: boolean;
} & StackProps) => {
  return (
    <Flex
      borderColor={showBorder ? "primary.50" : "transparent"}
      color={color}
      width="100%"
    >
      <HStack
        spacing={2}
        divider={<StackDivider borderColor="currentColor" opacity={0.4} />}
      >
        {/* use children on the Competition */}
        {children}

        {/* default content */}
        <Box textStyle="body">
          {comp.weeksPlayed >= comp.duration ? (
            <>{comp.duration} weeks completed</>
          ) : (
            <>
              {comp.weeksPlayed} of {comp.duration} weeks played
            </>
          )}
        </Box>
        <Box textStyle="body">Started {toDateString(comp.startDate)}</Box>
        {showPlayingDays && (
          <Box textStyle="body">
            Playing{" "}
            {comp.days.map((day, i) => (
              <Box as="span">
                {day}
                {i < comp.days.length - 1 ? ", " : ""}
              </Box>
            ))}
          </Box>
        )}
      </HStack>
    </Flex>
  );
};

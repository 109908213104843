/*
 * Save eclectic round, include the holes par at time of scoring for easy calculation of boogey/birdie
 */
import {
  createServer,
  RestSerializer,
  Model,
  belongsTo,
  hasMany,
  Factory,
  Response,
} from "miragejs";
import faker from "faker";
import { GENDERS, COMPETITION_TYPES } from "@/config/constants";
import holes from "@/data/holes";
import config from "@/config";

const API_URL = "/api/mothership";
const genX = (x, func) =>
  Array(x)
    .fill(null)
    .map(() => func());

function seedCompetitionRounds(server, users, course) {
  // Simulate a "matches" id
  const round = server.create("round", {
    course,
  });
  users.forEach((user) => {
    const otherPlayers = users.filter((u) => u.id !== user.id);
    const userRound = server.create("user_round", {
      user,
      marker: faker.random.arrayElement(otherPlayers),
      round,
    });
    for (let i = 0; i < 18; i++) {
      server.create("score", {
        holeNumber: i + 1,
        user,
        userRound,
      });
    }
  });
}

function getCompetitionData(schema, serialize, comp) {
  const course = serialize(schema.courses.find(comp.course)).course;
  let players = schema.users.find(comp.players).models.map((player) => {
    let scores;
    if (comp.type === COMPETITION_TYPES.ECLECTIC) {
      // Acting as score of an eclectic round
      scores = serialize(schema.scores.where({ userId: player.id })).scores;
    } else {
      // RACE_TO_THE_TOP
      scores = Array(comp.duration)
        .fill(null)
        .map(() => faker.datatype.number(1, comp.players.length));
    }

    const userRound = schema.userRounds.findBy({ userId: player.id });
    const serializedPlayer = serialize(player).user;
    delete serializedPlayer.scores;

    return {
      ...serializedPlayer,
      holeScores: scores,
      scoreTotals: userRound.attrs.scoreTotals,
    };
  });

  return {
    ...comp,
    players,
    course,
  };
}

function getRoundData(schema, serialize, player) {
  const userRound = schema.userRounds.findBy({ userId: player.id });
  const marker = schema.users.findBy({ userId: userRound.attrs.marker });
  return {
    player: serialize(player).user,
    userRound: serialize(userRound).userRound,
    marker: serialize(marker).user,
  };
}

/**
 * Route Controllers
 */

function getCompetitionsPreviews(schema, request) {
  const limit = 3;
  const competitions = schema.competitions.all().models.slice(0, limit);
  const data = competitions.map((comp) => {
    const competition = this.serialize(comp).competition;
    const course = this.serialize(schema.courses.find(competition.course))
      .course;
    const players = schema.users
      .find(competition.players)
      .models.map((playerModel) => {
        const player = this.serialize(playerModel).user;
        const homeCourse = this.serialize(
          schema.courses.find(parseInt(player.homeCourse, 10))
        ).course;
        return {
          ...player,
          homeCourse: {
            clubName: homeCourse.clubName,
            courseName: homeCourse.courseName,
          },
        };
      });
    return {
      ...competition,
      course,
      players,
    };
  });
  return { data };
}

// function getCompetitions(schema, request) {
//   const type = request.params.type;
//   let filters = {};
//   if (type) {
//     filters.type = type;
//   }

//   const competitions = this.serialize(schema.competitions.where(filters))
//     .competitions;
//   const data = competitions.map((competition) =>
//     getCompetitionData(schema, (item) => this.serialize(item), competition)
//   );
//   return { data };
// }

function getCompetition(schema, request) {
  const competition = this.serialize(
    schema.competitions.find(request.params.id)
  ).competition;
  return {
    data: {
      competition: getCompetitionData(
        schema,
        (item) => this.serialize(item),
        competition
      ),
    },
  };
}

// function getDailyResults(schema) {
//   const users = schema.users.all().models.slice(0, 10);
//   let rounds = users.map((player) =>
//     getRoundData(schema, (item) => this.serialize(item), player)
//   );

//   return {
//     data: {
//       rounds,
//     },
//   };
// }

function getHistory(schema) {
  const user = schema.users.first();
  const round = getRoundData(schema, (item) => this.serialize(item), user);
  return {
    data: {
      rounds: [round, round, round],
    },
  };
}

function getUserRound(schema, request) {
  const id = request.params.id;
  const userRound = schema.userRounds.find(id);
  const scores = schema.scores.where({ userRoundId: userRound.id });
  const round = schema.rounds.find(parseInt(userRound.attrs.roundId, 10));
  const course = schema.courses.find(parseInt(round.attrs.courseId, 10));

  return {
    data: {
      userRound: this.serialize(userRound).userRound,
      scores: this.serialize(scores).scores,
      course,
    },
  };
}

createServer({
  models: {
    user: Model.extend({
      scores: hasMany("score"),
      homeCourse: belongsTo("course"),
    }),
    course: Model,
    match: Model,
    competition: Model.extend({
      players: hasMany("user"),
      course: belongsTo(),
    }),
    score: Model.extend({
      user: belongsTo("user"),
      marker: belongsTo("user", { inverse: false }),
      userRound: belongsTo("user_round"),
    }),
    round: Model.extend({
      course: belongsTo("course"),
    }),
    user_round: Model.extend({
      user: belongsTo("user"),
      marker: belongsTo("user", { inverse: false }),
      // scores: hasMany("score"),
      round: belongsTo("round"),
    }),
  },

  serializers: {
    application: RestSerializer.extend({
      alwaysIncludeLinkageData: true,
    }),
  },

  factories: {
    user: Factory.extend({
      email: () => faker.internet.email(),
      fullName: () => faker.name.findName(),
      golflinkNumber: () => faker.datatype.number(10000).toString(),
      handicap: () => faker.datatype.number(10),
      gender: () => faker.random.objectElement(GENDERS),
      avatar: () => faker.image.avatar(),
    }),
    course: Factory.extend({
      clubName: () => faker.lorem.words(),
      courseName: () => faker.lorem.words(),
      name: () => faker.lorem.words(),
      image: () => faker.image.imageUrl(),
      golflinkCourseId: () => faker.datatype.number(100).toString(),
      handicapModifier: () => faker.random.arrayElement([0, 19, 37]),
      state: () => faker.address.state(),
      holes,
    }),
    // Expects to be created with a list of players
    competition: Factory.extend({
      title: () => faker.lorem.words(),
      description: () => faker.lorem.sentence(),
      host: () => faker.datatype.uuid(),
      startDate: () => faker.date.recent(11),
      // In weeks
      duration: 12,
      days: () => genX(3, faker.date.weekday),
      type: () => faker.random.objectElement(COMPETITION_TYPES),
      createdAt: () => new Date(),
    }),
    userRound: Factory.extend({
      scoreTotals: () => ({
        stableford: faker.datatype.number({ min: 25, max: 80 }),
        par: faker.datatype.number({ min: -8, max: 15 }),
        nett: faker.datatype.number({ min: 30, max: 144 }),
        gross: faker.datatype.number({ min: 30, max: 144 }),
      }),
      // Matches HCP in design
      handicap: () => faker.datatype.number({ min: -3, max: 18 }),
      // Matches "x Playing" in design
      dailyHandicap: () => faker.datatype.number({ min: -3, max: 18 }),
    }),
    score: Factory.extend({
      strokes: () => faker.datatype.number(12),
      score: () => faker.datatype.number({ min: 2, max: 5 }),
      noScore: () => faker.datatype.number({ min: 1, max: 10 }) <= 2,
      // Hole par adjusted for user match settings
      par: () => faker.datatype.number({ min: 2, max: 5 }),
      // Hole par adjusted for user match settings
      distance: () => `${faker.datatype.number({ min: 200, max: 600 })}m`,
      createdAt: () => new Date(),
    }),
  },

  seeds(server) {
    const course = server.create("course");
    const course2 = server.create("course");

    const users = server.createList("user", 10, {
      homeCourse: course,
    });

    server.create("competition", {
      type: COMPETITION_TYPES.ECLECTIC,
      players: users,
      course,
    });
    seedCompetitionRounds(server, users, course);

    server.create("competition", {
      type: COMPETITION_TYPES.RACE_TO_THE_TOP,
      players: users,
      course,
    });
    seedCompetitionRounds(server, users, course2);
  },

  routes() {
    /**
     * @typedef {Object} HoleScoreAbbr
     * @property {Number} score - final score
     * @property {Boolean} noScore - whether the player stroked out
     */

    /**
     * @typedef {Object} HoleScoreFull
     * @property {Number} holeNumber - 1 indexed
     * @property {Number} strokes - 1 indexed
     * @property {Number} score - final score
     * @property {Boolean} noScore - whether the player stroked out
     */

    /**
     * @typedef {Object} ScoreTotals
     * @property {Number} players.scoreTotals.stableford
     * @property {Number} players.scoreTotals.par
     * @property {Number} players.scoreTotals.nett
     * @property {Number} players.scoreTotals.gross
     */

    /**
     * @typedef {Object} CompetitionFull
     * @property {*} * - all standard competition properties
     * @property {Object} type - one of "RACE_TO_THE_TOP" or "ECLECTIC" different types have different score data
     * @property {Object} course
     * @property {Object[]} players
     * @property {HoleScoreFull[]} players.holeScores
     * @property {ScoreTotals} players.scoreTotals
     */

    /**
     * @typedef {Object} RoundPreview
     * @property {Player} round.player - all standard user properties
     * @property {Player} round.marker
     * @property {Object} round.userRound
     * @property {String} round.userRound.id - users round id for calling /daily-results/:id endpoint
     * @property {ScoreTotals} rounds.scoreTotals
     */

    /*
     * Create a competition
     *
     * @apiParam {String} title
     * @apiParam {Number} duration - duration in weeks
     */
    // this.post(`${API_URL}/competitions`, () => {
    //   return new Response(200);
    // });

    /*
     * Players active competitions
     * Includes basic overview info
     *
     * @apiSuccess {Object[]} competitions
     * @apiSuccess {*} competitions.* - all standard competition properties
     * @apiSuccess {Object} competitions.course
     * @apiSuccess {Object[]} competitions.players
     */
    this.get(`${API_URL}/competitions/preview`, getCompetitionsPreviews);

    /*
     * Get a players competitions
     * Includes full info
     *
     * @apiSuccess {CompetitionFull[]} competitions
     */
    // this.get(`${API_URL}/competitions`, getCompetitions);

    /*
     * Get a single competition
     * Includes full info
     *
     * @apiSuccess {CompetitionFull} competition
     */
    this.get(`${API_URL}/competitions/:id`, getCompetition);

    /*
     * Update a competition
     *
     * @apiParam {Number} duration - duration in weeks, must be greater than the competitions age
     */
    this.put(`${API_URL}/competitions/:id`, () => {
      return new Response(200);
    });

    /*
     * Delete or leave a competition
     *
     * If user is host/admin the competition is deleted
     * For all other users they leave the group
     *
     * @apiParam {String} id
     */
    this.delete(`${API_URL}/competitions/:id`, () => {
      return new Response(200);
    });

    /**
     * Rounds played today by any users
     * Supports pagination
     *
     * NOTE: Sorting and pagination is not implemented here but will be in the real api
     *
     * @apiParam {String} course - course id to filter by
     * @apiParam {String} sort_by - one of "stableford", "gross" or "par", defaults "stableford"
     * @apiParam {String} order - one of "ASC" or "DESC", defaults "DESC"
     * @apiParam {Number} [page] - pagination
     *
     * @apiSuccess {RoundPreview[]} rounds
     */
    // this.get(`${API_URL}/daily-results`, getDailyResults);

    /**
     * All rounds played by the authenticated user
     * Supports pagination
     *
     * NOTE: Sorting and pagination is not implemented here but will be in the real api
     *
     * @apiParam {String} sort_by - one of "stableford", "gross" or "par", defaults "stableford"
     * @apiParam {String} order - one of "ASC" or "DESC", defaults "DESC"
     * @apiParam {Number} [page] - pagination
     *
     * @apiSuccess {RoundPreview[]} rounds
     */
    this.get(`${API_URL}/history`, getHistory);

    /**
     * Get the full info of a users round
     * Use in UI: Expanded accordian Daily Results table
     *
     * @apiParam {String} id - users round id
     * @apiSuccess {Object} rounds.userRound
     * @apiSuccess {HoleScoreFull[]} rounds.scores - score for each hole, ordered by hole number
     * @apiSuccess {String} id - users round id
     **/
    this.get(`${API_URL}/user-round/:id`, getUserRound);

    this.passthrough();
    this.passthrough(`${config.serverUrl}/**`);
    this.passthrough(config.datoUrl);
  },
});

import { BoxProps } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

/*
  BlockMain wraps all content in DefaultLayout.
  BlockMain is a motion.div that animates in and out.
  We also use this box as our scrolling content container, hence the styles
  NB! All the styles are important!
  More: https://www.framer.com/docs/animate-presence/#props
 */

export const BlockMotion = ({ children }: BoxProps) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
        style={{
          backgroundColor: "white",
          flex: 1, // ensure desktop spans full available width
          overflow: "auto",
          position: "relative",
          height: "100%",
        }}
        className="BlockMotion"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

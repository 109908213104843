export function toTimeString(date) {
  return new Date(date).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function toDateTimeString(date) {
  const d = new Date(date);
  const timeString = d.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const dateString = d
    .toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    })
    .replace(",", "");
  return `${dateString}, ${timeString}`;
}

export function toDateString(date) {
  const d = new Date(date);
  const dateString = d
    .toLocaleDateString("en-US", {
      weekday: "short",
      month: "long",
      day: "numeric",
    })
    .replace(",", "");
  return `${dateString}`;
}

export function toShortDateString(date) {
  const d = new Date(date);
  const dateString = d
    .toLocaleDateString("en-US", {
      weekday: "short",
      month: "long",
      day: "numeric",
      year: "numeric",
    })
    .replace(",", "");
  return `${dateString}`;
}

export function toShortestDateString(date) {
  const d = new Date(date);
  const dateString = d
    .toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })
    .replace(",", "");
  return `${dateString}`;
}

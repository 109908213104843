// @refresh reset
// https://next.chakra-ui.com/docs/features/text-and-layer-styles

// Properties defined in a text style
// * Font family, weight, and size
// * Line height
// * Letter spacing
// * Text decoration (strikethrough and underline)
// * Text transform (uppercase, lowercase, and capitalization)

// textStyle="hSecondary"

// NB. the uppercase style only works when the component is `Heading`

const textStyles = {
  /* HEADINGS */
  hPrimary: {
    fontFamily: "heading",
    fontSize: { base: "36px", lg: "40px" }, // 5xl
    fontWeight: "500",
    letterSpacing: "normal",
    lineHeight: "none",
    ml: "-2px",
    pb: "1px",
  },
  hSecondary: {
    fontFamily: "heading",
    fontSize: { base: "27px", lg: "32px" }, // 4xl
    fontWeight: "500",
    letterSpacing: "normal",
    lineHeight: "none",
    ml: "-1px",
    pb: "1px",
  },
  hTertiary: {
    fontFamily: "heading",
    fontSize: { base: "21px", sm: "24px", xl: "28px" }, // 3xl
    fontWeight: "500",
    letterSpacing: "normal",
    lineHeight: "shortest",
  },
  hFour: {
    fontFamily: "heading",
    fontSize: { base: "17px", sm: "18px", xl: "20px" },
    fontWeight: "500",
    lineHeight: "shortest",
    letterSpacing: "body",
  },
  hMinor: {
    fontFamily: "heading",
    // fontSize: { base: "21px", lg: "27px", xl: "27px" },
    fontSize: { base: "21px", lg: "24px", "2xl": "27px" },
    fontWeight: "500",
    lineHeight: "shortest",
    // letterSpacing: "body",
    letterSpacing: "0.01em",
  },
  /* TEXT */
  xl: {
    fontSize: { base: "17px", lg: "21px" },
    lineHeight: "base",
    letterSpacing: "body",
  },
  lg: {
    fontSize: { base: "15px", lg: "17px", xl: "19px" },
    lineHeight: "base",
    letterSpacing: "body",
  },
  md: {
    fontSize: { base: "14px", lg: "16px", xl: "17px" },
    lineHeight: "base",
  },
  body: {
    fontSize: { base: "13px", lg: "15px" },
    lineHeight: "base",
  },
  bodyPrimary: {
    fontSize: { base: "13px", lg: "15px" },
    lineHeight: "base",
    color: "grey.600",
  },
  copy: {
    fontSize: { base: "13px", lg: "15px" },
    lineHeight: "base",
    letterSpacing: "body",
  },
  metaCopy: {
    fontSize: { base: "12px", lg: "13px" },
    fontWeight: "700",
    lineHeight: "base",
    letterSpacing: "wider",
    textTransform: "uppercase",
  },
  meta: {
    fontSize: { base: "11px", lg: "13px" },
    fontWeight: "400",
    lineHeight: "short",
    letterSpacing: "body",
  },
  metaBold: {
    fontSize: { base: "11px", lg: "13px" },
    fontWeight: "600",
    lineHeight: "short",
    letterSpacing: "wider",
    textTransform: "uppercase",
  },
  metaButton: {
    fontSize: { base: "11px", lg: "12px" },
    fontWeight: "400",
    lineHeight: "short",
    letterSpacing: "wider",
    textTransform: "uppercase",
  },
  crumb: {
    fontSize: { base: "9px", lg: "11px" },
    fontWeight: "700",
    lineHeight: "1",
    letterSpacing: "0.06em", // wider
    textTransform: "uppercase",
  },
  crumbLower: {
    fontSize: { base: "9px", lg: "11px" },
    fontWeight: "400",
    lineHeight: "1",
    letterSpacing: "wide",
    color: "grey.500",
  },
  label: {
    fontSize: { base: "10px", lg: "11px" },
    fontWeight: "400",
    color: "text.800",
  },
  bodyLabel: {
    fontSize: { base: "12px", md: "13px", lg: "15px" },
    lineHeight: "base",
  },
};

export default textStyles;

import * as React from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { FormElement, FormLabel } from "../ClubCompCreate";
import { ConditionalWrapper } from "../ConditionalWrapper";
// import { SearchInputRight } from "./index";
import { buttonH } from "theme/components/button";
import { useSearch } from "hooks/useSearch";
import { MdSearch } from "react-icons/md";

// NB: Formik requires a name prop to work
// This is passed implicitly to the search component from
// FormCompLayout b/c that component is wrapped by Formik's context
// We have access to the name in this component at `field.name`

interface ISearchPresenter {
  isInTable?: boolean;
  getItems: any;
  label: string;
  name: string;
  noResultsMessage: string | React.ReactNode;
  onSelect: (item: any) => void;
  onChange: (item: React.SyntheticEvent) => void;
  onBlur?: (item: any) => void;
  onIconClick: () => void;
  renderResults?: (items: any) => React.ReactNode;
  placeholder: string;
  showSearchIcon?: "left" | "right";
  value: string;
  wrapInFormElement?: boolean;
  // [x: string]: string | FieldHookConfig<any>;
  [x: string]: any;
}

export const SearchPresenter = ({
  isInTable,
  getItems,
  label,
  name,
  noResultsMessage,
  onSelect,
  onChange,
  onBlur,
  onIconClick,
  placeholder,
  renderResults,
  showSearchIcon = "left",
  value,
  wrapInFormElement = true,
  ...props
}: ISearchPresenter) => {
  const [showResults, setShowResults] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const { isLoading, hasNoResults, setSearch, items } = useSearch({ getItems });

  // TESTS
  console.log("SearchPresenter useSearch items", items);
  // console.log("SearchPresenter isActive", isActive);
  // console.log("SearchPresenter showResults", showResults);

  function handleSelect(item: any) {
    onSelect(item);
    setShowResults(false);
  }

  function handleChange(event) {
    // onChange value for the Chakra component *and* Formik
    // so that we can use Formik's validation
    setIsActive(true);
    setSearch(event);
    onChange(event);
    setShowResults(true);
  }

  // function handleFocus() {
  //   if (value !== "") setShowResults(true);
  //   setIsActive(true);
  // }

  function handleBlur() {
    // setShowResults(false);
    // setIsActive(false);
  }

  // function handleIconClick() {
  //   onIconClick();
  //   setShowResults(false);
  // }

  // const tableH = { base: "28px", lg: "36px" };
  const tableInputElW = { base: "25px", lg: "30px" };
  const inputPx = { base: "25px", lg: "30px" };
  const setHeight = isInTable ? buttonH : buttonH;
  const setInputSize = isInTable
    ? { h: "100%", w: tableInputElW }
    : { h: "100%", w: buttonH };

  return (
    <ConditionalWrapper
      condition={wrapInFormElement}
      wrapper={(children) => (
        <FormElement
          className="CompSearch"
          label={<FormLabel>{label}</FormLabel>}
        >
          {children}
        </FormElement>
      )}
    >
      <InputGroup alignItems="center">
        {/* eslint-disable indent */}
        <Input
          variant="clubhouse"
          h={setHeight}
          borderColor={isInTable ? "text.300" : "grey.100"}
          // ridiculous Chakra props issue… px doesn't work on an Input
          sx={
            isInTable
              ? {
                  pl: inputPx,
                  pr: inputPx,
                }
              : {
                  pl: 4,
                  pr: 4,
                }
          }
          id={name}
          placeholder={placeholder}
          {...props}
          name={name}
          // value={value}
          value={isActive ? value : ""}
          // We want both formik and the component
          // to react to what has been typed
          onChange={handleChange}
          onBlur={handleBlur}
          // onFocus={handleFocus}
          autoComplete="off"
        />
        {/* eslint-enable indent */}

        {showSearchIcon === "left" && (
          <InputLeftElement
            pointerEvents="none"
            zIndex="initial"
            {...setInputSize}
            children={<Icon as={MdSearch} color="text.800" />}
          />
        )}
        {/* leave this reset button for now… */}
        {/* <InputRightElement
          {...setInputSize}
          children={
            <SearchInputRight
              isLoading={isLoading}
              useHasQueryIcon={false}
              hasQuery={!!value}
              handleClick={handleIconClick}
            />
          }
        /> */}
        {showSearchIcon === "right" && (
          <InputRightElement
            pointerEvents="none"
            zIndex="initial"
            {...setInputSize}
            children={<Icon as={MdSearch} color="text.800" />}
          />
        )}
      </InputGroup>

      {/* RESULTS */}
      {/* https://reactjs.org/docs/render-props.html */}
      {showResults && (
        <VStack
          bg="white"
          boxShadow={isInTable ? "initial" : "lg"}
          borderRadius="md"
          left={0}
          minH="168px"
          p={isInTable ? 0 : 1.5}
          position="absolute"
          top={isInTable ? "60px" : "60px"}
          w="full"
          zIndex={101}
          spacing={1}
          sx={{
            "& > *": {
              width: "100%",
            },
          }}
        >
          {hasNoResults ? (
            <Text
              as="div"
              textStyle="copy"
              color="form.bad"
              // fontWeight="500"
              // h={buttonH}
              // px={1}
            >
              {noResultsMessage}
            </Text>
          ) : (
            <>
              {items.map((item: any, index: number) => {
                return (
                  <Button
                    w="full"
                    key={index}
                    variant={isInTable ? "outline" : "ghost"}
                    colorScheme="text"
                    borderColor={isInTable ? "text.50" : "initial"}
                    borderRadius={isInTable ? "2px" : 0}
                    justifyContent="flex-start"
                    height={{ base: "54px", lg: "60px" }}
                    // px={3}
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => handleSelect(item)}
                  >
                    {renderResults ? renderResults(item) : null}
                  </Button>
                );
              })}
            </>
          )}
        </VStack>
      )}
    </ConditionalWrapper>
  );
};

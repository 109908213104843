import { Component } from "react";
import PropTypes from "prop-types";
import Picker from "react-mobile-picker";
import { Box, Flex, Button, Text } from "@chakra-ui/react";
import "./ScorePicker.css";

// TODO: convert to functional component

class ScorePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueGroups: {
        unit: props.valueGroups.unit,
        decimal: props.valueGroups.decimal,
      },
    };
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value,
      },
    }));
  };

  toDecimal() {
    const { unit, decimal } = this.state.valueGroups;
    if (decimal) return `${unit}.${decimal}`;
    return unit;
  }

  render() {
    const {
      optionGroups,
      direction,
      btnText,
      isInModal,
      isInDrawer,
      isSubmitting,
    } = this.props;
    const { valueGroups } = this.state;

    return (
      <Flex
        // flex="1"
        direction="column"
        h="full"
        // this is bad, but fuck you:
        // if isInDrawer, then it's ConfigRowPickerHandicap
        // and as such, we want to show a dot
        className={
          isInDrawer ? "ConfigRowPickerHandicap ScorePicker" : "ScorePicker"
        }
      >
        {direction && (
          <Text textStyle="copy" color="text.600" pb={0}>
            {direction}
          </Text>
        )}
        <Flex flex="1" justify="center" align="center" px="12.5%">
          {/* https://github.com/adcentury/react-mobile-picker#props */}
          <Picker
            height={260}
            itemHeight={50}
            optionGroups={optionGroups}
            valueGroups={valueGroups}
            onChange={this.handleChange}
          />
        </Flex>
        <Box
          layerStyle="containerScoreBtn"
          px={isInModal ? "inset" : 0}
          pb={isInDrawer ? 16 : "inset"}
        >
          <Button
            w="full"
            onClick={() => this.props.onSelect(this.toDecimal())}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            {btnText}
          </Button>
        </Box>
      </Flex>
    );
  }
}

ScorePicker.propTypes = {
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSelect: PropTypes.func.isRequired,
  optionGroups: PropTypes.shape({
    unit: PropTypes.array,
    decimal: PropTypes.array,
  }),
  valueGroups: PropTypes.shape({
    unit: PropTypes.string,
    decimal: PropTypes.string,
  }),
  btnText: PropTypes.string,
  isInModal: PropTypes.bool,
  isInDrawer: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

ScorePicker.defaultProps = {
  optionGroups: {
    unit: undefined,
    decimal: undefined,
  },
  valueGroups: {
    unit: undefined,
    decimal: undefined,
  },
  btnText: "Save",
  isSubmitting: false,
};

export default ScorePicker;

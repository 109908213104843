import { Button } from "@chakra-ui/react";

/* This has an implicit default height of 8 applied form Chakra defaults! */

export const TableHeadingButton = (props: Record<string, any>) => (
  <Button
    // transform="rotate(-12deg)"
    // transform="translateY(0.25em)"
    // transformOrigin="center center"
    // textStyle="crumbLower"
    className="TableHeadingButton"
    colorScheme="text"
    fontWeight="500"
    size="sm"
    variant="ghost"
    _active={{
      bg: "text.700",
      color: "white",
    }}
    {...props}
  />
);

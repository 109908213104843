import { Spinner, Center, CenterProps } from "@chakra-ui/react";

export const BlockLoading = ({ maxH = "100vh" }: CenterProps) => (
  <Center pos="absolute" inset={0} maxH={maxH} className="BlockLoadingSpinner">
    <Spinner
      size="lg"
      // thickness="4px"
      // speed="0.77s"
      color="text.800"
      emptyColor="neutral.200"
      // emptyColor="complement.50"
    />
  </Center>
);

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Link } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { buttonH } from "theme/components/button";
import { ESortBy, ICourse } from "types";
import {
  TableHeader,
  TableHeaderLabel,
  TableHeaderLabels,
  TableSearchCourse,
} from "./index";

export const leaderboardHeaderLabels = {
  STFD: ESortBy.stableford,
  PAR: ESortBy.par,
  NETT: ESortBy.stroke,
};

export interface ILeaderboardPresenter {
  tableHeading: string;
  viewAllLink?: string;
}

interface Props {
  onSearchSelect: (selected: ICourse) => void;
  sortBy: ESortBy;
  setSortBy: (sortBy: ESortBy) => void;
  children: ReactNode;
}

export const LeaderboardPresenter = ({
  tableHeading,
  viewAllLink,
  onSearchSelect,
  sortBy,
  setSortBy,
  children,
}: ILeaderboardPresenter & Props) => (
  <Box as="section">
    <TableHeader
      heading={tableHeading}
      mobileStackDirection="column"
      showDivider
      leftSearch={
        <TableSearchCourse
          onSelect={onSearchSelect}
          name="course"
          label="Course"
          placeholder="Search for another course…"
        />
      }
      rightContent={
        <HStack spacing={1.5} transform={{ lg: "translateY(0.5em)" }}>
          <Box textStyle="crumb" fontWeight="400" color="text.500" pr={4}>
            Sort by
          </Box>
          <TableHeaderLabels>
            {Object.keys(leaderboardHeaderLabels).map((label, index) => (
              <TableHeaderLabel
                key={index.toString()}
                label={label}
                sortBy={sortBy}
                onClick={() => setSortBy(leaderboardHeaderLabels[label])}
              />
            ))}
          </TableHeaderLabels>
        </HStack>
      }
    />

    {/* CHILDREN */}
    {children}

    {/* CTA */}
    {viewAllLink !== undefined && (
      <Link
        as={ReactRouterLink}
        to={viewAllLink}
        d="block"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Button
          w="full"
          size="sm"
          variant="table"
          colorScheme="text"
          fontWeight="500"
          h={buttonH}
          rightIcon={<ChevronRightIcon boxSize="1.2em" />}
          iconSpacing={1}
          sx={{
            "&:hover .chakra-button__icon": {
              transform: "translateX(-0.3em)",
            },
          }}
        >
          View all
        </Button>
      </Link>
    )}
  </Box>
);

import { Box, HStack, Text, Icon } from "@chakra-ui/react";
import { MdInfo, MdWarning } from "react-icons/md";

/*
  Alert message
  Easier to make our own alerts vs using Chakra Alert composition
  see: https://chakra-ui.com/alert#composition
 */

// function MessageWrap(props) {
const MessageWrap = ({ underHeader, ...props }) => {
  if (underHeader) {
    return (
      <Box
        borderColor="white"
        _first={{
          borderTopWidth: "2px",
          // mt: "-1rem",
          // mt: "calc(var(--chakra-space-6) * -1)",
        }}
        mx="calc(var(--chakra-space-6) * -1)"
        {...props}
      />
    );
  }
  return <Box {...props} />;
};

interface IMessage {
  success?: boolean;
  underInput?: boolean;
  underHeader?: boolean;
  heading: string;
  children?: React.ReactNode;
}

export const Message = ({
  underHeader = false,
  underInput = false,
  success = false,
  heading,
  children,
  ...props
}: IMessage) => (
  <MessageWrap
    underHeader={underHeader}
    bg={success ? "complement.10" : "form.bad"}
    color={success ? "text.800" : "white"}
    mt={underInput && "6"}
    mb={underInput ? "2" : "6"}
    mx="calc(var(--chakra-space-6) * -1) !important"
    {...props}
  >
    <HStack
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
      py={3}
      px={3}
    >
      <Icon as={success ? MdInfo : MdWarning} />
      <Box
        transform={children ? "translateY(1px)" : "translateY(1px)"}
        textAlign="left"
      >
        <Text as="div" textStyle="crumb">
          {heading}
        </Text>
        {children && (
          <Text as="div" textStyle="meta" pt={1}>
            {children}
          </Text>
        )}
      </Box>
    </HStack>
  </MessageWrap>
);

import React from "react";
import { TPlayType, TMatchType, TTee } from "types";
import { friendlyToConstant } from "config/constants";

// all types except course will be initially undefined
export interface IFormData {
  course?: {
    id: string;
    value: string;
  };
  playType?: TPlayType;
  matchType?: TMatchType;
  tee?: TTee;
}

export default function useFormData(
  initialFormData: IFormData
): [IFormData, (name: any, value: any) => void, boolean] {
  const [formData, setFormData] = React.useState<IFormData | IFormData>(
    initialFormData
  );
  const [areAllFieldsSet, setAreAllFieldsSet] = React.useState<boolean>(false);

  function setFormOption(name, value) {
    const newValue = name === "tee" ? friendlyToConstant(value) : value;
    setFormData((state) => ({
      ...state,
      [name]: newValue,
    }));
  }

  React.useEffect(() => {
    const isEveryFieldSet = Object.values(formData).every(
      (value) => !!(typeof value !== "undefined" && value !== null)
    );
    setAreAllFieldsSet(isEveryFieldSet);
  }, [formData]);

  return [formData, setFormOption, areAllFieldsSet];
}

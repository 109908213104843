import { Box } from "@chakra-ui/react";
import { ScoreTabs, ScoreTabsFrame } from "../ScoreTabs";
import { PosterHoleScore } from "./index";
// import * as scoreLib from "lib/score";
// import * as dateLib from "lib/date";
import { IEclecticRoundTotalsBasic, EMatchType } from "types";

/*
  This is a duplicate of ScorePoster for Eclectic rounds.
  Eclectic rounds are ALWAYS STABLEFORD.

  See: https://callum.notion.site/YLG-Clubhouse-system-design-Mothership-4c1678643d804de1acfe3c9bd1f557c7

  ScorePoster displays the hole score as large typographic "poster".
  It is used in 2 places:
  1. ScoresSummary component (in turn used across
     ScoresSummarySection, ScoreInput & ScorecardRowModal)
  2. Scorecard page (the final round scorecard)
*/

interface IScorePoster {
  score: IEclecticRoundTotalsBasic;
}

export const ScorePosterEclectic = ({ score }: IScorePoster) => {
  // const matchType = EMatchType.stableford;
  // const date = new Date();
  // const time = dateLib.toDateString(date.toISOString());
  const label = "All signed match rounds";

  return (
    <Box flex="1" className="ScorePoster" bg="complement.50">
      <ScoreTabs>
        <ScoreTabsFrame showTabListDots>
          <PosterHoleScore
            bigNumber={score.totalScoreStableford}
            matchType={EMatchType.stableford}
            metaLabel={label}
            // time={time}
            variantIsEclectic={true}
          />
          <PosterHoleScore
            bigNumber={score.totalScoreStroke}
            matchType={EMatchType.stroke}
            metaLabel={label}
            // time={time}
            variantIsEclectic={true}
          />
        </ScoreTabsFrame>
      </ScoreTabs>
    </Box>
  );
};

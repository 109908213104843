import { Flex } from "@chakra-ui/react";

/* Makes the modal into a rounded card */

export const LgModalInner = (props) => (
  <Flex
    direction="column"
    mx="inset"
    my="inset2x"
    h="full"
    bg="white"
    rounded="xl"
    overflow="hidden"
    className="LgModalInner"
    {...props}
    // custom button styles that override LgModalCloseButton
    sx={{
      "& > button": {
        top: { base: "60px", xl: "w6" },
        right: { base: "w7", xl: "w6" },
      },
    }}
  >
    {props.children}
  </Flex>
);

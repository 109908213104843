import { ScorecardRowForModal } from "./index";
import { useUserContext } from "components/Context/User";
import { HoleStatsProvider, useScorecardContext } from "components/Context";
import { useHoleStatsForHistoricalMatch } from "hooks/index";
import * as courseLib from "lib/course";
import { ICourseHole, IHoleStatsContext, EScorecardVariant } from "types";

/*
  Used on ScorecardPresenter when the ScorecardContext variant is NOT "scoring"
  ie. the Clubhouse flow.
  * it's only end usage is ScorecardPostMatch on a Leaderboard
  * we statically set the variant as "leaderboard" as we ever use this
    component for any "eclectic" variant
  * uses ScorecardRowCard which is roughly the same as ScoreInputPresenter
  * NB! If going to add ability to edit mismatching score,
    you'll need to add the modal back in (see ScorecardRowCard)
*/

// TODO: possibly amalgamate with IScorecardRowModalCard in ScorecardRowModal
interface IScorecardRowModalHistorical {
  // playerRoundSigned: boolean;
  // markerRoundSigned: boolean;
  hole: ICourseHole;
  isMarker: boolean;
  mismatchingScore?: boolean;
}

export const ScorecardRowModalHistorical = ({
  // playerRoundSigned,
  // markerRoundSigned,
  isMarker,
  hole,
  mismatchingScore,
}: IScorecardRowModalHistorical) => {
  // Context
  const user = useUserContext();
  const { marker, match, matchType, scores } = useScorecardContext();

  // Get hole stats and format for a new Context
  const holeStats = useHoleStatsForHistoricalMatch(hole.number);

  const holeStatsContext: IHoleStatsContext = {
    markerScores: holeStats.markerScores,
    markerTeeStats: holeStats.markerTeeStats,
    playerScores: holeStats.playerScores,
    playerTeeStats: holeStats.playerTeeStats,
    time: holeStats?.time,
  };

  // TODO: is isMarker required?
  const gender = isMarker && marker ? marker.gender : user.gender;

  // Hole details relative to the player & course
  const userPar = courseLib.getPar(hole, match.tee, gender);
  const { distance, index: strokeIndex } = courseLib.getTeeFromGender(
    gender,
    match.tee,
    hole
  );
  const renderScore = (hole: number) =>
    isMarker ? scores[hole].marker : scores[hole].user;

  return (
    <HoleStatsProvider value={holeStatsContext}>
      <ScorecardRowForModal
        distance={distance}
        holeNumber={hole.number}
        matchType={matchType}
        mismatchingScore={mismatchingScore}
        par={userPar}
        scores={renderScore(hole.number)}
        strokeIndex={strokeIndex}
        variant={EScorecardVariant.leaderboard}
      />
    </HoleStatsProvider>
  );
};

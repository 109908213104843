import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
  useDisclosure,
  UseDisclosureProps,
  useMediaQuery,
} from "@chakra-ui/react";
import { ConfigRow, ButtonRightIcon } from "./index";
import { LgHeader } from "components/Block";
import { BlockContainerScore } from "components/Block2";
import { MdChevronLeft } from "react-icons/md";
import { TConfigRowTheme } from "types";

/* ConfigRowDrawer opens a drawer from a ConfigRow */

interface IConfigRowDrawer extends DrawerProps {
  avatarSrc?: string;
  btnBackLabel?: string;
  btnName?: string;
  btnTheme?: TConfigRowTheme;
  btnTruncateValue?: boolean;
  btnValue?: string | number;
  // https://stackoverflow.com/a/56210772
  // children(options: { setElementRef(el: HTMLElement): any }): React.ReactNode;
  children(disclosure: UseDisclosureProps): JSX.Element;
  fullBody?: boolean;
  heading?: string;
  // disclosure: UseDisclosureProps;
}

export const ConfigRowDrawer = ({
  avatarSrc,
  btnBackLabel = "Play",
  btnName,
  btnTheme,
  btnTruncateValue,
  btnValue,
  children,
  fullBody,
  heading,
}: IConfigRowDrawer) => {
  const disclosure = useDisclosure();
  const btnRef = React.useRef();
  const firstField = React.useRef();
  const [breakpoint] = useMediaQuery("(min-width: 1024px)");

  return (
    <>
      <ConfigRow
        ref={btnRef}
        onClick={disclosure.onOpen}
        name={btnName}
        value={btnValue}
        avatarSrc={avatarSrc}
        truncateValue={btnTruncateValue}
        theme={btnTheme}
      >
        <ButtonRightIcon />
      </ConfigRow>

      {/* DRAWER */}
      <Drawer
        placement="right"
        size={breakpoint ? "lg" : "full"}
        // is this .ts error caused by not forwardRefing?
        initialFocusRef={firstField}
        finalFocusRef={btnRef}
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      >
        <DrawerOverlay className="screen-h" />
        {/* className={fullBody ? "screen-h" : "screen-h screen-pb"}> */}
        <DrawerContent className="screen-h">
          <BlockContainerScore spacing={fullBody ? 0 : 4}>
            <LgHeader
              heading={heading}
              headingAs="h2"
              headingKind={{ base: "hSecondary", lg: "hFour" }}
              topLeftSlot={
                <Button
                  size="sm"
                  variant="ghost"
                  colorScheme="textButton"
                  px={0}
                  h="full"
                  leftIcon={<MdChevronLeft />}
                  iconSpacing="3px"
                  onClick={disclosure.onClose}
                  sx={{
                    "& .chakra-button__icon": {
                      transition: "all 0.7s cubic-bezier(.08,.52,.52,1)",
                      transform: "translateY(-0.5px)",
                      // mr: "8px !important",
                    },
                  }}
                >
                  {btnBackLabel}
                </Button>
              }
            />
            <DrawerBody
              py={0}
              // mt={fullBody ? -4 : undefined}
              // mx={fullBody ? -6 : undefined}
              overflowY="scroll"
              px={fullBody ? 0 : "inset"}
              mx={
                fullBody
                  ? {
                      base: "calc(var(--chakra-space-6) * -1) !important",
                      lg: "calc(var(--chakra-space-12) * -1) !important",
                    }
                  : undefined
              }
            >
              {/* {isFunction(children) ? children(disclosure) : children} */}
              {children(disclosure)}
            </DrawerBody>
          </BlockContainerScore>
        </DrawerContent>
      </Drawer>
    </>
  );
};

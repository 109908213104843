import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { Flex, Text } from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { ValidationModal } from "components/Notices";
import { LoginFormLayout } from "./LoginFormLayout";
import useQuery from "hooks/useQuery";
import useOpenOnTimeout from "hooks/useOpenOnTimeout";
import * as schemas from "lib/schemas";
import * as authActions from "store/actions/authentication";

export const FormLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  // These query params are set when an account is redirected here
  // after verifying their email
  const fromAccountVerified = query.get("account-verified") ? true : false;
  const fromPasswordReset = query.get("password-reset") ? true : false;
  const { isOpen, onClose } = useOpenOnTimeout(fromAccountVerified, 3000);

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    const response = await dispatch(
      authActions.loginUser({
        email: values.email,
        password: values.password,
      })
    );

    if (response.error) {
      setSubmitting(false);
      setStatus({
        apiError: response.error,
      });
      return;
    }

    history.push("/");
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: schemas.email,
          password: schemas.password,
        })}
        onSubmit={onSubmit}
      >
        {() => (
          <LoginFormLayout
            fromAccountVerified={fromAccountVerified}
            fromPasswordReset={fromPasswordReset}
          />
        )}
      </Formik>

      {/* EMAIL VERIFICATION REDIRECT: "SAVE TO HOME SCREEN DIRECTIONS" */}
      {fromAccountVerified && (
        <ValidationModal
          kind="half"
          heading="Add to Home Screen"
          closeButton
          isOpen={isOpen}
          onClose={onClose}
        >
          <Text textStyle="meta" color="white">
            Look for the "Add to Home Screen" option in your mobile browser's
            share menu.{" "}
            <Text as="span" textStyle="meta" color="white" opacity="0.7" pt={1}>
              Some phones don't have this. If that's the case, just close this
              and continue signing in.
            </Text>
          </Text>
          <Flex pt={6} justify="center">
            {/* <LgIconPlus /> */}
            <ArrowDownIcon h="48px" w="48px" color="white" />
          </Flex>
        </ValidationModal>
      )}
    </>
  );
};

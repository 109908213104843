import io from "socket.io-client";
import store from "../store/index";
import config from "../config";
import {
  LOBBY_SET_USERS,
  LOBBY_SET_READY_STATE,
  LOBBY_DELETE_INVITE,
} from "../store/actions/lobby";
import {
  SET_INVITES,
  ADD_INVITE,
  DELETE_INVITE,
} from "../store/actions/invites";
import * as matchActions from "../store/actions/match";
import history from "../history";

class Socket {
  constructor(url) {
    this.url = url;
    this.socket = null;
    this.matchId = null;
  }

  isConnected() {
    return this.socket && this.socket.connected;
  }

  connect(token) {
    if (this.socket && this.socket.connected) {
      console.error("A socket connection is already established");
      return;
    }

    this.socket = io(this.url, {
      query: `token=${token}`,
    });

    /**
     * General events
     */

    this.socket.on("connect", () => {
      console.info("Established socket connection");
    });

    this.socket.on("server_error", (data) => {
      console.info("GOT server_error", data);
    });

    /**
     * Notification events
     */

    this.socket.on("invites_pending", (data) => {
      store.dispatch({
        type: SET_INVITES,
        invites: data.invites,
      });
    });

    // Invite deleted by the host
    this.socket.on("invite_delete", (data) => {
      store.dispatch({
        type: DELETE_INVITE,
        inviteId: data.inviteId,
      });
    });

    // Invite deleted by host after user has joined
    this.socket.on("kicked", (data) => {
      store.dispatch(matchActions.kicked());
    });

    this.socket.on("invite_player", (data) => {
      store.dispatch({
        type: ADD_INVITE,
        invite: data.invite,
      });
    });

    this.socket.on("reconnect", async (data) => {
      if (this.matchId) {
        this.socket.emit("join_lobby", { matchId: this.matchId });
      }
    });

    /**
     * Lobby events
     */

    this.socket.on("users", (data) => {
      store.dispatch({
        type: LOBBY_SET_USERS,
        users: data.users,
        invited: data.invited,
      });
    });

    this.socket.on("marker_changed", async (data) => {
      await store.dispatch(matchActions.setMarker(data.marker, data.markerMatchSettings));
    });

    this.socket.on("lobby_end", async (data) => {
      history.push("/?lobby-ended=true");
    });

    this.socket.on("match_start", async (data) => {
      await store.dispatch(matchActions.startMatch(data.matchId));
      history.push("/score/1");
    });

    /**
     * Lobby events (for the host)
     */

    this.socket.on("invite_accepted", (data) => {
      store.dispatch({
        type: LOBBY_DELETE_INVITE,
        userId: data.userId,
      });
    });

    this.socket.on("invite_denied", (data) => {
      store.dispatch({
        type: LOBBY_DELETE_INVITE,
        userId: data.userId,
      });
    });

    this.socket.on("player_readied", (data) => {
      store.dispatch({
        type: LOBBY_SET_READY_STATE,
        userId: data.userId,
        ready: true,
      });
    });
  }

  disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  lobbyJoin(id) {
    this.matchId = id;
    this.socket.emit("join_lobby", { matchId: id });
  }

  lobbyLeave(id) {
    this.matchId = null;
    this.socket.emit("leave_lobby", { matchId: id });
  }
}

const socketInstance = new Socket(config.serverUrl);

export default socketInstance;

import {
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Divider,
} from "@chakra-ui/react";
import { LgModalInner, LgModalCloseButton } from "../Block/index";
import { MdAutorenew } from "react-icons/md";
import React from "react";

interface Props {
  buttonLabel: string;
  confirm: string;
  extraText?: React.ReactNode;
  heading: string;
  isDeleting?: boolean;
  isOpen: boolean;
  // you can write function types like this…
  onClose(): void;
  // …or like this
  onClick: () => void;
  showButtonIcon?: boolean;
}

export const DeleteModal = ({
  isOpen,
  onClose,
  onClick,
  buttonLabel,
  showButtonIcon = true,
  heading,
  confirm,
  extraText,
  isDeleting = false,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay bg="text.900" />
      <ModalContent
        shadow="none"
        // mb={0}
        // mt="auto"
        bg="transparent"
        color="white"
        alignItems="center"
        justifyContent="center"
        height="full"
        minHeight="full"
        className="DeleteModal-content"
      >
        <LgModalInner px={6} bg="transparent" justify="center">
          <LgModalCloseButton reverse />

          <Flex
            direction="column"
            h="full"
            w={{ base: "full", lg: "480px" }}
            pt={{ base: "w8", lg: 0 }}
            className="LgModalInner-firstChild"
          >
            <Text as="h2" textStyle="hTertiary" color="white">
              {heading}
            </Text>
            <Divider borderColor="whiteAlpha.500" my={4} />
            <Text textStyle="body" color="white">
              {confirm}
            </Text>
            {extraText}

            <Box mt="auto" pt={{ lg: "w5" }} pb={{ base: "w7", lg: "w9" }}>
              <Button
                w="full"
                color="text.800"
                colorScheme="whites"
                leftIcon={showButtonIcon ? <MdAutorenew /> : undefined}
                onClick={onClick}
                isLoading={isDeleting}
              >
                {buttonLabel}
              </Button>
            </Box>
          </Flex>
        </LgModalInner>
      </ModalContent>
    </Modal>
  );
};

import getIncompleteHoles from "../lib/getIncompleteHoles";
// import { MATCH_TYPES, PLAY_TYPES } from "../config/constants";
import { EPlayType, EMatchType } from "types";

const scoreLabelForMatchType = {
  [EMatchType.stableford]: {
    short: "STBF",
    abbr: "S'ford",
    long: "Stableford",
  },
  [EMatchType.par]: { short: "PAR", long: "Par" },
  [EMatchType.stroke]: { short: "SCR", long: "Score" },
};

function formatApiRoundsForStore(userRound, markerRound) {
  return {
    user: userRound,
    marker: markerRound,
  };
}

function formatApiScoresForStore(userScores, markerScores) {
  return userScores.reduce((obj, score, index) => {
    const userScore = score;
    const markerScore = markerScores[index];
    obj[score.holeNumber] = {
      user: {
        strokes: userScore.strokes,
        score: parseFloat(userScore.score),
        noScore: userScore.noScore,
      },
      marker: {
        ...(markerScore && {
          strokes: markerScore.strokes,
          score: parseFloat(markerScore.score),
          noScore: markerScore.noScore,
        }),
      },
      createdAt: score.createdAt,
    };
    return obj;
  }, {});
}

export default function formatMatchResponse(data) {
  const {
    match,
    userMatchSettings,
    userScores,
    markerScores,
    userRound,
    markerRound,
    hasVerifiedMarkersRound,
  } = data;

  // Custom properties added on the client
  match.scoreLabel = scoreLabelForMatchType[match.matchType] || {};
  match.isSocial = match.playType === EPlayType.social;
  const scores = formatApiScoresForStore(userScores, markerScores);
  const rounds = formatApiRoundsForStore(userRound, markerRound);
  const incompleteHoles = getIncompleteHoles(scores);

  return {
    match,
    userMatchSettings,
    scores,
    rounds,
    incompleteHoles,
    hasVerifiedMarkersRound,
  };
}

import { Flex, Button, Box, Icon } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import * as authActions from "store/actions/authentication";
import FormUpdateUserSettings from "components/Forms/UpdateUserSettings";
import { ConfigRow } from "components/Config";
import { DeleteAccountModal } from "components/Notices";
import { MdArrowBack } from "react-icons/md";

interface IAccountForm {
  buttonLabel?: string;
  buttonWrapperProps?: any;
  handleClose: () => void;
  wrapperProps?: any;
}

export const AccountForm = ({
  buttonWrapperProps,
  buttonLabel = "Save and close",
  handleClose,
  wrapperProps,
}: IAccountForm) => {
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(authActions.logoutUser());
  }

  return (
    <Flex direction="column" flex="1" {...wrapperProps}>
      <Box>
        <FormUpdateUserSettings onClose={handleClose} />
        <ConfigRow
          name="Sign out"
          onClick={handleLogout}
          iconSlot={
            <Box mr={2}>
              <Icon as={MdArrowBack} />
            </Box>
          }
        />
        <DeleteAccountModal />
      </Box>

      {/* SAVE: a pseudo with HTML form attribute. Use `onClose` on `FormUpdateUserSettings` if you need to add an event `onSubmit` */}
      <Box {...buttonWrapperProps}>
        <Button
          type="submit"
          variant="outline"
          colorScheme="textButton"
          w="full"
          // target a form submission
          form="update-user-settings"
          // onClick={handleClose}
        >
          {buttonLabel}
        </Button>
      </Box>
    </Flex>
  );
};

import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as matchActions from "../store/actions/match";
import * as api from "../lib/api";

export default function useVerifyRound(handleMismatchingScoreHoleNumbers) {
  const dispatch = useDispatch();
  const match = useSelector(matchActions.selectors.getMatch);
  const hasVerifiedMarkersRound = useSelector(
    matchActions.selectors.getHasVerifiedMarkersRound
  );
  const userMatchSettings = useSelector(
    matchActions.selectors.getUserMatchSettings
  );

  // Round is being verified
  const [isVerifyingRound, setIsVerifyingRound] = React.useState(false);
  // Round was verified just now
  const [verifyRoundResponse, setVerifyRoundResponse] = React.useState(null);
  // Round has been verifed now or in the past
  const [roundIsVerified, setRoundIsVerified] = React.useState(
    hasVerifiedMarkersRound
  );

  async function handleVerifyRound() {
    setIsVerifyingRound(true);
    setVerifyRoundResponse(null);
    const res = await api.verifyRound(match.id, userMatchSettings.marker.id);
    if (res.error) {
      setVerifyRoundResponse({
        errorCode: res.error.code,
      });
      if (res.error.details?.mismatchingScoreHoleNumbers) {
        handleMismatchingScoreHoleNumbers(
          res.error.details.mismatchingScoreHoleNumbers
        );
      }
    } else {
      // Clear incongruent scores
      handleMismatchingScoreHoleNumbers(null);
      setVerifyRoundResponse({
        success: true,
      });
      setRoundIsVerified(true);
      // TODO: can this be pushed across the socket so the other player doesn't need to refresh their page? (They never will, they'll expect this to just update automatically)
      await dispatch(matchActions.getMatch(match.id, { force: true }));
    }
    setIsVerifyingRound(false);
  }

  return {
    isVerifyingRound,
    verifyRoundResponse,
    roundIsVerified,
    handleVerifyRound,
  };
}

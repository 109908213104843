import { Flex } from "@chakra-ui/react";

export const TableHeaderLabels = (props) => {
  return (
    <Flex
      align="baseline"
      className="TableHeaderLabels"
      h="full"
      // transform={{ base: "translateY(-8px)", lg: "initial" }}
    >
      {props.children}
    </Flex>
  );
};

import { Box, Flex, Text } from "@chakra-ui/react";

export const HoleCircle = ({ holeNumber }: { holeNumber: number }) => (
  <Text as="div" textStyle="hSecondary" ml="0" d="flex" color="text.800">
    <Flex
      as="span"
      d="inline-flex"
      align="end"
      justify="center"
      h={8}
      lineHeight="32px"
      w={8}
      borderWidth="1px"
      borderColor="text.800"
      // color="text.800"
      rounded="full"
      fontSize="0.5em"
    >
      <Box lineHeight="29px" letterSpacing="0.05em" fontWeight="500">
        {holeNumber}
      </Box>
    </Flex>
  </Text>
);

import React from "react";
import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { LayoutRoot } from "./index";
import { Navbar, NavbarButton, Navside } from "components/Nav";
import { BlockMotion, BlockLoading } from "components/Block2";
import { DeleteRoundModal, DeleteLobbyModal } from "components/Notices";
import { LobbyInvite } from "components/Lobby/LobbyInvite";
// import { ConditionalWrapper } from "components/ConditionalWrapper";
import config from "config";
import * as authActions from "store/actions/authentication";
import * as matchActions from "store/actions/match";

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);

    this.onUnauthorized = this.onUnauthorized.bind(this);
    this.onAuthorized = this.onAuthorized.bind(this);
    this.onLogout = this.onLogout.bind(this);

    this.state = {
      isUserAuthed: !!this.props.user,
      // Url to resume playing current round, else null
      resumeRoundPath: null,
    };
  }

  async checkForMatchInProgress() {
    const matchInProgress = localStorage.getItem(config.localStorageMatchKey);
    const currentRound = localStorage.getItem(config.localStorageRoundKey);
    if (!matchInProgress) return;

    const matchRes = await this.props.dispatch(
      matchActions.getMatch(matchInProgress)
    );

    if (!(matchRes && matchRes.match)) {
      console.warn(`Failed to fetch your current match: ID ${matchInProgress}`);
      return;
    }

    const { match } = matchRes;
    if (match.started) {
      return `/score/${currentRound || 1}`;
    }

    // Redirect to lobby
    const isHost = match.host.id === this.props.user.id;
    const redirectUrl = isHost
      ? `/lobby?id=${match.id}`
      : `/lobby-invitation?id=${match.id}`;
    return redirectUrl;
  }

  async onUnauthorized() {
    await this.props.history.push("/signin");
  }

  async onAuthorized(token) {
    if (!this.props.user) {
      await this.props.dispatch(authActions.setToken(token));
      const userRes = await this.props.dispatch(authActions.fetchUser());

      if (userRes.error && userRes.error.code === "UNAUTHORIZED") {
        await this.props.dispatch(authActions.logoutUser());
      }
    }

    if (!this.props.isMatchPage) {
      const resumeRoundPath = await this.checkForMatchInProgress();
      this.setState({ resumeRoundPath });
    }
  }

  async onLogout() {
    await this.props.dispatch(authActions.logoutUser());
  }

  async componentDidMount() {
    // Authentication info is stored in localStorage
    const token = localStorage.getItem(config.localStorageBearerKey);

    if (!token) {
      this.onUnauthorized();
      return;
    }

    await this.onAuthorized(token);

    this.setState({
      isUserAuthed: true,
    });
  }

  render() {
    // Use "isUserAuthed" to conditionally render and stop
    // the page flash before redirecting onUnauthorized call
    const { isUserAuthed, resumeRoundPath } = this.state;
    const {
      component: Component,
      location,
      exact,
      path,
      showDeleteLobbyModal,
      showDeleteRoundModal,
    } = this.props;

    // console.log("DefaultLayout path", path);

    return (
      // NOTE: The key prop on the Route component is vital for page rendering
      // Without it, `location.push` behavior is unusual
      // The pathname will update before the page component switches
      // With HOCs such as withMatch this causes issues because withMatch
      // depends on the pathname & query params
      <Route
        location={location}
        key={location.key}
        exact={exact}
        path={path}
        className="Route"
        render={(matchProps) => {
          // console.log("Rendering route", matchProps);
          return (
            <>
              {/* Title can be set per page using react-helmet */}
              <HelmetProvider>
                <Helmet>
                  <title>Your LoCal Golf</title>
                </Helmet>
              </HelmetProvider>

              {/* ReactRootFirstChild */}
              <LayoutRoot>
                {/* Use "isUserAuthed" to conditionally render and stop the page flash before redirecting onUnauthorized call */}
                {isUserAuthed && (
                  <>
                    {/* SCORING NAV: mobile > lg only */}
                    <Navbar
                      path={path}
                      resumeRoundPath={resumeRoundPath}
                      buttonIsWhite={this.props.navButtonIsWhite}
                      round={
                        <>
                          {showDeleteLobbyModal && <DeleteLobbyModal />}
                          {showDeleteRoundModal && (
                            <DeleteRoundModal forNavbar />
                          )}
                          {!showDeleteLobbyModal && !showDeleteRoundModal && (
                            <Link to={resumeRoundPath ? resumeRoundPath : "/"}>
                              <NavbarButton
                                label={
                                  resumeRoundPath
                                    ? "Resume Round"
                                    : "Play Round"
                                }
                                path={resumeRoundPath ? resumeRoundPath : path}
                                activePath="/"
                              ></NavbarButton>
                            </Link>
                          )}
                        </>
                      }
                    >
                      {/* Unfinished match alert (duplicated for mobile) */}
                      {/* <LayoutAlert
                        left={0}
                        pos="absolute"
                        top={4}
                        user={this.props.user}
                      /> */}
                    </Navbar>

                    {/* CLUBHOUSE NAV: lg > only */}
                    <Navside path={path} />

                    {/* MAIN */}
                    <BlockMotion>
                      {/* <BlockLoading maxH={{ base: "90vh", xl: "40vh" }} */}

                      <Component
                        {...this.props.matchProps}
                        user={this.props.user}
                      />
                    </BlockMotion>
                  </>
                )}
              </LayoutRoot>

              {/* round invitation modal */}
              {this.props.allowInvite && this.props.invite && (
                <LobbyInvite invite={this.props.invite} />
              )}
            </>
          );
        }}
      />
    );
  }
}

DefaultLayout.propTypes = {
  // Page react component
  component: PropTypes.func.isRequired,
  // other stuff…
  // showLogo: PropTypes.bool,
  showDeleteLobbyModal: PropTypes.bool,
  showDeleteRoundModal: PropTypes.bool,
  // fix the page to avoid picker interaction issues
  showFixed: PropTypes.bool,
  // hamburger button color against the header
  navButtonIsWhite: PropTypes.bool,
};

export default connect((state) => ({
  user: state.authentication.user,
  invite: state.invites.invites[0],
}))(withRouter(DefaultLayout));

import { Text, HStack, Circle } from "@chakra-ui/react";
import { TextMeta } from "./index";

export const Marker = ({ name }: { name: string }) => (
  <HStack spacing="7px" align="center" pr={4}>
    <Circle
      size="18px"
      color="grey.400"
      border="1px solid"
      borderColor="grey.400"
    >
      <Text fontSize="60%" fontWeight="500">
        M
      </Text>
    </Circle>
    <TextMeta>{name}</TextMeta>
  </HStack>
);

import { ScoreMessage } from "./index";

// NB. moight use the incongruentScore to be rendered

export const ScoreMessageMismatching = ({
  isForScorecardPage,
}: {
  isForScorecardPage?: boolean;
}) => {
  // Alt copy for `Inaccurate score`:
  // Your marker's score doesn't match your score. Talk to your marker, agree on the score and edit the offending score.
  // "Your marker's score for this hole doesn't match this score. Please ensure they match."
  return (
    <ScoreMessage showBorder isSuccessBased heading="Your scores don't match">
      Talk to your marker and edit this score or theirs
      {isForScorecardPage &&
        ". Once fixed, close this dialog and sign your scorecard again."}
    </ScoreMessage>
  );
};

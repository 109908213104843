/**
 * Sponsors
 */

import { SPONSORS_FOR_COURSE_SUCCESS } from "../actions/sponsors";

const initialState = {};

function sponsors(state = initialState, action) {
  /* eslint-disable indent */
  switch (action.type) {
    case SPONSORS_FOR_COURSE_SUCCESS:
      return {
        ...state,
        [action.courseId]: action.sponsors,
        defaultSponsor: action.defaultSponsor,
      };
    default:
      return state;
  }
  /* eslint-enable indent */
}

/**
 * @returns {object|null}
 */
export const selectSponsorsForCourse = (state, courseId) => {
  const sponsors = state.sponsors && state.sponsors[courseId];
  const defaultSponsor = state.sponsors.defaultSponsor;

  // TESTS
  // console.log("selectSponsorsForCourse state", state);
  // console.log("selectSponsorsForCourse defaultSponsor", defaultSponsor);

  if (!sponsors) return defaultSponsor;

  return {
    sponsors,
    defaultSponsor
  }
};

export default sponsors;

/**
 * Fetch match and user match settings before rendering page
 * This could be refactored… But later
 */

import history from "../history";
import useMatch from "hooks/useMatch";
import useGetMatchIdFromPath from "hooks/useGetMatchIdFromPath";

export default function withMatch(Component) {
  return (props) => {
    const id = useGetMatchIdFromPath();

    if (!id) {
      history.push("/");
      return null;
    }

    const match = useMatch(id);
    // Don't render until match is fetched
    if (!match.match) return null;

    return <Component match={match} {...props} />;
  };
}

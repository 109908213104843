import { Text, HStack, StackDivider } from "@chakra-ui/react";
import { TextMetaCard } from "./index";
import { IPlayer } from "types";

export const CardPlayer = ({ player }: { player: IPlayer }) => {
  return (
    <HStack spacing={2} alignItems="baseline">
      <Text
        textStyle="md"
        color="white"
        fontWeight="400"
        letterSpacing="body"
        pr={1}
      >
        {player.fullName}
      </Text>
      <HStack divider={<StackDivider opacity="0.6" />}>
        <TextMetaCard isQuiet>{player.handicap} HCAP</TextMetaCard>
        <TextMetaCard isQuiet>{player.homeCourse?.clubName}</TextMetaCard>
      </HStack>
    </HStack>
  );
};

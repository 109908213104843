import { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";

export default function useOpenOnTimeout(condition, timeout) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (condition) {
      const timeoutId = setTimeout(() => onOpen(), timeout);
      return () => clearTimeout(timeoutId);
    }
  }, [condition, onOpen, timeout]);

  return { isOpen, onClose };
}

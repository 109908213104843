// @refresh reset
// https://next.chakra-ui.com/docs/theming/component-style
// https://next.chakra-ui.com/docs/form/input
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts

import { buttonH } from "./button";
export const inputLargeH = { base: "48px", lg: "60px" };

// must be Sentence case
const Input = {
  parts: ["field", "addon"],
  baseStyle: {
    field: {
      border: "initial",
      // borderColor: "grey.100",
      // borderRadius: "2px", // doesn't work!
      transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0s",
      _hover: {
        // bg: "primary.200",
        // borderColor: "primary.600",
      },
      _focus: {
        bg: "white",
        outline: "none",
        outlineOffset: "0",
      },
      _active: {
        outline: "none",
        outlineOffset: "0",
      },
    },
  },
  // Chakra sets h, fontSize, px, borderRadius here
  sizes: {
    sm: {
      field: {
        fontSize: { base: "11px", lg: "12px" }, // metaButton
        h: { base: "30px", lg: "36px" },
        // p: "16px",
        // borderRadius: "base",
      },
    },
    md: {
      field: {
        fontSize: { base: "14px", lg: "15px" }, // body
        h: buttonH,
        // p: "15px 12px",
        borderRadius: "3px",
      },
    },
    lg: {
      field: {
        h: buttonH,
        // p: "15px 12px",
        pb: "2px",
        borderRadius: "3px",
        fontSize: { base: "14px", lg: "15px" }, // body
      },
    },
  },
  variants: {
    default: {
      field: {
        // bg: "thiel.200",
        // borderRadius: "3px",
        // borderColor: "thiel.600",
        // _placeholder: { color: "transparent" },
      },
    },
    // use with size="lg"
    // there is also a matching button "setRow" variant
    setRow: {
      field: {
        pos: "relative",
        w: "full",
        px: "2px",
        borderRadius: 0,
        borderBottom: "1px",
        borderBottomColor: "grey.100",
        // these don't work? https://chakra-ui.com/docs/form/input#props
        // focusBorderColor: "form.bad",
        // errorBorderColor: "form.bad",
        color: "text.800",
        _placeholder: {
          color: "grey.400",
        },
        _focus: {
          borderBottomColor: "text.800",
        },
        _active: {
          // outline: "2px solid #204f6b",
          // outlineOffset: "2px",
          // outline: "2px solid #204f6b",
          // background: "rgb(0 0 0 / 5%)",
          // transform: "scale(1)",
          // borderColor: "transparent",
          borderBottomcolor: "text.800",
        },
      },
    },
    clubhouse: {
      field: {
        // bg: "powderblue.100",
        border: "1px solid",
        borderColor: "grey.200",
        _placeholder: {
          color: "text.300",
        },
        _hover: {
          borderColor: "text.300",
        },
        _focus: {
          // bg: "white",
          borderColor: "text.600",
          // boxShadow: "#FFC880 0 0 0 3px",
        },
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "default",
  },
};

export default Input;

import { useHistory } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import { LayoutClubhouse } from "components/Layout";
import { TableHeader } from "components/ClubTable";
import { AccountForm } from "components/AccountForm";
import { UserProvider } from "components/Context";
// import bg from "assets/images/local-hero-portrait-211009-2.jpg";

export const AccountPage = () => {
  const history = useHistory();
  const { onClose } = useDisclosure();

  function handleClose() {
    onClose();
    history.push("/clubhouse");
  }

  return (
    <UserProvider>
      <LayoutClubhouse>
        <TableHeader heading="Account" />

        {/* prettify the Account form */}
        {/* <Box bg="text.800" pos="relative">
          <CardImage variant="header" flatBottom id={0}>
            {""}
          </CardImage>
        </Box> */}

        {/* Account form … mt={-12} */}
        <AccountForm
          // buttonLabel="Save"
          handleClose={handleClose}
          buttonWrapperProps={{
            mt: "w5",
            textAlign: "right",
          }}
        />
      </LayoutClubhouse>
    </UserProvider>
  );
};

import { Flex, FlexProps } from "@chakra-ui/react";
// import { buttonH } from "theme/components/button";

/* Uses same buttonH at lg, but not at base */

interface FormlabelProps extends FlexProps {
  translateY?: string;
}

export const FormLabel = ({
  translateY = "0.8em",
  children,
}: FormlabelProps) => (
  <Flex
    alignItems="flex-start"
    color="text.400"
    fontWeight="400"
    // h={height}
    justifyContent={{ base: "flex-start", lg: "flex-end" }}
    pl={{ base: "1px", lg: 0 }}
    textStyle="bodyLabel"
    transform={{ lg: `translateY(${translateY})` }}
    w="full"
  >
    {children}
  </Flex>
);

import React from "react";
import { useDispatch } from "react-redux";
import { Box } from "@chakra-ui/react";
import {
  ScoresSummary,
  ScoreMessageNoScore,
  ScoreMessageMismatching,
} from "../Score";
import { ScoreMeta } from "components/ScoreInput";
import { ScorecardHeader } from "components/Scorecard";
import { ConfigRowPickerScore } from "../Config";
import DefaultVStack from "../VStack";
import { NO_SCORE } from "config/constants";
import * as matchActions from "store/actions/match";
// import { setPlayerTabIndex } from "lib/score";
import * as api from "lib/api";
import { useScorecardContext, useHoleStatsContext } from "components/Context";
import { EMatchType } from "types";
import { IScorecardRowModalCard } from "./ScorecardRowModal";

/*
  Used exclusively in ScorecardRowModal.
  Almost the same as ScoreInputPresenter,
  which is used during the round scoring flow.
  Uses ScoresSummary.
*/

interface IScorecardRowCard extends IScorecardRowModalCard {
  holeNumber: number;
  matchType: EMatchType;
  onClose: () => void;
}

export const ScorecardRowCard = ({
  holeNumber,
  matchType,
  onClose,
  playerRoundSigned,
  markerRoundSigned,
  isMarker,
  mismatchingScore,
}: IScorecardRowCard) => {
  const dispatch = useDispatch();
  // Context
  const { variant, isSocial, match, userMatchSettings } = useScorecardContext();
  const {
    markerScores,
    markerTeeStats,
    playerScores,
    playerTeeStats,
  } = useHoleStatsContext();

  // Interactive state
  // Use a custom tracked index so we can update the value & set a default
  // Default depends on isMarker
  const [tabIndex, setTabIndex] = React.useState(isMarker ? 0 : 1);
  const [isEditing, setIsEditing] = React.useState(false);

  // Set the account player's tabIndex so we can follow it thru data assignments
  // This index is toggled by isSocial:
  // ie. if it's a social match, there's only one player
  // 22-02-25: I think setPlayerTabIndex was setting this incorrectly.
  // const playerTab = isSocial ? setTabIndex(0) : setTabIndex(1);
  // const playerTab = setPlayerTabIndex(isSocial, tabIndex);
  // const setPlayerTabIndex = (isSocial, tabIndex) =>
  //   isSocial ? tabIndex === 0 : tabIndex === 1;
  const playerTab = isSocial ? true : false;

  // use playerTab to toggle holeStats per active tab
  const teeStats = playerTab ? playerTeeStats : markerTeeStats;
  const par = playerTab ? playerScores.par : markerScores?.par;
  const noScore = playerTab ? playerScores.noScore : markerScores?.noScore;

  // TESTS
  // console.log("playerTeeStats", playerTeeStats);
  // console.log("playerTab", playerTab);
  // console.log("teeStats", teeStats);

  function handleEditClick({ editingMarker }) {
    setIsEditing({ marker: editingMarker });
  }

  async function handleSelect(strokes) {
    const matchId = match.id;
    await api.setScore({
      matchId,
      ...(isEditing.marker && { userId: userMatchSettings.marker?.id }),
      holeNumber,
      strokes,
      noScore: strokes === NO_SCORE,
    });

    await dispatch(matchActions.getMatch(matchId, { force: true }));
    onClose();
  }

  return (
    <>
      <ScorecardHeader heading={`Hole ${holeNumber}`} variant={variant}>
        <ScoreMeta
          distance={teeStats.distance}
          par={par}
          strokeIndex={teeStats.strokeIndex}
        />
      </ScorecardHeader>

      {isEditing ? (
        <Box my={8} h="100%">
          <ConfigRowPickerScore
            isInModal
            matchType={matchType}
            valueGroups={{
              unit: isEditing.marker
                ? markerScores?.strokes.toString()
                : playerScores.strokes.toString(),
            }}
            onSelect={handleSelect}
            btnText="Submit"
          />
        </Box>
      ) : (
        <ScoresSummary
          isForScorecardPage
          tabIndex={tabIndex}
          playerRoundSigned={playerRoundSigned}
          markerRoundSigned={markerRoundSigned}
          onEditClick={handleEditClick}
          onTabClick={(index) => setTabIndex(index)}
          mismatchingScore={mismatchingScore}
          playerMessage={noScore && <ScoreMessageNoScore />}
          // only markers verify mismatching scores
          // so only markers are shown mismatching scores
          markerMessage={
            <DefaultVStack>
              {noScore && <ScoreMessageNoScore />}
              {mismatchingScore && (
                <ScoreMessageMismatching isForScorecardPage />
              )}
            </DefaultVStack>
          }
        />
      )}
    </>
  );
};

import React from "react";
import { useSelector } from "react-redux";
import { Text, Button, Box, Flex, Spinner, ScaleFade } from "@chakra-ui/react";
import { LobbyUserSettings } from "./index";
import useRoundSettings from "hooks/useRoundSettings";
import * as matchActions from "store/actions/match";
import * as nameLib from "lib/name";

function withLobbyUsers(Component) {
  return (props) => {
    const users = useSelector((state) => state.lobby.users);
    // Don't render until lobby users are fetched
    if (!(users && users.length)) return null;
    return <Component {...props} />;
  };
}

const LobbyInvited = (props) => {
  const user = useSelector((state) => state.authentication.user);
  const match = useSelector(matchActions.selectors.getMatch);
  const users = useSelector((state) => state.lobby.users);
  const userMatchSettings = useSelector(
    matchActions.selectors.getUserMatchSettings
  );

  const {
    userSettings,
    setUserSetting,
    areUserSettingsComplete,
    areLatestSettingsReadied,
    readyUser,
  } = useRoundSettings(match, {
    ready: users.find((u) => user.id === u.id)?.ready,
  });

  function handleSubmit() {
    readyUser();
  }

  if (!match) return null;

  const userFirstName = nameLib.getFirstName(user.fullName);
  const hostFirstName = nameLib.getFirstName(match.host.fullName);

  return (
    <>
      <Flex direction="column" h="full" flex="1">
        <Box flex="1" px={6}>
          {/**
           * This screen shows a host's invitation to a player.
           * The message contains details of the round for the player,
           * a kind of acceptance terms. It's a message, not a set of
           * Row values, b/c it's not part of the invited player's workflow.
           **/}

          {/* intro message: define the context of the required user actions */}
          <Text
            textStyle="copy"
            color="text.800"
            // pb={2}
            mb={2}
            // borderBottom="1px"
            // borderColor="primary.100"
          >
            <Box as="span" fontWeight="600">
              Hey {userFirstName},
            </Box>{" "}
            you've been invited to play with {match.host.fullName} at{" "}
            {match.course.name}. Check your handicap and click accept. Then{" "}
            {hostFirstName} can start the round.
          </Text>

          {/* show invited user's accepted round settings */}
          <LobbyUserSettings
            invitedUser
            settings={userSettings}
            marker={userMatchSettings.marker}
            match={match}
            onChange={setUserSetting}
            isFormComplete={areUserSettingsComplete && true}
          />
        </Box>

        <Box px={6} pt={8} layerStyle="containerScoreBtn" mt="auto">
          {/*
            AWAITING MESSAGE: once accepted, let the user know
            they are then waiting for the host to start the round
          */}
          <ScaleFade initialScale={0.9} in={areLatestSettingsReadied}>
            <Box>
              <Text as="div" textStyle="meta" color="form.good" pb={4}>
                <Spinner size="xs" speed="0.7s" />
                <Box as="span" ml={1} fontWeight="600">
                  Accepted!
                </Box>{" "}
                You'll be redirected to the first hole when all players have
                accepted and your host starts the round.
              </Text>
            </Box>
          </ScaleFade>
          <Button
            variant="solid"
            colorScheme="textButton"
            w="full"
            isDisabled={areLatestSettingsReadied}
            onClick={handleSubmit}
          >
            Accept
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default withLobbyUsers(LobbyInvited);

import { useCallback } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import * as inviteActions from "../store/actions/invites";

export default function useInvite(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAcceptInvite = useCallback(
    async (inviteId, matchId) => {
      const res = await dispatch(inviteActions.acceptInvite(inviteId));
      if (res.error) return;
      history.push(`/lobby-invitation?id=${matchId}`);
    },
    [dispatch, history]
  );

  const handleDenyInvite = useCallback(
    async (invite) => {
      await dispatch(inviteActions.denyInvite(invite.id));
    },
    [dispatch]
  );

  return {
    handleAcceptInvite,
    handleDenyInvite,
  };
}

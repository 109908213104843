import { Avatar, AvatarProps } from "@chakra-ui/react";
import { GrUser } from "react-icons/gr";

export const AvatarIcon = ({ name, src, ...props }: AvatarProps) => (
  <Avatar
    size="sm"
    name={name}
    src={src}
    bg="grey.100"
    // border="1px solid white"
    // mt="-1px"
    // default avatar customisation
    // https://chakra-ui.com/docs/media-and-icons/avatar#customize-the-fallback-avatar
    icon={<GrUser fontSize="1.1em" />}
    {...props}
  />
);

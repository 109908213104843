import * as React from "react";
import { IHoleStatsContext } from "types";

/*
  This Context component is built to accept a match object
  in the Score/Score component
 */

const HoleStatsContext = React.createContext<IHoleStatsContext | undefined>(
  undefined
);

interface IHoleStatsProvider {
  children: React.ReactNode;
  value: IHoleStatsContext;
}

function HoleStatsProvider({ children, value }: IHoleStatsProvider) {
  return (
    <HoleStatsContext.Provider value={value}>
      {children}
    </HoleStatsContext.Provider>
  );
}

function useHoleStatsContext() {
  const context = React.useContext(HoleStatsContext);
  if (context === undefined) {
    throw new Error(
      "useHoleStatsContext must be used within a HoleStatsProvider"
    );
  }
  return context;
}

export { HoleStatsProvider, useHoleStatsContext };

import React from "react";
import { Box, Input, Spinner } from "@chakra-ui/react";
import { LgImageHeader } from "../Block";
import { ConfigRowDrawer } from "../Config";
import { LgCardFull } from "../Block";
import { useSearch } from "hooks/useSearch";
import { EConfigRowTheme } from "types";
import config from "config";

/*

  This component handles search for both players and courses
  during the Scoring app flow

*/

export const RESULTS_LIMIT = 20;

interface IItem {
  name: string;
  value: string;
  isCourse: boolean;
  // TODO
  image: any;
  cardItems: any;
}

export const SearchScoring = ({
  kind = "course",
  getItems,
  onSelect,
  placeholder,
  drawerHeading,
  noResultsMessage,
}: {
  kind?: "player" | "course";
  getItems: (query: string) => void;
  onSelect: (e: any) => void;
  placeholder: string;
  drawerHeading: string;
  noResultsMessage: React.ReactNode;
}) => {
  const { isLoading, hasNoResults, setSearch, items } = useSearch({ getItems });

  // Set either course or player avatar
  // * match a searched-for player/course with their store Account
  //   to display either their avatar or course image
  // * player avatars should also use Cloudinary for performance (set upstream)
  function setAvatarSrc(kind, item) {
    if (kind === "course") {
      return item.image ? item.image.thumbnails.small.url : undefined;
    }
    return item.avatar ? item.avatar : undefined;
  }

  function handleSelect(item: IItem, disclosure) {
    // hand back the selected item to the parent
    onSelect(item);
    disclosure.onClose();
  }

  return (
    <>
      <Input
        size="lg"
        variant="setRow"
        placeholder={placeholder}
        onChange={setSearch}
      />

      {hasNoResults && noResultsMessage}

      <Box pt={isLoading ? "w4" : 1} pb={48}>
        {isLoading ? (
          <Spinner color="text.800" />
        ) : (
          <>
            {items.map((item: IItem, index: number) => {
              console.log(item);

              return (
                <ConfigRowDrawer
                  key={index}
                  avatarSrc={setAvatarSrc(kind, item)}
                  btnName={item.name}
                  btnValue={item.value}
                  btnTheme={EConfigRowTheme.avatar}
                  btnTruncateValue
                  btnBackLabel="Back"
                  fullBody
                  heading={drawerHeading}
                >
                  {(disclosure) => (
                    <LgCardFull
                      kind={kind}
                      item={item}
                      heading={item.value}
                      avatarSrc={setAvatarSrc(kind, item)}
                      lineItems={item.cardItems}
                      buttonLabel="Choose"
                      headerImageNode={
                        <LgImageHeader
                          src={
                            item.image
                              ? item.image.thumbnails.large.url
                              : config.imgCourseWithFlag
                          }
                          fallbackSrc={
                            item.image
                              ? item.image.thumbnails.small.url
                              : config.imgCourseWithFlagFallback
                          }
                          useShade
                          shade="blackAlpha.300"
                        />
                      }
                      onConfirm={() => handleSelect(item, disclosure)}
                    />
                  )}
                </ConfigRowDrawer>
              );
            })}
          </>
        )}
      </Box>
    </>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  Text,
  Icon,
  HStack,
  StackDivider,
  Divider,
} from "@chakra-ui/react";
import { MdPeople, MdGolfCourse } from "react-icons/md";
import { LgHeader, LgImageHeader } from "components/Block";
import DefaultVStack from "components/VStack";
import { MdArrowBack } from "react-icons/md";
// import { LobbyNotice } from "../Lobby/LobbyNotice";

export const HomeLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <LgImageHeader
        useShade
        useBlend
        useHomePosition
        shade="rgb(10 54 56 / 30%)"
      />

      {/* header */}
      <LgHeader isTransparent isMasthead />

      {/* home content */}
      <Flex direction="column" flex="1" pos="relative">
        {/* <Box flex="1">
          <LobbyNotice />
        </Box> */}

        {/* CLUBHOUSE LINK on DESKTOP */}
        <Box
          layerStyle="containerScoreBtn"
          pt={0}
          d={{ base: "none", lg: "block" }}
        >
          <Box py={10} w="full">
            <Divider opacity={1} borderColor="grey.100" />
          </Box>
          <DefaultVStack spacing="1.5">
            <Text textStyle="crumb" textAlign="center">
              Smartphone only
            </Text>
            <Text textAlign="center">
              Play and score rounds against other players
            </Text>
            <Text color="white" textStyle="crumb" textAlign="center" pt={4}>
              Your current device
            </Text>
            <Text color="white" textAlign="center" pt={2}>
              <Link to="/clubhouse">
                <Button
                  variant="outline"
                  colorScheme="whites"
                  leftIcon={<Icon as={MdArrowBack} mt="-1px" />}
                >
                  Go to the Clubhouse
                </Button>
              </Link>
            </Text>
          </DefaultVStack>
        </Box>

        {/* SCORING on MOBILE */}
        <Box layerStyle="containerScoreBtn" mt="auto" d={{ lg: "none" }}>
          <DefaultVStack spacing={3}>
            <Link to="/create-lobby?play-type=competitive">
              <Button w="full" variant="outline" colorScheme="whites">
                Match Round
              </Button>
            </Link>
            <Link to="/create-lobby?play-type=social">
              <Button w="full" variant="outline" colorScheme="whites">
                Practice Round
              </Button>
            </Link>
            <HStack
              divider={<StackDivider borderColor="whiteAlpha.500" />}
              sx={{
                "> a": {
                  w: "full",
                },
              }}
            >
              <Link to="/clubhouse">
                <Button
                  w="full"
                  variant="ghost"
                  colorScheme="whites"
                  leftIcon={<MdGolfCourse />}
                >
                  Daily Results
                </Button>
              </Link>
              <Link to="/clubhouse/competitions">
                <Button
                  w="full"
                  variant="ghost"
                  colorScheme="whites"
                  leftIcon={<MdPeople />}
                >
                  Competitions
                </Button>
              </Link>
            </HStack>
          </DefaultVStack>
        </Box>

        {/* use for notices, etc */}
        {children}
      </Flex>
    </>
  );
};

import { LgHeader } from "components/Block";
import { CreateLobby } from "components/Lobby";
import { BlockContainerScore } from "components/Block2";
import { UserProvider } from "components/Context/User";

const CreateLobbyPage = () => {
  return (
    <BlockContainerScore>
      <LgHeader heading="Round settings" />
      <UserProvider>
        <CreateLobby />
      </UserProvider>
    </BlockContainerScore>
  );
};

export default CreateLobbyPage;

import { Flex, FlexProps } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

export const NavbarShell = ({ children }: FlexProps) => {
  const location = useLocation();
  const isScorecard = location.pathname.includes("scorecard");

  return (
    <Flex
      className="NavbarShell t-safe"
      as="nav"
      align="center"
      bg={{ base: "transparent", lg: "neutral.200" }}
      justify="space-between"
      layerStyle="containerNav"
      // pos={isScorecard ? "relative" : { base: "fixed", lg: "relative" }}
      pos={{ base: "fixed", lg: "relative" }}
      w="full"
      zIndex={2}
    >
      {children}
    </Flex>
  );
};

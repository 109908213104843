import {
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import { LgModalCloseButton } from "components/Block";

export const ScorecardModal = ({ isOpen, onClose, children }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      isCentered
      motionPreset="scale"
      scrollBehavior="inside"
      closeOnOverlayClick
    >
      <ModalOverlay bg="rgb(0 0 0 / 40%)" />
      <ModalContent
        // alignItems="center"
        borderRadius="lg"
        pos="relative"
        maxW={{ base: "calc(100% - 3rem)", lg: "container.md" }}
        minHeight={{ base: "calc(100% - 3rem)", lg: "calc(100vh - 16vw)" }}
        // height="full"
        // minHeight="full"
        // mx={{ base: "inset", lg: "5vw" }}
        // my={{ base: "inset", lg: "8vw" }}
        m={0}
        overflow="hidden"
        shadow="none"
      >
        <LgModalCloseButton />
        {/* <BlockLoading /> */}
        <ModalBody pos="relative" p={0} w="full">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import { ScorePosterEclectic } from "components/Score";
import {
  ScorecardRowEclectic,
  ScorecardRowHeader,
} from "components/ScorecardRow";
import {
  EMatchType,
  ICourse,
  IScoresBasic,
  IEclecticRoundTotalsBasic,
} from "types";

// Eclectic rounds are always Stableford
const matchType = EMatchType.stableford;

type Props = {
  course: ICourse;
  scores: IScoresBasic;
  scoreTotals: IEclecticRoundTotalsBasic;
};

export const ScorecardEclecticPresenterPanel = ({
  course,
  scores,
  scoreTotals,
}: Props) => {
  console.log("ScorecardEclecticPresenterPanel scoreTotals", scoreTotals);

  return (
    <>
      {/* POSTER with TABS: player's score as stableford & par/stroke(?) */}
      <ScorePosterEclectic score={scoreTotals} />

      {/* OUT */}
      <ScorecardRowHeader
        isOut
        kind="header"
        matchType={matchType}
        scoreLabel="STFD"
        theme="sub"
        variantIsEclectic
      />
      {course?.holes.slice(0, 9).map((hole, index) => (
        <ScorecardRowEclectic
          key={`out-${index}-${hole.number}`}
          hole={hole}
          scores={scores}
        />
      ))}
      <ScorecardRowHeader
        isOut
        kind="footer"
        matchType={matchType}
        theme="meta"
        totalStrokes={scoreTotals.firstNineScoreStroke}
        totalScore={scoreTotals.firstNineScoreStableford.toString()}
        variantIsEclectic
      />

      {/* IN */}
      <ScorecardRowHeader
        kind="header"
        matchType={matchType}
        scoreLabel="STFD"
        theme="sub"
        variantIsEclectic
      />
      {course?.holes.slice(9, 18).map((hole, index) => (
        <ScorecardRowEclectic
          key={`in-${index}-${hole.number}`}
          hole={hole}
          scores={scores}
        />
      ))}

      {/* TOTALS */}
      {/* IN */}
      <ScorecardRowHeader
        kind="footer"
        matchType={matchType}
        theme="meta"
        totalStrokes={scoreTotals.lastNineScoreStroke}
        totalScore={scoreTotals.lastNineScoreStableford.toString()}
        variantIsEclectic
      />
      {/* OUT: repeat the `out` score */}
      <ScorecardRowHeader
        isOut
        kind="footer"
        matchType={matchType}
        theme="meta"
        totalStrokes={scoreTotals.firstNineScoreStroke}
        totalScore={scoreTotals.firstNineScoreStableford.toString()}
        variantIsEclectic
      />
      {/* Total Score */}
      <ScorecardRowHeader
        kind="header"
        theme="primary"
        matchType={matchType}
        isTotal
        headerLabel="Your score"
        totalStrokes={scoreTotals.totalScoreStroke}
        // TODO: totalScoreStableford is always out by 1?
        totalScore={scoreTotals.totalScoreStableford.toString()}
      />
    </>
  );
};

// see consts
// export const PLAY_TYPES = enu(["COMPETITION", "SOCIAL"]);
// export const TEES = enu(["PRO", "CHAMPIONSHIP", "STANDARD"]);
// export const MATCH_TYPES = enu(["STROKE", "STABLEFORD", "PAR"]);
// export const COMPETITION_TYPES = enu(["ECLECTIC", "RACE_TO_THE_TOP"]);

export enum EPlayType {
  comp = "COMPETITION",
  social = "SOCIAL",
}

export type TPlayType = "COMPETITION" | "SOCIAL";

export enum ETee {
  championship = "CHAMPIONSHIP",
  pro = "PRO",
  standard = "STANDARD",
}

export type TTee = "STANDARD" | "CHAMPIONSHIP" | "PRO";

export interface IHoleDetail {
  distance: number;
  index: number;
}

export interface ICourseLevel {
  championship?: IHoleDetail;
  ladiesStandard: IHoleDetail;
  ladiesChampionship?: IHoleDetail;
  pro?: IHoleDetail;
  standard: IHoleDetail;
}

export interface ICourseHole extends ICourseLevel {
  course: string[]; // Airtable ID
  id: string;
  number: number;
  par: {
    mens: number;
    ladies: number;
  };
}

interface Thumbnail {
  height: number;
  url: string;
  width: number;
}

export interface ICourse {
  id: number;
  clubName: string;
  courseName?: string | null;
  isCourse?: boolean;
  golflinkCourseId: string;
  handicapModifier: number;
  state: string;
  name: string;
  value?: string; // same as clubName
  holes: ICourseHole[];
  tees: string[];

  // airtable stuff
  image?: {
    src: string | null;
    thumbnails: {
      full: Thumbnail;
      large: Thumbnail;
      small: Thumbnail;
    };
  };

  // OTHER
  // cardItems: {
  //   address: string;
  //   holes: number;
  // }
  // slopeRatings
  // scratchRatings
  // parRatings
}

/* an abbreviated object for use on Scorecards so we don't have to pass all course data exactly */
export interface ICourseAbbr {
  id: number;
  clubName: string;
  courseName?: string | null;
  isCourse?: boolean;
  golflinkCourseId: string;
  handicapModifier: number;
  state: string;
  name: string;
  value?: string; // same as clubName
  tees: string[];
  holes: {
    id?: string;
    course: string[]; // Airtable ID
    number: number;
    par: {
      mens: number;
      ladies: number;
    };
  };
}

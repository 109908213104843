import {
  Tab,
  useTab,
  UseTabProps,
  forwardRef,
  // VisuallyHidden,
} from "@chakra-ui/react";

// use VisuallyHidden instead of the `sr` class?
// https://chakra-ui.com/docs/components/visually-hidden
export const ScoreTabSimple = forwardRef((props: UseTabProps, ref) => {
  const tabProps = useTab(props);
  // const isSelected = !!tabProps["aria-selected"];

  // if (!isSelected) return <VisuallyHidden>{tabProps.children}</VisuallyHidden>;

  return (
    <Tab
      {...tabProps}
      bg="transparent"
      flexDirection="column"
      justifyContent="center"
      alignItems="baseline"
      // className={!isSelected ? "sr" : undefined}
    >
      {tabProps.children}
    </Tab>
  );
});

import { Link } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { ScorecardRowForEclectic } from "./index";
import { Date } from "components/ClubTable/RowElements";
import { useUserContext } from "components/Context";
import {
  ICourseHole,
  EGender,
  EMatchType,
  EScorecardVariant,
  IScoresBasic,
} from "types";

/*
  Only used on ScorecardEclecticPresenterPanel
  * Eclectic rounds are ALWAYS STABLEFORD
  * uses ScorecardRowForEclectic
  * Uses ScorecardRowCard which is
    roughly the same as ScoreInputPresenter
*/

export const ScorecardRowEclectic = ({
  hole,
  scores,
}: {
  hole: ICourseHole;
  scores: IScoresBasic;
}) => {
  const user = useUserContext();

  // Map data
  // Eclectic rounds has a different shape to Scorecard's HoleStatsProvider
  const userGender = user.gender;
  const userPar = userGender === EGender.male ? hole.par.mens : hole.par.ladies;
  const teeDistance =
    userGender === EGender.male
      ? hole.standard.distance
      : hole.ladiesStandard.distance;
  const teeIndex =
    userGender === EGender.male
      ? hole.standard.index
      : hole.ladiesStandard.index;
  const holeNumber = hole.number - 1;
  const holeScores = {
    noScore: scores[holeNumber].noScore,
    scoreStableford: scores[holeNumber].scoreStableford,
    scoreStroke: scores[holeNumber].scoreStroke,
  };
  // console.log("holeScores", holeScores);

  return (
    <Link
      to={`/${scores[holeNumber].matchId}/scorecard`}
      as={ReactRouterLink}
      target="_blank"
      rel="noopener noreferrer"
      _hover={{
        textDecoration: "none",
      }}
    >
      <ScorecardRowForEclectic
        distance={teeDistance}
        holeNumber={hole.number}
        matchType={EMatchType.stableford}
        par={userPar}
        strokeIndex={teeIndex}
        scores={holeScores}
        variant={EScorecardVariant.eclectic}
        leftSlot={<Date date={scores[holeNumber].createdAt} />}
      />
    </Link>
  );
};

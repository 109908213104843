import {
  Flex,
  Switch,
  HStack,
  FormControl,
  FormLabel,
  useMediaQuery,
} from "@chakra-ui/react";
import { ScoreLegendPopover } from "./index";

export const preheaderHeight = { base: "40px", lg: "54px" };

export const PreHeader = ({
  leftContent,
  onSwitch,
}: {
  leftContent?: React.ReactNode;
  onSwitch: () => void;
}) => {
  const [breakpoint] = useMediaQuery("(min-width: 1280px)");

  return (
    <Flex
      alignItems="center"
      borderBottom="1px solid"
      borderColor="grey.100"
      color="text.600"
      className="PreHeader"
      direction={{ base: "column", lg: "row" }}
      justifyContent="space-between"
      px={1}
    >
      {/* L */}
      {leftContent}

      {/* R */}
      <HStack
        borderTop="1px solid"
        borderColor={{ base: "grey.100", lg: "initial" }}
        h={preheaderHeight}
        justifyContent="flex-end"
        spacing={3}
        width="100%"
      >
        {breakpoint && <ScoreLegendPopover />}

        {/* Total switch */}
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          w="auto"
        >
          <FormLabel
            htmlFor="show-gross"
            textStyle="crumb"
            // FormLabel doesn't let textStyle mange fontSize, WTF?
            // Chakra is very undocumented / badly designed
            fontSize={{ base: "9px", lg: "11px" }}
            color="text.600"
            fontWeight="500"
            mb="0"
            ml={0}
          >
            Show Gross
          </FormLabel>
          {/* pass switch state back up to Competition > TableRowComp */}
          <Switch
            id="show-gross"
            colorScheme="orange"
            onChange={onSwitch}
            defaultChecked
            sx={{
              outline: "none",
              boxShadow: "none",
            }}
          />
        </FormControl>
      </HStack>
    </Flex>
  );
};

export function forShow(value) {
  if (typeof value === "number") value = value.toString();
  if (value.startsWith("-")) {
    return `+${value.substr(1)}`;
  }
  return value;
}

export function toRealValue(value) {
  if (typeof value === "number") value = value.toString();
  if (value.startsWith("+")) {
    return `-${value.substr(1)}`;
  }
  return value;
}

import React from "react";
import { useHistory } from "react-router";
import { BlockLoading } from "../Block2";
import { CreateLobbyPresenter } from "./index";
import { useUserContext } from "components/Context/User";
import useFormData from "hooks/useFormData";
import useQuery from "hooks/useQuery";
import config from "config";
import * as api from "lib/api";
import * as formatData from "lib/formatData";
import { EPlayType, EMatchType } from "types";

const matchTypes = [
  {
    value: EMatchType.stroke,
  },
  {
    value: EMatchType.stableford,
  },
  {
    value: EMatchType.par,
  },
];

export const CreateLobby = () => {
  const user = useUserContext();
  const history = useHistory();
  const query = useQuery();
  const playType =
    query.get("play-type") === "social" ? EPlayType.social : EPlayType.comp;

  // interactive state
  const [isPageReady, setIsPageReady] = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);
  // Why not set `user.homeCourse` as the default here? Because it's an abbreviated course object. We need the full course object.
  const [selectedCourse, setSelectedCourse] = React.useState(undefined);

  // We're not using Formik here… and that's OK for now…
  const [formData, setFormOption, areAllFieldsSet] = useFormData({
    course: undefined,
    playType,
    matchType: undefined,
    tee: undefined,
  });

  // Form validation
  React.useEffect(() => {
    // SOCIAL settings expect tee to have defaults
    if (formData?.playType === EPlayType.social) {
      setIsFormValid(!!formData.tee);
      return;
    }
    setIsFormValid(areAllFieldsSet);
  }, [formData.playType, formData.tee, areAllFieldsSet]);

  // Set the initial course to the users home course
  React.useEffect(() => {
    async function getUsersCourse() {
      const response = await api.getCourse(user.homeCourse?.id);

      if (response.error) {
        console.error("Error fetching users home course:", response.error);
        return;
      }

      const { course } = response.json;
      setFormOption("course", formatData.formatCourseForRowComponents(course));
      setSelectedCourse(course);
      setIsPageReady(true);
    }

    getUsersCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.homeCourse]);

  // getCourse
  React.useEffect(() => {
    async function getCourse() {
      // Only run after page has loaded initial course
      if (!isPageReady) return;
      const response = await api.getCourse(formData.course?.id);
      setSelectedCourse(response.json.course);
    }

    getCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.course]);

  // handleSubmit
  async function handleSubmit() {
    // if playtype isSocial, then matchType is stableford
    const matchType =
      playType === EPlayType.social
        ? EMatchType.stableford
        : formData.matchType;
    const response = await api.createMatch({
      tee: formData.tee,
      course: formData.course?.id,
      playType,
      matchType,
    });

    if (response.error) {
      // TODO: Show error message
      console.error("Error creating match:", response.error);
      return;
    }

    const { match } = response.json;
    localStorage.setItem(config.localStorageMatchKey, match.id);
    history.push(`/lobby?id=${match.id}`);
  }

  if (!isPageReady) return <BlockLoading pt="w4" />;

  return (
    <CreateLobbyPresenter
      formData={formData}
      playType={playType}
      matchTypes={matchTypes}
      selectedCourse={selectedCourse}
      setFormOption={setFormOption}
      isFormValid={isFormValid}
      handleSubmit={handleSubmit}
    />
  );
};

import { Box, BoxProps, Flex } from "@chakra-ui/react";

interface IScoreSheetRowItem extends BoxProps {
  align?: "left" | "right" | "center";
  isHeading?: boolean;
  isBold?: boolean;
  onClick?: () => void;
  boxComponents?: React.ReactNode;
  boxProps?: any;
  childBoxProps?: any;
}

/* A cell item within a ScoreSheetRow */
const ScoreSheetRowItem = ({
  align = "right",
  isHeading,
  isBold,
  onClick,
  boxComponents,
  boxProps,
  childBoxProps,
  children,
}: IScoreSheetRowItem) => {
  // const colorRed = isNoScore ? "red" : isBold;
  // const colorBold = isBold ? "text.600" : "primary.400";
  // color={isNoScore ? colorRed : colorBold}

  return (
    <Flex
      className="ScoreSheetRowItem"
      as={isHeading ? "div" : "button"}
      pos="relative"
      // textStyle=meta
      fontSize={{ base: "11px", lg: "14px" }}
      fontWeight={isBold ? "500" : "400"}
      color={isBold ? "text.800" : "text.400"}
      pb={isBold ? 0 : "2px"}
      pt={isBold ? 0 : "1px"}
      h={{ base: "20px", lg: "28px" }}
      w={{ base: "20px", lg: "28px" }}
      _focus={{ outline: "none" }}
      {...boxProps}
      onClick={onClick}
      // align if it has boxComponents
      transform={boxComponents !== undefined ? "translateX(6px)" : undefined}
    >
      {boxComponents}
      <Box
        className="ScoreSheetRowItem-Child"
        pos="absolute"
        inset={0}
        mt={{ base: "1px", lg: "2px" }}
        textAlign={align}
        {...childBoxProps}
      >
        {children}
      </Box>
    </Flex>
  );
};

export default ScoreSheetRowItem;

import React from "react";
import { Icon, Button } from "@chakra-ui/react";
import { ConfigRow } from "../Config/ConfigRow";
import { MdHelp } from "react-icons/md";
import { IInvitedPlayer } from "types";
import { EConfigRowTheme } from "types";

export const NoMarkerIcon = () => (
  <Icon as={MdHelp} w="38px" h="38px" fill="grey.100" mr="2px" />
);

export interface IPlayerRow {
  children?: React.ReactNode;
  isHost?: boolean;
  isValid?: boolean;
  onKick: (user: any) => void;
  onSetMarker?: (key: any, value: any) => void;
  user: IInvitedPlayer;
}


// PlayerRow: customised ConfigRow
// if host, show remove button
export const PlayerRow = ({ children, isHost, isValid, onKick, user }: IPlayerRow) => {
  // const isValid = false;
  // let isValid;
  // if (isHost) isValid = true;
  // if (!isHost && user.ready) isValid = true;

  return (
    <ConfigRow
      key={user.id}
      // all PlayerRows contain set values, so all are `isSet`
      // this also allows use to set a child button for onKick
      isSet
      name={user.fullName}
      theme={isValid ? EConfigRowTheme.valid : EConfigRowTheme.awaiting}
      afterTextSlot={
        !isHost ? (
          <Button
            aria-label="Delete"
            size="sm"
            variant="crumb"
            color="grey.300"
            border="1px solid currentColor"
            transform="translateY(-1px)"
            ml={2}
            onClick={() => onKick(user.id)}
          >
            remove
          </Button>
        ) : undefined
      }
    >

      {children}
    </ConfigRow>
  );
};

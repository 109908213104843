import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Box, Button, useMediaQuery } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { TableHeaderStatus, BlockStack } from "components/Block2";
import { TableHeader } from "components/ClubTable";
import { Card } from "./index";
import * as competitionActions from "store/actions/competition";
import { ICompCardWithPlayers } from "types";

export const CompetitionsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const competitions = useSelector((state: any) => state.competitions);
  console.log("CompetitionsList data: ", competitions);

  useEffect(() => {
    dispatch(competitionActions.getCompetitionPreviews());
    setIsLoading(false);
  }, [dispatch]);

  const [breakpoint] = useMediaQuery("(min-width: 1024px)");

  if (isLoading) return <TableHeaderStatus tableHeading="Your Competitions" />;

  return (
    <Box as="section">
      <TableHeader
        heading="Your Competitions"
        rightContent={
          <Button
            size="sm"
            variant="outline"
            colorScheme="text"
            leftIcon={<AddIcon boxSize="0.8em" />}
            onClick={() => history.push("/clubhouse/new-competition")}
          >
            {breakpoint ? "New competition" : "New Comp"}
            {/* <Box as="span" d={{ lg: "none" }}>
              New comp
            </Box>
            <Box as="span" d={{ base: "none", lg: "inline" }}>
              New competition
            </Box> */}
          </Button>
        }
      />

      <BlockStack spacing="w3" pt="w3" pb={0}>
        {competitions.competitions?.map(
          (comp: ICompCardWithPlayers, index: number) => (
            <Card comp={comp} key={index.toString()} />
          )
        )}
      </BlockStack>
    </Box>
  );
};

/**
 * Lobby the user is currently in
 */

import {
  LOBBY_CLEAR,
  LOBBY_SET_USERS,
  LOBBY_INVITE_USER,
  LOBBY_DELETE_INVITE,
  LOBBY_SET_READY_STATE,
} from "../actions/lobby";

const initialState = {
  // Only used on lobby screens
  users: [],
  // Only for host on lobby screen
  invited: [],
};

// Use map instead of updating in place because users are from redux state
function setUsersReadyState(users, userId, ready) {
  return users.map((user) => {
    if (user.id === userId) {
      return {
        ...user,
        ready,
      };
    }

    return user;
  });
}

function lobby(state = initialState, action) {
  switch (action.type) {
    case LOBBY_CLEAR:
      return {
        ...initialState,
        users: [],
        invited: [],
      };
    case LOBBY_SET_USERS:
      return {
        ...state,
        users: action.users,
        invited: action.invited,
      };
    case LOBBY_INVITE_USER:
      return {
        ...state,
        invited: [...state.invited, action.user],
      };
    case LOBBY_DELETE_INVITE:
      return {
        ...state,
        invited: state.invited.filter((user) => user.id !== action.userId),
      };
    case LOBBY_SET_READY_STATE: {
      const updatedUsers = setUsersReadyState(
        state.users,
        action.userId,
        action.ready
      );
      return {
        ...state,
        users: updatedUsers,
      };
    }
    default:
      return state;
  }
}

export default lobby;

import { useParams } from "react-router";
import config from "../config";
import useQuery from "../hooks/useQuery";

export default function useGetMatchIdFromPath() {
  const query = useQuery();
  const params = useParams();
  // Until score page paths include the match id fallback to LocalStorage
  return (
    query.get("id") ||
    params.matchId ||
    localStorage.getItem(config.localStorageMatchKey)
  );
}

// theme.js
import colors from "./colors";
import typography from "./typography";
import breakpoints from "./breakpoints";
import { spacing } from "./spacing.ts";

const foundations = {
  colors,
  breakpoints,
  ...typography,
  space: spacing,
};

export default foundations;

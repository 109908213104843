import { useDisclosure } from "@chakra-ui/react";
import { RowLeft, Score } from "components/ClubTable/RowElements";
import {
  TableRowElement,
  TableRowGrid,
  CompetitionPlayer,
  ScorecardModal,
  STYLE_ScoreShellWidthDecimal,
} from "components";
import { IEclecticRound, ECompTotal, IUserMatchSettingsComp } from "types";

export function TableRowComp({
  index,
  round,
  sortBy,
  compName,
  compCourse,
}: {
  index: number;
  round: IEclecticRound;
  sortBy: string;
  compName: string;
  compCourse: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const filteredMatchSettings = round.userMatchSettings.filter(
    (element): element is IUserMatchSettingsComp => element !== null
  );

  return (
    <TableRowGrid
      d="flex"
      justifyContent="space-between"
      cursor="pointer"
      handleClick={() => onOpen()}
    >
      {/* L: NAME & SCORES */}
      <TableRowElement variant="comp" isLeft showShadow index={index}>
        <RowLeft
          player={round.player}
          handicap={filteredMatchSettings[0]?.dailyHandicap ?? 0}
          showSetWidth
          showPlayingHandicap
        />
      </TableRowElement>

      {/* R: TOTALS */}
      <TableRowElement
        variant="comp"
        index={index}
        showGradient
        justifyContent="flex-end"
      >
        {/* gross stroke, nett stroke */}
        <Score
          value={round.totalScoreStroke}
          isActive={sortBy === ECompTotal.STROKE_GROSS}
        />
        <Score
          value={round.totalScoreStrokeRank}
          isActive={sortBy === ECompTotal.RANK}
        />
        <Score
          value={round.totalScoreStrokeNett}
          isActive={sortBy === ECompTotal.STROKE_NETT}
        />
        <Score
          value={round.totalScoreStrokeNettRank}
          isActive={sortBy === ECompTotal.RANK}
        />

        {/* front nett stroke, back nett stroke */}
        <Score
          value={round.firstNineScoreStrokeNett}
          isActive={sortBy === ECompTotal.FIRST_NINE}
          scoreShellWidth={STYLE_ScoreShellWidthDecimal}
        />
        <Score
          value={round.firstNineScoreStrokeNettRank}
          isActive={sortBy === ECompTotal.RANK}
        />
        <Score
          value={round.lastNineScoreStrokeNett}
          isActive={sortBy === ECompTotal.LAST_NINE}
          scoreShellWidth={STYLE_ScoreShellWidthDecimal}
        />
        <Score
          value={round.lastNineScoreStrokeNettRank}
          isActive={sortBy === ECompTotal.RANK}
        />

        {/* total rounds */}
        <Score
          value={round.contributingRounds}
          isActive={sortBy === ECompTotal.ROUND_COUNT}
        />
      </TableRowElement>

      {/* PLAYER MODAL */}
      <ScorecardModal isOpen={isOpen} onClose={onClose}>
        <CompetitionPlayer
          heading={round.player.fullName || "Player's name"}
          // 230418 use 1 not 0 to avoid some deep bug, see email from callum@yourlocalgolf.com.au on 230308 "Q&A Scoring issues found on competition table and eclectic score card"
          handicap={filteredMatchSettings[0].dailyHandicap}
          round={round}
          index={index}
          compName={compName}
          compCourse={compCourse}
        />
      </ScorecardModal>
    </TableRowGrid>
  );
}

import { Link } from "react-router-dom";
import { Text, Icon } from "@chakra-ui/react";
import { LayoutClubhouse } from "components/Layout";
import { LeaderboardByDate, TableNotice } from "components/ClubTable";
import { SponsorInScore } from "components/Sponsor";
import { BlockStack } from "components/Block2";
import { useGetHistory, useGetUnverifiedRounds } from "lib/api";
import { UserProvider } from "components/Context";
import { useSponsorClubhouse } from "hooks";
import { MdWarning, MdArrowForward } from "react-icons/md";

export const ClubhouseYourHistory = () => {
  const { sponsor, defaultSponsor } = useSponsorClubhouse();
  const unverifiedRoundsRes = useGetUnverifiedRounds();
  const hasUnverifiedRounds = !!unverifiedRoundsRes.data?.unverifiedRounds
    ?.length;

  // TESTS
  // console.log(
  //   "unverifiedRoundsRes",
  //   unverifiedRoundsRes.data?.unverifiedRounds
  // );

  return (
    <UserProvider>
      <LayoutClubhouse
        sponsorSlot={<SponsorInScore sponsor={sponsor || defaultSponsor} />}
      >
        <BlockStack pb="w11">
          <LeaderboardByDate
            tableHeading="Your History"
            useGetRounds={useGetHistory}
            showRoundDate
            noRoundsMessage="You haven't played any rounds on this course."
            noticeSlot={
              hasUnverifiedRounds && (
                <TableNotice bg="form.bad" icon={MdWarning} my="3">
                  <Link to="/clubhouse/your-rounds-unsigned">
                    <Text as="span" fontWeight="500">
                      You have unsigned matches.{" "}
                    </Text>
                    <Text as="span" fontWeight="400">
                      Please verify them
                      <Icon as={MdArrowForward} />
                    </Text>
                  </Link>
                </TableNotice>
              )
            }
          />
        </BlockStack>
      </LayoutClubhouse>
    </UserProvider>
  );
};

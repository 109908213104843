import { useSelector } from "react-redux";
import * as courseLib from "lib/course";
import * as dateLib from "lib/date";
import { useScorecardContext } from "components/Context";
import { IPlayerBase, TTee, ICourseHole } from "types";

/*
  Only used in ScorecardRowModalHistorical!

  Unlike useHoleStats which returns for the current match, this hook returns the hole stats for a historical match. So we use context rather than the store.
*/

export default function useHoleStatsForHistoricalMatch(holeNumber: number) {
  const {
    isSocial,
    marker,
    markerMatchSettings,
    match,
    scores,
    userMatchSettings,
  } = useScorecardContext();

  const user: IPlayerBase = useSelector((state) => state.authentication.user);
  const tee: TTee = match.tee;

  // get this hole's scores
  const holeScores = scores[holeNumber];

  // hole is used as the key arguement in genderTeeStats
  const hole: ICourseHole = userMatchSettings.course.holes[holeNumber - 1];

  // filter scores by tee & gender for both user
  const genderTeeStats = courseLib.getTeeFromGender(user.gender, tee, hole);

  const playerTeeStats = {
    distance: genderTeeStats.distance,
    strokeIndex: genderTeeStats.index,
  };

  const playerScores = {
    par: courseLib.getPar(hole, tee, user.gender),
    strokes: holeScores?.user.strokes,
    score: holeScores?.user.score,
    noScore: holeScores?.user.noScore,
  };

  // TESTS
  // console.log("useHoleStatsForHistoricalMatch hole", hole);
  // console.log("scores", scores);
  // console.log("playerTeeStats", playerTeeStats);

  const markerHole = markerMatchSettings?.course.holes[holeNumber - 1];
  let markerTeeStats;
  let markerScores;

  if (!isSocial && marker) {
    const markerGender = marker.gender;
    const genderTeeStats = courseLib.getTeeFromGender(markerGender, tee, hole);

    markerTeeStats = {
      distance: genderTeeStats.distance,
      strokeIndex: genderTeeStats.index,
    };
    markerScores = {
      par: courseLib.getPar(markerHole, tee, markerGender),
      strokes: holeScores?.marker.strokes,
      score: holeScores?.marker.score,
      noScore: holeScores?.marker.noScore,
    };
  }

  return {
    markerScores,
    markerTeeStats,
    playerScores,
    playerTeeStats,
    time: dateLib.toTimeString(holeScores?.createdAt),
  };
}

import { Flex, Box, Button, Text, Avatar } from "@chakra-ui/react";
import { LgCardContent } from "./index";
import config from "config";

/*
  LgCardFull is only used in SearchScoring.
  …however SearchScoring is used in 2 places and toggles the theme of LgCardFull via the kind prop: "player" | "course"
 */

interface ICardFull {
  avatarSrc: string;
  buttonLabel?: string;
  heading: string;
  headerImageNode: React.ReactNode;
  item: any;
  kind: "player" | "course" | "default";
  lineItems: any;
  onConfirm: () => void;
}

export const LgCardFull = ({
  avatarSrc,
  buttonLabel = "Invite",
  heading = "Card name",
  headerImageNode,
  item,
  kind,
  lineItems,
  onConfirm,
}: ICardFull) => {
  const isCourse = kind === "course";

  return (
    <Flex
      direction="column"
      borderTop="2px"
      borderColor="white"
      pos="relative"
      h="full"
    >
      {isCourse && headerImageNode}

      <Box flex="1" px={6} pt="calc(var(--chakra-space-6) - 3px)">
        {!isCourse && (
          <Box pos="relative" pb={2}>
            <Avatar
              size="md"
              name={heading}
              src={avatarSrc}
              showBorder
              border="1px"
              borderColor="white"
              // boxShadow="#154a48 0 0 0 2px"
            />
          </Box>
        )}
        <Text
          as="h2"
          textStyle="hSecondary"
          color={isCourse ? "white" : "text.800"}
          pos="relative"
          pb={2}
        >
          {heading}
        </Text>

        <LgCardContent
          theme={isCourse ? "reverse" : "default"}
          lineOne={
            isCourse ? <>{lineItems.holes} holes</> : item.homeCourse.name
          }
          lineTwo={isCourse ? lineItems.address : <>{item.handicap} Handicap</>}
        />
      </Box>

      <Box layerStyle="containerScoreBtn">
        <Box>
          <Button
            // variant={isCourse ? "outline" : "solid"}
            variant="solid"
            color={isCourse ? "text.800" : "white"}
            colorScheme={isCourse ? "whites" : "textButton"}
            w="full"
            onClick={onConfirm}
          >
            {buttonLabel}
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

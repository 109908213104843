import { HStack, StackDivider, Text } from "@chakra-ui/react";

export function LgCardContent({
  theme = "default",
  lineOne,
  lineTwo,
}: {
  theme: "default" | "reverse";
  lineOne: any;
  lineTwo: any;
}) {
  const color = theme === "reverse" ? "white" : "grey.600";

  return (
    <HStack
      pos="relative"
      spacing={2}
      divider={<StackDivider borderColor={color} opacity={0.5} />}
    >
      <Text textStyle="copy" lineHeight="1" color={color}>
        {lineOne}
      </Text>
      <Text textStyle="copy" lineHeight="1" color={color}>
        {lineTwo}
      </Text>
    </HStack>
  );
}

import { Box, Text, HStack } from "@chakra-ui/react";
import { ScoreSheetTable } from "components/ProgressiveScoreCard";
import { CompetitionPlayerTotals } from "components/ClubTable";
import DefaultVStack from "components/VStack";
import { toShortestDateString } from "lib/date";
import { HOLES } from "config/constants";
import { IEclecticRound, EMatchType } from "types";
import { preheaderHeight } from "components/ClubTable/RowElements/PreHeader";

/* CompetitionPlayer closely resembles ScoreSheetPresenter and ScoreSheetRow */

interface ICompetitionPlayer {
  handicap: number;
  heading: string;
  round: IEclecticRound;
  index: number;
  compName: string;
  compCourse: string;
}

export const CompetitionPlayer = ({
  handicap,
  heading,
  round,
  index,
  compName,
  compCourse,
}: ICompetitionPlayer) => {
  const holesOut = HOLES.slice(0, 9);
  const scoresOut = round.holeScores.slice(0, 9);
  const holesIn = HOLES.slice(9, 18);
  const scoresIn = round.holeScores.slice(9, 18);

  const scoresOutStrokes = scoresOut.map((score) => score.scoreStroke);
  const scoresOutPars = scoresOut.map((score) => score.holeParUnadjusted);
  const scoresOutScores = scoresOut.map((score) => {
    return {
      score: score.scoreStableford,
      noScore: score.noScore,
    };
  });

  const scoresInStrokes = scoresIn.map((score) => score.scoreStroke);
  const scoresInPars = scoresIn.map((score) => score.holeParUnadjusted);
  const scoresInScores = scoresIn.map((score) => {
    return {
      score: score.scoreStableford,
      noScore: score.noScore,
    };
  });

  // TESTS
  // console.log("scoresOut", scoresOut);
  // console.log("scoresOut scores", scoresOutScores);

  return (
    <>
      <Box px={6}>
        {/* HEADING: same as ScoreSheetPresenter */}
        <Box bg="complement.50" mx={-6} pt={16} pb={4} px={6}>
          <Text as="h2" textStyle="hSecondary">
            {heading}
          </Text>
        </Box>

        {/* META */}
        <HStack
          mx={-6}
          px={6}
          spacing={3}
          borderBottomWidth="1px"
          // bg="neutral.200"
          alignItems="center"
          h={preheaderHeight}
        >
          <Text
            as="div"
            textStyle="body"
            borderRight="1px solid"
            borderRightColor="grey.200"
            pr={2.5}
          >
            Competition {compName} at {compCourse}
          </Text>
          <HStack spacing={2} alignItems="baseline">
            <Text as="div" textStyle="body">
              {handicap}
              <Text
                as="span"
                textStyle="crumb"
                fontWeight="500"
                color="text.600"
                pl={1}
              >
                HCP
              </Text>
            </Text>
            <Text fontSize="sm" color="text.400">
              Last round played {toShortestDateString(round.updatedAt)}
            </Text>
          </HStack>
        </HStack>
      </Box>

      {/* SCORES */}
      <DefaultVStack pt={5} spacing={4}>
        {/* SCORES OUT */}
        <Box px={6}>
          <ScoreSheetTable
            holeTotalLabel="Out"
            scoreRowHeading="St'fd"
            holes={holesOut}
            strokes={scoresOutStrokes}
            scores={scoresOutScores}
            pars={scoresOutPars}
            matchType={EMatchType.stableford}
          />
        </Box>
        {/* SCORES IN */}
        <Box px={6}>
          <ScoreSheetTable
            holeTotalLabel="In"
            scoreRowHeading="St'fd"
            holes={holesIn}
            strokes={scoresInStrokes}
            scores={scoresInScores}
            pars={scoresInPars}
            matchType={EMatchType.stableford}
          />
        </Box>

        {/* TOTALS (Restated) */}
        <Box px={6} pt={2}>
          <CompetitionPlayerTotals round={round} index={index} />
        </Box>
      </DefaultVStack>
    </>
  );
};

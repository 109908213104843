import useQuery from "hooks/useQuery";
import useOpenOnTimeout from "hooks/useOpenOnTimeout";
import { BlockContainerScore } from "components/Block2";
import { HomeLayout, RoundSetupNotice } from "components/Home";
import { useDocumentDimensions } from "hooks";

const HomePage = () => {
  useDocumentDimensions();
  const query = useQuery();
  const fromLobbyEnded = query.get("lobby-ended");
  const { isOpen, onClose } = useOpenOnTimeout(fromLobbyEnded, 1000);

  return (
    <BlockContainerScore>
      <HomeLayout>
        {/* Invited player kick-out notice */}
        {fromLobbyEnded && (
          <RoundSetupNotice isOpen={isOpen} onClose={onClose} />
        )}
      </HomeLayout>
    </BlockContainerScore>
  );
};

export default HomePage;

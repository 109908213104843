import React from "react";
import { TabPanels, TabPanel, TabList } from "@chakra-ui/react";
import { TabDot } from "./index";

// Compose Tabs with Dots or Buttons
export const ScoreTabsFrame = ({
  showTabListDots,
  footerTabList,
  children,
}: {
  showTabListDots?: boolean;
  footerTabList?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <>
      <TabPanels d="flex" flex="1" className="ScoreTabsFrame ScoreTabPanels">
        {React.Children.map(children, (Child) => (
          <TabPanel
            d="flex"
            flexDirection="column"
            flex="1"
            py={0}
            px={6}
            className="ScoreTabPanel"
          >
            {Child}
          </TabPanel>
        ))}
      </TabPanels>

      {/* Option: FOOTER TAB LIST DOTS */}
      {/* className="screen-pb--withinSponsor" */}
      {showTabListDots && (
        <TabList justifyContent="center" pt={4} pb={3} border="initial">
          {React.Children.map(children, () => (
            <TabDot />
          ))}
        </TabList>
      )}

      {/* Option: FOOTER TAB LIST */}
      {footerTabList}
    </>
  );
};

import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Flex, Text } from "@chakra-ui/react";
import { LobbyInviteSearch } from "./index";
import { PlayerRow, NoMarkerIcon } from "./index";
import { AvatarIcon } from "../Config/AvatarIcon";
import * as matchActions from "store/actions/match";
import * as lobbyActions from "store/actions/lobby";
import { getUsersFactory } from "lib/getUsersFactory";

export const InvitePlayers = ({
  setUserSetting,
  markerId,
}: {
  setUserSetting: (key: string, value: string) => void;
  markerId: number;
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  console.log("user", user);
  const hostId = useSelector((state) => state.authentication.user.id);
  const match = useSelector(matchActions.selectors.getMatch);
  const invitedUsers = useSelector((state) => state.lobby.invited);
  const usersInLobby = useSelector((state) => state.lobby.users);
  // const checkIsMarker = (user) =>
  //   markerId !== undefined && markerId === user.id;

  // get & find users
  const getUsers = useMemo(() => {
    const findUserInLobby = (user) =>
      usersInLobby.find((u) => u.id === user.id);
    const findUserInvited = (user) =>
      invitedUsers.find((u) => u.id === user.id);
    return getUsersFactory(user.id, {
      filter: (u) => !findUserInLobby(u) && !findUserInvited(u),
    });
  }, [user.id, usersInLobby, invitedUsers]);

  const handleInvite = (user) =>
    dispatch(lobbyActions.inviteUserToLobby(user, match.id));
  const handleKick = (userId) =>
    dispatch(lobbyActions.deletePlayerInvite(userId, match.id));

  // restrict to max 3 players only
  // ie. max 2 invited players or max 3 users in lobby
  const allowInvitePlayers = invitedUsers.length + usersInLobby.length < 3;

  // hey ho
  // console.log("invitedUsers", invitedUsers);
  // console.log("usersInLobby", usersInLobby);
  // console.log(invitedUsers.length + usersInLobby.length);
  // console.log(allowInvitePlayers);

  return (
    <Box layerStyle="containerInset" flex="1" pt={2}>
      <Flex mx="1px" justify="space-between" pb="3px">
        <Text as="h2" textStyle="crumb" color="text.400" fontWeight="500">
          Invited players
        </Text>
        <Text as="h2" textStyle="crumb" color="text.400" fontWeight="500">
          Marker
        </Text>
      </Flex>

      {/* LIST ACCEPTED USERS: host is always rendered here */}
      {usersInLobby.map((user) => {
        // const markers = user.markers;
        // // const isMarker = checkIsMarker(user);
        // return (
        //   <PlayerRowInLobby
        //     key={user.id}
        //     user={user}
        //     markers={markers}
        //     // isMarker={isMarker}
        //     isHost={user.id === hostId}
        //     onSetMarker={() => setUserSetting("marker", user)}
        //     onKick={handleKick}
        //   />
        // );
        const hasMarkers = user.markers.length > 0;
        // console.log("userInLobby hasMarkers", hasMarkers);
        return (
          <PlayerRow
            key={user.id}
            user={user}
            onKick={handleKick}
            isHost={user.id === hostId}
            isValid={user.id === hostId || hasMarkers}
          >
            {hasMarkers ? (
              <>
                {user.markers.map((marker, index) => (
                  <AvatarIcon
                    key={marker.id}
                    name={marker.fullName}
                    src={marker.avatar}
                    mr={index === user.markers.length - 1 ? 1 : -2}
                  />
                ))}
              </>
            ) : (
              <NoMarkerIcon />
            )}
          </PlayerRow>
        );
      })}

      {/* LIST INVITED USERS: always render below the host in `usersInLobby` */}
      {invitedUsers.map((user) => {
        const hasNoMarker = user.marker === undefined;
        // console.log("invitedUser hasNoMarker", hasNoMarker);
        return (
          <PlayerRow
            key={user.id}
            isValid={!hasNoMarker}
            onKick={handleKick}
            user={user}
          >
            {hasNoMarker ? <NoMarkerIcon /> : undefined}
          </PlayerRow>
        );
      })}

      {/* SEARCH for players to invite */}
      {allowInvitePlayers ? (
        <LobbyInviteSearch
          isHost
          users={[]}
          // userAccepted={userAccepted}
          getUsers={getUsers}
          onSelect={(player) => handleInvite(player)}
        />
      ) : (
        <Text textStyle="label" color="grey.400" pt={2}>
          We allow a maximum of 3 players in each player group
        </Text>
      )}
    </Box>
  );
};

import { ScoreMessage } from "./index";

export const ScoreMessageNoScore = ({
  onProgScorecard,
}: {
  onProgScorecard?: boolean;
}) => {
  return (
    <ScoreMessage
      // showBorder
      isSuccessBased
      successTheme
      heading="Double bogey assigned"
    >
      {onProgScorecard
        ? // eslint-disable-next-line quotes
          'Scores marked with a "–" and without a border mean you didn\'t score'
        : "You didn't score on this hole"}
      . Any time you record a "no score", we assigned it as a nett double bogey
      for your stats.
    </ScoreMessage>
  );
};

import { Form } from "formik";
import { chakra } from "@chakra-ui/react";

// chakra factory function to style the Formik Form
export const ChForm = chakra(Form, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: "1",
    layerStyle: "containerInsetFull",
  },
});

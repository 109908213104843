import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  IconButton,
  Icon,
  Text,
} from "@chakra-ui/react";
import { MdHelp } from "react-icons/md";

export const ScorecardRowPopover = () => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="Note"
          variant="unstyled"
          textStyle="hMinor"
          color="text.800"
          icon={<Icon as={MdHelp} boxSize="1.3em" />}
        >
          Legend
        </IconButton>
      </PopoverTrigger>
      <PopoverContent bg="text.900" w={52}>
        <PopoverArrow bg="text.900" />
        {/* <PopoverCloseButton /> */}
        <Text
          as="div"
          textStyle="meta"
          color="white"
          py={2}
          px={3}
          textAlign="center"
        >
          Your final Nett score is computed after your scorecard is signed
        </Text>
      </PopoverContent>
    </Popover>
  );
};

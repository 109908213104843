import { Box, BoxProps } from "@chakra-ui/react";

export interface IScoreBoxBase extends BoxProps {
  /* OUTER determines shell border */
  outer: boolean;
  /* INNER determines shell:before border */
  inner: boolean;
  /* PLUS determines radius */
  plus: boolean;
  /* NoScore is deterministic */
  noScore?: boolean;
}

/* DENOTE HOLE SCORE via BORDERS */
/* used in StablefordBox and StrokeBox  */
export const ScoreBoxBase = ({
  outer,
  inner,
  plus,
  noScore,
}: IScoreBoxBase) => {
  function showBorder(bool) {
    if (noScore) return false;
    if (bool) return true;
    return false;
  }

  return (
    <Box
      border="1px"
      /* OUTER border */
      borderColor={showBorder(outer) ? "text.200" : "transparent"}
      transform="translateY(-1px)"
      position="absolute"
      /* PLUS radius */
      borderRadius={plus ? "full" : undefined}
      inset={plus ? "-1px" : "0"}
      _before={{
        position: "absolute",
        inset: "1px",
        /* INNER border */
        // eslint-disable-next-line quotes
        content: '" "',
        border: "1px",
        borderColor: showBorder(inner) ? "text.200" : "transparent",
        /* PLUS radius */
        borderRadius: `${plus ? "999px" : "0"}`,
      }}
    />
  );
};

// _after={{
//   position: "absolute",
//   inset: "-1px",
//   /* OUTER */
//   // eslint-disable-next-line quotes
//   content: `${showPseudo(outer) ? '" "' : "initial"}`,
//   border: "1px",
//   borderColor: "text.200",
//   /* PLUS */
//   borderRadius: `${plus ? "999px" : "0"}`,
// }}

import { Tab, useTab, UseTabProps, forwardRef } from "@chakra-ui/react";

export const ScoreTabMeta = forwardRef((props: UseTabProps, ref) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps["aria-selected"];
  return (
    <Tab
      {...tabProps}
      // textStyle="metaCopy" // this don't work anymore?
      bg="transparent"
      color="text.800"
      fontWeight={isSelected ? "700" : "600"}
      opacity={isSelected ? "1" : "0.4"}
      _focus={{
        outline: "none",
        boxShadow: "none",
        zIndex: "1",
      }}
    />
  );
});

import { useState, useEffect } from "react";
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useGetCompetition } from "lib/api";
import {
  TableHeader,
  TableHeaderLabelComp,
  TableRowComp,
  TableRowCompStateless,
  TableNotice,
} from "./index";
import {
  CardHeaderComp,
  SettingsModal,
  TableHeaderStatus,
  STYLE_TableRowElementleftStyle,
} from "components";
import { renderCompetitionSortLabels, isObjectEmpty } from "lib";
import { IEclecticRound, IPlayerBase, ECompTotal } from "types";

const compHeaderLabels = {
  GROSS: ECompTotal.STROKE_GROSS,
  GROSS_RANK: ECompTotal.RANK,
  NETT: ECompTotal.STROKE_NETT,
  NETT_RANK: ECompTotal.RANK,
  FIRST9: ECompTotal.FIRST_NINE,
  FIRST9_RANK: ECompTotal.RANK,
  LAST9: ECompTotal.LAST_NINE,
  LAST9_RANK: ECompTotal.RANK,
  ROUNDS: ECompTotal.ROUND_COUNT,
};

export const Competition = ({ user }: { user: IPlayerBase }) => {
  const [comp, setComp] = useState<any>({});
  const [rounds, setRounds] = useState<any>({});
  const [players, setPlayers] = useState<[]>([]);
  const [userIsHost, setUserIsHost] = useState(false);
  const [sortBy, setSortBy] = useState<ECompTotal>(ECompTotal.STROKE_GROSS);
  const { competitionId } = useParams<{ competitionId: string }>();
  const [breakpoint] = useMediaQuery("(min-width: 1280px)");

  // This re-renders the page on every change in sortBy — not ideal
  const { data, error, mutate } = useGetCompetition(competitionId, sortBy);

  console.log("data", data);

  // if data, then set competition and rounds (if available)
  // https://stackoverflow.com/a/68436258
  useEffect(() => {
    if (!data) return undefined;
    setComp(data.competition);
    setRounds(data?.rounds);
  }, [data]);

  // if comp, then set userIsHost
  useEffect(() => {
    if (isObjectEmpty(comp)) return undefined;
    setPlayers(comp.players);
    setUserIsHost(user.id === comp.hostId);
  }, [user, comp]);

  // update comp settings from the settings modal
  const handleCompetitionUpdate = () => mutate();

  if (error)
    return (
      <TableHeaderStatus tableHeading="Competitions">
        <Text textStyle="body">Got an error here. Please contact support.</Text>
      </TableHeaderStatus>
    );

  if (!data)
    return <TableHeaderStatus showLoader tableHeading="Competitions" />;

  // TESTS
  // console.log("switchClick", switchClick);
  // console.log("rounds", rounds);
  // console.log("round[0]", rounds[0]);
  console.log("comp", comp);
  console.log("userIsHost", userIsHost);
  console.log("sortBy", sortBy);
  console.log("players", players);

  if (!comp)
    return (
      <TableHeaderStatus tableHeading="Competitions">
        <Text textStyle="body">No competition found.</Text>
      </TableHeaderStatus>
    );

  return (
    <Box as="section">
      {/* COMP HEADER */}
      <TableHeader heading="Competition" />
      <Box pt="w3">
        <CardHeaderComp
          comp={comp}
          userIsHost={userIsHost}
          ownerSettings={
            <SettingsModal
              comp={comp}
              onCompetitionUpdate={handleCompetitionUpdate}
            />
          }
        />
      </Box>

      {/* NOTICE: IF NO ROUNDS PLAYED */}
      {!rounds?.length && (
        <TableNotice>
          <Text as="span" fontWeight={500}>
            No rounds played yet
          </Text>
        </TableNotice>
      )}

      {/* TABLE HEADERS */}
      <TableHeader
        isComp
        showDivider
        pb={0}
        // remove the zIndex for the legend popover to show
        zIndex={0}
        useOverflowScrollOnRight={false}
        // overflow w/o scrollbar!
        overflow="auto"
        sx={{
          // "&:hover > *": {
          //   bg: "neutral.300",
          // },
          scrollbarWidth: "none", // Firefox
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        innerLeftStackProps={{
          alignItems: "center",
          width: { base: "auto", lg: "100%" },
          ...(!breakpoint && STYLE_TableRowElementleftStyle),
        }}
        innerRightStackProps={{
          width: { base: "auto", lg: "100%" },
        }}
        leftContent={
          <Flex>
            {/* matches TableRowCompHoles & RowLeft width */}
            <Flex alignItems="start" px={1} w={{ base: "105px", sm: "auto" }}>
              {/* match HeaderLabels */}
              <Text
                textStyle="crumb"
                color="text.600"
                fontWeight="500"
                pl="3px"
              >
                Player
              </Text>
            </Flex>
          </Flex>
        }
        rightContent={
          <Flex>
            {Object.values(compHeaderLabels).map((label, index) => {
              const showAsSpan =
                label ===
                (ECompTotal.RANK || ECompTotal.FRONT || ECompTotal.BACK)
                  ? true
                  : false;
              return (
                <TableHeaderLabelComp
                  key={index.toString()}
                  rawLabel={label}
                  showAsSpan={showAsSpan}
                  showWidthAsDecimalStyle={
                    label === ECompTotal.FIRST_NINE ||
                    label === ECompTotal.LAST_NINE
                  }
                  label={renderCompetitionSortLabels(label, true)}
                  sortBy={sortBy}
                  onClick={() => setSortBy(label)}
                />
              );
            })}
          </Flex>
        }
      />

      {/* PLAYERS */}
      {!!rounds.length ? (
        <Box>
          {rounds?.map((round: IEclecticRound, index: number) => (
            <TableRowComp
              key={index.toString()}
              index={index}
              round={round}
              sortBy={sortBy}
              compName={comp.name}
              compCourse={comp.course.name}
            />
          ))}
        </Box>
      ) : (
        <Box>
          {players.map((player: any, index: number) => (
            <TableRowCompStateless
              key={index.toString()}
              index={index}
              player={player}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

import { Box, TabList } from "@chakra-ui/react";
import { ScoreTabMeta } from "../ScoreTabs";
import { MdAssistantPhoto, MdSettings } from "react-icons/md";

export const ProgressiveScorecardFooter = () => (
  <TabList justifyContent="center" py={6} mt="auto">
    <ScoreTabMeta flexDirection="row" flex="initial" px={3}>
      <Box as={MdAssistantPhoto} mr={1} />
      <Box textStyle="metaButton" fontWeight="700">
        Scorecard
      </Box>
    </ScoreTabMeta>
    <ScoreTabMeta flexDirection="row" flex="initial" px={3}>
      <Box as={MdSettings} mr={1} />
      <Box textStyle="metaButton" fontWeight="700">
        Settings
      </Box>
    </ScoreTabMeta>
  </TabList>
);

import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { MdError } from "react-icons/md";
import React from "react";

export const TableNotice = ({
  bg = "orange.700",
  children,
  icon = MdError,
  ...props
}: {
  bg?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  [x: string]: any;
}) => {
  return (
    <Box
      bg={bg}
      color="white"
      fontWeight="400"
      px={{ base: 3, lg: 4 }}
      py={2}
      textStyle="body"
      {...props}
    >
      <HStack spacing={1.5} pb="3px" alignItems="flex-start">
        <Icon as={icon} mt="0.25em" />
        <Text fontWeight="400">{children}</Text>
      </HStack>
    </Box>
  );
};

// @refresh reset
// https://next.chakra-ui.com/docs/theming/component-style

// Common modifier styles includes:
// * Size: different sizes (e.g. small, medium, large)
// * Variant: different visual styles (e.g. outline, solid, ghost)
// * Color scheme: For a given variant, different color schemes
//    (e.g. an outline button with a red color scheme)
// * Color mode: change its visual styles based on color mode

// button colorScheme expects a color key, not a value
// https://github.com/chakra-ui/chakra-ui/issues/203#issuecomment-545016739

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts

export const buttonH = { base: "46px", lg: "48px" };

// must be Sentence case
const Button = {
  baseStyle: (props) => ({
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: 1,
    borderRadius: "sm",
    textDecoration: "none",
    // pb: "1px",
    transition: "all 0.2s cubic-bezier(.08,.52,.52,1)",
    _hover: {
      textDecoration: "none",
    },
    _active: {
      outline: "none",
      outlineOffset: 0,
      bg: "grey.50",
      // transform: "scale(0.98)",
    },
    _focus: {
      outline: "none",
      outlineOffset: 0,
      boxShadow: "initial",
    },
    sx: {
      "a, & a": {
        color: "text.800",
        textDecoration: "none",
      },
      "& .chakra-button__icon": {
        transition: "all 0.7s cubic-bezier(.08,.52,.52,1)",
        mt: "1px",
        // mr: "8px !important",
      },
      // "& [disabled]:hover": {
      //   bg: "text.900 !important",
      // },
    },
  }),
  // styles for different sizes ("sm", "md", "lg")
  // Chakra sets h, minW, fontSize and px here
  sizes: {
    sm: {
      // h: "17px",
      h: { base: "25px", lg: "28px" },
      px: { base: 2, lg: 3 },
      pb: 0,
      fontSize: { base: "9px", lg: "11px" }, // crumb
      fontWeight: 500,
      textTransform: "uppercase",
      letterSpacing: "0.075em",
      borderRadius: "2px",
      iconSpacing: "2px",
    },
    md: {
      h: { base: "28px", lg: "32px" },
      pt: "1px",
      px: { base: 2, lg: 2 },
      fontSize: { base: "10px", lg: "13px" }, // meta
      fontWeight: 600,
      textTransform: "uppercase",
      letterSpacing: "wider",
      borderRadius: "2px",
    },
    mdNew: {
      h: { base: "24px", lg: "24px" },
      pt: "1px",
      px: 3,
      fontSize: { base: "10px", lg: "13px" }, // meta
      borderRadius: "sm",
    },
    lg: {
      h: buttonH,
      px: { base: 4, lg: 5 },
      // pb: "2px",
      fontSize: { base: "13px", lg: "15px" }, // body
      fontWeight: 400,
      letterSpacing: "body",
      borderRadius: "full",
    },
    xl: {
      h: { base: 16 },
      px: { base: 4, lg: 5 },
      fontSize: { base: "14px", lg: "16px", xl: "17px" }, // md
    },
  },
  // styles for different visual variants ("outline", "solid")
  // used mostly for colours
  variants: {
    solid: {
      fontWeight: 500,
    },
    accent: {
      bg: "orange.500",
      color: "black",
      fontWeight: 500,
      _hover: {
        bg: "orange.600",
      },
    },
    default: {
      color: "white",
      fontWeight: "500", // if it's white, we want 500
      bg: "text.700",
      _hover: {
        bg: "text.800",
      },
    },
    // props available are theme, colorMode + colorScheme
    // https://chakra-ui.com/docs/theming/advanced#single-part-component
    outline: (props) => ({
      // color:
      //   props.colorScheme === "whites"
      //     ? "currentColor"
      //     : `${props.colorScheme}.800`,
      borderColor:
        props.colorScheme === "whites"
          ? "currentColor"
          : `${props.colorScheme}.200`,
      _hover: {
        color: `${props.colorScheme}.800`,
        borderColor: "currentColor",
        bg: props.colorScheme === "whites" ? "transparent" : "white",
      },
      _focus: {
        color: `${props.colorScheme}.800`,
        borderColor: "currentColor",
        boxShadow: "currentColor 0 0 0 1px !important",
        bg: props.colorScheme === "whites" ? "transparent" : "white",
      },
      _active: {
        color: `${props.colorScheme}.800`,
        borderColor: "currentColor",
        bg: props.colorScheme === "whites" ? "transparent" : "white",
        boxShadow: "currentColor 0 0 0 2px !important",
      },
    }),
    ghost: {
      // borderRadius: "md",
      // _hover: {
      //   bg: "blackAlpha.200",
      // },
    },
    ghostInverse: {
      borderRadius: "md",
      _hover: {
        bg: "blackAlpha.300",
      },
    },
    link: {
      color: "#000",
      textDecoration: "underline",
      fontWeight: "500",
      textTransform: "none",
      px: 0,
    },
    navLink: {
      color: "#000",
      textDecoration: "none",
      textTransform: "uppercase",
      borderRadius: "full",
      _hover: {
        bg: "none",
        textDecoration: "underline",
      },
    },
    crumb: {
      // variant: "outline",
      colorScheme: "text",
      height: "17px",
      rounded: "sm",
      iconSpacing: 1,
      px: 2,
      pb: "1px",
      fontSize: "xxs",
      fontWeight: "600",
      textTransform: "uppercase",
      lineHeight: "1",
      letterSpacing: "0.075em",
    },
    table: {
      borderRadius: 0,
      borderBottom: "1px solid",
      borderBottomColor: "grey.100",
      color: "text.500",
      pb: 0,
      _hover: {
        bg: "neutral.200",
      },
    },
    // use with size="lg"
    // there is also a matching input "setRow" variant
    setRow: {
      pos: "relative",
      w: "full",
      px: "2px",
      borderRadius: 0,
      borderBottom: "1px",
      borderColor: "grey.50",
      color: "text.800",
      _placeholder: {
        color: "grey.400",
      },
      _active: {
        // outline: "2px solid #204f6b",
        // outlineOffset: "2px",
        // outline: "2px solid #204f6b",
        // transform: "scale(1)",
        background: "rgb(0 0 0 / 5%)",
        borderColor: "text.800",
      },
      _disabled: {
        opacity: 1,
        _active: {
          borderColor: "grey.50",
        },
      },
    },
  },
  defaultProps: {
    size: "lg",
    variant: "solid",
    colorScheme: "textButton",
  },
};

export default Button;

import { useDispatch } from "react-redux";
import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { DeleteModal } from "./index";
import { MdArrowBack } from "react-icons/md";
import * as matchActions from "store/actions/match";
import history from "../../history";
import { buttonH } from "theme/components/button";

export const DeleteLobbyModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  async function handleConfirmDeleteLobby() {
    await dispatch(matchActions.leaveLobby());
    history.push("/");
  }

  return (
    <>
      {/* same button as in DeleteRoundModal */}
      <Flex alignItems="center" h={buttonH}>
        <Button
          size="lg"
          variant="ghost"
          colorScheme="textButton"
          justifyContent="flex-start"
          w="full"
          px={0}
          leftIcon={<MdArrowBack />}
          onClick={onOpen}
        >
          Delete Round Set-up
        </Button>
      </Flex>
      <DeleteModal
        heading="Delete Round Set-up"
        confirm="Are you sure? This will take you back to your home screen."
        buttonLabel="Delete Set-up"
        isOpen={isOpen}
        onClose={onClose}
        onClick={handleConfirmDeleteLobby}
      />
    </>
  );
};

// @refresh reset
// https://next.chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project

import { extendTheme } from "@chakra-ui/react";

import styles from "./styles";
import foundations from "./foundations/index";
import textStyles from "./textStyles";
import layerStyles from "./layerStyles";
import components from "./components";
// import Button from "./components/button";

const overrides = {
  styles,
  ...foundations,
  textStyles,
  layerStyles,
  components,
  // components: {
  //   Button,
  // },
};

export default extendTheme(overrides);

import { useState } from "react";
import { SearchPresenter } from "./index";
import { useField } from "formik";

// A Formik container that wraps SearchPresenter so that we
// can set all our functions with access to formik field states
// all in one clean place

// selector: (item: any) => string;
// [x: string]: any;

export const SearchFormikContainer = ({
  selector = (value) => value,
  ...props
}: {
  selector?: (item: any) => string;
  // Required elements for SearchPresenter
  name: string;
  label: string;
  placeholder: string;
  icon: React.ReactNode;
  getItems: any;
  noResultsMessage: string;
  [x: string]: any;
}) => {
  const [value, setValue] = useState<string>("");
  const [, , helpers] = useField(props as any);

  function handleSelect(item: any) {
    helpers.setValue(item);
    setValue(selector(item));
  }

  function handleChange(event) {
    setValue(event.target.value);
  }

  function handleIconClick() {
    helpers.setValue(null);
    helpers.setTouched(false);
  }

  return (
    <SearchPresenter
      onSelect={handleSelect}
      onIconClick={handleIconClick}
      {...props}
      // set onChange and maskValue after spreading props
      value={value}
      onChange={handleChange}
    />
  );
};

import { ICourseHole, IPlayerBase, ETee } from "types";

export enum EMatchType {
  stroke = "STROKE",
  stableford = "STABLEFORD",
  par = "PAR",
}

export enum ESortBy {
  stroke = "STROKE",
  stableford = "STABLEFORD",
  par = "PAR",
  dateAsc = "DATE_ASC",
  dateDesc = "DATE_DESC",
}

export type TMatchType = "STROKE" | "STABLEFORD" | "PAR";

export interface IRating {
  course: string[];
  iD: string;
  rating: number;
  ratingKind: string;
}

export interface IMatchCourse {
  clubName: string;
  courseName: string;
  golflinkCourseId: string;
  handicapModifier: number;
  holes: ICourseHole[];
  id: number;
  courseId: number;
  image: any;
  name: string;
  parRatings: IRating;
  scratchRatings: IRating;
  slopeRatings: IRating;
  state: string;
  tees: string[];
}

export interface IMatch {
  id: number;
  courseId: number;
  course: IMatchCourse;
  host: IPlayerBase;
  isSocial: boolean;
  matchType: EMatchType;
  playType: string;
  scoreLabel: {
    abbr: string;
    long: string;
    short: string;
  };
  started: boolean;
  tee: ETee;
}

export interface IUserMatchSettingsBasic {
  courseId: number;
  createdAt: string;
  dailyHandicap: number;
  handicap: number;
  id: number;
  match: number;
  ready: boolean;
  updatedAt: string;
  userId: number;
  userInMatch?: boolean;
  userLeftMatch?: boolean;
}

// Actual type from server
export interface IUserMatchSettingsComp {
  courseId: number;
  createdAt: string;
  dailyHandicap: number;
  handicap: number;
  id: number;
  marker: number;
  match: number;
  ready: boolean;
  updatedAt: string;
  userId: number;
  userInMatch: boolean;
  userLeftMatch: boolean;
}

export interface IUserMatchSettings extends IUserMatchSettingsBasic {
  // handicapForShow: string;
  marker?: IPlayerBase;
  markerCourse?: IMatchCourse;
  markerMatchSettings?: IUserMatchSettingsBasic;
}

import React from "react";
import {
  Flex,
  Button,
  ButtonProps,
  Icon,
  Text,
  forwardRef,
} from "@chakra-ui/react";
import { MdSettings, MdHelp, MdCheckCircle } from "react-icons/md";
import { AvatarIcon } from "./index";
import { EConfigRowTheme, TConfigRowTheme } from "types";

interface IConfigRow extends ButtonProps {
  afterTextSlot?: React.ReactNode;
  avatarSrc?: string;
  children?: React.ReactNode;
  iconSlot?: React.ReactNode;
  isActive?: boolean;
  isSet?: boolean; // sets a value as uneditable
  isVisuallyReduced?: boolean;
  name?: string;
  theme?: TConfigRowTheme;
  truncateValue?: boolean;
  value?: string | number;
}

const iconSize = "0.933333333em";

// example of .ts with forwardRef
// https://reactjs.org/docs/forwarding-refs.html
// https://chakra-ui.com/guides/as-prop#option-1-using-forwardref-from-chakra-uireact
// forwardRef<BoxProps, "div">((props, ref) =>
// https://stackoverflow.com/a/60555007

export const ConfigRow = forwardRef(
  (
    {
      afterTextSlot,
      avatarSrc,
      children,
      iconSlot,
      isActive,
      isSet,
      isVisuallyReduced,
      name,
      theme,
      truncateValue,
      type = "button",
      value,
      ...props
    }: IConfigRow,
    ref
  ) => {
    const renderThemeIcon = (
      theme: TConfigRowTheme,
      name: string,
      avatarSrc: string
    ) => {
      switch (theme) {
        case EConfigRowTheme.avatar:
          return <AvatarIcon name={name} src={avatarSrc} />;
        case EConfigRowTheme.valid:
          return (
            <Icon
              as={MdCheckCircle}
              color="complement.500"
              w={iconSize}
              h={iconSize}
            />
          );
        case EConfigRowTheme.awaiting:
          return (
            <Icon as={MdHelp} color="primary.100" w={iconSize} h={iconSize} />
          );
        case EConfigRowTheme.settings:
          return (
            <Icon as={MdSettings} color="black" w={iconSize} h={iconSize} />
          );
        default:
          return undefined;
      }
    };

    return (
      <Button
        // Buttons without type="button" will submit it's parent form
        type={type}
        // this allows us to set a child button for onKick in <PlayerRow>
        as={isSet ? "div" : undefined}
        variant="setRow"
        borderColor={isVisuallyReduced ? "transparent" : "text.50"}
        opacity={isVisuallyReduced ? "0.333" : undefined}
        // disabled={disabled}
        ref={ref}
        {...props}
      >
        {/* render an icon based on supplied theme, defaults to undefined */}
        {theme && renderThemeIcon(theme, name, avatarSrc)}

        {/* backup slot for flexible icon use */}
        {iconSlot}

        {/* TEXT */}
        <Flex
          align="center"
          justify="space-between"
          flex="1"
          w="full"
          ml={theme ? 2 : 0}
        >
          <Flex align="baseline" textAlign="left" flex="1">
            <Text
              as="div"
              textStyle="body"
              isTruncated={truncateValue}
              maxWidth={truncateValue ? "70%" : "full"}
              // Safari flexbox issue:
              // if no label, make sure the text is full width
              w={name ? "auto" : "full"}
            >
              {value ? value : name}
            </Text>
            {value && name && (
              <Text
                as="label"
                color="grey.400"
                htmlFor={name}
                ml={truncateValue ? "4px" : "8px"}
                mb={0}
                textStyle="label"
                verticalAlign="baseline"
              >
                {name}
              </Text>
            )}

            {/* only used in PlayerRow, but it's crucial */}
            {afterTextSlot}
          </Flex>
        </Flex>

        {children}
      </Button>
    );
  }
);

import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./assets/css/custom-utils.css";
import "./assets/css/safe-area.css";
import "./assets/fonts/fontface.css";
import "./mockApi.js";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import theme from "./theme";

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Routes />
    </ChakraProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

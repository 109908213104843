// ScoreShell width variations
export const STYLE_ScoreShellWidth = {
  base: "46px",
  md: "60px",
  xl: "70px",
};
export const STYLE_ScoreShellWidthDecimal = {
  base: "59px",
  md: "73px",
  xl: "83px",
};

export const STYLE_TableRowElementleftStyle = {
  position: "sticky",
  left: 0,
  background: "white",
  zIndex: 1,
  _before: {
    // eslint-disable-next-line quotes
    content: `""`,
    // don't use rgb(0 0 0 / 0.5) because it doesn't work here
    bgGradient: "linear(to-l, transparent, rgba(51, 51, 51, 0.08))",
    pos: "absolute",
    top: "0",
    bottom: "0",
    right: "-18px",
    left: "100%",
  },
} as const;

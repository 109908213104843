import { Box } from "@chakra-ui/react";

interface TableRowGridProps {
  // index is the record's rank within the TableRow
  children: React.ReactNode;
  // optional click event for mobile competition player
  handleClick?: () => void;
  // display as grid
  isGrid?: boolean;
  // ...props
  [x: string]: any;
}

export const TableRowGrid = ({
  children,
  handleClick,
  isGrid,
  ...props
}: TableRowGridProps) => {
  return (
    <Box
      className="TableRowGrid"
      display={isGrid ? "grid" : "flex"}
      // minChildWidth="200px"
      overflowX="auto"
      pos="relative"
      gridTemplateColumns={{
        base: "minmax(110px, 1fr) minmax(233px, 1fr)",
        xl: "minmax(340px, 3fr) 1fr",
      }}
      w="full"
      // Set bg in TableRowElement via the index
      sx={{
        "&:hover > *": {
          bg: "neutral.300",
        },
        scrollbarWidth: "none", // Firefox
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      // dumb, but fuck you!
      onClick={handleClick}
      // fallback
      {...props}
    >
      {children}
    </Box>
  );
};

import { Text, UseDisclosureProps } from "@chakra-ui/react";
import { DeleteModal } from "components/Notices";
// import useQuery from "../../hooks/useQuery";
// import useOpenOnTimeout from "../../hooks/useOpenOnTimeout";

/* INVITED PLAYER KICK-OUT NOTICE */
export const InvitePlayersModal = ({ isOpen, onClose }: UseDisclosureProps) => {
  // const query = useQuery();
  // const fromLobbyEnded = query.get("lobby-ended");
  // const { isOpen, onClose } = useOpenOnTimeout(fromLobbyEnded, 1000);

  return (
    <DeleteModal
      heading="Time to invite players"
      // confirm="You've successfully configured the round. Now it's time to invite players."
      confirm="Invite up to three players."
      buttonLabel="OK"
      showButtonIcon={false}
      isOpen={isOpen}
      onClose={onClose}
      onClick={onClose}
      extraText={
        <>
          <Text textStyle="copy" color="white" opacity={0.7} pt={1}>
            Wait until they've set their handicap and marker, and clicked
            accept. When everyone is ready, you'll be allowed to start the
            match.
          </Text>
        </>
      }
    />
  );
};

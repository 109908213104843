// import PropTypes from "prop-types";
import { ScoreBoxBase } from "./index";

type TKind =
  | -5
  | -4
  | -3
  | -2
  | -1
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | undefined;

/* STROKE: DENOTE HOLE SCORE via BORDERS */
/* Very similar to StablefordBox but not the same */
const StrokeBox = ({ kind }: { kind: TKind }) => {
  // MAP
  // double bogey +2 par = 2 squares
  // bogey +1 par = 1 square
  // par 0 par = null
  // birdie -1 par = 1 circle
  // eagle -2 par = 2 circles
  // albatross -3 par = 3 circles

  // const shell = [0, undefined]; // if shell, don't show border
  // inner border
  const inner = [-5, -4, -3, -2, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // outer border
  const outer = [-5, -4, -3, -2, -1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // adds circle
  const plus = [-1, -2, -3, -4, -5];

  // convert arrays to a boolean so we can debug ScoreBoxBase
  // const shellActive = shell.includes(kind);
  const innerActive = inner.includes(kind);
  const outerActive = outer.includes(kind);
  const plusActive = plus.includes(kind);

  return (
    <ScoreBoxBase
      // shell={shellActive}
      inner={innerActive}
      outer={outerActive}
      plus={plusActive}
    ></ScoreBoxBase>
  );
};

export default StrokeBox;

import { LgHeader } from "components/Block/LgHeader";
import { FormForgotPassword } from "components/Forms";
import { BlockContainerScore } from "components/Block2";

const ForgotPasswordPage = () => {
  return (
    <BlockContainerScore spacing={0} pb="w7">
      <LgHeader heading="Forgot Password?" />
      <FormForgotPassword />
    </BlockContainerScore>
  );
};

export default ForgotPasswordPage;

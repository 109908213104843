import { Flex, Box } from "@chakra-ui/react";
import { Score } from "components/ClubTable/RowElements";
import { IEclecticRound } from "types";
// import { IEclecticRound, ECompTotal } from "types";
// import { TableHeaderLabelComp } from "./index";
// import { renderCompetitionSortLabels } from "lib";

// const labels = {
//   GROSS: ECompTotal.STROKE_GROSS,
//   NETT: ECompTotal.STROKE_NETT,
//   FIRST9: ECompTotal.FIRST_NINE,
//   LAST9: ECompTotal.LAST_NINE,
//   RANK: ECompTotal.RANK,
//   ROUNDS: ECompTotal.ROUND_COUNT,
// };

interface Props {
  label: string;
  children?: React.ReactNode;
  isAlt?: boolean;
  isHeading?: boolean;
}

const Label = ({
  children,
  isHeading,
  isScoreHeading,
}: {
  children: string;
  isHeading?: boolean;
  isScoreHeading?: boolean;
}) => (
  <Box
    fontSize={{ base: "11px", lg: "14px" }}
    fontWeight={isHeading || isScoreHeading ? "500" : "400"}
    // color={isHeading ? "text.800" : "text.400"}
    color="text.800"
    pl="3px"
    pr={isScoreHeading ? 4 : 0}
    textAlign={isScoreHeading ? "right" : "left"}
    flex={isScoreHeading ? "initial" : 1}
    w={isScoreHeading ? scoreWidth : "auto"}
  >
    {children}
  </Box>
);

const Total = ({ label, children, isAlt, isHeading }: Props) => (
  <Flex
    alignItems="center"
    borderBottom="1px solid"
    borderColor={isHeading ? "grey.400" : "grey.100"}
    bg={isAlt ? "neutral.100" : "bg.white"}
    h={{
      // same as { defaultHeight } from "components/ClubTable"?
      // base: "calc(var(--chakra-sizes-9) + 9px)",
      base: "30px",
      lg: isHeading ? "36px" : "42px",
    }}
    sx={{
      "& > div + div": {
        borderLeft: "1px solid",
        borderColor: "grey.100",
      },
    }}
  >
    <Label isHeading={isHeading}>{label}</Label>
    {children}
  </Flex>
);

const scoreWidth = { base: "54px", md: "54px", xl: "64px" };

const TotalScore = ({ value }: { value: number }) => (
  <Score value={value} showEdge={false} scoreShellWidth={scoreWidth} />
);

export function CompetitionPlayerTotals({ round }: { round: IEclecticRound }) {
  // console.log("CompetitionPlayerTotals round", round);

  return (
    <>
      <Total label="Totals" isHeading>
        <Label isScoreHeading>Score</Label>
        <Label isScoreHeading>Rank</Label>
      </Total>

      {/* gross stroke, nett stroke */}
      <Total label="Gross" isAlt>
        <TotalScore value={round.totalScoreStroke} />
        <TotalScore value={round.totalScoreStrokeRank} />
      </Total>
      <Total label="Nett">
        <TotalScore value={round.totalScoreStrokeNett} />
        <TotalScore value={round.totalScoreStrokeNettRank} />
      </Total>

      {/* front nett stroke, back nett stroke */}
      <Total label="Front 9" isAlt>
        <TotalScore value={round.firstNineScoreStrokeNett} />
        <TotalScore value={round.firstNineScoreStrokeNettRank} />
      </Total>
      <Total label="Back 9">
        <TotalScore value={round.lastNineScoreStrokeNett} />
        <TotalScore value={round.lastNineScoreStrokeNettRank} />
      </Total>

      {/* rounds */}
      <Total label="Rounds" isAlt>
        <TotalScore value={round.contributingRounds} />
      </Total>
    </>
  );
}

import { Flex, Box, Text } from "@chakra-ui/react";
import { LgImageHeader } from "components/Block/index";
import DefaultVStack from "components/VStack";
import { TScorecardVariant, IScorecardUiConfig } from "types";

/*
  ScorecardHeader is used in:
  * ScorecardPresenter
  * ScoreInputPresenter
 */

interface IScorecardHeader {
  buttonSlot?: React.ReactNode;
  children?: React.ReactNode;
  heading: string;
  headingStyle?: string;
  settingsSlot?: React.ReactNode;
  // pass uiConfig & variant as props
  // so we can use it w/o being tied to context
  // …but maybe they should always be via context…
  uiConfig?: IScorecardUiConfig;
  variant: TScorecardVariant;
}

// Default uiConfig is same as ScorecardContext
const uiConfigDefault = {
  showHeader: true,
  showMarkerInSettings: false,
  // …except here
  showHeaderImage: false,
};

export const shScoringH = "22.5vh";
export const shLeaderboardH = "240px";
export const shEclecticH = "auto";

export const ScorecardHeader = ({
  buttonSlot,
  children,
  heading = "Hole 1",
  headingStyle = "hPrimary",
  settingsSlot, // used only on eclectic variant
  uiConfig = uiConfigDefault,
  variant,
}: IScorecardHeader) => {
  const variantH = (variant: TScorecardVariant) => {
    if (variant === "scoring") return shScoringH;
    if (variant === "leaderboard") return shLeaderboardH;
    if (variant === "eclectic") return shEclecticH;
    return "auto";
  };

  return (
    <Flex
      as="header"
      direction="column"
      justify="space-between"
      bg="complement.50"
      pos="relative"
      w="full"
      h={variantH(variant)}
      // minH={settingsSlot !== undefined ? variantH : "40"}
      // {...props}
      sx={{
        // mobile portrait
        // "@media (orientation: portrait) and (max-width: 640px)": {
        //   height: "200px",
        // },
        // mobile landscape
        "@media (orientation: landscape) and (max-width: 1024px)": {
          height: "auto",
        },
      }}
      className="ScorecardHeader pt-safe"
    >
      {/* BG IMAGE */}
      {uiConfig.showHeaderImage && <LgImageHeader useShade useBlend />}

      {/* NAV BUTTON */}
      <Box pos="relative">
        <Flex
          direction="column"
          align="center"
          justify="center"
          px={6}
          layerStyle="navHeight"
        >
          {buttonSlot}
        </Flex>
      </Box>

      {/* SCORE HGROUP */}
      <DefaultVStack
        pos="relative"
        px={6}
        pb={4}
        color={uiConfig.showHeaderImage ? "white" : "text.800"}
      >
        <Flex justify="space-between" align="flex-end">
          {/* color="white" ? */}
          <Text as="h2" textStyle={headingStyle} fontWeight="500">
            {heading}
          </Text>

          {children}
        </Flex>

        {/* used only on eclectic variant */}
        {settingsSlot}
      </DefaultVStack>
    </Flex>
  );
};

import * as api from "../../lib/api";
// import formatMatchResponse from "../../lib/formatMatchResponse";
import config from "../../config";
import * as constants from "../../config/constants";
import history from "../../history";
import * as lobbyActions from "./lobby";

export const MATCH_SET = "MATCH_SET";
export const MATCH_CLEAR = "MATCH_CLEAR";
export const MATCH_MARKER_SET = "MATCH_MARKER_SET";
export const MATCH_READY = "MATCH_READY";
export const MATCH_SET_SCORE = "MATCH_SET_SCORE";

export const setMarker = (marker, markerMatchSettings) => async (dispatch) => {
  dispatch({
    type: MATCH_MARKER_SET,
    marker,
    markerMatchSettings,
  });
};

/**
 * @typedef {object} UserMarkerObject
 * @property {object} user
 * @property {object} marker
 */

/**
 * Set match
 *
 * @param {object} match
 * @param {object} userMatchSettings
 * @param {UserMarkerObject} scores
 * @param {UserMarkerObject} rounds
 * @param {boolean} hasVerifiedMarkersRound
 */
export const setMatch = (
  match,
  userMatchSettings,
  scores,
  rounds,
  incompleteHoles,
  hasVerifiedMarkersRound
) => async (dispatch) => {
  dispatch({
    type: MATCH_SET,
    match,
    userMatchSettings,
    scores,
    rounds,
    incompleteHoles,
    hasVerifiedMarkersRound,
  });
};

/**
 * Get a matches data
 *
 * @param {number} matchId
 * @param {object} options
 * @param {boolean} options.force - force a refetch
 */
export const getMatch = (matchId, options = {}) => async (
  dispatch,
  getState
) => {
  const state = getState();

  // If match already fetched
  // Refetch if match is missing userMatchSettings
  // (fetched before settings user_match_settings and starting match)
  // Should be refeteched after start a match to ensure you have both your and your markers match settings
  if (
    !options?.force &&
    state.match.match &&
    state.match.match.id === matchId &&
    state.match.userMatchSettings
  ) {
    return {
      match: state.match.match,
    };
  }

  const response = await api.getMatch(matchId);
  if (response.status === 404) {
    // TODO: For offline support check a valid 404 response was recieved
    localStorage.removeItem(config.localStorageMatchKey);
    localStorage.removeItem(config.localStorageRoundKey);
    history.push("/");
    return;
  }
  if (response.error) return response;

  const {
    match,
    userMatchSettings,
    scores,
    rounds,
    incompleteHoles,
    hasVerifiedMarkersRound,
  } = response.json;

  dispatch(
    setMatch(
      match,
      userMatchSettings,
      scores,
      rounds,
      incompleteHoles,
      hasVerifiedMarkersRound
    )
  );
  return {
    match,
  };
};

/**
 * Invalidate match, new data will be refetched
 *
 * @param {object} data
 */
const invalidateMatchInStore = () => async (dispatch) => {
  dispatch({
    type: MATCH_CLEAR,
  });
  await dispatch(lobbyActions.clearLobby());
};

/**
 * Clear match data from client
 */
export const clearMatch = () => {
  localStorage.removeItem(config.localStorageMatchKey);
  localStorage.removeItem(config.localStorageRoundKey);
};

export const startMatch = (matchId) => async (dispatch) => {
  // Nullify match so it's re-fetced with user match settings and marker info
  await dispatch(invalidateMatchInStore());
  // Set round in progress to 1
  localStorage.setItem(config.localStorageMatchKey, matchId);
  localStorage.setItem(config.localStorageRoundKey, 1);
};

export const kicked = () => async (dispatch) => {
  clearMatch();
  // Must redirect before clearing store
  // The page expects match data in the store else errors
  history.push("/");
  dispatch(invalidateMatchInStore());
};

/**
 * Delete the users round from the db and leave the match
 */
export const deleteRound = () => async (dispatch, getState) => {
  clearMatch();
  const state = getState();
  const match = selectors.getMatch(state);
  await api.deleteRound(match.id);
  // Must redirect before clearing store
  // The page expects match data in the store else errors
  history.push("/");
  dispatch(invalidateMatchInStore());
};

/**
 * Leave the lobby & delete users match settings
 * If host kick other players from the lobby
 */
export const leaveLobby = () => async (dispatch, getState) => {
  const state = getState();
  clearMatch();
  // User may not be in a match
  // Case: /create-lobby page
  if (state.match.match) {
    const matchId = state.match.match.id;
    await api.leaveLobby(matchId);
  }
  dispatch(invalidateMatchInStore());
};

/**
 * Set the users round settings are including ready state
 *
 * @param {number} matchId
 * @param {object} settings
 */
export const ready = (matchId, settings) => async (dispatch) => {
  const res = await api.readyForMatch(matchId, settings);
  if (res.error) return res;

  localStorage.setItem(config.localStorageMatchKey, matchId);

  await dispatch({
    type: MATCH_READY,
    matchId,
    userMatchSettings: res.json.settings,
  });
  return res.json.settings;
};

/**
 * Set the score for a hole
 *
 * At this point when a user got no score strokes will be equal to
 * constants.NO_SCORE
 *
 * @param {number} holeNumber
 * @param {number} usersStrokes
 * @param {number} markersStrokes
 */
export const setScore = (holeNumber, userStrokes, markerStrokes) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const match = selectors.getMatch(state);
  const wasMarkerPassed = markerStrokes ?? false;

  const requestBase = {
    matchId: match.id,
    holeNumber,
  };
  const setScoreRequests = [];
  setScoreRequests.push(
    api.setScore({
      ...requestBase,
      strokes: userStrokes,
      noScore: userStrokes === constants.NO_SCORE,
    })
  );
  if (wasMarkerPassed) {
    const userMatchSettings = selectors.getUserMatchSettings(state);
    setScoreRequests.push(
      api.setScore({
        ...requestBase,
        userId: userMatchSettings.marker.id,
        strokes: markerStrokes,
        noScore: markerStrokes === constants.NO_SCORE,
      })
    );
  }

  const [userRes, markerRes] = await Promise.all(setScoreRequests);

  // On hole 18 the LocalStorage variable is removed
  if (holeNumber < 18) {
    localStorage.setItem(config.localStorageRoundKey, holeNumber + 1);
  }

  await dispatch({
    type: MATCH_SET_SCORE,
    holeNumber,
    score: {
      user: userRes.json.score,
      marker: wasMarkerPassed && markerRes.json.score,
      createdAt: userRes.json.score.createdAt,
    },
  });
};

/**
 * Selectors
 */

export const selectors = {
  getMatch: (state) => state.match.match,
  getIsSocial: (state) => state.match.match.isSocial,
  getScoreLabel: (state) => state.match.match.scoreLabel,
  getScores: (state) => state.match.scores,
  getRounds: (state) => state.match.rounds,
  getIncompleteHoles: (state) => state.match.incompleteHoles,
  getCourse: (state) => state.match.match.course,
  getUserCourse: (state) => state.match.userMatchSettings?.course,
  getMarkerCourse: (state) =>
    state.match.userMatchSettings?.markerMatchSettings?.course,
  getHole: (hole) => (state) =>
    state.match.userMatchSettings?.course.holes[hole - 1],
  getMarkerHole: (hole) => (state) =>
    state.match.userMatchSettings?.markerMatchSettings?.course.holes[hole - 1],
  getUserMatchSettings: (state) => state.match.userMatchSettings,
  getMarker: (state) => state.match.userMatchSettings?.marker,
  getHasVerifiedMarkersRound: (state) => state.match.hasVerifiedMarkersRound,
};

import React from "react";
import { useParams, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ScoreInputPresenter } from "components/ScoreInput";
import { ProgressiveScoreCard } from "components/ProgressiveScoreCard";
import { BlockContainerScore } from "components/Block2";
import { SponsorInScore } from "components/Sponsor";
import config from "config";
import withMatch from "hoc/withMatch";
import * as constants from "config/constants";
import * as matchActions from "store/actions/match";
// import useHoleStats from "hooks/useHoleStats";
import { useSponsorHole, useHoleStats, useDocumentDimensions } from "hooks";
import {
  HoleStatsProvider,
  ScorecardProvider,
  ScoreProvider,
  UserProvider,
} from "components/Context";

const ScorePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useDocumentDimensions();

  const { hole } = useParams<Record<string, string>>();
  const holeNumber = parseInt(hole, 10);
  const holeStats = useHoleStats(holeNumber);

  const match = useSelector(matchActions.selectors.getMatch);
  const userMatchSettings = useSelector(
    matchActions.selectors.getUserMatchSettings
  );
  const incompleteHoles = useSelector(
    matchActions.selectors.getIncompleteHoles
  );

  const golflinkCourseId = match?.course?.golflinkCourseId;
  const { sponsor, defaultSponsor } = useSponsorHole(
    golflinkCourseId,
    holeNumber
  );
  // console.log("sponsor", sponsor);

  // set store values to 3 x context objects
  const scorecardContext = {
    isSocial: match?.isSocial,
    marker: userMatchSettings?.marker,
    markerMatchSettings: userMatchSettings?.markerMatchSettings,
    markerCourse: userMatchSettings?.markerMatchSettings?.course,
    match: match,
    matchType: match?.matchType,
    // uiConfig,
    userCourse: userMatchSettings?.course,
    userMatchSettings: userMatchSettings,
    variant: "scoring",
    // scores: scores,
    // rounds: rounds,
  };

  const scoreInputContext = {
    holeNumber,
    incompleteHoles,
    // sponsor,
  };

  const holeStatsContext = {
    markerScores: holeStats.markerScores,
    markerTeeStats: holeStats.markerTeeStats,
    playerScores: holeStats.playerScores,
    playerTeeStats: holeStats.playerTeeStats,
    time: holeStats?.time,
  };

  const sponsorH = "110px";

  // if (!match) {
  //   history.push("/");
  //   return null;
  // }

  async function handleSetScores(usersStrokes, markersStrokes) {
    await dispatch(
      matchActions.setScore(holeNumber, usersStrokes, markersStrokes)
    );
  }

  function goToNextPage() {
    if (holeNumber !== 18) {
      return history.push(`/score/${holeNumber + 1}`);
    }
    if (incompleteHoles.length) {
      return history.push(`/score/${incompleteHoles[0]}`);
    }

    localStorage.removeItem(config.localStorageMatchKey);
    localStorage.removeItem(config.localStorageRoundKey);
    return history.push(`/${match.id}/scorecard`);
  }

  return (
    <BlockContainerScore spacing={0}>
      <UserProvider>
        <ScorecardProvider value={scorecardContext}>
          <ScoreProvider value={scoreInputContext}>
            <HoleStatsProvider value={holeStatsContext}>
              <ScoreInputPresenter
                key={holeNumber}
                onSetScores={handleSetScores}
                onClickNext={goToNextPage}
                roundSettingsSlot={
                  <ProgressiveScoreCard
                    course={match.course.name}
                    matchType={match.matchType}
                    tee={constants.constantToFriendly(match.tee)}
                    handicap={userMatchSettings.dailyHandicapForShow}
                    marker={
                      !match.isSocial ? userMatchSettings.marker.fullName : null
                    }
                    markersHandicap={
                      !match.isSocial
                        ? userMatchSettings.markerMatchSettings
                            .dailyHandicapForShow
                        : null
                    }
                  />
                }
                sponsorH={sponsorH}
                sponsorSlot={
                  <SponsorInScore
                    sponsor={sponsor || defaultSponsor}
                    height={sponsorH}
                  />
                }
              />
            </HoleStatsProvider>
          </ScoreProvider>
        </ScorecardProvider>
      </UserProvider>
    </BlockContainerScore>
  );
};

export default withMatch(ScorePage);

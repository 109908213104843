import { useFormikContext } from "formik";
import { Box, Button, Text } from "@chakra-ui/react";
import {
  ConfigRowInput,
  ConfigRowInputWrap,
  ConfigRowDrawer,
  ConfigRowPickerHandicap,
  ConfigRowButtonsInline,
} from "components/Config";
import { LgLink } from "components/Typography";
import { MessageErrorApi, GolflinkNumberInput, ChForm } from "components/Form";
// import DefaultVStack from "components/VStack";
import * as handicapLib from "lib/handicap";
import { EGender, EConfigRowTheme } from "types";

export const RegisterFormLayout = () => {
  const checkFieldsValid = (formik, fields) =>
    fields.every((name) => !formik.errors[name]);

  const apiMessageErrorMap = {
    EMAIL_TAKEN: {
      primary: "An account already exists with this email",
      secondary: <LgLink to="/signin" label="Try signing in" />,
    },
  };

  const formik = useFormikContext<any>();

  return (
    <ChForm>
      <MessageErrorApi messageMap={apiMessageErrorMap} />

      {/* we don't use VStack here */}
      <Box flex="1">
        <Text textStyle="copy" color="grey.400" pb={4}>
          Already have an account? <LgLink to="/signin" label="Sign in" />
        </Text>

        <ConfigRowDrawer
          btnName="Full name"
          btnValue={formik.values.fullName}
          btnTheme={
            !!formik.values.fullName
              ? EConfigRowTheme.valid
              : EConfigRowTheme.awaiting
          }
          heading="Your full name"
          btnBackLabel="Create account"
          // theme={showBtnValid ? EConfigRowTheme.valid : EConfigRowTheme.awaiting}
        >
          {(disclosure) => (
            <ConfigRowInputWrap
              btnText="Submit"
              isValid={!formik.errors.fullName}
              onSubmit={() => disclosure.onClose()}
            >
              <ConfigRowInput placeholder="Type your name…" name="fullName" />
            </ConfigRowInputWrap>
          )}
        </ConfigRowDrawer>

        <ConfigRowDrawer
          btnName="Email"
          btnValue={formik.values.email}
          btnTheme={
            !!formik.values.email
              ? EConfigRowTheme.valid
              : EConfigRowTheme.awaiting
          }
          heading="Your email"
          btnBackLabel="Create account"
        >
          {(disclosure) => (
            <ConfigRowInputWrap
              btnText="Submit"
              isValid={!formik.errors.email}
              onSubmit={() => disclosure.onClose()}
            >
              <ConfigRowInput
                placeholder="Type your email…"
                type="email"
                name="email"
              />
            </ConfigRowInputWrap>
          )}
        </ConfigRowDrawer>

        <GolflinkNumberInput btnBackLabel="Create account" />

        <ConfigRowDrawer
          btnName="Handicap"
          btnValue={
            formik.values.handicap
              ? handicapLib.forShow(formik.values.handicap.toString())
              : "Your handicap"
          }
          btnTheme={
            !!formik.values.handicap
              ? EConfigRowTheme.valid
              : EConfigRowTheme.awaiting
          }
          heading="Your handicap"
          btnBackLabel="Create account"
        >
          {(disclosure) => (
            <ConfigRowPickerHandicap
              isInDrawer={true}
              handicap={formik.values.handicap}
              onSelect={(value) => {
                formik.setFieldValue("handicap", value);
                disclosure.onClose();
              }}
            />
          )}
        </ConfigRowDrawer>

        <ConfigRowDrawer
          btnName="Password"
          btnTheme={
            !!formik.values.password
              ? EConfigRowTheme.valid
              : EConfigRowTheme.awaiting
          }
          heading="Enter your password"
          btnBackLabel="Create account"
        >
          {(disclosure) => (
            <ConfigRowInputWrap
              btnText="Submit"
              isValid={checkFieldsValid(formik, [
                "password",
                "passwordConfirm",
              ])}
              onSubmit={() => disclosure.onClose()}
            >
              <ConfigRowInput
                placeholder="Type your password…"
                name="password"
                type="password"
              />

              <ConfigRowInput
                placeholder="Confirm Password"
                name="passwordConfirm"
                type="password"
                mt={4}
              />
            </ConfigRowInputWrap>
          )}
        </ConfigRowDrawer>

        <ConfigRowButtonsInline
          options={["Female", "Male"]}
          btnName="Sets your tee"
          btnValue="Gender"
          showBtnValid={!!formik.values.gender}
          onChange={(value) => {
            formik.setFieldValue(
              "gender",
              value === "Male" ? EGender.male : EGender.female
            );
          }}
        />
      </Box>

      {/* keep outside VStack for mobile layout */}
      <Box pt={{ lg: 12 }}>
        <Button
          type="submit"
          variant="solid"
          colorScheme="textButton"
          w="full"
          isDisabled={!(formik.dirty && formik.isValid)}
          isLoading={!formik.isValidating && formik.isSubmitting}
          loadingText="Submitting"
        >
          Create account
        </Button>
      </Box>
    </ChForm>
  );
};

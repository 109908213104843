import React from "react";
import { useLocation } from "react-router-dom";
import {
  Flex,
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Spacer,
  VStack,
  HStack,
  StackDivider,
  useMediaQuery,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  NavbarShell,
  NavbarButtonSmall,
  NavbarAccountPresenter,
  NavbarLink,
} from "./index";
import { LgLogo } from "components/Block";

interface Props {
  buttonIsWhite?: boolean;
  path: string;
  round: React.ReactNode;
  children: React.ReactNode;
}

export const Navbar = ({
  buttonIsWhite,
  path = "/",
  round,
  children,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const isNotHomePath = location.pathname !== "/";
  const isScoringPath = location.pathname.startsWith("/score/");
  const isClubhousePath = location.pathname.startsWith("/clubhouse");

  function handleClose() {
    onClose();
  }

  const whiteButtonStyles = {
    bg: "transparent",
    color: "white",
  };
  const scoringButtonStyles = {
    bg: "transparent",
    color: "text.800",
  };
  const clubhouseButtonStyles = {
    bg: "white",
    boxShadow: "lg",
    color: "text.800",
  };

  // make the DOM clean: only render when needed
  // 1024 = breakpoints.lg
  const [render] = useMediaQuery("(max-width: 1023px)");

  if (!render) {
    return null;
  }

  return (
    <NavbarShell>
      <Box pl={3}>{isNotHomePath && <LgLogo />}</Box>

      {/* for absolutely positioned notices! */}
      {children}

      <Spacer />

      <IconButton
        onClick={onOpen}
        aria-label="Open menu"
        icon={<HamburgerIcon />}
        variant="unstyled"
        fontSize="24px"
        px={0}
        zIndex={101}
        height="48px"
        width="48px"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        // flex={1}
        // themes
        {...(buttonIsWhite && whiteButtonStyles)}
        {...(isClubhousePath && clubhouseButtonStyles)}
        // {...(isScoringPath && scoringButtonStyles)}
        _hover={{
          bg: "initial",
        }}
        _focus={{
          boxShadow: isClubhousePath ? "lg" : "none",
        }}
      />
      <Drawer placement="left" size="full" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          pt={0}
          bg="complement.50"
          maxW={{
            base: "80%",
            lg: "35%",
          }}
        >
          {/* <NavbarModalHeader onClose={onClose} /> */}
          <Box flex="1" layerStyle="containerInset">
            {/* PSEUDO-CONTENT to set the layout */}
            <Flex alignItems="center" layerStyle="navHeight" color="text.800" />

            {/* NAV LIST */}
            <VStack
              mt={2}
              alignItems="flex-start"
              color="text.800"
              spacing={1}
              divider={<StackDivider borderColor="blackAlpha.200" />}
            >
              {/* ACCOUNT */}
              <Box w="full" pb={3}>
                <NavbarAccountPresenter onParentClose={handleClose} />
              </Box>

              {/* ROUND */}
              <Box w="full">{round}</Box>

              {/* CLUBHOUSE */}
              <NavbarLink link="/clubhouse" label="Clubhouse" path={path} />
              <NavbarLink
                link="/clubhouse/leaderboard"
                label="Daily Leaderboard"
                path={path}
              />
              <NavbarLink
                link="/clubhouse/your-rounds-history"
                label="Your History"
                path={path}
              />
              <NavbarLink
                link="/clubhouse/your-eclectic-round"
                label="Eclectic round"
                path={path}
              />
              <NavbarLink
                link="/clubhouse/competitions"
                label="Competitions"
                path={path}
              />

              {/* ABOUT, TERMS & PRIVACY */}
              <Box pt={5}>
                <HStack
                  spacing={3}
                  divider={<StackDivider borderColor="blackAlpha.200" />}
                >
                  <NavbarButtonSmall tiny label="About YLG" />
                  <NavbarButtonSmall tiny label="Terms of use" />
                  <NavbarButtonSmall tiny label="Your privacy" />
                </HStack>
              </Box>
            </VStack>
          </Box>
        </DrawerContent>
      </Drawer>
    </NavbarShell>
  );
};

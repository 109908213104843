/**
 * This middleware handles connecting the disconnecting the socket connection on user sign in and sign out
 *
 * Sockets requrie a auth toke as they are used to provied a given user notification information
 *
 * If requiring the socket intead of using DI is an issue for testing
 * consider moving the connection to the stores authentication actions
 */
import { LOGIN_SUCCESS, LOGOUT } from "../actions/authentication";
import socket from "../../lib/socket";

const createSocketMiddleware = () => {
  return () => {
    return (next) => (action) => {
      switch (action.type) {
        case LOGIN_SUCCESS:
          socket.connect(action.token);
          return next(action);
        case LOGOUT:
          if (socket.isConnected()) {
            socket.disconnect();
          }
          return next(action);
        default:
          return next(action);
      }
    };
  };
};

export default createSocketMiddleware;

import { Icon } from "@chakra-ui/react";
import { MdAnnouncement } from "react-icons/md";
import { ScoreMessage, scoresMessageIconStyle } from "components/Score";

export const ScoreSheetMessage = ({
  heading,
  children,
}: {
  heading: string;
  children: React.ReactNode;
}) => {
  return (
    <ScoreMessage
      isSuccessBased={false}
      bg="rgba(0,0,0,0.05)"
      color="text.800"
      iconSlot={<Icon as={MdAnnouncement} {...scoresMessageIconStyle} />}
      heading={heading}
    >
      {children}
    </ScoreMessage>
  );
};

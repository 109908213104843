import {
  Input,
  InputProps,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";

interface IConfigRowInput {
  name: string;
  placeholder?: string;
}

export const ConfigRowInput = ({
  name,
  placeholder = "Type…",
  ...props
}: IConfigRowInput & InputProps) => {
  const formik = useFormikContext();

  return (
    <FormControl isInvalid={formik.errors[name] && formik.touched[name]}>
      <Input
        variant="setRow"
        id={name}
        placeholder={placeholder}
        // name={name}
        {...formik.getFieldProps(name)}
        // spread all the Chakra Input props
        {...props}
      />
      <FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

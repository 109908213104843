import { Tabs, TabsProps } from "@chakra-ui/react";

export const ScoreTabs = (props: TabsProps) => (
  <Tabs
    className="ScoreTabs"
    d="flex"
    defaultIndex={0}
    flexDir="column"
    h="full"
    isFitted
    size="lg"
    variant="unstyled"
    // don't need flex if parent is properly sized
    // flex="1"
    // index={index}
    // onChange={onChange}
    {...props}
  ></Tabs>
);

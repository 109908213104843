import { Text, TextProps, Link } from "@chakra-ui/react";
import { Message } from "components/Form";
import config from "config";

export const MessageNoCourse = () => (
  <>
    Looks like we don't have your course data yet. Please{" "}
    <Link
      textDecoration="underline"
      isExternal
      href={`mailto:${config.siteEmail}`}
    >
      email us
    </Link>{" "}
    your course details and we'll take our best shot.
  </>
);

export const MessageNoCourseWrapped = ({ color = "white" }: TextProps) => (
  <Message underInput heading="We can't find your course">
    <Text as="div" textStyle="meta" color={color}>
      <MessageNoCourse />
    </Text>
  </Message>
);

import { Flex } from "@chakra-ui/react";
import { CardImage } from "./index";

export const CardHeaderShell = ({
  children,
  id,
  finishedSlot,
}: {
  children: React.ReactNode;
  id?: number;
  finishedSlot?: React.ReactNode;
}) => {
  return (
    <CardImage variant="header" flatBottom id={id ? id : 0}>
      {finishedSlot}
      <Flex
        alignItems={{ base: "flex-start", lg: "flex-end" }}
        direction={{ base: "column", lg: "row" }}
        h="full"
        justifyContent={{ base: "flex-end", lg: "space-between" }}
        p={{ base: 4, lg: 6 }}
        pb={{ base: 4, lg: 5 }}
        pos="relative"
        w="full"
      >
        {/* FOR LEFT & RIGHT ELEMENTS */}
        {children}
      </Flex>
    </CardImage>
  );
};

/**
 * @module FormatData
 */

/**
 * Format course data recieved from the api to the expected structure
 * @param {object} course - course from the db
 */
export function formatCourseForRowComponents(course) {
  return {
    ...course,
    id: course.id,
    name: course.state,
    value: course.name.replace("Golf Club", "GC").replace("Course", ""),
    isCourse: true,
    cardItems: {
      // Address is not in airtable, show state instead
      address: course.state,
      holes: course.holes.length,
    },
  };
}

/**
 * Format user data recieved from the api to the expected structure
 * @param {object} user - user from the db
 */
export function formatUserForRowComponents(user) {
  return {
    ...user,
    id: user.id,
    name: user.homeCourse.name,
    value: user.fullName,
    cardItems: {
      course: user.homeCourse.name,
      handicap: user.handicapForShow,
    },
  };
}

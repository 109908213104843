import { LgHeader } from "components/Block/LgHeader";
import { FormResetPassword } from "components/Forms";
import { BlockContainerScore } from "components/Block2";

const ResetPasswordPage = () => {
  return (
    <BlockContainerScore spacing={0} pb="w7">
      <LgHeader heading="Reset your password" headingKind="hSecondary" />
      <FormResetPassword />
    </BlockContainerScore>
  );
};

export default ResetPasswordPage;

// import type { TGender, IUser } from "types";

export enum EGender {
  male = "MALE",
  female = "FEMALE",
}

export type TGender = "MALE" | "FEMALE";

export interface IUser {
  // avatar: string;
  email: string;
  fullName: string;
  golflinkNumber: string;
  handicap: string;
  id: string;
}

export interface IRoundPlayerBase extends IUser {
  avatar?: string;
  gender: TGender;
  homeCourse?: {
    // clubName: string;
    id?: number;
    name?: number;
    state?: number;
  };
  isEmailVerified?: boolean;
  updatedAt?: string;
}

export interface IPlayerBase extends IUser {
  avatar?: string;
  createdAt: string;
  gender: TGender;
  homeCourse?: {
    // clubName: string;
    id?: number;
    name?: number;
    state?: number;
  };
  isEmailVerified?: boolean;
  updatedAt?: string;
}

export interface IPlayer {
  id: number;
  // player
  // name?: string;
  fullName?: string;
  avatar?: string | undefined;
  clubName?: string;
  homeCourse?: {
    clubName: string;
  };
  handicap?: number;
  playingHandicap?: number;
  markerName?: string;
  // scores
  stablefordScore?: number;
  parScore?: number;
  nettScore?: number;
  nett?: string | number;
}

export interface IPlayerRecord extends IPlayer {
  scoreTotals?: {
    gross: number;
    nett: number;
    par: number;
    stableford: number;
  };
}

export interface IInvitedPlayer extends IPlayer {
  ready: boolean;
  markers: any[];
}

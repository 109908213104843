import React from "react";
import { Flex, TabList, TabPanels, TabPanel, Box } from "@chakra-ui/react";
import { ScorePoster } from "./index";
import { ScoreTab, ScoreTabs } from "../ScoreTabs";
import * as nameLib from "lib/name";
import { useScorecardContext, useHoleStatsContext } from "components/Context";

/*
  ScoresSummary is used on:
  * ScorecardRowCard
  * ScoreInputPresenter
*/

interface IScoresSummary {
  isForScorecardPage?: boolean;
  markerMessage?: React.ReactNode;
  markerRoundSigned?: boolean;
  mismatchingScore?: boolean;
  playerMessage?: React.ReactNode;
  playerRoundSigned?: boolean;
  tabIndex: number;
  // events, etc
  onEditClick: (event) => void;
  onTabClick: (index) => void;
  children?: React.ReactNode;
}

export function ScoresSummary({
  isForScorecardPage,
  mismatchingScore,
  playerRoundSigned,
  markerRoundSigned,
  playerMessage,
  markerMessage,
  tabIndex,
  onEditClick,
  onTabClick,
  children,
}: IScoresSummary) {
  const {
    isSocial,
    marker,
    markerMatchSettings,
    userMatchSettings,
  } = useScorecardContext();
  const { markerScores, playerScores, time } = useHoleStatsContext();

  return (
    <Flex h="full" flex="1" direction="column">
      <ScoreTabs index={tabIndex} onChange={(index) => onTabClick(index)}>
        <TabList>
          {markerScores && (
            <ScoreTab
              label={`${nameLib.getFirstName(marker.fullName)}'s score`}
              handicap={markerMatchSettings.dailyHandicap}
            ></ScoreTab>
          )}
          <ScoreTab
            label="Your score"
            handicap={userMatchSettings.dailyHandicap}
            showNoBorder={!markerScores}
          ></ScoreTab>
        </TabList>

        {/* PANELS */}
        <TabPanels px={6} flex="1">
          {markerScores && (
            <TabPanel p={0} flex="1">
              <ScorePoster
                score={markerScores}
                time={time}
                editable={
                  isForScorecardPage
                    ? !markerRoundSigned && mismatchingScore
                    : !markerRoundSigned
                }
                onEditClick={() => onEditClick({ editingMarker: true })}
              />
              {/* Marker message */}
              {markerMessage && <Box pt={2}>{markerMessage}</Box>}
            </TabPanel>
          )}
          <TabPanel p={0} flex="1" d={isSocial ? "block !important" : ""}>
            <ScorePoster
              score={playerScores}
              time={time}
              editable={
                isForScorecardPage
                  ? !playerRoundSigned && mismatchingScore
                  : !playerRoundSigned
              }
              onEditClick={() => onEditClick({ editingMarker: false })}
            />
            {/* Player message */}
            {playerMessage && <Box pt={2}>{playerMessage}</Box>}
          </TabPanel>
        </TabPanels>
      </ScoreTabs>

      {/* For actions if required, eg. button CTA */}
      {/* DO NOT USE for MESSAGES! */}
      {children}
    </Flex>
  );
}

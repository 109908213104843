import { Flex, useMediaQuery, Box, Icon } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectors as authSelectors } from "store/actions/authentication";
import { MdWarning } from "react-icons/md";
import { TableRowElement, TableRowGrid } from "./index";
import { RowLeft, TextMeta, Score } from "./RowElements";
import { EMatchType, IPlayerBase } from "types";

/*
  Exact duplicate of TableRowRound w/o any modal child
  …and new data shape for round
 */

interface Round {
  createdAt: string;
  totalScorePar: number;
  totalScoreStableford: number;
  totalScoreStrokeNett: number;
}

export function TableRowRoundStatic({
  index,
  round,
}: {
  index: number;
  round: Round;
}) {
  const [breakpoint] = useMediaQuery("(min-width: 1024px)");
  const user: IPlayerBase = useSelector(authSelectors.getUser);
  console.log(user);

  const player = {
    avatar: user.avatar || undefined,
    name: user.fullName,
    handicap: user.handicap,
  };

  return (
    // the `px` allows us nice overflow alignment on mobile
    // NB! must always be matched with mx on the parent!
    <TableRowGrid px={{ base: "inset", lg: 0 }}>
      {/* L */}
      <TableRowElement variant="round" isLeft hasDivider index={index}>
        <Flex
          alignItems="baseline"
          justifyContent="flex-end"
          w={{ base: "20px", lg: 10 }}
          transform="translateY(0.25em)"
        >
          <TextMeta>{index + 1}</TextMeta>
        </Flex>
        <RowLeft
          player={player}
          date={round.createdAt}
          // showPlayingHandicap
          // ready for incomplete holes warning
          iconSlot={
            <Box pl={1.5} transform="translateY(-0.1em)">
              <Icon as={MdWarning} color="form.bad" boxSize="1.2em" />
            </Box>
          }
        />
      </TableRowElement>

      {/* R */}
      <TableRowElement
        variant="round"
        index={index}
        justifyContent="flex-end"
        showGradient={breakpoint ? false : true}
        // this whole thing is wrapped by TableRowGrid
        // but setting a width here is a nice easy customisation
        // width={{ xl: "540px" }}
      >
        {/* {breakpoint && round.marker && <Marker name={round.marker.fullName} />} */}
        <Score
          value={round.totalScoreStableford ? round.totalScoreStableford : "–"}
          matchType={EMatchType.stableford}
          showEdge
        />
        <Score
          value={round.totalScorePar ? round.totalScorePar : "–"}
          matchType={EMatchType.par}
          showEdge
        />
        <Score
          value={round.totalScoreStrokeNett ? round.totalScoreStrokeNett : "–"}
          matchType={EMatchType.stroke}
          showEdge
        />
      </TableRowElement>
    </TableRowGrid>
  );
}

import React from "react";
import { Text, Box, Flex } from "@chakra-ui/react";

export const NavsideHeading = ({
  label = "Round Play",
  icon,
}: {
  label: string;
  icon: React.ReactNode;
}) => (
  <Flex
    as="span"
    justifyContent="flex-start"
    alignItems="center"
    textStyle="body"
    fontWeight="500"
    color="text.800"
    w="full"
    h="36px"
    borderBottom="1px solid"
    borderColor="grey.100"
    mb="6px"
  >
    <Box
      transform="translateY(-0.1em)"
      sx={{
        " .chakra-icon": {
          fill: "currentColor",
          h: "1.125em",
          w: "1.125em",
        },
      }}
    >
      {icon}
    </Box>
    <Text as="span" ml={1}>
      {label}
    </Text>
  </Flex>
);

import { useCallback, useEffect, useRef, useState } from "react";

type Dimensions = {
  width: number | null;
  height: number | null;
};

export const useDocumentDimensions = () => {
  // Check if the window object is available for SSR compatibility
  const hasWindow = typeof window !== "undefined";

  // Initialize state to store screen dimensions
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: null,
    height: null,
  });

  // Initialize state to store estimated keyboard height
  const [keyboardHeight, setKeyboardHeight] = useState<number | null>(null);

  // Reference to store the initial dimensions for comparison
  const initialDimensions = useRef<Dimensions | null>(null);

  // Function to retrieve the current dimensions
  const getDimensions = useCallback((): Dimensions => {
    if (!hasWindow) return { width: null, height: null };
    const width = window.innerWidth;
    const height = window.visualViewport
      ? window.visualViewport.height
      : window.innerHeight;
    return { width, height };
  }, [hasWindow]);

  // Function to update CSS variables in the :root scope
  const updateRootCSSVariable = useCallback(
    (
      width: number | null,
      height: number | null,
      keyboardHeight: number | null
    ) => {
      if (hasWindow) {
        document.documentElement.style.setProperty(
          "--viewport-width",
          width ? `${width}px` : "0"
        );
        document.documentElement.style.setProperty(
          "--viewport-height",
          height ? `${height}px` : "0"
        );
        document.documentElement.style.setProperty(
          "--keyboard-height",
          keyboardHeight ? `${keyboardHeight}px` : "0"
        );
      }
    },
    [hasWindow]
  );

  // handle changes in dimensions
  useEffect(() => {
    if (hasWindow) {
      const handleResize = (e: Event) => {
        const newDimensions = getDimensions();

        // If initialDimensions haven't been set yet, set them
        if (initialDimensions.current === null) {
          initialDimensions.current = newDimensions;
        }

        // Calculate the change in height
        const deltaHeight =
          initialDimensions.current &&
          initialDimensions.current.height !== null &&
          newDimensions.height !== null
            ? initialDimensions.current.height - newDimensions.height
            : 0;

        // If the height change is significant, assume the keyboard is open and update its estimated height
        if (Math.abs(deltaHeight) > 200) {
          setKeyboardHeight(Math.abs(deltaHeight));
          initialDimensions.current = newDimensions;
        }

        // Update the dimensions and CSS variables accordingly
        setDimensions(newDimensions);
        updateRootCSSVariable(
          newDimensions.width,
          newDimensions.height,
          keyboardHeight
        );
      };

      // Initialize dimensions and CSS variables once
      const initDimensions = getDimensions();
      updateRootCSSVariable(
        initDimensions.width,
        initDimensions.height,
        keyboardHeight
      );

      // Attach event listeners for resizing
      window.addEventListener("resize", handleResize);
      window.addEventListener("orientationchange", handleResize);
      window.visualViewport?.addEventListener("resize", handleResize);

      // Cleanup
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("orientationchange", handleResize);
        window.visualViewport?.removeEventListener("resize", handleResize);
      };
    }
  }, [hasWindow, getDimensions, keyboardHeight, updateRootCSSVariable]);

  return { dimensions, keyboardHeight };
};

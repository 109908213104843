import * as Yup from "yup";
import * as schemas from "lib/schemas";
import { Formik } from "formik";
import {
  Box,
  Text,
  Button,
  Input,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { MessageErrorApi, Message, ChForm } from "components/Form";
import { LgLink } from "components/Typography";
import DefaultVStack from "components/VStack";
import * as api from "lib/api";

export const FormForgotPassword = () => {
  const apiMessageErrorMap = {
    EMAIL_UNVERIFIED: {
      primary: "Please verify your email.",
      secondary: (
        <>
          Please check your email for a verification code. Can't find it?{" "}
          <LgLink to="/resend-verification-email" label="Send a new link" />
        </>
      ),
    },
    INVALID_CREDENTIALS: {
      primary: "Invalid email or password",
    },
  };

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    setStatus({ successful: false });
    const response = await api.forgotPassword(values.email);

    if (response.error) {
      setSubmitting(false);
      setStatus({
        apiError: response.error,
      });
      return;
    }

    setStatus({ successful: true });
  };

  return (
    <Formik
      initialStatus={{
        successful: false,
      }}
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        email: schemas.email,
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <ChForm>
          <DefaultVStack flex="1" spacing={4}>
            <Text textStyle="copy" color="grey.400">
              Get a link to reset your password.
              <br />
              Remembered it? <LgLink to="/signin" label="Sign in" />
            </Text>

            <FormControl
              isInvalid={
                formik.errors.email && formik.touched.email ? true : false
              }
            >
              <Input
                {...formik.getFieldProps("email")}
                id="email"
                variant="setRow"
                placeholder="Type your email…"
                type="email"
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            {formik.status.successful && (
              <Message success heading="Sent!">
                Please check your email to set a new password
              </Message>
            )}

            <MessageErrorApi messageMap={apiMessageErrorMap} />
          </DefaultVStack>

          {/* keep outside VStack for mobile layout */}
          <Box pt={{ lg: 12 }}>
            <Button
              type="submit"
              variant="solid"
              colorScheme="textButton"
              w="full"
              isDisabled={!(formik.dirty && formik.isValid)}
              isLoading={!formik.isValidating && formik.isSubmitting}
              loadingText="Submitting"
            >
              Send Password Reset
            </Button>
          </Box>
        </ChForm>
      )}
    </Formik>
  );
};

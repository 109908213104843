// @refresh reset
// https://next.chakra-ui.com/docs/theming/component-style

// multipart component
// name must be Sentence case
const Tabs = {
  parts: ["tabs", "tablist", "tab", "tabpanel"],
  baseStyle: {
    tabs: {
      // bg: "brand.500",
    },
    tablist: {
      // borderBottomWidth: "1px",
      // borderBottomColor: "blackAlpha.100",
    },
    tab: {
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  sizes: {
    sm: {
      tab: {
        fontSize: "rg",
        px: 2,
        py: 2,
      },
    },
  },
  variants: {
    account: {
      tab: {
        color: "black",
        _selected: {
          color: "brand.500",
          borderBottom: "brand.500",
        },
        _disabled: {
          color: "gray.500",
        },
      },
    },
    meta: {
      tab: {
        bg: "transparent",
        color: "text.600",
        _focus: {
          outline: "none",
          boxShadow: "none",
          zIndex: "1",
        },
      },
    },
  },
  defaultProps: {
    tab: {
      size: "sm",
      variant: "account",
    },
  },
};

export default Tabs;

import * as React from "react";
import { useSelector } from "react-redux";
import { selectors as authSelectors } from "store/actions/authentication";
import { IPlayerBase } from "types";

const UserContext = React.createContext<IPlayerBase | undefined>(undefined);

function UserProvider({ children }) {
  const user: IPlayerBase = useSelector(authSelectors.getUser);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

function useUserContext() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserContext };

import { Flex, Button, Text, UseDisclosureProps } from "@chakra-ui/react";
import { ValidationModal } from "components/Notices";
// import useQuery from "../../hooks/useQuery";
// import useOpenOnTimeout from "../../hooks/useOpenOnTimeout";

/* INVITED PLAYER KICK-OUT NOTICE */
export const NoticeAwaitingPlayers = ({
  isOpen,
  onClose,
}: UseDisclosureProps) => {
  // const query = useQuery();
  // const fromLobbyEnded = query.get("lobby-ended");
  // const { isOpen, onClose } = useOpenOnTimeout(fromLobbyEnded, 1000);

  return (
    <ValidationModal
      kind="half"
      heading="Awaiting invited players"
      closeButton
      isOpen={isOpen}
      onClose={onClose}
    >
      <Text textStyle="copy" color="white" fontWeight="500">
        We're waiting on an invited player to set their round properly before we
        can begin.
      </Text>
      <Text textStyle="meta" color="white" pt={1} opacity="0.7">
        Ask your fellow players if theyre having trouble. Once they've set their
        round and clicked "accept". we're good to go.
      </Text>
      <Flex pt={6} justify="center">
        <Button
          size="sm"
          variant="outline"
          colorScheme="whites"
          px={8}
          onClick={onClose}
        >
          OK
        </Button>
      </Flex>
    </ValidationModal>
  );
};

import * as api from "../../lib/api";

export const LOBBY_CLEAR = "LOBBY_CLEAR";
export const LOBBY_SET_USERS = "LOBBY_SET_USERS";
export const LOBBY_INVITE_USER = "LOBBY_INVITE_USER";
export const LOBBY_DELETE_INVITE = "LOBBY_DELETE_INVITE";
export const LOBBY_SET_READY_STATE = "LOBBY_SET_READY_STATE";

/**
 * Set list of lobby users
 *
 * @param {object} data
 * @param {Array.<object>} data.users - all users in the lobby
 * @param {Array.<object>} data.invited - users invited to the lobby
 */
export const setLobbyUsers = (data) => async (dispatch) => {
  dispatch({
    type: LOBBY_SET_USERS,
    users: data.users,
    invited: data.invited,
  });
};

/**
 * Add a user to the lobby
 *
 * @param {object} user
 */
export const inviteUserToLobby = (user, matchId) => async (dispatch) => {
  const response = await api.inviteToLobby(user.id, matchId);

  if (response.error) {
    return;
  }

  dispatch({
    type: LOBBY_INVITE_USER,
    user,
  });
};

/**
 * Delete an invite
 *
 * @param {object} user
 */
export const deletePlayerInvite = (userId, matchId) => async (dispatch) => {
  const response = await api.deleteInvite(userId, matchId);

  if (response.error) {
    return;
  }

  dispatch({
    type: LOBBY_DELETE_INVITE,
    userId,
  });
};

/**
 * Clear lobby state
 *
 * @param {object} user
 */
export const clearLobby = (userId, matchId) => async (dispatch) => {
  dispatch({
    type: LOBBY_CLEAR,
  });
};

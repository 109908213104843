import withMatch from "hoc/withMatch.js";
import withMatchSocket from "hoc/withMatchSocket.js";
import { BlockContainerScore } from "components/Block2";
import { LgHeader } from "components/Block";
import LobbyHost from "components/Lobby/LobbyHost";
import { UserProvider } from "components/Context/User";

const LobbyPage = () => {
  return (
    <BlockContainerScore spacing={0}>
      <LgHeader heading="Round settings" />
      <UserProvider>
        <LobbyHost />
      </UserProvider>
    </BlockContainerScore>
  );
};

export default withMatch(withMatchSocket(LobbyPage));

/*
 * Configuration file
 * For application wide access to environment variables and shared site data
 *
 * Sensitive data should be stored in a .env file
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  PROD: process.env.NODE_ENV === "production",

  siteUrl: process.env.REACT_APP_SITE_URL,
  serverUrl: process.env.REACT_APP_SERVER_URL,
  datoUrl: "https://graphql.datocms.com",
  datoApiToken: process.env.REACT_APP_DATO_API_TOKEN,

  // Project specific
  brandUrl: "https://www.yourlocalgolf.com",
  siteEmail: "info@yourlocalgolf.com.au",

  // Storage
  localStorageBearerKey: "auth_token",
  localStorageMatchKey: "current_match_id",
  localStorageRoundKey: "current_round_number",

  // brand images
  imgCourseWithFlag:
    "https://res.cloudinary.com/your-local-golf-2/image/upload/c_scale,w_800/v1660973507/your-local-golf/local-hero-portrait-211009-3_npwlve.jpg",
  imgCourseWithFlagFallback:
    "https://res.cloudinary.com/your-local-golf-2/image/upload/c_scale,e_blur:50,w_100/v1660973507/your-local-golf/local-hero-portrait-211009-3_npwlve.jpg",
  imgGrassLandscape:
    "https://res.cloudinary.com/your-local-golf-2/image/upload/c_scale,w_2000/v1660973521/your-local-golf/comp-card-view-03_pzrfwf.jpg",
  imgGrassLandscapeFallback:
    "https://res.cloudinary.com/your-local-golf-2/image/upload/c_scale,e_blur:50,w_300/v1660973521/your-local-golf/comp-card-view-03_pzrfwf.jpg",
  imgGrassPortrait:
    "https://res.cloudinary.com/your-local-golf-2/image/upload/c_scale,w_8000/v1660975837/your-local-golf/local-hero-portrait-211009-2_dpllby.jpg",
  imgTestAvatar:
    "https://res.cloudinary.com/your-local-golf-2/image/upload/c_scale,w_100/v1660976272/your-local-golf/test-avatar.jpg",
};

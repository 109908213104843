import { Box, Flex, Text, Button } from "@chakra-ui/react";
import {
  ConfigRow,
  ConfigRowDrawer,
  ConfigRowPickerHandicap,
  AvatarIcon,
} from "../Config";
import { IPlayerBase, EConfigRowTheme, EPlayType } from "types";

export interface ILobbyUserSettings {
  settings: any;
  marker?: IPlayerBase;
  match: {
    course: any;
    playType: string;
  };
  invitedUser?: boolean;
  isFormComplete?: boolean;
  onChange: (key: string, value: any) => void;
  onSubmit?: () => void;
}

export const LobbyUserSettings = ({
  settings,
  marker,
  match,
  invitedUser,
  isFormComplete,
  onChange,
  onSubmit,
}: ILobbyUserSettings) => {
  return (
    <Flex direction="column" h="full">
      <Text
        textStyle="copy"
        color="text.500"
        pb={4}
        mb={1}
        borderBottom="1px"
        borderColor="primary.50"
      >
        To set your Round Handicap accurately, make sure your Official Handicap
        is up to date. This is set by your country's Golf Association.
      </Text>
      <Box flex="1">
        {/* OFFICIAL HANDICAP: last 20 rounds, official */}
        <ConfigRowDrawer
          heading="Update your handicap"
          btnBackLabel="Settings"
          btnName="Update your Official Handicap"
          btnValue={settings.handicapForShow}
          btnTheme={EConfigRowTheme.valid}
        >
          {(disclosure) => (
            <ConfigRowPickerHandicap
              isInDrawer
              handicap={settings.handicap}
              onSelect={(value) => {
                onChange("handicap", value);
                disclosure.onClose();
              }}
            />
          )}
        </ConfigRowDrawer>

        {/* ROUND HANDICAP: computed from course & round settings */}
        <ConfigRow
          name="Your Round Handicap"
          value={settings.dailyHandicapForShow}
          theme={EConfigRowTheme.valid}
          isSet
        />

        {/* IF INVITED PLAYER, SHOW MARKER */}
        {/* Prior to automated markers, this showed marker search */}
        {invitedUser && match.playType === EPlayType.comp && (
          <ConfigRow
            name="Your marker"
            value={marker.fullName}
            theme={EConfigRowTheme.valid}
          >
            <AvatarIcon name={marker.fullName} src={marker.avatar} />
          </ConfigRow>
        )}
      </Box>

      {!invitedUser && (
        <Box layerStyle="containerScoreBtn" px={0}>
          <Button
            variant="solid"
            colorScheme="textButton"
            w="full"
            onClick={onSubmit}
          >
            Save
          </Button>
        </Box>
      )}
    </Flex>
  );
};

// @refresh reset
// import { theme, extendTheme, mode } from "@chakra-ui/react";
// import { extendTheme } from "@chakra-ui/react";

// Theme colors extend the Chakra base colors
// https://github.com/chakra-ui/chakra-ui/blob/develop/packages/theme/src/foundations/colors.ts

// the Chakra color tokens begin at 500
// the tints go down to 50 and shades up to 900

// this enables the button colorScheme to use a color key, not a value
// https://github.com/chakra-ui/chakra-ui/issues/203#issuecomment-545016739

// colour palette
// https://coolors.co/facc52-b0c9a8-b2dff5-b5e0de-fcd8cd-f2ebd6-e8e0dd-eff7f6

// or see: https://www.notion.so/callum/Design-system-components-tokens-2b8676cbf9034d9aa8541ddd3cab0671

const colors = {
  neutral: {
    100: "#fcfcfc",
    200: "#f9f9f9",
    // 300: "#F7F9F8",
    300: "#f7f9fa",
    // #F3F4F3
    // breeze.500
  },
  legend: {
    noscore: "#4D4D4D", // 333?
    doublebogey: "#b2580a",
    bogey: "#FF7A00",
    par: "#e1e1e1",
    birdie: "#91dced",
    eagle: "#2dd0f5",
    holeinone: "#0fcf6a",
    // 500: "#FFA837",
    // 600: "#f48c12",
    // 700: "#ff7601",
  },
  // https://coolors.co/154a48
  // primary: {
  //   50: "rgba(21, 74, 72, 0.125)",
  //   75: "rgba(21, 74, 72, 0.175)",
  //   100: "rgba(21, 74, 72, 0.25)",
  //   200: "rgba(21, 74, 72, 0.4)",
  //   300: "rgba(21, 74, 72, 0.5)",
  //   400: "rgba(21, 74, 72, 0.7)",
  //   401: "#DFF0F1",
  //   // 500: "#154a48",
  //   500: "#204f6b",
  //   600: "#154a48",
  //   700: "#154a48",
  // },
  primary: {
    50: "rgb(51 51 51 / 12%)",
    75: "rgb(51 51 51 / 17%)",
    100: "rgb(51 51 51 / 25%)",
    200: "rgb(51 51 51 / 40%)",
    300: "rgb(51 51 51 / 50%)",
    400: "rgb(51 51 51 / 70%)",
    500: "rgb(51 51 51 / 78%)",
    600: "rgb(51 51 51 / 87%)",
    700: "#333",
  },
  // #333
  text: {
    // 50: "rgb(51 51 51 / 12%)",
    // 75: "rgb(51 51 51 / 17%)",
    // 100: "rgb(51 51 51 / 25%)",
    // 200: "rgb(51 51 51 / 40%)",
    // 300: "rgb(51 51 51 / 50%)",
    // 400: "rgb(51 51 51 / 78%)",
    // 500: "rgb(51 51 51 / 87%)",
    // 600: "rgb(51 51 51 / 100%)",
    // Figma
    // 50: "#e7e7e7",
    // 75: "#dcdcdc",
    // 100: "#a6a6a6",
    // 200: "#7c7c7c",
    // 300: "#666666",
    // 400: "#3d3d3d",
    // 500: "#333",
    // 600: "#333",
    // 700: "#333",
    // 800: "#111",
    // themera.vercel.app using #333
    50: "#F2F2F2",
    100: "#DBDBDB",
    200: "#cacaca",
    300: "#ADADAD",
    400: "#969696",
    500: "#808080",
    600: "#666666",
    700: "#4D4D4D",
    800: "#333333",
    900: "#1A1A1A",
  },
  // chakra buttons rely this, which is kinda constrained:
  // default bg: 500
  // hover bg: 600
  // active bg: 700
  // see: https://github.com/chakra-ui/chakra-ui/blob/4e2d7b5899467a74df81f2ed601424fd726980e7/packages/theme/src/components/button.ts#L107
  textButton: {
    50: "#fdfdfd", // text.50
    100: "#F2F2F2", // text.50
    200: "##dfdfdf", // // text.200
    500: "#333", // text.800
    600: "#1A1A1A", // text.900
    700: "#1A1A1A", // text.900
  },
  // #524A3E
  grey: {
    // 50: "rgb(82 74 62 / 12%)",
    // 75: "rgb(82 74 62 / 17%)",
    // 100: "rgb(82 74 62 / 25%)",
    // 200: "rgb(82 74 62 / 40%)",
    // 300: "rgb(82 74 62 / 50%)",
    // 400: "rgb(82 74 62 / 70%)",
    // 500: "rgb(82 74 62 / 78%)",
    // 600: "rgb(82 74 62 / 87%)",
    // 700: "rgb(82 74 62 / 100%)",
    // themera.vercel.app using #3A342C
    // 50: "#F4F3F0",
    // 100: "#E0DCD6",
    // 200: "#CCC6BC",
    // 300: "#B9B0A2",
    // 400: "#A59988",
    // 500: "#91836E",
    // 600: "#746958",
    // 700: "#574F42",
    // 800: "#3A342C",
    // 900: "#1D1A16",
    // themera.vercel.app using #504D49
    50: "#F3F2F2",
    100: "#eaeaea",
    200: "#C7C5C2",
    300: "#B1AEAA",
    400: "#9B9891",
    500: "#868179",
    600: "#6B6761",
    700: "#504D49",
    800: "#353431",
    900: "#1B1A18",
  },
  // https://coolors.co/ff9100
  orange: {
    50: "#FFF7ED",
    100: "#FFDFB6",
    200: "#FFDFB6",
    300: "#FFC880",
    400: "#FFB049",
    500: "#FFA837",
    600: "#f48c12", // FF9100 ffa027 FF9100
    700: "#ff7601", // FF9100 ffa027
  },
  // https://coolors.co/d2e9eb light cyan
  breeze: {
    100: "rgb(210 233 235 / 25%)",
    200: "rgb(210 233 235 / 40%)",
    300: "rgb(210 233 235 / 70%)",
    301: "#dff0f1",
    400: "rgb(210 233 235 / 70%)",
    // ------------------------------- nice, was used on mobile nav panel
    500: "rgb(210, 233, 235)",
    // 500: "rgb(130 226 187)",
  },
  // 500 = https://coolors.co/EBF5F6 azure
  mint: {
    500: "#EBF5F6", // --------------------------- where used?
  },
  // https://coolors.co/72B9C0
  midblue: {
    50: "rgb(114 185 192 / 20%)",
    400: "#8ec7cc",
    500: "#72B9C0",
  },
  // https://coolors.co/13c2c3 maximum blue green
  complement: {
    // 10: "rgb(209 230 204 / 30%)",
    // 50: "rgb(210 233 235 / 70%)",
    10: "rgb(210 233 235 / 30%)",
    // ----------------------------------------- this is the header bg
    // 50: "rgb(210 233 235 / 70%)",
    // 50: "rgb(55 239 181)",
    // spotify green = #1cb954
    // 50: "rgb(42 224 150)", // fluro green
    // 50: "#45ef81", // fluro green
    // 50: "#ff682e", // orange
    // 50: "#ff9143", // orange
    // 50: "#d0eef5", // breeze
    // 50: "#f7f0d7", // beigey
    50: "#d7ecef", // dirty sky
    // ----------------------------------------- mobile nav panel, breeze.500
    75: "rgb(210, 233, 235)",
    // -------------------------------------------- unsure these are used…
    100: "#e4ecec", // light-grey-mint
    200: "rgba(19, 194, 195, 0.4)",
    300: "rgba(19, 194, 195, 0.5)",
    400: "rgba(19, 194, 195, 0.7)",
    500: "#13c2c3",
    // 500: "#EBF5F6",
    600: "#13c2c3",
  },
  tone: {
    50: "rgba(205, 231, 232, 0.075)",
    100: "rgba(205, 231, 232, 0.2)",
    200: "rgba(205, 231, 232, 0.4)",
    300: "rgba(205, 231, 232, 0.5)",
    400: "rgba(205, 231, 232, 0.7)",
    500: "#cde7e8",
    600: "#cde7e8",
  },
  form: {
    good: "#38a169",
    goodTint: "rgba(56, 161, 105, 0.1)",
    bad: "#e53e3e",
    badTint: "rgba(229, 62, 62, 0.1)",
  },
  // for bad button
  formbad: {
    300: "rgba(229, 62, 62, 0.1)",
    500: "#e53e3e",
    600: "#e53e3e",
  },
  // for chakra buttons
  // not sure why there is no simple Chakra default for this…
  blacks: {
    50: "rgb(0 0 0 / 9%)",
    100: "rgb(0 0 0 / 18%)",
    500: "#151515",
    600: "#151515",
  },
  whites: {
    50: "rgb(255 255 255 / 9%)",
    100: "rgb(255 255 255 / 18%)",
    500: "#fff",
    600: "#fff",
  },
};

export default colors;

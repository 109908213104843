import { Button, Circle, useDisclosure, Icon } from "@chakra-ui/react";
import { DeleteModal } from "./index";
import { MdClose } from "react-icons/md";
import { IPlayer } from "types";

export const compPlayerButtonH = { base: "32px", lg: "40px" };

export const compPlayerButton = {
  w: "full",
  variant: "ghost",
  size: "lg",
  h: compPlayerButtonH,
  px: { base: 0, lg: 2 },
  color: "black",
  fontWeight: "400",
  border: "1px solid",
  borderColor: "transparent",
  borderRadius: "2px",
  transition: "all 0.4s cubic-bezier(.08,.52,.52,1)",
};

export const DeleteCompPlayerModal = ({
  index,
  player,
  onClick,
}: {
  index: number;
  player: IPlayer;
  onClick: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    onClick();
    onClose();
  };

  return (
    <>
      <Button
        key={index.toString()}
        onClick={onOpen}
        {...compPlayerButton}
        justifyContent="space-between"
        _hover={{
          bg: "neutral.100",
          borderColor: "grey.100",
        }}
        sx={{
          "&:hover .chakra-button__icon div": {
            bg: "transparent",
          },
          "&:hover .chakra-button__icon svg": {
            color: "text.800",
          },
        }}
        rightIcon={
          <Circle bg="transparent" p="2px">
            <Icon
              as={MdClose}
              boxSize="1em"
              color={{ base: "currentColor", lg: "transparent" }}
            />
          </Circle>
        }
      >
        {player.fullName}
      </Button>
      {/* nameLib.getFirstName(player.fullName) */}
      <DeleteModal
        heading={`Remove ${player.fullName}?`}
        confirm="Are you sure? Are they really that bad?"
        buttonLabel="Remove player"
        isOpen={isOpen}
        onClose={onClose}
        onClick={handleClick}
      />
    </>
  );
};

import { Text, TextProps } from "@chakra-ui/react";

export const TextMeta = (props: TextProps) => (
  <Text
    as="div"
    textStyle="metaButton"
    // fontWeight="500"
    color="grey.400"
    letterSpacing="wide"
    {...props}
  >
    {props.children}
  </Text>
);

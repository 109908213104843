import React from "react";
import * as courseLib from "lib/course";
import { useScorecardContext } from "components/Context";

/*
  This hook must be used within ScorecardProvider
  in order to useScorecardContext
 */

export default function useFormatScoreTotals() {
  // const { match, userMatchSettings, scores } = useScorecardContext();
  const context = useScorecardContext();

  // Runs on initial render and after editing a score
  return React.useMemo(() => {
    const userScores = [];
    const markerScores = [];
    context &&
      Object.entries(context.scores).forEach(([key, score]) => {
        userScores.push(score.user);
        markerScores.push(score.marker);
      });
    const user = courseLib.calcScoreTotals(userScores, {
      matchType: context.match?.matchType,
      dailyHandicap: context.userMatchSettings?.dailyHandicap,
    });
    let marker;
    if (context.userMatchSettings?.marker) {
      marker = courseLib.calcScoreTotals(markerScores, {
        matchType: context.match?.matchType,
        dailyHandicap:
          context.userMatchSettings?.markerMatchSettings.dailyHandicap,
      });
    }
    return {
      user,
      marker,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.match]);
}

import { Box, Divider } from "@chakra-ui/react";
import { FormElement } from "components/ClubCompCreate";

export const FormElementDivider = () => {
  return (
    <FormElement>
      <Box py={2}>
        <Divider opacity="100%" borderColor="grey.100" />
      </Box>
    </FormElement>
  );
};

import { ModalCloseButton } from "@chakra-ui/react";

export const LgModalCloseButton = ({
  reverse,
  size = 8,
  topRight = "w3",
  ...props
}: {
  reverse?: boolean;
  topRight?: any;
  [x: string]: any;
}) => {
  const sizeStyle = {
    height: size,
    width: size,
  };
  return (
    <ModalCloseButton
      rounded="full"
      bg={reverse ? "blackAlpha.500" : "blackAlpha.100"}
      color={reverse ? "white" : "currentColor"}
      top={topRight}
      right={topRight}
      zIndex={1}
      _hover={
        reverse
          ? { bg: "blackAlpha.700", color: "white" }
          : { bg: "blackAlpha.200", color: "black" }
      }
      _focus={{ FlexShadow: "none" }}
      _active={{ bg: "black", color: "white" }}
      {...sizeStyle}
      {...props}
    />
  );
};

import {
  Tab,
  Box,
  Text,
  useTab,
  UseTabProps,
  forwardRef,
} from "@chakra-ui/react";
// import { buttonH } from "theme/components/button";

// COMPOSED: CHAKRA TAB
// `isSelected` is passed to all children of `TabList`
// https://chakra-ui.com/tabs#creating-custom-tab-components
//  <TabList>
//   <Tab>

// Saved gradient styles
// linear-gradient(to top, rgb(209 230 204 / 70%) 50%, rgb(209 230 204 / 100%))

interface IScoreTab extends UseTabProps {
  label?: string;
  handicap?: number; // this gets assigned in ScoresSummary
  showNoBorder?: boolean;
  className?: string;
}

export const ScoreTab = forwardRef(
  (
    { label, handicap, showNoBorder = false, className, ...props }: IScoreTab,
    ref
  ) => {
    // TODO: Do we need forwardRef?
    // get isSelected via the `useTab` hook
    // https://chakra-ui.com/docs/disclosure/tabs#creating-custom-tab-components
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps["aria-selected"];

    return (
      <Tab
        // required for ScorecardPresenter on eclectic variant sr-only
        className={`ScoreTab ${className}`}
        {...tabProps}
        // layerStyle={handicap ? "navHeight" : undefined}
        flexDirection="column"
        pos="relative"
        h="60px"
        py={2}
        bg="white"
        _focus={{
          outline: "none",
          boxShadow: "none",
          zIndex: "1",
        }}
        _first={{
          pl: 2,
          pr: 0,
        }}
        _last={{
          pl: 0,
          pr: 2,
        }}
      >
        <Box
          p={2}
          border={showNoBorder ? "none" : "1px solid"}
          borderColor={isSelected ? "text.800" : "transparent"}
          borderRadius="md"
          w="full"
        >
          <Text
            as="div"
            textStyle="metaButton"
            color={isSelected ? "text.800" : "grey.400"}
            fontWeight="600"
            lineHeight="none"
          >
            {label}
          </Text>
          {handicap && (
            <Text
              as="div"
              textStyle="crumb"
              color={isSelected ? "text.800" : "grey.400"}
              fontWeight="400"
              textAlign="center"
              pt="3px"
            >
              Handicap {handicap}
            </Text>
          )}
        </Box>
      </Tab>
    );
  }
);

// name the forwardRef for dev tools
ScoreTab.displayName = "ScoreTab";

import { ECompTotal, EMatchType } from "types";

export function renderCompetitionSortLabels(sortBy: string, showRank: boolean) {
  switch (sortBy) {
    case ECompTotal.ROUND_COUNT:
      return "Rnds";
    case ECompTotal.FIRST_NINE:
      return "Front";
    case ECompTotal.LAST_NINE:
      return "Back";
    case ECompTotal.STROKE_GROSS:
      return "Gross"; // This is the Stableford total
    case ECompTotal.STROKE_NETT:
      return "Nett"; // This is the Stroke total
    default:
      // RANK
      return showRank ? "Rank" : "";
  }
}

export function matchSortByCompToLabel(sortBy: string) {
  switch (sortBy) {
    case EMatchType.stableford:
      return "STFD";
    case EMatchType.par:
      return "PAR";
    case EMatchType.stroke:
      return "NETT";
    default:
      return "";
  }
}

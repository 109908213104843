import * as api from "./api";
import * as formatData from "./formatData";

// Can't set types here because:
// "Type 'IFetchCourses' is not a valid async function return type in ES5/ES3 because it does not refer to a Promise-compatible constructor value.ts(1055)"
// Refrain from type aliasing promises
// interface IFetchCourses {
//   query: string;
//   RESULTS_LIMIT: number;
// }

export async function fetchCourses(
  query = "",
  RESULTS_LIMIT = 20
): Promise<any> {
  if (!query) return [];
  const res: any = await api.getCourses(query);
  // Don't waste resources formatting results which won't be rendered
  return res.json.courses
    .slice(0, RESULTS_LIMIT)
    .map(formatData.formatCourseForRowComponents);
}

import { Form, useFormikContext } from "formik";
import { chakra, Box, Button, Divider, VStack } from "@chakra-ui/react";
import {
  FormInputText,
  FormSelect,
  FormSearchCourse,
  FormSearchPlayer,
  FormSelectDuration,
  FormSelectDays,
  FormElement,
} from "./index";
import { ICreateCompValues, ECreateCompField } from "components/ClubCompCreate";
// import FormMessageErrorApi from "@/components/FormMessageErrorApi";
// import FormMessage from "@/components/FormMessage";
// import { formElements } from "./formElements";

const FormikForm = chakra(Form, {
  baseStyle: {
    py: { base: 4, lg: "w5" },
  },
});

export const FormCompLayout = () => {
  const formik = useFormikContext<ICreateCompValues>();
  // const apiMessageErrorMap = {
  //   EMAIL_TAKEN: {
  //     primary: "An account already exists with this email",
  //     secondary: <Link href="/login">Try signing in</Link>,
  //   },
  // };

  return (
    <FormikForm>
      <VStack
        align="flex-start"
        spacing={{ base: 3, lg: 5 }}
        sx={{
          "> *": {
            w: "full",
          },
        }}
      >
        {/* we could do this to standardise & reuse this form */}
        {/* {fields.map((option: any) => (
            <React.Fragment key={option.name}>
              {formElements(option)}
            </React.Fragment>
          ))} */}
        <FormInputText
          label="Competition name"
          name={ECreateCompField.competitionName}
          placeholder="Enter a competition name…"
        />
        <FormSelect
          name={ECreateCompField.competitionType}
          label="Competition type"
        />
        <FormSearchCourse
          name={ECreateCompField.course}
          label="Course"
          placeholder="Enter a course…"
        />
        <FormSelectDuration name={ECreateCompField.duration} label="Duration" />
        <FormSelectDays name={ECreateCompField.days} label="Days" />

        {/* PLAYERS */}
        {/* <FormElement >
          <Divider opacity="100%" />
        </FormElement> */}
        <FormSearchPlayer
          name={ECreateCompField.players}
          label="Players"
          placeholder="Enter a player's name…"
        />

        {/* CTA */}
        <Box pt={2}>
          <Divider opacity="100%" />
        </Box>
        <FormElement>
          <Box pt="w1" w="full">
            <Button
              fontWeight="500"
              isDisabled={!formik.isValid}
              isLoading={formik.isSubmitting}
              loadingText="Submitting"
              minW={{ base: "full", lg: "full" }}
              type="submit"
              variant="accent"
            >
              Create comp
            </Button>

            {/* {apiError && (
            <Box pt="w2">
            <FormMessageErrorApi messageMap={apiMessageErrorMap} />
            </Box>
          )} */}
          </Box>
        </FormElement>
      </VStack>
    </FormikForm>
  );
};

import {
  Text,
  Flex,
  Button,
  HStack,
  StackDivider,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { MdVerifiedUser } from "react-icons/md";
import { CardHeaderShell } from "./index";
import { PreHeaderMeta } from "components/ClubTable/RowElements/PreHeaderMeta";
import DefaultVStack from "components/VStack";
// import { convertToDisplayText } from "lib/comp";
import { ICompCard } from "types";

/* Only used in Competition */

// const mobileBadgeStyle = {
//   position: "absolute",
//   top: 6,
//   right: 6,
// };

export const CardHeaderComp = ({
  comp,
  userIsHost,
  ownerSettings,
}: {
  comp: ICompCard;
  userIsHost: boolean;
  ownerSettings: React.ReactNode;
}) => {
  const [breakpoint] = useMediaQuery("(min-width: 1280px)");

  return (
    <CardHeaderShell>
      <DefaultVStack spacing={0.5}>
        {/* TITLE */}
        <Text
          as="h2"
          textStyle="hTertiary"
          color="white"
          pl="2px"
          pb={{ base: "1", lg: "1px" }}
        >
          {comp.name}
        </Text>

        {/* COURSE */}
        <HStack
          spacing={4}
          color="white"
          divider={
            breakpoint ? (
              <StackDivider borderColor="currentColor" opacity={0.4} />
            ) : undefined
          }
        >
          <Flex
            textStyle="body"
            color="white"
            fontWeight="400"
            letterSpacing="body"
          >
            {/* Turn this back on when comps are more than just eclectic */}
            {/* <Text as="span" fontWeight="400">
              {convertToDisplayText(comp.type)} competition at{" "}
            </Text> */}
            {comp.course?.clubName}
            {comp.course?.courseName && (
              <Box pl={1.5} opacity={0.7}>
                {comp.course?.courseName}
              </Box>
            )}
          </Flex>
          <Box
            transform={breakpoint ? "translate(-12px, 2px)" : "initial"}
            // Chakra props wants a string not undefined
            // {...(breakpoint ? {} : mobileBadgeStyle)}
            position={breakpoint ? "relative" : "absolute"}
            top={breakpoint ? 0 : 2}
            right={breakpoint ? 0 : 2}
          >
            {userIsHost ? (
              <>{ownerSettings}</>
            ) : (
              // matching the button in SettingsModal, set via ownerSettings
              <Button
                color="white"
                disabled
                iconSpacing="6px"
                size="sm"
                variant="ghostInverse"
                _disabled={{
                  opacity: 1,
                  cursor: "default",
                }}
                rightIcon={<MdVerifiedUser />}
              >
                Member
              </Button>
            )}
          </Box>
        </HStack>

        {/* DETAILS */}
        <PreHeaderMeta
          color="grey.100"
          showBorder={false}
          comp={comp}
        ></PreHeaderMeta>
      </DefaultVStack>
    </CardHeaderShell>
  );
};

import React from "react";
import "typeface-inter";
import { Route, withRouter } from "react-router";
import { NavbarAuth } from "components/Nav";
import { BlockMotion, BlockLoading } from "components/Block2";
import { LayoutRoot } from "./index";
import config from "config";

class AuthLayout extends React.Component {
  constructor(props) {
    super(props);

    this.onAuthorized = this.onAuthorized.bind(this);
  }

  onAuthorized() {
    this.props.history.push("/");
  }

  async componentDidMount() {
    // Check if user is already authed
    const auth = localStorage.getItem(config.localStorageBearerKey);

    if (auth) {
      this.onAuthorized();
    }
  }

  render() {
    const { component: Component, exact, path } = this.props;

    return (
      <Route
        exact={exact}
        path={path}
        render={(matchProps) => (
          <LayoutRoot>
            <BlockLoading />

            {/* NAV: mobile > lg only */}
            <NavbarAuth/>

            {/* MAIN */}
            <BlockMotion>
              <Component {...matchProps} />
            </BlockMotion>
          </LayoutRoot>
        )}
      />
    );
  }
}

export default withRouter(AuthLayout);

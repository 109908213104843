import React from "react";
import { Flex, Button } from "@chakra-ui/react";
import { ConfigRow } from "./index";
import { EConfigRowTheme } from "types";

export const ConfigRowButtonsInline = ({
  btnName,
  btnValue,
  showBtnValid,
  options,
  onChange,
}: {
  btnName: string;
  btnValue: string;
  showBtnValid: boolean;
  options: string[];
  onChange: (e: any) => void;
}) => {
  const [activeOption, setActiveOption] = React.useState(null);

  function handleClick(option) {
    setActiveOption(option);
    onChange(option);
  }

  return (
    <ConfigRow
      isSet
      name={btnName}
      value={btnValue}
      theme={showBtnValid ? EConfigRowTheme.valid : EConfigRowTheme.awaiting}
    >
      <Flex>
        {options.map((option) => (
          <Button
            key={option}
            kind="crumb"
            // variant={isActive ? "solid" : "outline"}
            variant="outline"
            colorScheme="textButton"
            borderColor="text.100"
            px={4}
            pt="1px"
            h="32px"
            w="auto"
            _first={{
              roundedTopRight: "0",
              roundedBottomRight: "0",
            }}
            _last={{
              roundedTopLeft: "0",
              roundedBottomLeft: "0",
              ml: "-1px",
            }}
            _active={{
              bg: "complement.50",
              color: "text.800",
            }}
            isActive={activeOption === option}
            onClick={() => handleClick(option)}
          >
            {option}
          </Button>
        ))}
      </Flex>
    </ConfigRow>
  );
};

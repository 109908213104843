import { Box, Text } from "@chakra-ui/react";
import { TableHeaderStatus } from "components/Block2";
import { ScorecardEclecticPresenterPanel } from "components/Scorecard";
import * as api from "lib/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectors as authSelectors } from "store/actions/authentication";
import { TableHeader, TableNotice, TableSearchCourse } from "./index";

/*
  Unlike other tables, the Eclectic Round displays a round scorecard, not a list of rounds. So it is much close to the Scoring flow's Scorecard component.
  But is has no marker — it's just for the user.
 */

export const TableEclecticRound = ({
  tableHeading = "Your Eclectic Round",
  noRoundsMessage = "You haven't played any rounds on this course.",
}: {
  tableHeading?: string;
  noRoundsMessage?: string;
}) => {
  // get user via store to avoid types against `undefined`…
  const user = useSelector(authSelectors.getUser);
  const userHomeCourse = user.homeCourse.id;
  const [courseId, setCourseId] = useState<number>(userHomeCourse);
  // Set the Course. Why not set `user.homeCourse` as the default here? Because it's an abbreviated course object. We need the full course object.
  const [selectedCourse, setSelectedCourse] = useState(undefined);

  const { data, error } = api.useGetEclecticRound(courseId);

  // Set the initial course to the users home course
  useEffect(() => {
    async function getUsersCourse() {
      try {
        const response = await api.getCourse(courseId);
        const { course } = response.json;
        setSelectedCourse(course);
      } catch (e) {
        console.error("Error fetching users home course:", e);
      }
    }

    getUsersCourse();
  }, [user.homeCourse.id, courseId]);

  // Set the course from a search
  useEffect(() => {
    async function getCourse() {
      // Only run after page has loaded initial course
      if (!data) return;

      try {
        const response = await api.getCourse(courseId);
        const { course } = response.json;
        setSelectedCourse(course);
      } catch (e) {
        console.error("Error fetching course:", e);
      }
    }

    getCourse();
  }, [courseId, data]);

  if (error)
    return (
      <TableHeaderStatus tableHeading={tableHeading}>
        <Text textStyle="body">Got an error here. Please contact support.</Text>
      </TableHeaderStatus>
    );

  // We originally used !data to await it before using it…
  // …but that causes the course search to unmount and so it's input value reverts back to it's default (the users home course)
  // See B's note in LG-237 or: https://github.com/callumflack/local-golf-app/pull/72/commits/9c84a769617a0fcb1d623db8403498804be3e5a0
  // Also noted in LG-246
  // if (!data)
  //   return <TableHeaderStatus showLoader tableHeading={tableHeading} />;

  // Don't destructure to avoid managing !data
  // const { eclecticRound } = data;

  // TESTS
  // console.log("user", user);
  // console.log("useGetEclecticRound data", data?.eclecticRound);

  return (
    <Box as="section">
      <TableHeader
        heading={tableHeading}
        mobileStackDirection="column"
        showDivider
        leftSearch={
          <TableSearchCourse
            onSelect={(course) => setCourseId(course.id)}
            name="course"
            label="Course"
            placeholder="Search for another course…"
          />
        }
      />

      {data?.eclecticRound && selectedCourse ? (
        <ScorecardEclecticPresenterPanel
          course={selectedCourse!}
          scores={data?.eclecticRound.holeScores}
          scoreTotals={data?.eclecticRound.totals}
        />
      ) : (
        <TableNotice mt="w3">{noRoundsMessage}</TableNotice>
      )}
    </Box>
  );
};

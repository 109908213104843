import arrayOfRange from "../lib/arrayOfRange";

/**
 * Shorthand to create an enum object
 * Named enu because enum is a reserved keyword in JS
 *
 * @example
 * // returns {
 * //   PRIMARY: "PRIMARY",
 * //   SECONDARY: "SECONDARY",
 * // }
 * enu(["PRIMARY", "SECONDARY"]);
 */
const enu = (arr) => {
  const object = arr.reduce(
    (acc, item) => ({
      ...acc,
      [item]: item,
    }),
    {}
  );
  return Object.freeze(object);
};

// USER
export const GENDERS = enu(["MALE", "FEMALE"]);

// MATCH
export const PLAY_TYPES = enu(["COMPETITION", "SOCIAL"]);
export const MATCH_TYPES = enu(["STROKE", "STABLEFORD", "PAR"]);
export const TEES = enu(["PRO", "CHAMPIONSHIP", "STANDARD"]);
export const COMPETITION_TYPES = enu(["ECLECTIC", "RACE_TO_THE_TOP"]);
export const COMPETITION_DAYS = enu([
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]);

export const NO_SCORE = "No Score";

export const possibleStrokes = {
  [MATCH_TYPES.STROKE]: () => arrayOfRange(12),
  [MATCH_TYPES.PAR]: () => [...arrayOfRange(12), NO_SCORE],
  [MATCH_TYPES.STABLEFORD]: () => [...arrayOfRange(12), NO_SCORE],
};

// INVITE
export const INVITE_STATES = enu(["ACCEPTED", "DENIED", "PENDING"]);

// Show to user in friendly format
// Send to server in constants format
const capitalize = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
export const constantToFriendly = (value) => capitalize(value);
export const friendlyToConstant = (value) => value.toUpperCase();

// HELPERS
export const HOLES = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
];

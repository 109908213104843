import { Box, useMediaQuery } from "@chakra-ui/react";
import { NavbarShell } from "./index";
import { LgLogo } from "components/Block";

export const NavbarAuth = () => {
  // make the DOM clean: only render when needed
  // 1024 = breakpoints.lg
  const [render] = useMediaQuery("(max-width: 1023px)");

  if (!render) {
    return null;
  }

  return (
    <NavbarShell>
      {/* double containerNav on purpose */}
      <Box px={{ base: 3, lg: 6 }} color="text.800">
        <LgLogo height={32} />
      </Box>
    </NavbarShell>
  );
};

import { Text } from "@chakra-ui/react";
import { TextMeta } from "./index";

export const Handicap = ({
  value,
  label,
}: {
  value: number;
  label?: string;
}) => (
  <TextMeta>
    {value}
    {label && (
      <Text as="span" ml={1}>
        {label}
      </Text>
    )}
  </TextMeta>
);

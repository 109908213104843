import { Link } from "@chakra-ui/react";
import config from "config";

export const NavbarButtonSmall = ({
  href = config.brandUrl,
  label = "About YLG",
  tiny = false,
}: {
  href?: string;
  label: string;
  tiny?: boolean;
}) => (
  <Link
    isExternal
    target="_blank"
    href={href}
    pt="1px"
    fontSize={tiny ? "10px" : "12px"}
    fontWeight={tiny ? "400" : "500"}
    h={tiny ? "18px" : "48px"}
    color={tiny ? "primary.400" : "text.600"}
  >
    {label}
  </Link>
);

import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { Flex, Box, Spinner, Button, Text } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import * as api from "lib/api";

const VerifyingMessage = () => {
  return (
    <>
      <Box textAlign="center">
        <Spinner color="text.800" speed="0.7s" thickness="4px" size="xl" />
      </Box>
      <Text texStyle="hTertiary" textAlign="center" color="text.800" pt={4}>
        Verifying
      </Text>
    </>
  );
};

const ErrorMessage = (props) => {
  return (
    <>
      <Box textAlign="center">
        <WarningIcon color="text.800" w="36px" h="36px" />
      </Box>
      <Box pt={4} textAlign="center">
        <Text kind="hMinor" color="text.800">
          {props.message}
        </Text>
        <Link to="/resend-verification-email">
          <Text textStyle="body" pt={2} pb={5} color="blackAlpha.600">
            Send another email verification?
          </Text>
          <Button
            colorScheme="textButton"
            variant="outline"
            isLoading={props.sending}
            loadingText="Sending"
          >
            Send again
          </Button>
        </Link>
      </Box>
    </>
  );
};

// Set error messages for the returned error codes here
function getErrorMessageFromResponse(error) {
  /* eslint-disable indent */
  switch (error.code) {
    case "INVALID_TOKEN":
      return "Invalid email verification token";
    case "ALREADY_VERIFIED":
      return "Your email has already been verified";
    default:
      return "Email verification failed";
  }
  /* eslint-enable indent */
}

const VerifyEmailPage = ({ error, ...props }) => {
  const query = useQuery();
  const token = query.get("token");
  const history = useHistory();
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    async function sendVerifyEmailAddress() {
      if (!token) {
        setErrorMessage("Email verification token is missing");
        return;
      }

      const response = await api.verifyEmail(token);
      if (response?.error) {
        setErrorMessage(getErrorMessageFromResponse(response.error));
        return;
      }

      history.push("/signin?account-verified=true");
    }

    sendVerifyEmailAddress();
  }, [history, token]);

  return (
    <>
      <Flex flex="1" direction="column" align="center" justify="center" mx={6}>
        {errorMessage ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <VerifyingMessage />
        )}
      </Flex>
    </>
  );
};

export default VerifyEmailPage;

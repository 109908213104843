import { Router, Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
// https://dev.to/joserfelix/page-transitions-in-react-1c8g
// https://www.framer.com/docs/animate-presence/
// import { AnimatePresence, motion } from "framer-motion";
import history from "./history";
import DefaultLayout from "components/Layout/DefaultLayout";
import AuthLayout from "components/Layout/AuthLayout";
import Login from "pages/Login";
import Register from "pages/Register";
import VerifyEmail from "pages/VerifyEmail";
import ResendVerificationEmail from "pages/ResendVerificationEmail";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import Home from "pages/Home";
import CreateLobby from "pages/CreateLobby";
import Lobby from "pages/Lobby";
import LobbyInvited from "pages/LobbyInvited";
import JoinLobby from "pages/JoinLobby";
import Score from "pages/Score";
import Scorecard from "pages/Scorecard";
import { AccountPage } from "pages/Account";
import { ClubhousePage } from "pages/Clubhouse";
import { ClubhouseLeaderboard } from "pages/ClubhouseLeaderboard";
import { ClubhouseCompetitionsPage } from "pages/ClubhouseCompetitions";
import { ClubhouseCompetitionPage } from "pages/ClubhouseCompetition";
import { CreateCompetition } from "pages/CreateCompetition";
import { ClubhouseYourHistory } from "pages/ClubhouseYourHistory";
import { ClubhouseYourEclecticRound } from "pages/ClubhouseYourEclecticRound";
import { ClubhouseYourHistoryUnsigned } from "pages/ClubhouseYourHistoryUnsigned";

const Routes = (props) => (
  <Router history={history}>
    <Route
      render={({ location }) => (
        <Switch location={location} key={location.pathname}>
          <AuthLayout exact showFixed path="/signin" component={Login} />
          <AuthLayout exact showFixed path="/signup" component={Register} />
          <AuthLayout
            exact
            showFixed
            path="/verify-email"
            component={VerifyEmail}
          />
          <AuthLayout
            exact
            showFixed
            path="/resend-verification-email"
            component={ResendVerificationEmail}
          />
          <AuthLayout
            exact
            showFixed
            path="/forgot-password"
            component={ForgotPassword}
          />
          <AuthLayout
            exact
            showFixed
            path="/reset-password"
            component={ResetPassword}
          />
          <DefaultLayout
            exact
            showFixed
            allowInvite
            navButtonIsWhite
            path="/"
            component={Home}
          />
          <DefaultLayout
            exact
            showFixed
            allowInvite
            navButtonIsWhite
            path="/account"
            component={AccountPage}
          />
          <DefaultLayout
            exact
            showFixed
            allowInvite
            showDeleteLobbyModal
            path="/create-lobby"
            component={CreateLobby}
          />
          <DefaultLayout
            exact
            showFixed
            allowInvite
            showDeleteLobbyModal
            path="/lobby"
            isMatchPage
            component={Lobby}
          />
          <DefaultLayout
            exact
            showFixed
            allowInvite
            showDeleteLobbyModal
            path="/lobby-invitation"
            isMatchPage
            component={LobbyInvited}
          />
          <DefaultLayout
            exact
            showFixed
            showDeleteLobbyModal
            path="/join-lobby"
            isMatchPage
            component={JoinLobby}
          />
          <DefaultLayout
            exact
            showFixed
            showDeleteRoundModal
            path="/score/:hole"
            isMatchPage
            component={Score}
          />
          <DefaultLayout
            exact
            navButtonIsWhite
            path="/:matchId/scorecard"
            isMatchPage
            allowInvite
            component={Scorecard}
          />
          <DefaultLayout exact path="/clubhouse" component={ClubhousePage} />
          <DefaultLayout
            exact
            path="/clubhouse/leaderboard"
            component={ClubhouseLeaderboard}
          />
          <DefaultLayout
            exact
            path="/clubhouse/your-rounds-history"
            component={ClubhouseYourHistory}
          />
          <DefaultLayout
            exact
            path="/clubhouse/your-rounds-unsigned"
            component={ClubhouseYourHistoryUnsigned}
          />
          <DefaultLayout
            exact
            path="/clubhouse/your-eclectic-round"
            component={ClubhouseYourEclecticRound}
          />
          <DefaultLayout
            exact
            path="/clubhouse/competitions"
            component={ClubhouseCompetitionsPage}
          />
          <DefaultLayout
            exact
            path="/clubhouse/new-competition"
            component={CreateCompetition}
          />
          <DefaultLayout
            exact
            path="/clubhouse/competitions/:competitionId"
            component={ClubhouseCompetitionPage}
          />
          <Redirect exact path="*" to="/" />
        </Switch>
      )}
    />
  </Router>
);

export default Routes;

import { useState } from "react";
import Select from "react-select";
import { FormElement, FormLabel } from "./index";
import { useField, useFormikContext } from "formik";
import { chakra } from "@chakra-ui/react";

// react-select
// https://react-select.com/props#components
// Styling: https://blog.logrocket.com/getting-started-with-react-select/

// style the react-select
// see: https://react-select.com/home
// const ChSelect = chakra(Select, {
//   baseStyle: {
//     ...Input.baseStyle.field,
//     // h: { base: "40px", lg: "48px" },
//   },
// });
const ChSelect = chakra(Select, {});

export const FormSelectDays = ({
  label,
  ...props
}: {
  label: string;
  [x: string]: any;
}) => {
  const [field] = useField(props);
  const formikProps = useFormikContext();
  const [large, setLarge] = useState(false);

  // https://react-select.com/styles#select-props
  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#DDDCDA",
      borderRadius: "3px",
      height: large ? "70px" : "48px",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#B1AEAA",
      fontSize: "15px",
      marginLeft: "7px",
    }),
  };

  return (
    <FormElement
      className="CompDuration"
      label={<FormLabel>{label}</FormLabel>}
    >
      {/* <InputSelect
        id={field.name}
        options={[
          { label: "Monday", value: 1 },
          { label: "Tuesday", value: 2 },
          { label: "Wednesday", value: 3 },
          { label: "Thursday", value: 4 },
          { label: "Friday", value: 5 },
          { label: "Saturday", value: 6 },
          { label: "Sunday", value: 7 },
        ]}
        onChange={(event: any) => {
          //console.log("event = ", event)
          let daysToSaveInDB = [];
          for (let day in event) {
            daysToSaveInDB.push(event[day].label);
          }
          //console.log("daysToSaveInDB = ", daysToSaveInDB)
          formikProps.setFieldValue("days", daysToSaveInDB);
        }}
      /> */}
      <ChSelect
        id={field.name}
        // h={{ base: "40px", lg: "48px" }}
        // control="48px"
        styles={customStyles}
        isMulti
        options={[
          { label: "Monday", value: 1 },
          { label: "Tuesday", value: 2 },
          { label: "Wednesday", value: 3 },
          { label: "Thursday", value: 4 },
          { label: "Friday", value: 5 },
          { label: "Saturday", value: 6 },
          { label: "Sunday", value: 7 },
        ]}
        onChange={(event: any) => {
          let daysToSaveInDB = [];
          for (let day in event) {
            daysToSaveInDB.push(event[day].label);
          }
          // console.log("daysToSaveInDB = ", daysToSaveInDB);
          if (daysToSaveInDB.length > 3) {
            setLarge(true);
          } else {
            setLarge(false);
          }
          formikProps.setFieldValue("days", daysToSaveInDB);
        }}
      />
    </FormElement>
  );
};

import { Box, Text } from "@chakra-ui/react";
import { DeleteRoundModal } from "components/Notices";
import { ConfigRow } from "components/Config";
import * as constants from "config/constants";
import { EConfigRowTheme } from "types";

interface IRoundSettingsList {
  course: string;
  matchType: string;
  tee: string;
  handicap: number | string;
  marker: string;
  markersHandicap: number;
}

// Requires a flex parent
export const RoundSettingsList = ({
  course,
  matchType,
  tee,
  handicap,
  marker,
  markersHandicap,
}: IRoundSettingsList) => (
  <>
    {/* <LgHeadingModal mb={3}>Round settings</LgHeadingModal> */}
    <Box
      bg="complement.50"
      pt={16}
      pb={4}
      px={4}
      mx={-6}
      mb={3}
      className="RoundSettingsList"
    >
      <Text as="h2" textStyle="hSecondary" px={2}>
        Round settings
      </Text>
    </Box>
    <ConfigRow isSet isValid name="Course" value={course} />
    <ConfigRow
      isSet
      theme={EConfigRowTheme.valid}
      name="Competition"
      value={constants.constantToFriendly(matchType)}
    />
    <ConfigRow isSet theme={EConfigRowTheme.valid} name="Tee" value={tee} />
    <ConfigRow
      isSet
      theme={EConfigRowTheme.valid}
      name="Your Round Handicap"
      value={handicap}
    />
    {marker && (
      <>
        <ConfigRow
          isSet
          theme={EConfigRowTheme.valid}
          name="Your Marker"
          value={marker}
        />
        <ConfigRow
          isSet
          theme={EConfigRowTheme.valid}
          name="Marker's Round Handicap"
          value={markersHandicap}
        />
      </>
    )}
    <DeleteRoundModal />
  </>
);

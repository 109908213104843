import {
  Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import { ScoreSheet, RoundSettingsList } from "./index";
import { ScoreTabs, ScoreTabsFrame } from "../ScoreTabs";
import { LgModalInner, LgModalCloseButton } from "../Block";
import { ProgressiveScorecardFooter } from "./ProgressiveScorecardFooter";
import { MdSettings } from "react-icons/md";
// TODO?
// import {
//   useScorecardContext,
//   useScoreContext,
//   useHoleStatsContext,
// } from "components/Context";
interface IProgressiveScoreCard {
  course: string;
  handicap: number;
  marker: string;
  markersHandicap: number;
  matchType: string;
  tee: string;
}

// COMPONENT: RoundModal (Scorecard + Settings)
export const ProgressiveScoreCard = ({
  course,
  handicap,
  marker,
  markersHandicap,
  matchType,
  tee,
}: IProgressiveScoreCard) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex justify="center">
        <Box>
          <Button
            size="md"
            variant="ghost"
            colorScheme="textButton"
            px={3}
            zIndex="3"
            rightIcon={<MdSettings />}
            onClick={onOpen}
            sx={{
              "& .chakra-button__icon": {
                mt: "-1px",
                ml: "4px",
              },
            }}
          >
            Scorecard
          </Button>
        </Box>
      </Flex>

      <Modal size="full" isOpen={isOpen} onClose={onClose} motionPreset="scale">
        <ModalOverlay />
        <ModalContent
          m={0}
          bg="transparent"
          shadow="none"
          height="full"
          minHeight="full"
        >
          <LgModalInner>
            <LgModalCloseButton />
            {/* 2 x TABS: PROGRESS SCORECARD + SETTINGS */}
            <ScoreTabs overflowY="auto" flex={1}>
              <ScoreTabsFrame footerTabList={<ProgressiveScorecardFooter />}>
                {/* 1st TAB: ROUND PROGRESS SCORECARD
                (this also contains 2 x tabs) */}
                <ScoreSheet matchType={matchType} />

                {/* 2nd TAB: ROUND SETTINGS */}
                <RoundSettingsList
                  course={course}
                  matchType={matchType}
                  tee={tee}
                  handicap={handicap}
                  marker={marker}
                  markersHandicap={markersHandicap}
                />
              </ScoreTabsFrame>
            </ScoreTabs>
          </LgModalInner>
        </ModalContent>
      </Modal>
    </>
  );
};

import { Text, VStack, Tag, TagLabel } from "@chakra-ui/react";

export const DisplayAddedPlayers = ({
  playerNames = [],
}: {
  playerNames: unknown;
}) => {
  //console.log("DisplayAddedPlayers playerNames = ", playerNames)
  let displayPlayerNames = playerNames.map((playerName, index) => {
    return (
      <Tag
        size="md"
        key={index}
        borderRadius="full"
        variant="solid"
        colorScheme="blackAlpha"
      >
        <TagLabel>{playerName}</TagLabel>
      </Tag>
    );
  });

  return (
    <div>
      <Text textStyle="copy">Added Players</Text>
      <VStack spacing={4}>{displayPlayerNames}</VStack>
    </div>
  );
};

import { Button, Box, useDisclosure } from "@chakra-ui/react";
import { DeleteModal } from "./index";

export const DeleteCompModal = ({
  onClick,
  isDeleting,
}: {
  onClick: () => void;
  isDeleting: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box pt="w1">
      <Button
        variant="outline"
        size="lg"
        colorScheme="formbad"
        h="32px"
        fontWeight="400"
        borderRadius="2px"
        justifyContent="space-between"
        // transition="all 0.4s cubic-bezier(.08,.52,.52,1)"
        onClick={onOpen}
        _hover={{
          bg: "formbad.300",
        }}
      >
        Delete this competition
      </Button>
      <DeleteModal
        heading="Delete competition"
        confirm="Are you sure? There's no turning back from this…"
        buttonLabel="Delete"
        isOpen={isOpen}
        onClose={onClose}
        onClick={onClick}
        isDeleting={isDeleting}
      />
    </Box>
  );
};

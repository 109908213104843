import {
  FETCH_COMPETITION_PREVIEWS_ERROR,
  FETCH_COMPETITION_PREVIEWS
} from "../actions/competition";

let cloneObject = obj => {
  return JSON.parse(JSON.stringify(obj));
};

const initialState = {
  fetchError: false,
  competitions: []
};

function lobby(state = initialState, action) {
  let newState = cloneObject(state);
  switch (action.type) {
    case FETCH_COMPETITION_PREVIEWS:
      newState.competitions = action.competitions;
      return newState;
    case FETCH_COMPETITION_PREVIEWS_ERROR:
      newState.fetchError = true;
      return newState;
    default:
      return state;
  }
}

export default lobby;
